/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Copy
import copy from "clipboard-copy";

// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// Manzia
import { WebAppDomain } from "appSettings";

// Constants
const { grey } = colors;
const { size } = typography;
const LoaderUrl = `https://${WebAppDomain}`;

function BotCode({ webDomain }) {

  const [copied, setCopied] = useState(false);
  const webDomainPath = webDomain.replaceAll("-", "_").replaceAll(".", "-");

  const copyClipboard = () => {
    const copyText = document.getElementById("manzia-iframe-snippet");
    copy(copyText.innerText);
    setCopied(true);
  }

  function renderScriptTags() {
    const baseUrl = `${LoaderUrl}/${webDomainPath}/`;
    const loaderScriptUrl = `${baseUrl}lex-web-ui-loader.min.js`;
    const exitDetectorScript = `${baseUrl}manzia-exit-detector.min.js`;
    const scripts = `
        <script src="${loaderScriptUrl}" defer></script>
        <script src="${exitDetectorScript}" defer></script>
     `;
     return (
        <MDBox mb={2}>
            <MDTypography 
              id="manzia-iframe-snippet"
              p={3} 
              sx={{ backgroundColor: grey[100], fontSize: size.md, overflowWrap: "break-word" }}
              >
                {scripts}
            </MDTypography>
        </MDBox>
        
    );
  }

  const renderedScripts = renderScriptTags();

  return (
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={10}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="body2" color="text">
                Chatbot code snippet:
              </MDTypography>
              <MDBox display="flex" alignItems="center">
                <IconButton size="small" aria-label="copy tag" color="inherit" onClick={copyClipboard}>
                  <Icon fontSize="small">content_copy</Icon>
                </IconButton>
                <MDTypography variant="caption" color="success">
                  {copied ? "Copied" : ""}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={10}>
              {renderedScripts}
          </Grid>
        </Grid>
  );
}

BotCode.propTypes = {
  webDomain: PropTypes.string.isRequired
}

export default BotCode;
