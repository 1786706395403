/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import * as Yup from "yup";
import form from "layouts/train/new-product/schemas/form";

/* eslint-disable */
const illegalChars = /^[a-zA-Z0-9,\.\-'"\?!()\s]*$/;
const fourAndIllegalChars = /^\s*(?:[\w,'.\-\?!]+\s+){4,}[\w,'.\-\?!]+\s*$/;
/* eslint-enable */

const {
  formField: { productName, problems, features, productDescription, insights },
} = form;


const validations = 
  Yup.object().shape({
    [productName.name]: Yup.string().matches(illegalChars, productName.illegalChars).required(productName.errorMsg),
    [productDescription.name]: Yup.string().matches(fourAndIllegalChars, productDescription.invalidMsg).required(productDescription.errorMsg),
    [problems.name]: Yup.array().of(Yup.string().required(problems.errorMsg)).min(1, problems.errorMsg).max(10, problems.invalidMsg),
    [insights.name]: Yup.array().of(Yup.object().shape({
      [insights.companySize.name]: Yup.string().matches(illegalChars, insights.companySize.invalidMsg).required(insights.companySize.errorMsg),
      [insights.industry.name]: Yup.string().matches(illegalChars, insights.industry.invalidMsg).required(insights.industry.errorMsg),
      [insights.type.name]: Yup.string().matches(illegalChars, insights.type.invalidMsg).required(insights.type.errorMsg),
      [insights.jobTitle.name]: Yup.string().matches(illegalChars, insights.jobTitle.invalidMsg).required(insights.jobTitle.errorMsg),
      [insights.role.name]: Yup.string().matches(illegalChars, insights.role.invalidMsg).required(insights.role.errorMsg),
      [insights.responsibility.name]: Yup.string().matches(illegalChars, insights.responsibility.invalidMsg).required(insights.responsibility.errorMsg),
      [insights.useCase.name]: Yup.string().matches(illegalChars, insights.useCase.invalidMsg).required(insights.useCase.errorMsg),
    })).min(1, insights.errorMsg).max(4, insights.invalidMsg),
    // [insights.name]: Yup.array().of(Yup.string().required(insights.errorMsg)).min(1, insights.errorMsg).max(10, insights.invalidMsg),
    [features.name]: Yup.array().of(Yup.object().shape({
      [features.productFeature.name]: Yup.string().matches(illegalChars, features.productFeature.invalidMsg).required(features.productFeature.errorMsg),
      [features.productBenefit.name]: Yup.string().matches(illegalChars, features.productBenefit.invalidMsg).required(features.productBenefit.errorMsg),
      [features.productAdvantage.name]: Yup.string().matches(illegalChars, features.productAdvantage.invalidMsg),
      [features.featureLink.name]: Yup.string().url(features.featureLink.invalidMsg),
    })).min(1, features.errorMsg).max(10, features.invalidMsg),
  });

export default validations;
