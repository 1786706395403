/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useContext } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
// import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// GraphQL
import { gql, useMutation } from '@apollo/client';
// import * as mutations from 'graphql/mutations';
import createEmailInvites from "layouts/settings/components/CreateEmail/invitemutation";

// Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// Manzia
import NotificationView from "layouts/common/Notification";
import LoadView from "layouts/common/LoadView";
import { OnboardRole } from "appSettings";
import { verifyEmail } from "helpers";

function CreateEmail({ email, emailRoles, onComplete, messageId }) {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { account: { id, organizationName, v2chatBotId } } 
    = useLocalChatbotUser({ userId: USERID });

  
  const [createEmailInvite, { data: inviteData, loading: inviteLoading, 
    error: inviteError }] = useMutation(gql`${createEmailInvites(email, {
      accountId: id,
      organizationName,
      emailRoles,
      manziaChatbotId: v2chatBotId,
      senderUserId: USERID,
      messageId
    })}`);
  
  const validEmails = (emails) => {
    if (!emails || emails.length === 0) return false;
    const emailArr = emails.split(",");
    const valid = emailArr.reduce((memo, mail) => memo && verifyEmail(mail.trim()), true);
    return valid;
  }

  useEffect(() => {
    if (!validEmails(email)) { return; }
    // Create email invites
    createEmailInvite();
  }, [email]);

  useEffect(() => {
    if (!inviteData) return;
    onComplete(inviteData);
  }, [inviteData]);

  return (
      <MDBox>
          {inviteLoading && <LoadView />}
          {(inviteError) && <NotificationView 
            color="error"
            message={`Error sending to email(s): ${email}. Please retry later.`}
          />}
          {(inviteData) && <NotificationView 
            color="success"
            message={`Email was successfully sent to ${email}.`}
          />}
      </MDBox>
  );
}

CreateEmail.propTypes = {
  onComplete: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  emailRoles: PropTypes.shape({
    [OnboardRole.TEAM_MEMBER]: PropTypes.arrayOf(PropTypes.string),
    [OnboardRole.DEVELOPER]: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  messageId: PropTypes.string.isRequired
}

export default CreateEmail;
