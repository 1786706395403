/* eslint-disable */
export const createManziaProductWithTraining = `
  mutation CreateManziaUserProductTraining(
    $productInput: CreateManziaUserProductInput!
    $productCondition: ModelManziaUserProductConditionInput
    $trainingInput: CreateManziaProductTrainingInput!
    $trainingCondition: ModelManziaProductTrainingConditionInput
  ) {
    a1: createManziaUserProduct(input: $productInput, condition: $productCondition) {
        id
        productName
        productImage
        productType
        trainingStatus
        createdByUserId
        trainedChatBotId
        deleteTime
        createdAt
        updatedAt
        __typename
    },
    a2: createManziaProductTraining(input: $trainingInput, condition: $trainingCondition) {
        id
        userProductId
        trainingData
        trainedByUserId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        createdAt
        updatedAt
        __typename
    }
  }
`;

export const updateManziaProductWithTraining = `
  mutation UpdateManziaUserProductTraining(
    $productInput: UpdateManziaUserProductInput!
    $productCondition: ModelManziaUserProductConditionInput
    $trainingInput: UpdateManziaProductTrainingInput!
    $trainingCondition: ModelManziaProductTrainingConditionInput
  ) {
    a1: updateManziaUserProduct(input: $productInput, condition: $productCondition) {
        id
        productName
        productImage
        productType
        trainingStatus
        createdByUserId
        trainedChatBotId
        deleteTime
        createdAt
        updatedAt
        __typename
    },
    a2: updateManziaProductTraining(input: $trainingInput, condition: $trainingCondition) {
        id
        userProductId
        trainingData
        trainedByUserId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        createdAt
        updatedAt
        __typename
    }
  }
`;
