/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

* Coded by www.manzia.com

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useMemo, useEffect, useContext } from "react";

import PropTypes from "prop-types";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDBadge from "components/MDBadge";
import MDAlert from "components/MDAlert";

// Moustache
import Mustache from "mustache";

// Auth
import { AuthUserContext } from "App";

// Account
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";
// import usePrevious from "customhooks/usePrevious";
import useLazyAxios from "customhooks/useLazyAxios";

// S3
// import useS3Client from "customhooks/useS3Client"
// import useLazyS3Client from "customhooks/useLazyS3Client";
import { botStyleTemplates, WebAppDomain } from "appSettings";

// Manzia
import ColorField from "layouts/settings/components/ColorField";
import InputField from "layouts/settings/components/InputField";
import PublishStyles from "layouts/settings/components/PublishStyles";
import initialValues from "layouts/settings/schemas/initialValues";
import validations from "layouts/settings/schemas/validations";
import form from "layouts/settings/schemas/form";
import { cssToJson, getHexColorCode, extractNumber, decodeHtmlEntities } from "helpers";

// State Views
import LoadView from "layouts/common/LoadView";
// import ErrorView from "layouts/common/ErrorView";
import NoDataView from "layouts/common/NoDataView";

// Templates
import webuiTemplate from "layouts/settings/templates/webuiTemplate";


// Constants
// const USERID = "20966bf1-2054-49ca-9328-7f43b8f8c65b";
// const WebAppDomain = "web.manzia.com";
// WebAppDomain}}/{{WebAppDomainPath

/**
 * Steps:
 * 
 * 3- append custom css styles as inline styles in webui template
 * 4- Sidenav Formik form to capture user css inputs as json
 * 4a - Use current css values as initial values of Formik form.
 * 5- On refresh, render the webui template as HTML using Moustache
 * 6- Display the rendered HTML as srcdoc attribute of iFrame
 * 7- On publish, pass form values as input to custom css template
 * 8- Render the custom css styles using Moustache
 * 9- Upload the custom css styles to S3
 * 10- Invalidate the custom css file in CloudFront
 */

/* eslint-disable func-names */
Mustache.escape = function(text) {return text;};
/* eslint-enable func-names */

function BotAppearanceManager() {
  // Account webdomain
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { account: { webDomain } } = useLocalChatbotUser({ userId: USERID });
  
  // Fetch current custom css styles from S3
  const WebAppDomainPath = webDomain.replaceAll("-", "_").replaceAll(".", "-");

  const { loading: currentStyleLoading, error: currentStyleError, 
    value: currentStyles, fetch: fetchStyles } = useLazyAxios();

  const { loading: configLoading, error: configError, value: configData, 
    fetch: fetchConfig } = useLazyAxios();

  // Fetch custom styles
  useEffect(() => {
    fetchStyles()({
      method: 'get',
      url: `https://${WebAppDomain}/${WebAppDomainPath}/${botStyleTemplates.currentCssStyles}`,
      responseType: 'text',
      timeout: 10000,
      maxRedirects: 0, 
    });
  }, []);

  // Fetch config
  useEffect(() => {
    fetchConfig()({
      method: 'get', 
      url: `https://${WebAppDomain}/${WebAppDomainPath}/${botStyleTemplates.configKey}`,
      timeout: 10000,
      maxRedirects: 0, 
    });
  }, []);
  
    // Manage State
  if (currentStyleLoading || configLoading) return ( <LoadView /> );
  // if (currentStyleError) return ( <ErrorView error={{ message: `${currentStyleError}` }} /> );

  if (currentStyleError || !currentStyles) {
    return (
      <Card id="bot-appearance" sx={{ overflow: "visible" }}>
        <NoDataView info={{ title: "Missing chatbot styles", message: `Missing css styles. Cannot display chatbot style options. Please retry later.`}} />
      </Card>
    );
  }

  if (configError || !configData) {
    return (
      <Card id="bot-appearance" sx={{ overflow: "visible" }}>
          <NoDataView info={{ title: "Missing chatbot configuration", message: `Missing chatbot configuration. Cannot render chatbot UI. Please retry later.`}} />
      </Card>
    );
  }

  return (
    <BotAppearance 
      currentStyles={currentStyles}
      webDomain={webDomain}
    />
  );
}


function BotAppearanceView({ templateValues, webDomain }) {
  const WebAppDomainPath = webDomain.replaceAll("-", "_").replaceAll(".", "-");
  const botHtml = Mustache.render(webuiTemplate, { ...templateValues, WebAppDomain, WebAppDomainPath, webDomain });
  // console.log("Render HTML: ", botHtml);

  return (
    <MDBox display='flex' justifyContent='center'>
      <iframe
        title="Bot Styles" 
        width="90%" 
        height="640" 
        srcDoc={botHtml} 
        frameBorder="0" 
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen 
      />
    </MDBox>
  );
}

function BotAppearance({ currentStyles, webDomain }) {
  
  const { formId, formField: { toolbar, messagelistcontainer, messagebubble, 
    cardtitle, cardactions } } = form;
  const { themedark, title } = toolbar;
  const { messagebot, messagehuman, messagetext } = messagebubble;
  const { cardtitleprimary } = cardtitle;
  const { btn } = cardactions;

  // Upload
  // const { loading: upLoading, error: uploadError, data: uploadData, runCommand: uploadStyles } 
  //   = useLazyS3Client(awsCommands.s3.putObject);

  function getInitialValues(styles) {
      const currentJson = cssToJson(styles);
      // console.log("Current styles: ", currentJson);
      const initValues = { ...initialValues };
      // Iterate
      const jsonEntries = Object.entries(currentJson);

      for (let i=0; i<jsonEntries.length; i += 1) {
        const [key, value] = jsonEntries[i];
        // console.log(`${key}: ${value}`);
        switch (key) {
          case '.toolbar.theme--dark':
            initValues[toolbar.name][themedark.name][themedark.backgroundColor.name] 
              = getHexColorCode(value['background-color'])
            break;
          case '.toolbar__title':
            initValues[toolbar.name][title.name][title.fontFamily.name] 
              = `${decodeHtmlEntities(value['font-family'].replaceAll('!important;', "").trim())}`;
            initValues[toolbar.name][title.name][title.color.name] 
              = getHexColorCode(value.color);
            initValues[toolbar.name][title.name][title.fontSize.name] 
              = extractNumber(value['font-size']) ?? 1.875;
            break;
          case '.message-list-container':
            initValues[messagelistcontainer.name][messagelistcontainer.backgroundColor.name]
              = getHexColorCode(value['background-color']);
            break;
          case '.message-bubble .message-text':
            initValues[messagebubble.name][messagetext.name][messagetext.fontSize.name]
              = extractNumber(value['font-size']) ?? 1;
            break;
          case '.message-bot .message-bubble':
            initValues[messagebubble.name][messagebot.name][messagebot.backgroundColor.name]
              = getHexColorCode(value['background-color']);
            break;
          case '.message-human .message-bubble':
            initValues[messagebubble.name][messagehuman.name][messagehuman.backgroundColor.name]
              = getHexColorCode(value['background-color']);
            break;
          case '.message-text':
            initValues[messagebubble.name][messagetext.name][messagetext.color.name]
              = getHexColorCode(value.color);
            break;
          case '.card__title.card__title--primary':
            initValues[cardtitle.name][cardtitleprimary.name][cardtitleprimary.backgroundColor.name]
             = getHexColorCode(value['background-color']);
            break;
          case '.card__actions .btn':
            initValues[cardactions.name][btn.name][btn.backgroundColor.name]
              = getHexColorCode(value['background-color']);
            break;
          default:
            break;
        }
      }

      return initValues;
  }

  const formInitialValues = useMemo(() => getInitialValues(currentStyles), [currentStyles]);

  const [formState, setFormState] = useState(formInitialValues);
  const [publishState, setPublishState] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);

  // const prevFormState = usePrevious(formState);
  // const previousChangeStatus = usePrevious(changeStatus);

  useEffect(() => {
    setTimeout(() => setChangeStatus(false), 1000);
  }, [formState]);

  const handleSubmit = (values) => {
      // console.log("Clicked submit...");
      setFormState({ ...values });
  };

  const handleChanges = (values) => {
      // console.log("Clicked apply changes...");
      setChangeStatus(true);
      setPublishState(true);
      setFormState({ ...values });
  };

  const onPublished = () => setPublishState(false);

  return (
    <Card id="bot-appearance" sx={{ overflow: "visible" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDTypography variant="h5">Custom bot styles</MDTypography>
        {/* <MDBadge variant="contained" color="success" badgeContent="enabled" container /> */}
        <PublishStyles 
          templateValues={formState}
          onPublish={onPublished} 
          disabled={!publishState}
        >
          Publish
        </PublishStyles>
      </MDBox>
      <MDBox p={3}>
        {!publishState && (
          <MDBox mb={2}>
              <MDAlert color="warning">
                  <MDTypography variant="button" color="white" fontWeight="medium">
                      Publish bot styles in order to apply them on your website.
                  </MDTypography>
              </MDAlert>
          </MDBox>
        )}
        <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
            <Formik
              initialValues={formInitialValues}
              validationSchema={validations}
              onSubmit={handleSubmit}
            >
              {({ values, isSubmitting }) => 
                (
                  <Form id={formId} autoComplete="off">
                    <MDBox p={3}>
                      <ColorField
                        initialColor={values[toolbar.name][themedark.name][themedark.backgroundColor.name]} 
                        type={themedark.backgroundColor.type} 
                        label={themedark.backgroundColor.label} 
                        name={`${toolbar.name}.${themedark.name}.${themedark.backgroundColor.name}`} 
                      />
                    </MDBox>
                    <MDBox p={3}>
                      <InputField 
                        type={title.fontFamily.type} 
                        label={title.fontFamily.label} 
                        name={`${toolbar.name}.${title.name}.${title.fontFamily.name}`} 
                      />
                    </MDBox>
                    <MDBox p={3}>
                      <InputField 
                        type={title.fontSize.type} 
                        label={title.fontSize.label}
                        adorn={title.fontSize.adorn} 
                        name={`${toolbar.name}.${title.name}.${title.fontSize.name}`} 
                      />
                    </MDBox>
                    <MDBox p={3}>
                      <ColorField
                        initialColor={values[toolbar.name][title.name][title.color.name]} 
                        type={title.color.type} 
                        label={title.color.label} 
                        name={`${toolbar.name}.${title.name}.${title.color.name}`} 
                      />
                    </MDBox>
                    <MDBox p={3}>
                      <ColorField
                        initialColor={values[messagelistcontainer.name][messagelistcontainer.backgroundColor.name]} 
                        type={messagelistcontainer.backgroundColor.type} 
                        label={messagelistcontainer.backgroundColor.label} 
                        name={`${messagelistcontainer.name}.${messagelistcontainer.backgroundColor.name}`} 
                      />
                    </MDBox>
                    <MDBox p={3}>
                      <ColorField
                        initialColor={values[messagebubble.name][messagehuman.name][messagehuman.backgroundColor.name]} 
                        type={messagehuman.backgroundColor.type} 
                        label={messagehuman.backgroundColor.label} 
                        name={`${messagebubble.name}.${messagehuman.name}.${messagehuman.backgroundColor.name}`} 
                      />
                    </MDBox>
                    <MDBox p={3}>
                      <ColorField
                        initialColor={values[messagebubble.name][messagebot.name][messagebot.backgroundColor.name]} 
                        type={messagebot.backgroundColor.type} 
                        label={messagebot.backgroundColor.label} 
                        name={`${messagebubble.name}.${messagebot.name}.${messagebot.backgroundColor.name}`} 
                      />
                    </MDBox>
                    <MDBox p={3}>
                      <ColorField
                        initialColor={values[messagebubble.name][messagetext.name][messagetext.color.name]}
                        type={messagetext.color.type} 
                        label={messagetext.color.label} 
                        name={`${messagebubble.name}.${messagetext.name}.${messagetext.color.name}`} 
                      />
                    </MDBox>
                    <MDBox p={3}>
                      <InputField
                        type={messagetext.fontSize.type} 
                        label={messagetext.fontSize.label}
                        adorn={messagetext.fontSize.adorn}  
                        name={`${messagebubble.name}.${messagetext.name}.${messagetext.fontSize.name}`} 
                      />
                    </MDBox>
                    {/* <MDBox p={3}>
                      <ColorField
                        initialColor={values[cardtitle.name][cardtitleprimary.name][cardtitleprimary.backgroundColor.name]} 
                        type={cardtitleprimary.backgroundColor.type} 
                        label={cardtitleprimary.backgroundColor.label} 
                        name={`${cardtitle.name}.${cardtitleprimary.name}.${cardtitleprimary.backgroundColor.name}`} 
                      />
                    </MDBox>
                    <MDBox p={3}>
                      <ColorField
                        initialColor={values[cardactions.name][btn.name][btn.backgroundColor.name]} 
                        type={btn.backgroundColor.type} 
                        label={btn.backgroundColor.label} 
                        name={`${cardactions.name}.${btn.name}.${btn.backgroundColor.name}`} 
                      />
                    </MDBox> */}
                    <MDBox mt={2} p={3} justifyContent='flex-end' alignItems='center'>
                      <MDBox mr={1}>
                        {changeStatus && <CircularProgress color="inherit" />}
                      </MDBox>
                      <MDButton
                        disabled={isSubmitting}
                        type="submit"
                        variant="outlined"
                        color="dark"
                        onClick={() => handleChanges(values)}
                      >
                        Apply Changes
                      </MDButton>
                    </MDBox>
                  </Form>
              )}
            </Formik>
            </Grid>
            <Grid item sm={12} md={6}>
                <BotAppearanceView 
                    webuiTemplate={webuiTemplate}
                    templateValues={formState}
                    webDomain={webDomain}
                />
            </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

// typechecking props for FormField
BotAppearance.propTypes = {
  currentStyles: PropTypes.string.isRequired,
  webDomain: PropTypes.string.isRequired
};

BotAppearanceView.propTypes = {
  webDomain: PropTypes.string.isRequired,
  templateValues: PropTypes.shape({
    toolbar: PropTypes.shape({
      themedark: PropTypes.shape({
        backgroundColor: PropTypes.string
      })
    }).isRequired,
    messagelistcontainer: PropTypes.shape({
      backgroundColor: PropTypes.string
    }).isRequired,
    messagebubble: PropTypes.shape({
      messagebot: PropTypes.shape({
        backgroundColor: PropTypes.string
      }),
      messagehuman: PropTypes.shape({
        backgroundColor: PropTypes.string
      }),
      messagetext: PropTypes.shape({
        color: PropTypes.string
      })
    }).isRequired,
    cardtitle: PropTypes.shape({
      cardtitleprimary: PropTypes.shape({
        backgroundColor: PropTypes.string
      })
    }).isRequired,
    cardactions: PropTypes.shape({
        btn: PropTypes.shape({
          backgroundColor: PropTypes.string
        })
    }).isRequired
  }).isRequired
};

export default BotAppearanceManager;
