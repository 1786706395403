/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

// Material UI
import InputAdornment from '@mui/material/InputAdornment';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

/* eslint-disable react/jsx-no-duplicate-props */
function MDInputNumber({ adorn, ...rest }) {
  return (
    <MDInput
      {...rest}
      inputProps={{ step: 'any' }}
      InputProps={{
        endAdornment: 
            <InputAdornment position="end">
                {adorn}
            </InputAdornment>
      }}
    />
  )
}
/* eslint-enable react/jsx-no-duplicate-props */

function InputField({ label, name, variant, type, adorn, ...rest }) {
  return (
    <MDBox mb={1.5}>
      {type === "number" ?
        (<Field 
          {...rest} 
          name={name}
          adorn={adorn}
          type={type}
          as={MDInputNumber} 
          variant={variant} 
          label={label} 
          fullWidth 
        />) :
        (<Field {...rest} type={type} name={name} as={MDInput} variant={variant} label={label} fullWidth />)}
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Specifies the default values for props:
InputField.defaultProps = {
  variant: 'outlined',
  adorn: ""
};

MDInputNumber.defaultProps = {
  adorn: ""
};

MDInputNumber.propTypes = {
  adorn: PropTypes.string
};

// typechecking props for FormField
InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  type: PropTypes.oneOf([ "number", "text", "password"]).isRequired,
  adorn: PropTypes.string
};

export default InputField;
