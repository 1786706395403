/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback, useRef } from "react";

import { useNavigate } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// Manzia hooks
// import useLocalUserProducts from "customhooks/useLocalUserProducts";

// Auth
// import { AuthUserContext } from "App";

// State Views
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
// import NoDataView from "layouts/common/NoDataView";
import PublishState from "layouts/common/PublishStatus";

// Manzia
import PublishButton from "layouts/deploy/publish/components/PublishButton";
import validations from "layouts/test/products/components/schemas/validations";
import { isEmptyObject } from "helpers";

// Check for complete products
function isCompleteProduct(training) {
  let trainingData;
  try {
    trainingData = JSON.parse(training.trainingData);
  } catch (error) { trainingData = null; }
  if (isEmptyObject(trainingData)) return false;
  return validations.isValidSync(trainingData);
}

function mapTrainings(trainings = []) {
  return trainings.map(training => {
    const productJson = JSON.parse(training.trainingData);
    return { ...training, userProduct: { id: training.userProductId, productName: productJson.productName } };
    
  }); 
}

// Only display product with sufficient training data
function filterUserProducts(products, trainings) {
  const completeSet = new Set();
  trainings.forEach(train => {
    if (isCompleteProduct(train)) completeSet.add(train.userProductId);
  });
  return products.filter(prod => completeSet.has(prod.id));
}
/**
 * - Use ProductTrainings from entire account
 * - Use the most recent ProductTraining to check for completeness
 */
function HeaderData({ trainedChatBotId, onProductSelected }) {
  // const { state } = useLocation();

  const { loading: botLoading, error: botError, data: botData } = useQuery(gql`${queries.getManziaChatbot}`, {
    variables: { id: trainedChatBotId }, fetchPolicy: 'cache-first',
  });

  const prodSelected = useCallback((product) => {
    onProductSelected(product);
  }, []);

  if (botLoading) return <LoadView />;
  if (botError) return <ErrorView error={{ message: `${botError}` }} />;

  // console.log("Bot data: ", botData);
  const userProducts = botData?.getManziaChatbot?.chatbotUserProducts?.items || [];
  const productTrainings = botData?.getManziaChatbot?.chatbotProductTrainings?.items || [];

  return (
    <Header 
      userProducts={userProducts}
      productTrainings={productTrainings}
      onProductSelected={prodSelected}
      trainedChatBotId={trainedChatBotId}
    />
  );
}

function Header({ userProducts, productTrainings, onProductSelected, trainedChatBotId }) {
  // UserProduct
  // const { state } = useLocation();
  // const { userProducts, productTrainings } = useLocalUserProducts({ botId: trainedChatBotId });
  // console.log("Local products: ", userProducts);
  // console.log("Local trainings: ", productTrainings);
  const products = useRef(filterUserProducts(userProducts, productTrainings));
  const [product, setProduct] = useState(products.current[0]);
  const [published, setPublished] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // User came to test page from product page
    if (product) { onProductSelected(product); }
  }, []);

  const goEdit = useCallback(() => navigate(`/products/${product?.id}`), [product]);

  const onPublish = () => setPublished(true);

  const handleProduct = (_, value) => {
    setProduct(value);
    onProductSelected(value);
  }
  return (
      <MDBox p={2}>
        {!published && <PublishState />}
        <Grid container spacing={3} justifyContent="center" alignItems="flex-end">
          <Grid item xs={12} sm={8}>
            <MDBox mt={-1}>
              <MDBox display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Select product
                </MDTypography>
              </MDBox>
              
              <Autocomplete
                value={product}
                name="product-name"
                autoComplete
                isOptionEqualToValue={(option, value) => option.productName === value.productName}
                getOptionLabel={(option) => option.productName}
                onChange={handleProduct}
                options={products.current.sort((a, b) => a.productName.localeCompare(b.productName))}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6} sm={2}>
                <MDButton 
                  variant="outlined" 
                  color="dark" 
                  fullWidth
                  onClick={goEdit}
                  disabled={!product}
                >
                  Edit
                </MDButton>
              </Grid>
            
              <Grid item xs={6} sm={2}>
                <PublishButton 
                  chatbotId={trainedChatBotId}
                  trainings={mapTrainings(productTrainings)}
                  onPublish={onPublish}
                >
                  {/* <Icon>add</Icon>&nbsp; Deploy Bot */}
                  Publish
                </PublishButton>
              </Grid>
        </Grid>
      </MDBox>
   
  );
}

HeaderData.propTypes = {
  trainedChatBotId: PropTypes.string.isRequired,
  onProductSelected: PropTypes.func.isRequired
};

Header.propTypes = {
  userProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  productTrainings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  onProductSelected: PropTypes.func.isRequired,
  trainedChatBotId: PropTypes.string.isRequired,
};

export default HeaderData;
