/**
=========================================================
* Manzia Inc - v1.1.0
=========================================================

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Roy Manzi

 =========================================================

*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// Table
import DataTable from "examples/Tables/DataTable";
import ProductCell from "layouts/train/product-page/components/ProductCell";
import DefaultCell from "layouts/train/product-page/components/DefaultCell";
import StatusCell from "layouts/train/product-page/components/StatusCell";
import ActionCell from "layouts/train/product-page/components/ActionCell";

// State Views
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import NoDataView from "layouts/common/NoDataView";

// Manzia
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";
import productValidation from "layouts/deploy/publish/components/validations";


// Actions
import { productActions } from "appSettings";

// Images
import blackChair from "assets/images/ecommerce/black-chair.jpeg";

// Utilities
import moment from "moment";

function completeProduct(training) {
    let trainingData;
    try {
      trainingData = JSON.parse(training.trainingData);
    } catch (error) { trainingData = null; }
    if (!trainingData) return false;
    return productValidation.isValidSync(trainingData);
  }

function TrainedProducts({ trainedChatBotId, trainings }) {
    const { chatbot } = useLocalChatbotUser({ chatbotId: trainedChatBotId });
    const { loading, error, data, fetchMore } = useQuery(gql`${queries.manziaUserProductsByTrainedChatBotId}`, {
        variables: { trainedChatBotId, limit: 100 }, fetchPolicy: 'cache-and-network',
    });

    if (loading) return <LoadView />
    if (error) return <ErrorView error={{ message: `${error}` }} />
    if (data?.manziaUserProductsByTrainedChatBotId?.items.length === 0) 
        return (
            <NoDataView 
                info={{ text: "No product information", message: `No product information has been added to ${chatbot?.chatbotName}. Click below to add product information.`}} 
                action={{ label: "Add product", route: "/new-product"}}
            />)

    const PRODUCTS = data.manziaUserProductsByTrainedChatBotId.items.map((item) => {
        
        const trainArr = trainings.filter(train => train.userProductId === item.id && train.trainingStatus === "active");
        const [training] = trainArr;
        let completion = "Incomplete data";
        if (training) {
            completion = completeProduct(training) ? "Completed" : completion;
        }
        const status = completion === "Completed" ? { icon: "done", color: "success", status: completion } :
            { icon: "warning", color: "warning", status: completion }

        return {
            product: { image: blackChair, name: item.productName }, 
            productType: item.productType,
            updatedAt: moment(item.updatedAt).fromNow(), 
            status,
            actions: { 
                icons: [ 
                    {icon: "edit", color: "info", action: productActions.edit }, 
                    {icon: "delete", color: "error", action: productActions.delete }, 
                    {icon: "chevron_right", color: "info", action: productActions.test },
                ], 
                payload: { ...item } 
            }
        }
    });

    const tableData = {
        columns: [
          { Header: "Product", accessor: "product", width: "30%" },
          { Header: "Last Updated", accessor: "updatedAt", width: "15%" },
          { Header: "Status", accessor: "status", align: "left", width: "30%" },
          { Header: "Actions", accessor: "actions", align: "center", width: "25%" },
        ],
        rows: PRODUCTS.map((item) => ({
              product: <ProductCell image={item.product.image} name={item.product.name} />,
              updatedAt: <DefaultCell>{item.updatedAt}</DefaultCell>,
              status: <StatusCell icon={item.status.icon} color={item.status.color} status={item.status.status} />,
              actions: <ActionCell icons={item.actions.icons} payload={item.actions.payload} />
        }))
    };

    return (
        <MDBox mt={8} mb={2}>
            <MDBox mb={1} ml={2}>
                <MDTypography variant="h6" fontWeight="medium">
                    Products
                </MDTypography>
            </MDBox>
            <DataTable
                table={tableData}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
                onLoadMore={() => fetchMore({
                    variables: {
                      nextToken: data.manziaUserProductsByTrainedChatBotId.nextToken,
                      limit: 100
                    },
                })}
            />
        </MDBox>
    );
}

// Typechecking props for BaseLayout
TrainedProducts.propTypes = {
    trainedChatBotId: PropTypes.string.isRequired,
    trainings: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        userProductId: PropTypes.string.isRequired,
        trainingData: PropTypes.string.isRequired
    })).isRequired
  };

export default TrainedProducts;