/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useRef, useEffect } from "react";

import PropTypes from "prop-types";

// @mui material components
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Manzia
// import LinkURL from "layouts/common/LinkURL";
import BoldLinks from "layouts/common/BoldLinks";


function InfoList({ infoData }) {
  
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  const { title, description, steps, getStartedLink, videoLink } = infoData;

  const renderImage = ({ url, alt }) => {
    if (!url || url.length === 0) return null;
    return (
      <MDBox
        my={2}
        component="img"
        src={url}
        alt={alt}
        shadow="lg"
        borderRadius="lg"
        width="100%"
      />
    );
  }

  const renderSteps = steps.map((step, key) => {
    const itemKey = `item-${key}`;
    // console.log("Step: ", step);
    return (
      <MDBox key={itemKey} component="li" lineHeight={1}>
        {/* {addLinkElements(step)} */}
        <BoldLinks 
          text={step.text}
          links={step.links}
          bolds={step.bolds}
        />
        {step.image && (renderImage(step.image))}
      </MDBox>
    );
  });

  const renderVideoLink = (videoUrl) => {
    if (!videoUrl) return null;
    return (
      <MDBox display='flex' justifyContent='center'>
        <iframe
          title="Install Manzia Chatbot" 
          width="75%" 
          height="240px" 
          src={videoUrl} 
          frameBorder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen 
        />
      </MDBox>
    );
  };

  const renderedVideo = renderVideoLink(videoLink);

  return (
    <MDBox>
      <MDBox p={3}>
        <MDTypography variant="h4" color="dark" fontWeight="bold">{title.text}</MDTypography>
        {title.image && (renderImage(title.image))}
      </MDBox>
      <MDBox pb={3} px={3}>
          <BoldLinks 
            text={description.text}
            links={description.links}
            bolds={description.bolds}
          />
        {description.image && (renderImage(description.image))}
        <MDBox component="ul" m={1} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderSteps}
        </MDBox>
        {getStartedLink && 
          <BoldLinks 
            text={getStartedLink.text}
            links={getStartedLink.links}
            bolds={getStartedLink.bolds}
          />
        }
        <Divider />
        {videoLink && <MDBox>{renderedVideo}</MDBox>}
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Socials
InfoList.propTypes = {
    infoData: PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string
        })
      }),
      description: PropTypes.shape({
        text: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string
        }),
        links: PropTypes.arrayOf(PropTypes.shape({
          linkText: PropTypes.string,
          linkURL: PropTypes.string,
          type: PropTypes.string
        })),
        bolds: PropTypes.arrayOf(PropTypes.string)
      }),
      steps: PropTypes.arrayOf(PropTypes.shape({
        linkText: PropTypes.string,
        linkURL: PropTypes.string
      })),
      getStartedLink: PropTypes.shape({
        text: PropTypes.string,
        links: PropTypes.arrayOf(PropTypes.shape({
          linkText: PropTypes.string,
          linkURL: PropTypes.string,
          type: PropTypes.string
        })),
        bolds: PropTypes.arrayOf(PropTypes.string)
      }),
      videoLink: PropTypes.string
    }).isRequired,
};

export default InfoList;
