
// React router
import { Outlet } from "react-router-dom";

// Material
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function RouteLayout() {
    return ( 
      <DashboardLayout>
        <DashboardNavbar />
        <Outlet /> 
        <Footer />
      </DashboardLayout>
    );
  }

export default RouteLayout;