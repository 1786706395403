import { useCallback, useEffect, useState } from "react";

// Lex
// import LexV2Client from "layouts/test/aws/lexv2client";
import { DeleteSessionCommand, PutSessionCommand, 
    GetSessionCommand, RecognizeTextCommand, LexRuntimeV2Client } from "@aws-sdk/client-lex-runtime-v2";

// Amplify
import { Auth } from 'aws-amplify';
import awsconfig from "amplifyconfiguration.json";

// Constants
import { lexV2Commands } from "appSettings";

/**
 * Auth.currentCredentials()
  .then(credentials => {
    const route53 = new Route53({
      apiVersion: '2013-04-01',
      credentials: Auth.essentialCredentials(credentials)
    });
  })
*/

export default function useLexV2Client(commandType, dependencies = []) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [value, setValue] = useState();
    const [lexv2Client, setLexv2Client] = useState();

    useEffect(() => {
        const getClient = async () => {
            const credentials = await Auth.currentCredentials();
            setLexv2Client(new LexRuntimeV2Client({
                credentials: Auth.essentialCredentials(credentials),
                region: awsconfig.aws_project_region
            }))
        }
        try {
            getClient();
        } catch (_) {
            // console.log("Failed to created LexRuntimeV2Client", err);
        }
    }, []);

    const runCommand = useCallback(() => (params) => {
        setLoading(true);
        setError(undefined);
        setValue(undefined);
        // console.log("Lex params: ", params);
        let command;
        switch (commandType) {
            case lexV2Commands.deleteSession:
                command = new DeleteSessionCommand(params);
                break;
            case lexV2Commands.putSession:
                command = new PutSessionCommand(params);
                break;
            case lexV2Commands.getSession:
                command = new GetSessionCommand(params);
                break;
            case lexV2Commands.recognizeText:
                command = new RecognizeTextCommand(params);
                break;
            default:
                break;
        }

        lexv2Client?.send(command)
        .then((data) => setValue(data))
        .catch(() => { 
            setError(`Error: AWS LexV2Client ${commandType} operation failed.`);
            // console.log("Error: ", JSON.stringify(err));
        })
        .finally(() => setLoading(false));
        
        
    }, [lexv2Client, ...dependencies]);

    return { loading, error, value, runCommand, isReady: !!lexv2Client };
}
