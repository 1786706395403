/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";

// react-router-dom components
// import { Link } from "react-router-dom";

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
// import MDButton from "components/MDButton";

// Auth
import { AuthUserContext } from "App";

// Manzia
// import LinkURL from "layouts/common/LinkURL";
import useLocalChatbotUser from 'customhooks/useLocalChatbotUser';
import LoadView from "layouts/common/LoadView";


function NoPublishVersion() {
    return (
        <MDBox my={2}>
            <MDAlert color="warning">
                <MDTypography variant="button" color="white" fontWeight="medium">
                    Publish a version of your product information in order to activate the chatbot on your website.
                </MDTypography>
            </MDAlert>
        </MDBox>
    );
}

function PublishState() {
    // User
    const authUser = useContext(AuthUserContext);
    const USERID = authUser?.sub;
    const {  chatbot } = useLocalChatbotUser({ userId: USERID });

    return chatbot ? <PublishStatus chatbot={chatbot} /> : <NoPublishVersion />;
}

function PublishStatus({ chatbot }) {
    
    // Get deployments
    const { loading, error, data } = useQuery(gql`${queries.manziaBotDeploymentsByManziaChatbotIdAndLastDeployedAt}`, {
        variables: {
            manziaChatbotId: chatbot.id,
            sortDirection: 'DESC',
            limit: 1,
        }, fetchPolicy: 'cache-and-network'
    });

    if (loading) return <LoadView loadingMessage="" />;
    if (error) return <NoPublishVersion />;
    const items = data?.manziaBotDeploymentsByManziaChatbotIdAndLastDeployedAt?.items || [];

    return items.length > 0 ? null : <NoPublishVersion />;
}

// typechecking props for UserInfo
PublishStatus.propTypes = {
    chatbot: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired
};

export default PublishState;