import { useCallback, useState, useEffect } from "react"

// S3 client
// import s3Client from "layouts/settings/aws/s3client";
import { GetObjectCommand, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

// Auth
import { Auth } from 'aws-amplify';
import { awsCommands } from "appSettings";
import awsconfig from "amplifyconfiguration.json";

export default function useLazyS3Client(commandType, dependencies =[]) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [value, setValue] = useState();
    const [client, setClient] = useState();

    useEffect(() => {
        const getClient = async () => {
            const credentials = await Auth.currentCredentials();
            setClient(new S3Client({
                credentials: Auth.essentialCredentials(credentials),
                region: awsconfig.aws_project_region
            }))
        }
        try {
            getClient();
        } catch (_) {
            // console.log("Failed to created S3Client", err);
        }
    }, []);

    const runCommand = useCallback(() => (options) => {
        setLoading(true);
        setError(undefined);
        setValue(undefined);
        // console.log("S3 params: ", options);
        let command;
        switch (commandType) {
            case awsCommands.s3.getObject:
                command = new GetObjectCommand(options);
                break;
            case awsCommands.s3.putObject:
                command = new PutObjectCommand(options);
                break;
            default:
                break;
        }
        
        client?.send(command)
            .then((data) => setValue(data))
            .catch(() => { 
                setError(`Error: AWS S3Client ${commandType} operation failed.`);
                // console.log("Error: ", JSON.stringify(err));
            })
            .finally(() => setLoading(false));
    }, [ client, ...dependencies]);

    return { loading, error, data: value, runCommand, isReady: !!client };
}
