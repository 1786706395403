
export const createManziaAccountUser = (onboardState) => `
  mutation CreateManziaAccount(
    $accountInput: CreateManziaAccountInput!
    $accountCondition: ModelManziaAccountConditionInput
    $userInput: UpdateManziaUserInput!
    $userCondition: ModelManziaUserConditionInput
    $onboardInput: CreateManziaOnboardInput!
    $onboardCondition: ModelManziaOnboardConditionInput
  ) {
    a1: createManziaAccount(input: $accountInput, condition: $accountCondition) {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        industryCode
        deleteTime
        createdAt
        updatedAt
        __typename
    },
    a2: updateManziaUser(input: $userInput, condition: $userCondition) {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        createdAt
        updatedAt
        __typename
    },
    a3: ${onboardState ? "updateManziaOnboard" : "createManziaOnboard"}(input: $onboardInput, condition: $onboardCondition) {
        emailAddress
        onboardState
        userId
        accountId
        webDomain
        organizationName
        deleteTime
        createdAt
        updatedAt
        __typename
    }
  }
`;

export const updateManziaAccountUser = `
  mutation UpdateManziaAccount(
    $accountInput: UpdateManziaAccountInput!
    $accountCondition: ModelManziaAccountConditionInput
    $userInput: UpdateManziaUserInput!
    $userCondition: ModelManziaUserConditionInput
    $onboardInput: UpdateManziaOnboardInput!
    $onboardCondition: ModelManziaOnboardConditionInput
  ) {
    a1: updateManziaAccount(input: $accountInput, condition: $accountCondition) {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        industryCode
        deleteTime
        createdAt
        updatedAt
        __typename
    },
    a2: updateManziaUser(input: $userInput, condition: $userCondition) {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        createdAt
        updatedAt
        __typename
    },
    a3: updateManziaOnboard(input: $onboardInput, condition: $onboardCondition) {
        emailAddress
        onboardState
        userId
        accountId
        webDomain
        organizationName
        deleteTime
        createdAt
        updatedAt
        __typename
    }
  }
`;

const accountUserMutation = (account, onboardState) => account ? updateManziaAccountUser : createManziaAccountUser(onboardState);

export default accountUserMutation;