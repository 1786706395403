

const chatMutation = (chatId, idx) =>  `
    c${idx}: deleteManziaTestChatMessage(input: { id: "${chatId}"}) {
      id
      testConversationId
      timestamp
      message
      sender
      lastModifiedAt
      deleteTime
      createdAt
      updatedAt
    }
  `;

const conversationMutation = (conversationId, idx) =>  `
  a${idx}: deleteManziaTestConversation(input: { id: "${conversationId}"}) {
    id
    userProductId
    testedByUserId
    lastModifiedAt
    title
    isExample
    deleteTime
    createdAt
    updatedAt
  }
`;

export function deleteConversationsMutation(conversations = []) {
    
    if (conversations.length < 1) return undefined;

    let chatItems = [];

    const convMutations = conversations.map((conversation, idx) => {
      const { id, testChatMessages: { items = [] } } = conversation;
      chatItems = [ ...chatItems, ...items ];
      return conversationMutation(id, idx + 1);
    });

    const chatMutations = chatItems.map((item, index) => chatMutation(item.id, index + 1));

    const mutation = `
      mutation DeleteTestConversationChatMessages {
        ${convMutations.join(',')},
        ${chatMutations.join(',')}
      }
    `;
    // console.log("Delete mutation: ", mutation);
    return mutation;
}

function deleteConversationMutation(conversation) {
  
  return deleteConversationsMutation([ conversation ]);
}

export default deleteConversationMutation;