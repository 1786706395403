/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef, useCallback } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// GraphQL
import { gql, useMutation } from '@apollo/client';
// import createManziaAccountUser from "layouts/onboard/components/BackendView/signupmutation";
import accountUserMutation from "layouts/onboard/components/BackendView/signupmutation";

// Subscriptions
import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from 'graphql/subscriptions';
import * as mutations from 'graphql/mutations';

// @mui material components
import Icon from '@mui/material/Icon';
import { green, red } from '@mui/material/colors';
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// formik components
import { Formik, Form } from "formik";

// schemas for form and form fields
import validations from "layouts/onboard/schemas/validations";
import form from "layouts/onboard/schemas/form";
import initialValues from "layouts/onboard/schemas/initialValues";

// Manzia
import InputField from "layouts/onboard/components/InputField";

// UUID
import { v4 as uuidv4 } from 'uuid';

// Custom Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";
import useLazyAxios from "customhooks/useLazyAxios";
import useAuth from "customhooks/useAuth";
import useAxios from "customhooks/useAxios";
import useLocalOnboardState from "customhooks/useLocalOnboardState";

// Manzia
// import UserRoles from "layouts/onboard/components/UserRoles";
// import { OnboardRole, OnboardState, UserFlows } from "appSettings";
import { SIGNUP_API, OnboardState, ManziaAuthState } from "appSettings";
import { isEmptyObject, DOMAIN_NAME } from "helpers";

// State Views
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";

// Common components
// import NotificationView from "layouts/common/Notification";

// Data
import industryCategories from "layouts/train/new-product/options/productCategories";
import invalidDomains from "layouts/onboard/data/invalidDomains";


// Constants
const accountId = uuidv4();

function BackendView({ userId, userEmail, manziaAccountId }) {

  const { backend: {  organizationName, webDomain, industryCode }, formId } = form;

  // Navigation
  const navigate = useNavigate();

  // Local values
  const { account } = useLocalChatbotUser({ userId });
  const { onboardState } = useLocalOnboardState({ emailAddress: userEmail });

  // Create an account
  const accountRef = useRef(manziaAccountId || account?.id || accountId);
  const chatbotRef = useRef();
  const industryCodeRef = useRef();
  const [completedBackend, setCompletedBackend] = useState(false);
  const [childProtection, setChildProtection] = useState(false);

  const [createAccount, { data: accountData, loading: accountLoading, 
    error: accountError }] = useMutation(gql`${accountUserMutation(account, onboardState)}`);

  const [updateOnboard, { loading: onboardLoading, error: onboardError }] 
    = useMutation(gql`${mutations.updateManziaOnboard}`);
  
  const [updateAccount, { data: updateAccountData, loading: updateAccountLoading, 
    error: updateAccountError }] = useMutation(gql`${mutations.updateManziaAccount}`);

  // Create lexv2 bot
  const { loading: botLoading, error: botError, value: botData, 
    fetch: createBot } = useLazyAxios();

  // Sign Out
  // Login
  const { loading: signoutLoading, error: signoutError, value: signoutData,
    command: authSignout } = useAuth(ManziaAuthState.signOut.key);
  
  
  // Subscribe to bot creation events
  /* eslint-disable consistent-return */
  useEffect(() => {
      if (!accountData) return;
      // Subscribe to creation of ManziaChatbotWebUI
      const createWebUISub = API.graphql(
          graphqlOperation(subscriptions.onCreateManziaChabotWebUI)
      ).subscribe({
          next: ({ value }) => {
            // console.log("WebUI subscribe value: ", { provider, value });
            const { data: { onCreateManziaChabotWebUI: { id: chatbotWebId } } } = value;
            if (chatbotWebId === chatbotRef.current) {
              setCompletedBackend(true);
            }
          },
          error: () => { 
            updateOnboard({
              variables: {
                input: {
                  emailAddress: userEmail,
                  onboardState: OnboardState.NO_CHATWEBUI
                }
              }
            });
            // console.warn("WebUI create error: ", error); 
          }
      });

      // Create the backend Lex Bot
      createBot()({
        method: 'post', 
        url: SIGNUP_API.createBot, 
        data: {
          accountId: accountRef.current,
          industryCode: industryCodeRef.current
        },
        timeout: 60000,
        maxRedirects: 1, 
      });

      // createBotSub.unsubscribe();
      return () => createWebUISub.unsubscribe();

  }, [accountData]);
  /* eslint-enable consistent-return */

  // Set chatbotId
  /* eslint-disable dot-notation */
  useEffect(() => {
    if (!botData) return;
    if (botData?.code === 'LEXBOT_SUCCESS') {
      const { result: { Item } } = botData;
      chatbotRef.current = Item.id.S;
      if (Item["__typename"]?.S === 'ManziaChabotWebUI') {
        setCompletedBackend(true);
      }
    }
  }, [botData]);
  /* eslint-enable dot-notation */

  // Update ManziaOnboard state on failure
  useEffect(() => {
    if (!accountError && !botError) return;

    updateOnboard({
      variables: {
        input: {
          emailAddress: userEmail,
          onboardState: accountError ? OnboardState.ACCOUNT_FAILED : OnboardState.CREATE_BOT_FAILED,
          userId,
          accountId: accountRef.current,
        }
      }
    });

  }, [accountError, botError]);

  // Update account with new chatbotId
  useEffect(() => {
    if (!chatbotRef.current) return;
    updateAccount({
      variables: {
        input: {
          id: accountRef.current,
          v2chatBotId: chatbotRef.current
        }
      }
    });
  }, [chatbotRef.current])


  // Update ManziaOnboard state on success
  useEffect(() => {
    if (!completedBackend) return;

    // Update onboarding
    updateOnboard({
      variables: {
        input: {
          emailAddress: userEmail,
          onboardState: OnboardState.ONBOARD_COMPLETE,
          userId,
          accountId: accountRef.current,
        }
      }
    });

  }, [completedBackend]);

  const handleChange = () => setChildProtection(!childProtection);

  const handleSubmit = (values, actions) => {
    // console.log("Submitted: ", values);
    // console.log(`AccountId: ${accountRef.current}, UserId: ${userId}, emailAddress: ${userEmail}`);

    const { code } = industryCategories.find(industry => industry.title === values[industryCode.name]);
    industryCodeRef.current = code;

    createAccount({
      variables: {
          accountInput: {
              id: accountRef.current,
              v2chatBotId: accountRef.current,
              accountOwnerUserId: userId,
              accountOwnerEmail: userEmail,
              organizationName: values[organizationName.name],
              webDomain: values[webDomain.name],
              industryCode: code
          },
          userInput: {
            id: userId,
            accountId: accountRef.current,
            isAccountOwner: true
          },
          onboardInput: {
            emailAddress: userEmail,
            onboardState: OnboardState.ONBOARD_STARTED,
            userId,
            accountId: accountRef.current,
            organizationName: values[organizationName.name],
            webDomain: values[webDomain.name]
          }
      }
    });
    
    actions.setSubmitting(false);
    // actions.resetForm();
    
  };

  const backToLogin = useCallback(() => {
    // Logout
    authSignout()();
    
  }, []);

  useEffect(() => {
    if (!signoutData) return;
    navigate("/", { replace: true });
  }, [signoutData]);

  const goToWelcome = useCallback(() => navigate("/onboard/welcome", {
    state: {
      onboardState: OnboardState.NO_PRODUCTS,
      userId,
      userEmail
    } }), [userId]);

  const { fetch: checkDomain } = useAxios();

  const validateWebDomain = (domain) => {
    
    if (!DOMAIN_NAME.test(domain) || invalidDomains.indexOf(domain) > -1) return Promise.resolve(webDomain.invalidMsg);
    // if (ENV === 'dev') return Promise.resolve(undefined);

    const domainPromise = new Promise((resolve) => {
      checkDomain()({
        method: 'get',
        url: `${SIGNUP_API.checkDomain}?domain=${domain}`,
        timeout: 8000,
        maxRedirects: 5, 
      })
        .then(() => resolve(undefined))
        .catch(() => resolve(webDomain.offlineMsg))
    });
    return domainPromise;
  }

  const accountIndustry = industryCategories.find(industry => industry.code === account?.industryCode);

  // const { code } = industryCategories.find(industry => industry.title === values[industryCode.name]);
    return (
        <MDBox p={3}>
            <MDBox mb={6}>
              <MDTypography variant="h4" fontWeight="bold" color="dark">
                  Create backend resources...
              </MDTypography>
              <MDTypography variant="body2" fontWeight="regular" color="dark">
                  Fill out the fields below and click Create Backend to create the resources needed to deploy your chatbot.
              </MDTypography>
            </MDBox>
            
            <MDBox my={1}>
              {(onboardLoading || signoutLoading) && <LoadView />}
              {(onboardError || signoutError) && <ErrorView error={{ message: `${onboardError || signoutError}` }} />}
              
            </MDBox>
            
            <Formik
              initialValues={account ? 
                { 
                  [organizationName.name]: account.organizationName || "",
                  [webDomain.name]: account.webDomain || "",
                  [industryCode.name]: accountIndustry?.title || ""
                } : initialValues.backend}
              validationSchema={validations.backend}
              onSubmit={handleSubmit}
              initialErrors={{}}
            >
              {({ values, isSubmitting, isValid, setFieldValue }) =>  
               (
                <Form id={formId} autoComplete="off">
                    <MDBox pb={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <InputField 
                            name={organizationName.name}
                            label={organizationName.label} 
                            placeholder={organizationName.placeholder}
                            type={organizationName.type}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputField 
                            name={webDomain.name}
                            label={webDomain.label} 
                            placeholder={webDomain.placeholder}
                            type={webDomain.type}
                            validate={validateWebDomain}
                          />
                        </Grid>
                        <Grid item xs={12}>
                        <Autocomplete
                          value={values[industryCode.name]}
                          name={industryCode.name}
                          label={industryCode.label}
                          options={industryCategories.map(item => item.title)}
                          onChange={(e, value) => {
                            // console.log("Value: ", value);
                            setFieldValue(industryCode.name, value);
                          }}
                          renderInput={(params) => (
                            <InputField
                              {...params}
                              name={industryCode.name} 
                              label={industryCode.label} 
                              InputLabelProps={{ shrink: true }}   
                            />
                          )}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <MDBox display="flex" alignItems="center" ml={-1}>
                              <Checkbox 
                                  checked={childProtection}
                                  onChange={handleChange}
                                  inputProps={{ 'aria-label': 'controlled' }}
                              />
                              <MDBox ml={2}>
                                <MDTypography
                                    variant="button"
                                    fontWeight="regular"
                                    color="dark"
                                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                >
                                    I certify that my use of ManziaBot is NOT related to a website, program, application that is directed or targeted, in whole or in part, to children under the age of 13 and thus subject to the
                                </MDTypography>
                                <MDTypography
                                    component="a"
                                    href="https://www.ftc.gov/business-guidance/privacy-security/childrens-privacy"
                                    target="_blank"
                                    rel="noreferrer"
                                    variant="button"
                                    fontWeight="bold"
                                    color="info"
                                    textGradient
                                >
                                    &nbsp;Children&apos;s Online Privacy Protection Act (COPPA)
                                </MDTypography>
                              </MDBox>
                          </MDBox>
                        </Grid>
                      </Grid>
                      <MDBox my={2} display="flex" justifyContent="flex-end">
                        {(accountLoading || botLoading) && <CircularProgress /> }
                        <MDButton
                          disabled={isSubmitting || !isValid || completedBackend || accountLoading || botLoading || updateAccountLoading || signoutLoading || !!botData || !childProtection}
                          type="submit"
                          variant="gradient"
                          color="info"
                          size="large"
                        >
                          Create backend
                        </MDButton>
                      </MDBox>
                    </MDBox>
                    
                </Form>
              )}
            </Formik>
           
            {/* <MDBox>
              <NotificationView 
                color="info"
                message="Kindly note. It may a few minutes to create all backend resources."
              />
            </MDBox> */}
            <Grid container justifyContent="center" alignItems="center" spacing={2} my={2}>
              <Grid item xs={12} sm={4}>
                  <BackendStep 
                    loading={accountLoading || updateAccountLoading}
                    error={(accountError && accountError.length > 0) || (updateAccountError && updateAccountError.length > 0)}
                    done={!isEmptyObject(accountData) || !isEmptyObject(updateAccountData)}
                    title="Create account..."
                  />
              </Grid>
              <Grid item xs={12} sm={4}>
                  <BackendStep 
                    loading={botLoading}
                    error={(botError && botError.length > 0)}
                    done={botData?.code === 'LEXBOT_SUCCESS'}
                    title="Create chatbot..."
                  />
              </Grid>
              <Grid item xs={12} sm={4}>
                  <BackendStep 
                    loading={!completedBackend && !!botData}
                    error={completedBackend && onboardError && onboardError.length > 0}
                    done={completedBackend}
                    title="Backend complete..."
                  />
              </Grid>
          </Grid>
          {(accountError || botError) && <ErrorView error={{ message: `Creating backend resources failed. Click below to go to Login and retry at a later time.` }} />}
          <MDBox p={3} display="flex" justifyContent="flex-end">
          {(accountError || botError) ? 
            (
                <MDButton
                  disabled={onboardLoading || signoutLoading}
                  variant="gradient"
                  color="info"
                  size="large"
                  onClick={backToLogin}
                >
                  Go to Login
                </MDButton>
            ) : 
              (<MDButton
                disabled={!completedBackend || (onboardError && onboardError.length > 0)}
                variant="gradient"
                color="info"
                size="large"
                onClick={goToWelcome}
              >
                Next
              </MDButton>)}
          </MDBox>
        </MDBox>
    )
}

function BackendStep({ loading, error, done, title }) {
  const [stepError, setStepError] = useState(error ? "failed": null);
  
  // Add loading timeout
  /* eslint-disable consistent-return */
  useEffect(() => {
    if (!loading) return;

    const interval = setInterval(() => {
      setStepError("timed out.");
    }, 90000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [loading]);
  /* eslint-enable consistent-return */

  return (
    <MDBox display="flex" flexDirection="column" alignItems="center">
      {(loading && !stepError) && <CircularProgress color="success" />}
      {done && <Icon fontSize="large" sx={{ color: green[500] }}>check_circle_outlined</Icon>}
      {stepError && <Icon fontSize="large" sx={{ color: red[500] }}>error_outline</Icon>}
      {(!stepError && !loading && !done) && <Icon fontSize="large">data_usage</Icon>}
      <MDBox mt={2}>
        <MDTypography
          color="dark"
          variant="h6"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {stepError ? `${title.substring(0, title.indexOf('.'))} ${stepError}.` : title}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  
}

BackendStep.defaultProps = {
  loading: false,
  error: false,
  done: false,
};

BackendStep.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  done: PropTypes.bool
};

BackendView.defaultProps = {
  manziaAccountId: null
}

BackendView.propTypes = {
  userId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  manziaAccountId: PropTypes.string
};

export default BackendView;
