/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext, useEffect, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router components
// import { useNavigate } from "react-router-dom";

// Material
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import Tooltip from "@mui/material/Tooltip";

// GraphQL
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
import colors from "assets/theme/base/colors";

// Material Dashboard
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';
import MDSnackbar from "components/MDSnackbar";

// Auth
import { AuthUserContext } from "App";

// Custom hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";
import useLazyAxios from "customhooks/useLazyAxios";

// Manzia
import { MANZIA_BILL_RATE, ManziaStripeAPI, OnboardRole, 
  SubscriptionStatus } from "appSettings";
import { isEmptyObject } from "helpers";
import SubscribeButton from "layouts/common/Subscribe";
import PastDue from "layouts/common/PastDue";

function TrackBalanceUser({ userId }) {
  
  const { account } = useLocalChatbotUser({ userId });
  const stripeSubscriptionStatus = account?.stripeSubscriptionStatus ?? "none";
  const [subStatus, setSubStatus] = useState(stripeSubscriptionStatus);

  const handleStatus = (status) => setSubStatus(status);

  if (!account) {
    return (
      <MDBox mx={1}>
        <MDBadge 
          variant="contained" 
          color="error" 
          badgeContent="Balance: $0" 
          container
          size="lg"
        />
      </MDBox>
    );
  }

  switch(subStatus) {
    case SubscriptionStatus.past_due:
    case SubscriptionStatus.unpaid:
      return (<PastDue 
          onSubscriptionStatus={handleStatus}
        />);
    default:
      return (<TrackBalance 
          onSubscriptionStatus={handleStatus}
        />);
  }

}

function NoTrackBalanceUser() {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  
  if (!USERID) {
    return (
      <MDBox mx={1}>
        <MDBadge 
          variant="contained" 
          color="error" 
          badgeContent="Balance: $0" 
          container
          size="lg"
        />
      </MDBox>
    );
  }

  return <TrackBalanceUser  userId={USERID} />;
}

function TrackBalance({ onSubscriptionStatus }) {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const userRoleRef = useRef(authUser ? authUser["custom:roleId"]: null);
  const { account: { id: accountId, totalPaymentAmount = 0.0, stripeSubscriptionStatus = "none", 
    totalConversations = 0, billRate = MANZIA_BILL_RATE, 
    accountOwnerEmail: emailAddress } } = useLocalChatbotUser({ userId: USERID });
  const [accountBalance, setAccountBalance] = useState(Math.max(0.0, totalPaymentAmount - (totalConversations * billRate)));
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [paymentStatus, setPaymentStatus] = useState("");

  // Fetch account
  const [getAccount, { loading, error, data, called }] = useLazyQuery(gql`${queries.getManziaAccount}`, {
    variables: { id: accountId }, fetchPolicy: 'cache-and-network',
  });

  // Fetch stripe customer
  const { loading: custLoading, data: custData } = useQuery(gql`${queries.getManziaStripeCheckout}`, {
    variables: { accountId }, fetchPolicy: 'cache-and-network',
  });

  // Stripe Checkout
  const { loading: checkoutLoading, error: checkoutError, value: checkoutData, 
    fetch: addFunds } = useLazyAxios();

  const showPaymentStatus = (status) => setPaymentStatus(status);
  const hidePaymnentStatus = () => setPaymentStatus("");
  
  useEffect(() => {
    // Recent balance
    getAccount();
  }, []);
  
  useEffect(() => {
    if (!data || !called || loading) return;
    const paymentAmt = data.getManziaAccount?.totalPaymentAmount ?? 0.0;
    const conversations = data.getManziaAccount?.totalConversations ?? 0;
    const status = data.getManziaAccount?.stripeSubscriptionStatus ?? "none";
    setAccountBalance(Math.max(0.0, paymentAmt - (conversations * billRate)));
    onSubscriptionStatus(status);
  }, [called, loading]);

  useEffect(() => {
    if (!checkoutData) return;
    
    const { code, result } = checkoutData;
    switch (code) {
      case 'Success':
        if (result?.url) {
          // Go to checkout
          window.location = result.url;
        } else {
          showPaymentStatus(code);
        }
        break;
      default:
        showPaymentStatus("Failed");
        break;
    }
  }, [checkoutData]);

  useEffect(() => {
    if (!checkoutError) return;
    showPaymentStatus("Failed");
  }, [checkoutError]);

  const handleReload = () => {
    showPaymentStatus("");
    getAccount();
  };

  const handleFunds = (billingLimit) => {
    const stripeCustomerId = custData?.getManziaStripeCheckout?.stripeCustomerId;
    const currentLimit = custData?.getManziaStripeCheckout?.billingLimit;
    
    // NOTE: We mulitply by 10 because the number we are senging here is the number
    // of units priced at $1 per 10 units (i.e a package == 10 units)
    const newLimit = Math.trunc(Math.max(0, billingLimit - (currentLimit ?? 0)) * 10);
  
    const request = {
      method: ManziaStripeAPI.createCheckoutSession.method, 
      url: ManziaStripeAPI.createCheckoutSession.url, 
      data: {
        accountId,
        email: emailAddress,
        billingLimit: newLimit,
        subscriptionStatus: stripeSubscriptionStatus ?? "none"
      },
      timeout: 30000,
      maxRedirects: 5, 
    };

    // Stripe customer
    if (stripeCustomerId){
      request.data.stripeCustomerId = stripeCustomerId;
    }

    // Stripe subscription
    const stripeSubscription = custData?.getManziaStripeCheckout?.stripeSubscription;
    if (stripeSubscription) {
      request.data.stripeSubscription = stripeSubscription;
    }

    // Payment Time !!!!
    addFunds()(request);
  }

  const getColors = () => {
    if (accountBalance < 5.0) return "error";
    if (accountBalance < 10.0) return "warning";
    return "success";
  }

  const formattedBalance = () => {
    const numberFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
    });

    return numberFormat.format(accountBalance.toFixed(2));
  }

  const color = getColors();

  const renderPaymentSuccess = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Add Funds"
      content="Spending limit has been successfully adjusted."
      dateTime=""
      open={paymentStatus === "Success"}
      onClose={hidePaymnentStatus}
      close={hidePaymnentStatus}
    />
  );

  const renderPaymentError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Add Funds"
      content="Error encountered while adding funds. Try again later."
      dateTime=""
      open={paymentStatus === "Failed"}
      onClose={hidePaymnentStatus}
      close={hidePaymnentStatus}
    />
  );

  return (
      <>
        
          <MDBox mx={1}>
            <Tooltip title="Monthly spending limit less monthly usage for current billing cycle." placement="top">
                <MDBadge 
                  variant="contained" 
                  color={color} 
                  badgeContent={`Balance: ${formattedBalance()}`} 
                  container
                  size="lg"
                />
            </Tooltip>
              
            </MDBox>
            {(loading || checkoutLoading || custLoading) ? 
              (<MDBox mx={1}>
                <CircularProgress
                  size={24}
                  sx={{
                    color: darkMode ? colors.white.main : colors.info.main,
                  }}
                />
                </MDBox>) : 
              (<MDBox mx={1}>
                <Tooltip title="Refresh balance" placement="top">
                  <MDButton 
                    color={darkMode ? "white" : "dark"}
                    variant="outlined"
                    onClick={handleReload}
                    disabled={!isEmptyObject(error)}
                    iconOnly
                  >
                    <Icon>refresh</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>)
            }
       
        
        <MDBox mx={1}>
          <SubscribeButton
            checkoutHandler={handleFunds}
            subscriptionStatus={stripeSubscriptionStatus ?? "none"}
            currentLimit={custData?.getManziaStripeCheckout?.billingLimit}
            disabled={checkoutLoading || checkoutError?.length > 0 || custLoading || userRoleRef.current !== OnboardRole.ACCOUNT_OWNER}
          >
            <Icon>add</Icon>&nbsp; Add Funds
          </SubscribeButton>
          {/* <MDButton 
            variant="gradient" 
            color="info"
            onClick={handleFunds}
            disabled={checkoutLoading || checkoutError?.length > 0 || custLoading || userRoleRef.current !== OnboardRole.ACCOUNT_OWNER}
          >
            <Icon>add</Icon>&nbsp; Add Funds
          </MDButton> */}
        </MDBox>
        {renderPaymentSuccess}
        {renderPaymentError}
      </>
  );
}

TrackBalance.propTypes = {
    onSubscriptionStatus: PropTypes.func.isRequired
};

TrackBalanceUser.propTypes = {
  userId: PropTypes.string.isRequired
};

export default NoTrackBalanceUser;