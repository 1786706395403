/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useRef } from "react";

// react-routers components
import { useParams } from "react-router-dom";

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import MDButton from "components/MDButton";

// Auth
import { AuthUserContext } from "App";

// State Views
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import NoDataView from "layouts/common/NoDataView";

// Manzia
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";
import SendEmail from "layouts/conversations/components/SendEmail";

// Helpers
import { extractAvatarInitials } from "helpers";
import moment from "moment";


function Conversation({ shadow }) {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const timestampRef = useRef();
  const { conversationId } = useParams();
  const { chatbot: { chatbotName } } = useLocalChatbotUser({ userId: USERID });

  // Fetch conversation
  const { loading: convLoading, error: convError, data: convData } = useQuery(gql`${queries.getManziaConversation}`, {
    variables: { sessionId: conversationId }, fetchPolicy: 'cache-first',
  });
  
  // Fetch utterances
  const { loading, error, data } = useQuery(gql`${queries.listManziaUtterances}`, {
    variables: { sessionId: conversationId }, fetchPolicy: 'cache-first',
  });

  if (loading || convLoading) return (
    <LoadView loadingMessage="Fetching messages..." />
  );

  if (error || convError) return (
    <ErrorView error={{ message: `${error || convError}}` }} />
  );

  if (!convData?.getManziaConversation) return (
    <NoDataView 
      info={{ title: "No conversation found", message: "No conversation was found for the selected session. Please reach out to the Manzia AI Support Team." }}
      action={{ label: "Manzia AI Support Team", route: "/support" }}
    />
  )

  function displayName(role) {
    switch (role) {
      case 'system':
        return chatbotName;
      default:
        return "Visitor"
    }
  }

  function getTitle() {
    if (!timestampRef.current) return "Conversation";
    const chatMom = moment.unix(timestampRef.current);
    return `Conversation - ${chatMom.format("ddd, MMM Do YYYY, h:mm A")}`;
  }

  // const downloadConversation = () => {};

  const messages = data.listManziaUtterances?.items ?? [];
  if (!timestampRef.current && messages.length > 0) {
    timestampRef.current = messages[0].timestamp;
  }
  
  const renderMessages = messages.map(({ role, content: message, timestamp }) => (
    <MDBox key={`${timestamp}`} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <MDBox mr={2}>
      <MDAvatar bgColor={role === 'system' ? "info" : "error"} size="sm" shadow="md">{extractAvatarInitials(displayName(role))}</MDAvatar>
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {displayName(role)}
        </MDTypography>
        <MDTypography variant="button" color="dark" fontWeight="regular">
          {message}
        </MDTypography>
      </MDBox>
    </MDBox>
  ));

  return (
    <Card sx={{ 
        height: "100%", 
        minHeight: "75vh", 
        overflowY: "auto", 
        boxShadow: !shadow && "none",
        marginTop: 2 
    }}>
      <MDBox pt={3} px={3}>
      <Grid container spacing={3} justifyContent="center" alignItems="flex-end">
        <Grid item xs={12} sm={8}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {getTitle()}
          </MDTypography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <SendEmail conversation={convData.getManziaConversation} />
        </Grid>
        {/* <Grid item xs={6} sm={2}>
          <MDButton 
            variant="outlined" 
            color="dark" 
            fullWidth
            onClick={downloadConversation}
          >
            Download
          </MDButton>
        </Grid> */}
      </Grid>
        
      </MDBox>
      <MDBox p={3}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderMessages}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
Conversation.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
Conversation.propTypes = {
  shadow: PropTypes.bool,
};

export default Conversation;
