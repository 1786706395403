const createTestConversationChatMessage = `
  mutation CreateTestConversationChatMessage(
    $conversationInput: CreateManziaTestConversationInput!,
    $conversationCondition: ModelManziaTestConversationConditionInput,
    $chatMessageInput: CreateManziaTestChatMessageInput!,
    $chatMessageCondition: ModelManziaTestChatMessageConditionInput,
  ) {
    a1: createManziaTestConversation(input: $conversationInput, condition: $conversationCondition) {
      id
      userProductId
      testedByUserId
      lastModifiedAt
      title
      isExample
      deleteTime
      testChatMessages {
        items {
          id
          testConversationId
          timestamp
          message
          sender
          lastModifiedAt
          deleteTime
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    },
    a2: createManziaTestChatMessage(input: $chatMessageInput, condition: $chatMessageCondition) {
        id
        testConversationId
        timestamp
        message
        sender
        lastModifiedAt
        deleteTime
        createdAt
        updatedAt
    }
  }
`;

export default createTestConversationChatMessage;