/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';
// import * as mutations from 'graphql/mutations';

// Mutations
import useDeleteConversation from "customhooks/useDeleteConversation";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Auth
import { AuthUserContext } from "App";

// State Views
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import NoDataView from "layouts/common/NoDataView";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

// Manzia
import ChatDelete from "layouts/test/products/components/ChatDelete";

// Utils
import { sortByField } from "helpers";

// Constants
import { CONVERSATION_TITLE } from "appSettings";

function Sidenav({ product, onChatSelected }) {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  
  // Fetch conversations
  const [conversations, setConversations] = useState([]);
  const [ , setActiveConversation] = useState();
  const { loading, error, data } = useQuery(gql`${queries.manziaTestConversationsByTestedByUserIdAndUserProductId}`, {
    variables: product ? { testedByUserId: USERID, userProductId: {eq: product.id}} : { testedByUserId: USERID }, 
    fetchPolicy: 'cache-and-network',
  });
  

  const { loading: deleteLoading, error: deleteError, 
    runCommand: deleteConversation } = useDeleteConversation();

  function filterDelete(conv) {
    // Has no title set
    let canDelete = true;
    canDelete = canDelete && conv.title === CONVERSATION_TITLE;
    return canDelete;
  }

  function filterConversation(convs = []) {
    let keepArray = [];
    const deleteArray = [];
    
    convs.forEach((conv) => {
      if (filterDelete(conv)) {
        deleteArray.push({ ...conv });
      } else {
        keepArray.push({ ...conv });
      }
    });
    deleteArray.sort(sortByField('lastModifiedAt', false));
    const first = deleteArray.shift();
    if (first) keepArray = [ first, ...keepArray ]; 
    
    return { keepArray, deleteArray };
  }

  useEffect(() => {
    if (data?.manziaTestConversationsByTestedByUserIdAndUserProductId?.items.length > 0) {
      const { keepArray, deleteArray } = filterConversation(data.manziaTestConversationsByTestedByUserIdAndUserProductId.items);
      keepArray.sort(sortByField('lastModifiedAt', false));
      setConversations(keepArray);
      // onChatSelected(convArr[0]);
      deleteConversation()(deleteArray);
    }
  }, [data]);

  const handleSelection = (conv) => {
    setActiveConversation(conv);
    onChatSelected(conv);
  }

  const handleDelete = (conv) => {
    // Update state
    setConversations((prevState) => (
      [ ...prevState.filter(item => item.id !== conv.id) ]
    ));
  }

  const handleStartConversation = () => {
    const noMessages = conversations.filter(conv => conv.title === CONVERSATION_TITLE);
    onChatSelected(noMessages[0]);
  }


  const renderSidenavItems = conversations.map((item, key) => {
    const { id, title } = item;
    return (
      <MDBox key={id} component="li" pt={key === 0 ? 0 : 1}>

        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(5)} ${pxToRem(8)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",  
            "&:hover": {
              backgroundColor: light.main,
            },
            '&:hover .actionButton': {
              visibility: 'visible',
            }
          })}
        >
          <MDButton 
              variant="text" 
              onClick={() => handleSelection({ ...item })}
              color={darkMode ? "white" : "dark"}
          >
            {title.length > 20 ? `${title.substring(0, 20)}...` : title}
          </MDButton>
          <MDBox
            className="actionButton"
            lineHeight={1} 
            color={darkMode ? "white" : "text"}
            sx={{ visibility: 'hidden' }}
          >
            <ChatDelete 
              conversation={{ ...item }}
              onDeleted={handleDelete}
            />
            {/* <IconButton 
              size="small" 
              aria-label="delete" 
              color="text"
              onClick={() => handleDelete({ ...item })}
            >
              <Tooltip title="Delete" placement="top">
                <DeleteIcon fontSize="small" />
              </Tooltip>
            </IconButton> */}
          </MDBox>
        </MDTypography>
      </MDBox>
    );
  });

  function getError() {
    let noError = true;
    noError = noError && !error;
    if (!noError) return { noError, error };
    noError = noError && !deleteError;
    if (!noError) return { noError, error: deleteError };

    return { noError, error: undefined };
  }

  const { noError, error: errorMessage } = getError();

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <MDBox p={2}>
        <MDButton 
          variant="gradient" 
          color="info" 
          fullWidth
          onClick={handleStartConversation}
        >
          <Icon>add</Icon>&nbsp; New Chat
        </MDButton>
      </MDBox>
      {(loading || deleteLoading) && <LoadView />}
      {!noError && <ErrorView error={{ message: `${errorMessage}` }} />}
      {(conversations.length === 0 && noError) ? 
        (<NoDataView info={{ text: "No Chats", message: ``}} />) : 
        (<MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={2}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {conversations.length > 0 ? renderSidenavItems : null}
        </MDBox>)
      }
      
    </Card>
  );
}

Sidenav.defaultProps = {
  product: null
}

Sidenav.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  onChatSelected: PropTypes.func.isRequired
};

export default Sidenav;
