
// React router
import { Outlet } from "react-router-dom";

function AppRoutes() {
    return ( 
        <Outlet /> 
    );
  }

export default AppRoutes;