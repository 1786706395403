/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
// import MDButton from "components/MDButton";

// Auth
import { AuthUserContext } from "App";

// Manzia
import useLocalChatbotUser from 'customhooks/useLocalChatbotUser';
import { MANZIA_BILL_RATE } from "appSettings";


function NoBalance() {
    return (
        <MDBox my={2}>
            <MDAlert color="warning">
                <MDTypography variant="button" color="white" fontWeight="medium">
                    Account has zero balance. Click Add Funds above to stop missing out on visitor conversations.
                </MDTypography>
            </MDAlert>
        </MDBox>
    );
}

function BalanceState() {
    // User
    const authUser = useContext(AuthUserContext);
    const USERID = authUser?.sub;
    const {  account } = useLocalChatbotUser({ userId: USERID });
    if (!account) return null;
    const { totalPaymentAmount = 0.0, totalConversations = 0,
        billRate = MANZIA_BILL_RATE } = account;
    const balance = Math.max(0.0, totalPaymentAmount - (totalConversations * billRate));

    return balance < 1.0 ? <NoBalance /> : null;
}

export default BalanceState;