/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { Hub } from 'aws-amplify';

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


// Manzia
// import NotificationView from 'layouts/common/Notification';
import { UserFlows, ManziaAuthState } from "appSettings";
import { maskEmail, IS_VALID_CODE } from "helpers";
import useAuth from "customhooks/useAuth";

// Common components
import NotificationView from "layouts/common/Notification";
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";

function AuthConfirmSignup({ username }) {
    
    const navigate = useNavigate();
    const [signupCode, setSignupCode] = useState('');

    // Confirm signup
    const { loading: confirmLoading, error: confirmError, 
        command: authConfirmSignup } = useAuth(ManziaAuthState.confirmSignUp.key);

    // Confirm signup
    const { loading: resendLoading, error: resendError, 
        value: resendData, command: authResend } = useAuth(ManziaAuthState.resendSignUp.key);


    useEffect(() => {
        // Listen for signup confirmation success
        const confirmListener = Hub.listen('auth', ({ payload }) => {
            const { event } = payload;
            if (event === 'autoSignIn') {
                /**
                 * user.attributes: {sub: '10225566-9903-4b6a-aaa9-561d78f72cf2', email_verified: true, name: 'Roy Manzi', email: 'roymanzi@yahoo.com'}
                 */
                const { attributes: { sub: userId, email } } = payload.data;
                navigate("/onboard/backend", { state: { 
                    userFlow: UserFlows.SIGNUP_FLOW, 
                    userId,
                    userEmail: email
                }, replace: true });

              // assign user
            } else if (event === 'autoSignIn_failure') {
              // redirect to sign in page
              navigate("/", { replace: true });
            }
        });

        return () => confirmListener();

    }, []);

    const handleChange = ({ currentTarget }) => setSignupCode(currentTarget.value);

    const handleSubmit = () => {
        // Confirm signup
        authConfirmSignup()({
            username, 
            code: signupCode
        });
    };

  const handleResend = () => {
    // Resend code
    authResend()({ username });
  };

  const getConfirmErrMessage = () => {
    const error = confirmError || resendError;
    if (!error) return error;
    const { name: errorCode } = error;
    const email = maskEmail(username);
    switch (errorCode) {
        case 'CodeDeliveryFailureException':
            return `Failed to deliver verification code to ${email}`;
        case 'UserNotFoundException':
            return "Unknown user account. Try to signup again."
        case 'TooManyFailedAttemptsException':
            return "Too many attempts. Wait a few minutes and try again.";
        case 'CodeMismatchException':
            return "Provided verification code does not match expected value. Try again.";
        case 'ExpiredCodeException':
            return "Provided verification code has expired. Please click resend code.";
        case 'AliasExistsException':
            return `Account with provided email: ${email} already exists. Please login.`;
        case 'UsernameExistsException':
            return `Found existing account with provided email: ${email}. Please login.`;
        case 'TooManyRequestsException':
            return "Too many signup attempts. Wait a few minutes and try again.";
        case 'NotAuthorizedException':
            return "Not authorized. Please try signing up again.";
        default:
            return "Encountered an error during signup. Please try again later.";
    }
  }

  const error = getConfirmErrMessage();

  return (
    <MDBox>
        {resendLoading && <LoadView />}
        {resendData ? 
        <NotificationView 
          color="success"
          message="Verification code resent."
        /> : 
        <MDTypography variant="body2" color="text" fontWeight="regular">
            {`A verification code has been sent to ${maskEmail(username)}. Enter it below.`}
        </MDTypography>
        }
        {error && <ErrorView error={{ message: `${error}`}} />}
        <MDBox my={2}>
            <MDInput 
                name="confirmSignup"
                type="text"
                label="Enter verification code"
                variant="outlined"
                value={signupCode}
                onChange={handleChange}
                fullWidth 
            />
        </MDBox>
        <MDBox 
            mt={4} 
            mb={1}
            display="flex"
            alignItems="center"
            >
            {confirmLoading && <CircularProgress />}
            <MDButton 
                variant="gradient" 
                color="info" 
                size="large"
                type="submit" 
                fullWidth
                disabled={!IS_VALID_CODE.test(signupCode)}
                onClick={handleSubmit}
            >
                Confirm Signup
            </MDButton>
        </MDBox>
        <MDBox mt={3} textAlign="center">
            <MDTypography variant="button" color="text">
                Did not receive code?{" "}
                <MDTypography
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                    sx={{ cursor: "pointer" }}
                    onClick={handleResend}
                >
                    Resend code
                </MDTypography>
            </MDTypography>
        </MDBox>
    </MDBox>
  )
}

AuthConfirmSignup.propTypes = {
    username: PropTypes.string.isRequired,
};

export default AuthConfirmSignup;
