/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Chat
// import ChatUI from "layouts/train/new-product/components/Chat";
// import ProductIntent from "layouts/train/new-product/components/Intent";
// import ExpandAccordion from "layouts/train/new-product/components/Expand";

// Intent Data
// import { socialInfo } from "layouts/train/new-product/components/Intent/intentdata";

// NewUser page components
import DynamicFormField from "layouts/train/new-product/components/DynamicFormField";

function SocialProof({ formData }) {
  const { formField: { testimonials, reviews, caseStudies, socialMedia, 
    press, productName }, values } = formData;
 
  return (
    <MDBox>
      {/* <ExpandAccordion title="Sample utterances and conversation flow">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
              <ProductIntent 
                utterances={socialInfo.utterances}
                intentInfo={socialInfo.info} 
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChatUI chats={socialInfo.conversations} />
          </Grid>
        </Grid>
      </ExpandAccordion> */}
      

      <MDBox my={4}>
        <MDTypography variant="h5" fontWeight="bold" color="dark">
          Social Proof (Building Trust)
        </MDTypography>
        <MDTypography variant="body2" color="dark">
            {`${values[productName.name]} can direct web visitors to testimonials, reviews, case studies references in order to address trust related objections.`} 
        </MDTypography>
      </MDBox>
      
      
      <MDBox mt={1.625}>
        <MDBox my={1}>
          <MDTypography variant="body2" color="dark" fontWeight="medium">Testimonials</MDTypography>
        </MDBox>
        <DynamicFormField 
          label={testimonials.label}
          type={testimonials.type}
          name={testimonials.name}
          placeholder={testimonials.placeholder}
        />

        <MDBox my={1}>
          <MDTypography variant="body2" color="dark" fontWeight="medium">Reviews</MDTypography>
        </MDBox>
        <DynamicFormField 
          label={reviews.label}
          type={reviews.type}
          name={reviews.name}
          placeholder={reviews.placeholder}
        />

        <MDBox my={1}>
          <MDTypography variant="body2" color="dark" fontWeight="medium">Case Studies</MDTypography>
        </MDBox>
        <DynamicFormField 
          label={caseStudies.label}
          type={caseStudies.type}
          name={caseStudies.name}
          placeholder={caseStudies.placeholder}
        />

        <MDBox my={1}>
          <MDTypography variant="body2" color="dark" fontWeight="medium">Social Media</MDTypography>
        </MDBox>
        <DynamicFormField 
          label={socialMedia.label}
          type={socialMedia.type}
          name={socialMedia.name}
          placeholder={socialMedia.placeholder}
        />

        <MDBox my={1}>
          <MDTypography variant="body2" color="dark" fontWeight="medium">Press &amp; Media</MDTypography>
        </MDBox>
        <DynamicFormField 
          label={press.label}
          type={press.type}
          name={press.name}
          placeholder={press.placeholder}
        />

      </MDBox>
    </MDBox>
  );
}

// typechecking props for Profile
SocialProof.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default SocialProof;
