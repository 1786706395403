import { useCallback, useState } from "react"

// Apollo client
import { useApolloClient, gql, } from '@apollo/client';

// GraphQL
import * as queries from 'graphql/queries';

// Mutations
import { deleteConversationsMutation } from "layouts/test/products/components/Sidenav/mutations";

export default function useDeleteConversation(dependencies = []) {
    const apolloClient = useApolloClient();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [value, setValue] = useState();

    function appendChatMessages(conversation) {
        if (Array.isArray(conversation.testChatMessages.items)) return conversation;
        // Fetch the cached items
        const data = apolloClient.readQuery({
            query: gql`${queries.getManziaTestConversation}`,
            variables: { id: conversation.id },
        });
        if (!data || !data.getManziaTestConversation) return conversation;
       
        return { ...data.getManziaTestConversation };
    }

    const runCommand = useCallback(() => (conversations) => {
        setLoading(true);
        setError(undefined);
        setValue(undefined);
        
        const updatedConvs = conversations.map(conv => appendChatMessages(conv));
        const mutation = deleteConversationsMutation(updatedConvs);
        if (mutation) {
            apolloClient.mutate({
                mutation: gql`${mutation}`,
                refetchQueries: [ 
                    'ManziaTestConversationsByTestedByUserIdAndUserProductId',
                ]
            }).then((data) => setValue(data))
                .catch(() => { 
                    setError(`Error: ApolloClient delete conversations operation failed.`);
                    // console.log("Error: ", JSON.stringify(err));
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
        
    }, dependencies);

    return { loading, error, value, runCommand };
}
