import * as React from 'react';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// GraphQL
import { gql, useMutation } from '@apollo/client';
import * as mutations from 'graphql/mutations';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Material Dashboard
import MDButton from 'components/MDButton';

// Manzia
import ProgressButton from 'layouts/common/ProgressButton';
import NotificationView from 'layouts/common/Notification';

function DeleteButton({ userProduct, children, ...rest }) {
  const [open, setOpen] = React.useState(false);
  const [deleteProduct, { data, loading, error }] = useMutation(gql`${mutations.deleteManziaUserProduct}`, {
    refetchQueries: [ 'manziaUserProductsByTrainedChatBotId'],
    variables: { input: { id: userProduct.id } }
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getDialogContent() {
      return (
        <DialogContent>
          <DialogContentText>
            {`Delete is irreversible. Are you sure you want to delete ${userProduct.productName}?`}
          </DialogContentText>
          
          {error && <NotificationView 
            color="error"
            message={`Error while deleting ${userProduct.productName}. Please retry.`}
          />}
          {data && <NotificationView 
            color="success"
            message={`Success. ${userProduct.productName} was successfully deleted.`}
          />}

        </DialogContent>
      );
  }

  return (
    <div>
      <MDButton 
          {...rest}
          onClick={handleClickOpen}
      >
        {children}
      </MDButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Product:</DialogTitle>
        {getDialogContent()}
        <DialogActions>
          <MDButton 
              variant="outlined"
              color="dark" 
              onClick={handleClose}
          >
            Cancel
          </MDButton>
          {data || error ? 
            (<MDButton variant="gradient" color="dark" disabled>
              Delete
            </MDButton>) :
            (<ProgressButton 
                variant="gradient"
                color="error" 
                clickHandler={deleteProduct}
                loading={loading}
              >
                Delete
              </ProgressButton>)
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

// Typechecking props for BaseLayout
DeleteButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  userProduct: PropTypes.shape({
    id: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired
  }).isRequired,
};


export default  DeleteButton;