/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// GraphQL
import { gql, useMutation } from '@apollo/client';
import * as mutations from 'graphql/mutations';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Hooks
// import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Manzia
import RadioButtons from "layouts/onboard/components/RadioButtons";
import { OnboardRole, OnboardState, UserFlows } from "appSettings";
import ProgressButton from "layouts/common/ProgressButton";

// State Views
import ErrorView from "layouts/common/ErrorView";

function getRoleLabel(role) {
  switch (role) {
      case OnboardRole.ACCOUNT_OWNER:
          return "Owner".toUpperCase();
      case OnboardRole.TEAM_MEMBER:
          return "Member".toUpperCase();
      case OnboardRole.DEVELOPER:
          return "Developer".toUpperCase();
      default:
          return "";
  }
}

function UserRoles({ userId, userRole, onboardState, invitation }) {

  const { accountId, organizationName, emailAddress }  = invitation;

  // const authUser = useContext(AuthUserContext);
  // const { name: fullName = "Roy Tester" } = authUser;

  const navigate = useNavigate();

  const [teamMember, setTeamMember] = useState('team_member_true');
  const [teamDeveloper, setTeamDeveloper] = useState("team_developer_true");
  const [createAccount, setCreateAccount] = useState("create_account_true");

  const [createUserRole, { data: roleData, loading: roleLoading, 
    error: roleError }] = useMutation(gql`${mutations.createManziaUserRole}`, {
    refetchQueries: [ 'GetManziaUserRole']
  });

  // Add user to account
  const [updateUser, { data: userData, loading: userLoading, 
    error: userError }] = useMutation(gql`${mutations.updateManziaUser}`);

  // Add user to account
  const [deleteInvite, { data: deleteData, loading: deleteLoading, 
    error: deleteError }] = useMutation(gql`${mutations.deleteManziaTeamInvite}`);

  useEffect(() => {
    if (!roleData) return;
    if (teamMember === 'team_member_true') {
      // Create team member
      updateUser({
        variables: {
          input: {
            id: userId,
            accountId,
            emailAddress,
            roleId: OnboardRole.TEAM_MEMBER,
          }
        }
      });

    } else if (teamDeveloper === 'team_developer_true') {
      // Create developer
      updateUser({
        variables: {
          input: {
            id: userId,
            accountId,
            emailAddress,
            roleId: OnboardRole.DEVELOPER,
          }
        }
      });
    }
  }, [roleData]);

  // Navigation
  useEffect(() => {
    if (!userData) return;
    const { roleId } = userData.createManziaUser;
    switch (roleId) {
      case OnboardRole.DEVELOPER:
        // Navigate to settings
        navigate("/settings/install-bot", { state: {
          navItemPath: 'install-bot'
        }, replace: true });
        break;
      case OnboardRole.TEAM_MEMBER:
        // Navigate to home page
        navigate("/products", { replace: true });
        break;
      default:
        // Navigate to home page
        navigate("/products", { replace: true });
        break;
    }
  }, [userData])

  useEffect(() => {
    if (!deleteData) return;
    navigate("/onboard/backend", { state: { 
        userFlow: UserFlows.SIGNUP_FLOW, 
        userId,
        userEmail: emailAddress
    }, replace: true });
  }, [deleteData]);

  const handleOption = (option) => {
    if (option.teamMember) setTeamMember(option.teamMember);
    if (option.teamDeveloper) setTeamDeveloper(option.teamDeveloper);
    if (option.createAccount) setCreateAccount(option.createAccount);
  };


  const handleOnboarding = () => {
    // Join account as team member
    if (teamMember === 'team_member_true') {
        // deleteTime: now.add(90, 'days').unix(),
      createUserRole({
        variables: {
          input: {
            userId,
            roleId: OnboardRole.TEAM_MEMBER,
            accountId,
            roleName: OnboardRole.TEAM_MEMBER
          }
        }
      });
    } else if (teamDeveloper === 'team_developer_true') {
      // Join team as developer
      createUserRole({
        variables: {
          input: {
            userId,
            roleId: OnboardRole.DEVELOPER,
            accountId,
            roleName: OnboardRole.DEVELOPER
          }
        }
      });
    } else {
      // Create a new account
      // 1. Delete invitation
      // 2. Navigate to backend
      deleteInvite({
        variables: {
          input: { accountId, emailAddress }
        }
      });
    }

  };

  return (
    <Card id="user-roles" sx={{ overflow: "visible" }}>
      <MDBox px={3} pt={3}>
        <MDTypography variant="h2">Welcome to Manzia</MDTypography>
        <MDTypography variant="body2">{`We've detected that you received an email invitation from ${organizationName} to join their team in a ${getRoleLabel(userRole)} role.`}</MDTypography>
      </MDBox>
      {(roleError || userError || deleteError) && <ErrorView error={{ message: `${roleError || userError}` }} />}
      <Divider />
      <MDBox px={3} pb={3}>
          {(userRole === OnboardRole.TEAM_MEMBER) && <MDBox my={2}>
            <RadioButtons 
              name="teamMember"
              title={`Do you want to continue and sign up with the MEMBER role in the ${organizationName} team?`}
              value={teamMember}
              handleChange={handleOption}
              radioList={[
                { id: "team_member_true", value: "team_member_true", label: "Yes"},
                { id: "team_member_false", value: "team_member_false", label: "No"}
              ]}
            />
             <Divider />
          </MDBox>}
          {(userRole === OnboardRole.DEVELOPER) && <MDBox my={2}>
            <RadioButtons 
              name="teamDeveloper"
              title={`Do you want to continue and sign up with the DEVELOPER role in the ${organizationName} team?`}
              value={teamDeveloper}
              handleChange={handleOption}
              radioList={[
                { id: "team_developer_true", value: "team_developer_true", label: "Yes"},
                { id: "team_developer_false", value: "team_developer_false", label: "No"}
              ]}
            />
             <Divider />
          </MDBox>}
          {(teamMember === 'team_member_false' 
            || teamDeveloper === 'team_developer_false' ) && <MDBox my={2}>
            <RadioButtons 
              name="createAccount"
              title="Do you want to create a separate new account with Manzia?"
              value={createAccount}
              handleChange={handleOption}
              radioList={[
                { id: "create_account_true", value: "create_account_true", label: "Yes"},
                { id: "create_account_false", value: "create_account_false", label: "No"}
              ]}
            />
             <Divider />
          </MDBox>}
          {(teamDeveloper === 'team_developer_true' && onboardState === OnboardState.NO_PRODUCTS) && 
          <MDTypography variant="h5">
              Send notification to account owner
          </MDTypography>}
      </MDBox>
      <MDBox p={3} display="flex" justifyContent="flex-end">
        <ProgressButton 
            variant="gradient"
            color="info" 
            clickHandler={handleOnboarding}
            loading={roleLoading || userLoading || deleteLoading}
            size="large"
            disabled={roleLoading || roleError?.length > 0 || userError?.length > 0 || userLoading || deleteLoading}
          >
            Next
        </ProgressButton>
        
      </MDBox>
    </Card>
  );
}

UserRoles.propTypes = {
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  onboardState: PropTypes.string.isRequired,
  invitation: PropTypes.shape({
    emailAddress: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    organizationName: PropTypes.string.isRequired
  }).isRequired
};


export default UserRoles;
