const template = `
/* Example custom css file for lex-web-ui. Entire file is commented out as a default. Uncomment and
adjust as needed. */

.toolbar.theme--dark {
   background-color: {{toolbar.themedark.backgroundColor}} !important;
}

.toolbar__title {
 font-family: {{toolbar.title.fontFamily}} !important;
 font-size: {{toolbar.title.fontSize}}em !important;
 color: {{toolbar.title.color}} !important;
}

.message-list-container {
 background-color: {{messagelistcontainer.backgroundColor}} !important
}

.message-bot .message-bubble {
   background-color: {{messagebubble.messagebot.backgroundColor}} !important;
}

.message-human .message-bubble {
   background-color: {{messagebubble.messagehuman.backgroundColor}} !important;
}

.message-bubble p {
   margin-bottom: 8px;
}

.message-bubble p:last-child {
   margin-bottom: 0px;
}

.message-bubble .message-text {
   padding-left:  0;
   padding-right: 0;
   line-height: 1.6;
   font-size: {{messagebubble.messagetext.fontSize}}rem;
}

.message-bubble {
   border-radius: 10px !important;
   padding: 2px 18px !important;
}

.message-text {
 color: {{messagebubble.messagetext.color}};
 width: 100%;
}

.headline {
   font-size: 1.2rem !important;
   line-height: 1.4 !important;
}

.card__title {
   padding: 10px 16px !important;
}

.card__text {
   padding: 8px 16px 16px !important;
   line-height: 1.4;
}

.card__title.card__title--primary {
   background-color: {{cardtitle.cardtitleprimary.backgroundColor}} !important;
}


.input-group--text-field input,
.input-group--text-field textarea,
.input-group--text-field label {
   font-size: 14px !important;
}

.card__actions .btn {
 margin: 4px 4px !important;
 font-size: 1em !important;
 min-width: 44px !important;
 background-color: {{cardactions.btn.backgroundColor}} !important;
}

button.min-button {
   border-radius: 60px;
}

.message-button {
   display: none;
}
`;

export default template;