/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef, useContext } from "react";

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

// GraphQL
import { gql, useMutation, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from 'graphql/subscriptions';

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
// import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";

// Auth
import { AuthUserContext } from "App";

// Custom Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";
import useLazyAxios from "customhooks/useLazyAxios";
import useSessionStorage from "customhooks/useSessionStorage";
import useAxios from "customhooks/useAxios";
import useLocalUserProducts from "customhooks/useLocalUserProducts";
import useLocalOnboardState from "customhooks/useLocalOnboardState";

// formik components
import { Formik, Form, ErrorMessage } from "formik";

// Manzia
// import NextSteps from "layouts/onboard/components/NextSteps";
import ErrorView from "layouts/common/ErrorView";
import LoadView from "layouts/common/LoadView";
import DelayedLoadView from "layouts/common/DelayedLoadView";
import LoadMessages from "layouts/onboard/components/WelcomeView/loadMessages";
import NotificationView from "layouts/common/Notification";
import InputField from "layouts/onboard/components/InputField";
import InputURL from "layouts/onboard/components/InputURL";
import FormField from "layouts/train/new-product/components/FormField";
import { SIGNUP_API, OnboardState, ManziaAuthKeys } from "appSettings";
import { updateManziaProductWithTraining, 
  createManziaProductWithTraining } from "layouts/onboard/components/WelcomeView/prodmutations";

// schemas for form and form fields
import validations from "layouts/onboard/schemas/validations";
import form from "layouts/onboard/schemas/form";
import initialValues from "layouts/onboard/schemas/initialValues";
import { isValidUrl } from "helpers";
import demoTypes from "layouts/train/new-product/options/demoTypes";

// Utils
import moment from "moment";

// Constants
const productTimeout = 90000;

function WelcomeView() {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { state } = useLocation();
  const userId = state?.userId || USERID;
  const { loginUser } = useLocalChatbotUser({ userId });
  const loginEmail = state?.userEmail || loginUser?.emailAddress;
  const { onboardState = OnboardState.NO_PRODUCTS } = useLocalOnboardState({ emailAddress: loginEmail });
  const { userProducts, productTrainings } = useLocalUserProducts({ userProductId: userId, productTrainingId: userId });
  const [boardingState, setBoardingState] = useState((userProducts.length > 0 || productTrainings.length > 0) ? OnboardState.CREATED_PRODUCT : (state?.onboardState || onboardState));
  
  const [submittedValues, setSubmittedValues] = useState(initialValues.welcome);
  const [savedValues, setSavedValues] = useSessionStorage(ManziaAuthKeys.welcomeProduct, initialValues.welcome);
  const submitCount = useRef(0);
  const [productDetails, setProductDetails] = useState(null);
  const [productError, setProductError] = useState(null);
  const [prodLoading, setProdLoading] = useState(false);
  const navigate = useNavigate();

  const { formField: 
    {  botName, botTone, productName, productPage, demos }, formId } = form;

  const { loading: userLoading, error: userError, data: userData } = useQuery(gql`${queries.getManziaUser}`, {
    variables: { id: userId }, fetchPolicy: 'cache-and-network',
  });

  const { loading, error, value, fetch } = useLazyAxios();

  // Fetch onboard state
  const { loading: onboardStateLoading, error: onboardStateError, 
    data: onboardStateData } = useQuery(gql`${queries.getManziaOnboard}`, {
      variables: { emailAddress: loginEmail }, fetchPolicy: 'cache-and-network',
    });

  const [updateBotName, { loading: botLoading, 
    error: botError }] = useMutation(gql`${mutations.updateManziaChatbot}`);

  const [updateProductTraining, { loading: updateLoading, 
    error: updateError }] = useMutation(gql`${updateManziaProductWithTraining}`);

  const [createProductTraining, { data: createData, loading: createLoading, 
    error: createError }] = useMutation(gql`${createManziaProductWithTraining}`);

  const [updateOnboard, { loading: onboardLoading, 
    error: onboardError }] = useMutation(gql`${mutations.updateManziaOnboard}`);

  // Listen for updates
  useEffect(() => {
    // Subscribe to creation of ManziaChatbot
    const createProductSub = API.graphql(
        graphqlOperation(subscriptions.onCreateManziaProductPages, {
          filter: {
            accountId: { eq:  loginUser?.accountId }
          }
      })
    ).subscribe({
        next: ({ value: product }) => {
          // console.log("CreateProduct subscribe value: ", { provider, product });
          const { data: { onCreateManziaProductPages: { productDetails: productData } } } = product;
          try {
            const productJson = JSON.parse(productData);
            setProductDetails(productJson);
            setProductError(null);
          } catch (_) {
            // console.log("Error parsing product json", e);
            setProductError("Error while generating product details");
          }
          setProdLoading(false);
        },
        error: () => { 
          // console.warn("CreateProduct error: ", err);
          setProductError("Error while creating product details");
          setProdLoading(false);
        }
    });

    return () => createProductSub.unsubscribe();
  }, []);
  
    // Update onboard state
  useEffect(() => {
    if (!onboardStateData?.getManziaOnboard) return;
    const { onboardState: boardState } = onboardStateData.getManziaOnboard;
    if (boardState === OnboardState.CREATED_PRODUCT) {
      setBoardingState(boardState);
    }
  }, [onboardStateData])
  
  // Creating product
   /* eslint-disable consistent-return */
  useEffect(() => {
    if (!value) return;

    // Start listening
    if (value.data) { 
      setProductDetails(value.data); 
    } else if (value.errors) { 
      setProductError(value.errors.message || "Encountered error generating product info.");
    }

    // Halt if data or errors
    if (value.data || value.errors) return;
    
    // Set listening timeout
    setProdLoading(true);

    const timeout = setTimeout(() => {
      setProdLoading(false);
      if (!productDetails && !value?.data) {
        setProductError("Create product details timed out.");
      }
    }, productTimeout);

    // Cleanup the interval on component unmount
    return () => clearTimeout(timeout);
    
  }, [value]);
  /* eslint-enable consistent-return */
  
  useEffect(() => {
    if (!productDetails) return;

    // Update submit count
    submitCount.current += 1;

    const { userAccount: { v2chatBotId } } = userData.getManziaUser;
    const now = moment();

    if (boardingState === OnboardState.NO_PRODUCTS && (userProducts.length === 0 || productTrainings.length === 0)) {
      // Create product + training data
      createProductTraining({
        variables: {
          productInput: {
            id: userId,
            productName: submittedValues[productName.name],
            createdByUserId: userId,
            trainedChatBotId: v2chatBotId
          },
          trainingInput: {
            id: userId,
            userProductId: userId,
            trainingData: JSON.stringify({ 
              ...productDetails, 
              productName: submittedValues[productName.name], 
              productPage: `https://${submittedValues[productPage.name]}`,
              demos: {
                [demos.scheduleDemo.name]: `${submittedValues[demos.name][demos.scheduleDemo.name]}`,
                [demos.demoType.name]: submittedValues[demos.name][demos.demoType.name]
              }
            }),
            trainedByUserId: userId,
            trainedChatBotId: v2chatBotId,
            lastModifiedAt: now.format(),
            trainingStatus: "active"
          }
        },
        refetchQueries: [ "GetManziaUser" ]
      })
    } else {
      // Update product + training data
      updateProductTraining({
        variables: {
          productInput: {
            id: userId,
            productName: submittedValues[productName.name]
          },
          trainingInput: {
            id: userId,
            trainingData: JSON.stringify({ 
              ...productDetails, 
              productName: submittedValues[productName.name], 
              productPage: `https://${submittedValues[productPage.name]}`,
              demos: {
                [demos.scheduleDemo.name]: `${submittedValues[demos.name][demos.scheduleDemo.name]}`,
                [demos.demoType.name]: submittedValues[demos.name][demos.demoType.name]
              }
            }),
            lastModifiedAt: now.format()
          }
        },
        refetchQueries: [ "GetManziaUser" ]
      });
    }
    
  }, [productDetails]);

  useEffect(() => {
    if (!createData) return;
    setBoardingState(OnboardState.CREATED_PRODUCT);

    const { emailAddress } = userData.getManziaUser;
    updateOnboard({
      variables: {
        input: {
          emailAddress,
          onboardState: OnboardState.CREATED_PRODUCT,
        }
      }
    });
  }, [createData]);

  useEffect(() => {
    if (!createError) return;
    const { emailAddress } = userData.getManziaUser;
    updateOnboard({
      variables: {
        input: {
          emailAddress,
          onboardState: OnboardState.PRODUCT_ERROR
        }
      }
    });
  }, [createError]);

  const handleSubmit = (values, actions) => {
    // console.log("Submitted: ", values);

    /**
     * Do these on client-side so local cache is updated too!!!
     * - if values have not changed from previous submit, return.
     * - Create ManziaUserProduct & ManziaProductTraining
     */

    const botNameChanged = (submittedValues[botName.name] !== values[botName.name] || submittedValues[botTone.name] !== values[botTone.name]);
    const prodNameChanged = submittedValues[productName.name] !== values[productName.name];
    const prodPageChanged = submittedValues[productPage.name] !== values[productPage.name];
    const demoChanges = (submittedValues[demos.scheduleDemo.name] !== values[demos.scheduleDemo.name]) || (submittedValues[demos.demoType.name] !== values[demos.demoType.name]);
    
    const { userAccount: { id: accountId, v2chatBotId } } = userData.getManziaUser;
    
    // console.log(`botNameChanged: ${botNameChanged}, prodNameChanged: ${prodNameChanged}, prodPageChanged: ${prodPageChanged}`);

    if (botNameChanged) {
      // Update ManziaChatbot
      updateBotName({
        variables: {
          input: {
            id: v2chatBotId,
            chatbotName: values[botName.name],
            chatbotTone: values[botTone.name]
          }
        }
      });
    }

    if (prodPageChanged) {
      // Fetch the product training data as JSON
      fetch()({
        method: 'post', 
        url: SIGNUP_API.createProduct, 
        data: {
          accountId,
          userId,
          v2chatBotId,
          productName: values[productName.name],
          productPage: `https://${values[productPage.name]}`,
          demos: {
            [demos.scheduleDemo.name]: `${values[demos.name][demos.scheduleDemo.name]}`,
            [demos.demoType.name]: values[demos.name][demos.demoType.name]
          }
        },
        timeout: productTimeout,
        maxRedirects: 0, 
      });
    }
    /**
     * Note that we use the userId for the first product & product training instances
     */
    if ((prodNameChanged || demoChanges) && !prodPageChanged && productDetails) {
      updateProductTraining({
        variables: {
          productInput: {
            id: userId,
            productName: values[productName.name]
          },
          trainingInput: {
            id: userId,
            trainingData: JSON.stringify({ 
              ...productDetails, 
              productName: values[productName.name], 
              productPage: `https://${values[productPage.name]}`,
              demos: {
                [demos.scheduleDemo.name]: `${values[demos.name][demos.scheduleDemo.name]}`,
                [demos.demoType.name]: values[demos.name][demos.demoType.name]
              }
            })
          }
        },
        refetchQueries: [ "GetManziaUser" ]
      });
    }

    // Update state
    setSubmittedValues(values);
    setSavedValues(values);
    
    actions.setSubmitting(false);
    // actions.resetForm();
    
  };

  // const handleDemo = (e, value) => {
  //   switch (e.target.name) {
  //     case 'demo-type':
  //       setDemoAction(value);
  //       break;
  //     default:
  //       break;
  //   }
  // }

  const { fetch: checkDomain } = useAxios();

  const validateWebDomain = (domain) => {
    const domainURL = `https://${domain}`;
    if (!isValidUrl(domainURL)) return Promise.resolve(productPage.invalidMsg);
    // if (ENV === 'dev') return Promise.resolve(undefined);

    const domainPromise = new Promise((resolve) => {
      checkDomain()({
        method: 'get',
        url: `${SIGNUP_API.checkDomain}?domain=${domain}`,
        timeout: 8000,
        maxRedirects: 5, 
      })
        .then(() => resolve(undefined))
        .catch(() => resolve(productPage.offlineMsg))
    });
    return domainPromise;
  }

  const firstError = userError || error || createError || onboardStateError || productError;
  const secondError = botError || updateError || onboardError;

  // console.log(`First error: ${firstError}, Second error: ${secondError}`);

  if (userLoading || onboardStateLoading || loading) return (
      <Card id="welcome-view" sx={{ overflow: "visible", marginTop: 3 }}>
        <MDBox p={3}>
          <MDTypography variant="h4" fontWeight="bold" color="dark">Add product information</MDTypography>
          <MDTypography variant="body2" fontWeight="regular" color="dark">Provide the URL to the product information that the chatbot will use when engaging website visitors to your website.</MDTypography>
          <LoadView />
        </MDBox>
      </Card>
  );
  
  return (
      <Card id="welcome-view" sx={{ overflow: "visible", marginTop: 3 }}>
        <MDBox p={3}>
          <MDTypography variant="h4" fontWeight="bold" color="dark">Add product information.</MDTypography>
          <MDTypography variant="body2" fontWeight="regular" color="dark">Provide the URL to the product information that the chatbot will use when engaging website visitors to your website.</MDTypography>
          {firstError && 
            <NotificationView 
              color="error"
              message="Encountered an error. Click below to manually add product information."
              route={{ text: "Add product manually", path: "/new-product", replace: true }}
            />
          }
          {!userData.getManziaUser &&
            <NotificationView 
              color="info"
              message="Missing data. Please click below to manually add product information."
              route={{ text: "Add product manually", path: "/new-product", replace: true }}
            />
          }
          {prodLoading && 
            <DelayedLoadView 
              messages={LoadMessages} 
              cycleTime={productTimeout}
              color="success" 
            />}
        </MDBox>
        
        <MDBox my={1} px={3}>
          {secondError && 
          <MDBox display="flex" flexDirection="column" alignItems="center">
            <ErrorView error={{ message: `${botError || updateError || onboardError}` }} />
          </MDBox>}
          
          {(productDetails && !firstError && !secondError) && 
            (<>
              <NotificationView 
                color="success"
                message={`Successfully added product information. Next step is to customize and test ${submittedValues[botName.name]}`}
              />
              <MDBox my={1} display="flex" justifyContent="center">
                <MDButton
                  variant="outlined"
                  color="dark"
                  size="large"
                  onClick={() => navigate("/test-product", { replace: true })}
                >
                  {`Test ${submittedValues[botName.name]}`}
                </MDButton>
              </MDBox> 
            </>)
          }
        </MDBox>
        
          <Formik
            initialValues={savedValues}
            validationSchema={validations.welcome}
            onSubmit={handleSubmit}
            validateOnMount
          >
            {({ isSubmitting, isValid, values, setFieldValue }) => 
              (
              <Form id={formId}>
                  <MDBox pb={3} px={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <InputField 
                          name={botName.name}
                          label={botName.label} 
                          placeholder={botName.placeholder}
                          type={botName.type}
                          helperText="Enter a bot name. It will be visible to visitors of your website."
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputField 
                          name={botTone.name}
                          label={botTone.label} 
                          placeholder={botTone.placeholder}
                          type={botTone.type}
                          helperText="Describe the tone the bot should employ."
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputField 
                          name={productName.name}
                          label={productName.label} 
                          placeholder={productName.placeholder}
                          type={productName.type}
                        />
                      </Grid>
                    
                      <Grid item xs={12}>
                        <InputURL 
                          name={productPage.name}
                          label={productPage.label} 
                          placeholder={productPage.placeholder}
                          type={productPage.type}
                          validate={validateWebDomain}
                          helperText="Enter the URL that contains information such as features, benefits, social proof, key insights etc about your product."
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <MDBox my={2}>
                            <MDBox display="inline-block" my={1}>
                              <MDTypography
                                component="label"
                                variant="button"
                                fontWeight="regular"
                                color="dark"
                              >
                                Enter product demo, trial, schedule demo, book meeting etc. URL.
                              </MDTypography>
                            </MDBox>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={5} sm={4}>
                                  <Autocomplete
                                    value={values[demos.name][demos.demoType.name]}
                                    name="demo-type"
                                    autoComplete
                                    onChange={(_, val) => setFieldValue(`${demos.name}.${demos.demoType.name}`, val, false)}
                                    options={demoTypes}
                                    renderInput={(params) => <MDInput {...params} variant="standard" label={demos.demoType.label} sx={{ height: '100%' }}/>}
                                  />
                                  <MDBox mt={0.75}>
                                    <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                      <ErrorMessage name={`${demos.name}.${demos.demoType.name}`} />
                                    </MDTypography>
                                  </MDBox>
                                </Grid>
                                <Grid item xs={7} sm={8}>
                                  <FormField 
                                    name={`${demos.name}.${demos.scheduleDemo.name}`}
                                    label={demos.scheduleDemo.label} 
                                    placeholder={demos.scheduleDemo.placeholder}
                                    type={demos.scheduleDemo.type}
                                    variant="standard"
                                  />
                                </Grid>
                            </Grid>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox my={2} display="flex" spacing={2} justifyContent="flex-end" px={3}>
                    {(productDetails && !firstError && !secondError) &&
                      <MDButton
                        variant="outlined"
                        color="dark"
                        size="large"
                        sx={{ marginRight: 2 }}
                        onClick={() => navigate("/test-product", { replace: true })}
                      >
                        {`Test ${submittedValues[botName.name]}`}
                      </MDButton>}
                    {(botLoading || createLoading || updateLoading || onboardLoading) && <MDBox mr={2}><CircularProgress color="inherit" /></MDBox> }
                    <MDButton
                      disabled={isSubmitting || !isValid || loading || botLoading || createLoading || updateLoading || onboardLoading || prodLoading || !!userError || !!error || !!createError || !!onboardStateError || !!productError}
                      type="submit"
                      variant="gradient"
                      color="info"
                      size="large"
                    >
                      Submit
                    </MDButton>
                  </MDBox>
              </Form>
            )}
          </Formik>
      </Card>
    
  );
}

// WelcomeView.propTypes = {
//   userId: PropTypes.string.isRequired,
//   onboardState: PropTypes.string.isRequired
// };

export default WelcomeView;
