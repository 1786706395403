/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getManziaBotSessions = /* GraphQL */ `
  query GetManziaBotSessions($accountId: ID!, $period: String!) {
    getManziaBotSessions(accountId: $accountId, period: $period)
  }
`;
export const getManziaBotSessionsWithUtterances = /* GraphQL */ `
  query GetManziaBotSessionsWithUtterances($accountId: ID!, $period: String!) {
    getManziaBotSessionsWithUtterances(accountId: $accountId, period: $period)
  }
`;
export const getManziaVisitorUtterances = /* GraphQL */ `
  query GetManziaVisitorUtterances($accountId: ID!, $period: String!) {
    getManziaVisitorUtterances(accountId: $accountId, period: $period)
  }
`;
export const getManziaBotSessionsWithCallToAction = /* GraphQL */ `
  query GetManziaBotSessionsWithCallToAction(
    $accountId: ID!
    $period: String!
  ) {
    getManziaBotSessionsWithCallToAction(accountId: $accountId, period: $period)
  }
`;
export const getManziaConversations = /* GraphQL */ `
  query GetManziaConversations($accountId: ID!, $period: String!) {
    getManziaConversations(accountId: $accountId, period: $period)
  }
`;
export const getManziaConversationIntents = /* GraphQL */ `
  query GetManziaConversationIntents($accountId: ID!, $period: String!) {
    getManziaConversationIntents(accountId: $accountId, period: $period)
  }
`;
export const getManziaEmailLeads = /* GraphQL */ `
  query GetManziaEmailLeads($accountId: ID!, $period: String!) {
    getManziaEmailLeads(accountId: $accountId, period: $period)
  }
`;
export const getManziaTestConversations = /* GraphQL */ `
  query GetManziaTestConversations($accountId: ID!, $period: String!) {
    getManziaTestConversations(accountId: $accountId, period: $period)
  }
`;
export const getManziaChatbot = /* GraphQL */ `
  query GetManziaChatbot($id: ID!) {
    getManziaChatbot(id: $id) {
      id
      accountId
      lexChatBotId
      chatbotName
      chatbotTone
      chatbotAliasId
      chatbotVersion
      testChatbotAliasId
      testChatbotVersion
      deployedVersion
      deploymentStatus
      deleteTime
      account {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotUserProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatbotDeployments {
        items {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatBotWebUI {
        id
        lexChatBotId
        webDomain
        loaderScriptUrl
        snippetUrl
        webAppBase
        webAppBucket
        chatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotProductTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaChatbots = /* GraphQL */ `
  query ListManziaChatbots(
    $filter: ModelManziaChatbotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManziaChatbots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaChabotWebUI = /* GraphQL */ `
  query GetManziaChabotWebUI($id: ID!) {
    getManziaChabotWebUI(id: $id) {
      id
      lexChatBotId
      webDomain
      loaderScriptUrl
      snippetUrl
      webAppBase
      webAppBucket
      chatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaChabotWebUIS = /* GraphQL */ `
  query ListManziaChabotWebUIS(
    $filter: ModelManziaChabotWebUIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManziaChabotWebUIS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lexChatBotId
        webDomain
        loaderScriptUrl
        snippetUrl
        webAppBase
        webAppBucket
        chatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaAccount = /* GraphQL */ `
  query GetManziaAccount($id: ID!) {
    getManziaAccount(id: $id) {
      id
      v2chatBotId
      accountOwnerUserId
      accountOwnerEmail
      organizationName
      webDomain
      totalPaymentAmount
      totalConversations
      billRate
      currency
      accountType
      stripeSubscriptionStatus
      industryCode
      industryCategory
      lastBotDeploymentId
      deleteTime
      accountUsers {
        items {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      accountChatBot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaAccounts = /* GraphQL */ `
  query ListManziaAccounts(
    $filter: ModelManziaAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManziaAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaUser = /* GraphQL */ `
  query GetManziaUser($id: ID!) {
    getManziaUser(id: $id) {
      id
      accountId
      emailAddress
      fullName
      isAccountOwner
      roleId
      deleteTime
      userRole {
        userId
        roleId
        accountId
        roleName
        roleDescription
        deleteTime
        teamUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        rolePolicy {
          roleId
          policyName
          policyDescription
          permissions
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      userAccount {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      userProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      productTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      botDeployments {
        items {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      testConversations {
        items {
          id
          userProductId
          testedByUserId
          lastModifiedAt
          title
          isExample
          deleteTime
          createdAt
          updatedAt
          manziaIntentExampleConversationsBotId
          manziaIntentExampleConversationsUserProductIntentId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaUsers = /* GraphQL */ `
  query ListManziaUsers(
    $filter: ModelManziaUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManziaUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaUserProduct = /* GraphQL */ `
  query GetManziaUserProduct($id: ID!) {
    getManziaUserProduct(id: $id) {
      id
      productName
      productImage
      productType
      trainingStatus
      createdByUserId
      trainedChatBotId
      deleteTime
      trainedChatBot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      productTraining {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      testConversations {
        items {
          id
          userProductId
          testedByUserId
          lastModifiedAt
          title
          isExample
          deleteTime
          createdAt
          updatedAt
          manziaIntentExampleConversationsBotId
          manziaIntentExampleConversationsUserProductIntentId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaUserProducts = /* GraphQL */ `
  query ListManziaUserProducts(
    $filter: ModelManziaUserProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManziaUserProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productName
        productImage
        productType
        trainingStatus
        createdByUserId
        trainedChatBotId
        deleteTime
        trainedChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        productTraining {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaProductTraining = /* GraphQL */ `
  query GetManziaProductTraining($id: ID!) {
    getManziaProductTraining(id: $id) {
      id
      userProductId
      trainingData
      trainedByUserId
      trainedChatBotId
      botDeploymentId
      lastModifiedAt
      deleteTime
      trainingStatus
      userProduct {
        id
        productName
        productImage
        productType
        trainingStatus
        createdByUserId
        trainedChatBotId
        deleteTime
        trainedChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        productTraining {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      trainedByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      botDeployment {
        id
        manziaChatbotId
        lastDeployedAt
        deployedByUserId
        productTrainingId
        productName
        deployedUsername
        userDeployMessage
        versionToDeploy
        status
        productTraining {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        manziaChatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        deployedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaProductTrainings = /* GraphQL */ `
  query ListManziaProductTrainings(
    $filter: ModelManziaProductTrainingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManziaProductTrainings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userProductId
        trainingData
        trainedByUserId
        trainedChatBotId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        trainedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        botDeployment {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaBotDeployment = /* GraphQL */ `
  query GetManziaBotDeployment($id: ID!) {
    getManziaBotDeployment(id: $id) {
      id
      manziaChatbotId
      lastDeployedAt
      deployedByUserId
      productTrainingId
      productName
      deployedUsername
      userDeployMessage
      versionToDeploy
      status
      productTraining {
        id
        userProductId
        trainingData
        trainedByUserId
        trainedChatBotId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        trainedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        botDeployment {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      manziaChatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      deployedByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaBotDeployments = /* GraphQL */ `
  query ListManziaBotDeployments(
    $filter: ModelManziaBotDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManziaBotDeployments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        manziaChatbotId
        lastDeployedAt
        deployedByUserId
        productTrainingId
        productName
        deployedUsername
        userDeployMessage
        versionToDeploy
        status
        productTraining {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        manziaChatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        deployedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaTestConversation = /* GraphQL */ `
  query GetManziaTestConversation($id: ID!) {
    getManziaTestConversation(id: $id) {
      id
      userProductId
      testedByUserId
      lastModifiedAt
      title
      isExample
      deleteTime
      testChatMessages {
        items {
          id
          testConversationId
          timestamp
          intentName
          message
          sender
          lastModifiedAt
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      manziaIntentExampleConversationsBotId
      manziaIntentExampleConversationsUserProductIntentId
      __typename
    }
  }
`;
export const listManziaTestConversations = /* GraphQL */ `
  query ListManziaTestConversations(
    $filter: ModelManziaTestConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManziaTestConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userProductId
        testedByUserId
        lastModifiedAt
        title
        isExample
        deleteTime
        testChatMessages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        manziaIntentExampleConversationsBotId
        manziaIntentExampleConversationsUserProductIntentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaTestChatMessage = /* GraphQL */ `
  query GetManziaTestChatMessage($id: ID!) {
    getManziaTestChatMessage(id: $id) {
      id
      testConversationId
      timestamp
      intentName
      message
      sender
      lastModifiedAt
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaTestChatMessages = /* GraphQL */ `
  query ListManziaTestChatMessages(
    $filter: ModelManziaTestChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManziaTestChatMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testConversationId
        timestamp
        intentName
        message
        sender
        lastModifiedAt
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaConversation = /* GraphQL */ `
  query GetManziaConversation($sessionId: ID!) {
    getManziaConversation(sessionId: $sessionId) {
      sessionId
      startTimestamp
      accountId
      startUtterance
      userProductId
      conversationPDFPath
      scheduledEmailAt
      lastUserEmailAt
      deleteTime
      userProduct {
        id
        productName
        productImage
        productType
        trainingStatus
        createdByUserId
        trainedChatBotId
        deleteTime
        trainedChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        productTraining {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      utterances {
        items {
          sessionId
          timestamp
          botId
          conversationId
          userProductId
          userId
          intentId
          role
          content
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaConversations = /* GraphQL */ `
  query ListManziaConversations(
    $sessionId: ID
    $filter: ModelManziaConversationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManziaConversations(
      sessionId: $sessionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sessionId
        startTimestamp
        accountId
        startUtterance
        userProductId
        conversationPDFPath
        scheduledEmailAt
        lastUserEmailAt
        deleteTime
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        utterances {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaUtterance = /* GraphQL */ `
  query GetManziaUtterance($sessionId: ID!, $timestamp: AWSTimestamp!) {
    getManziaUtterance(sessionId: $sessionId, timestamp: $timestamp) {
      sessionId
      timestamp
      botId
      conversationId
      userProductId
      userId
      intentId
      role
      content
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaUtterances = /* GraphQL */ `
  query ListManziaUtterances(
    $sessionId: ID
    $timestamp: ModelIntKeyConditionInput
    $filter: ModelManziaUtteranceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManziaUtterances(
      sessionId: $sessionId
      timestamp: $timestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sessionId
        timestamp
        botId
        conversationId
        userProductId
        userId
        intentId
        role
        content
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaIntentExample = /* GraphQL */ `
  query GetManziaIntentExample($botId: ID!, $userProductIntentId: ID!) {
    getManziaIntentExample(
      botId: $botId
      userProductIntentId: $userProductIntentId
    ) {
      botId
      userProductIntentId
      intentId
      userId
      deleteTime
      conversations {
        items {
          id
          userProductId
          testedByUserId
          lastModifiedAt
          title
          isExample
          deleteTime
          createdAt
          updatedAt
          manziaIntentExampleConversationsBotId
          manziaIntentExampleConversationsUserProductIntentId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaIntentExamples = /* GraphQL */ `
  query ListManziaIntentExamples(
    $botId: ID
    $userProductIntentId: ModelIDKeyConditionInput
    $filter: ModelManziaIntentExampleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManziaIntentExamples(
      botId: $botId
      userProductIntentId: $userProductIntentId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        botId
        userProductIntentId
        intentId
        userId
        deleteTime
        conversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaTeamInvite = /* GraphQL */ `
  query GetManziaTeamInvite($emailAddress: ID!, $accountId: ID!) {
    getManziaTeamInvite(emailAddress: $emailAddress, accountId: $accountId) {
      emailAddress
      accountId
      manziaChatbotId
      organizationName
      userRole
      emailMessageId
      senderUserId
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaTeamInvites = /* GraphQL */ `
  query ListManziaTeamInvites(
    $emailAddress: ID
    $accountId: ModelIDKeyConditionInput
    $filter: ModelManziaTeamInviteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManziaTeamInvites(
      emailAddress: $emailAddress
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        emailAddress
        accountId
        manziaChatbotId
        organizationName
        userRole
        emailMessageId
        senderUserId
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaUserRole = /* GraphQL */ `
  query GetManziaUserRole($userId: ID!) {
    getManziaUserRole(userId: $userId) {
      userId
      roleId
      accountId
      roleName
      roleDescription
      deleteTime
      teamUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      rolePolicy {
        roleId
        policyName
        policyDescription
        permissions
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaUserRoles = /* GraphQL */ `
  query ListManziaUserRoles(
    $userId: ID
    $filter: ModelManziaUserRoleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManziaUserRoles(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        roleId
        accountId
        roleName
        roleDescription
        deleteTime
        teamUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        rolePolicy {
          roleId
          policyName
          policyDescription
          permissions
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaRolePolicy = /* GraphQL */ `
  query GetManziaRolePolicy($roleId: ID!) {
    getManziaRolePolicy(roleId: $roleId) {
      roleId
      policyName
      policyDescription
      permissions
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaRolePolicies = /* GraphQL */ `
  query ListManziaRolePolicies(
    $roleId: ID
    $filter: ModelManziaRolePolicyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManziaRolePolicies(
      roleId: $roleId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        roleId
        policyName
        policyDescription
        permissions
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaOnboard = /* GraphQL */ `
  query GetManziaOnboard($emailAddress: ID!) {
    getManziaOnboard(emailAddress: $emailAddress) {
      emailAddress
      onboardState
      userId
      accountId
      webDomain
      organizationName
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaOnboards = /* GraphQL */ `
  query ListManziaOnboards(
    $emailAddress: ID
    $filter: ModelManziaOnboardFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManziaOnboards(
      emailAddress: $emailAddress
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        emailAddress
        onboardState
        userId
        accountId
        webDomain
        organizationName
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaStripeCheckout = /* GraphQL */ `
  query GetManziaStripeCheckout($accountId: ID!) {
    getManziaStripeCheckout(accountId: $accountId) {
      accountId
      stripeCustomerId
      stripeSubscription
      stripeSubscriptionItem
      latestSubscriptionInvoice
      billingLimit
      lastSessionId
      lastTotalAmountPaid
      lastSubTotalAmountPaid
      lastPaymentAt
      lastPaymentIntentId
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaStripeCheckouts = /* GraphQL */ `
  query ListManziaStripeCheckouts(
    $accountId: ID
    $filter: ModelManziaStripeCheckoutFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManziaStripeCheckouts(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        stripeCustomerId
        stripeSubscription
        stripeSubscriptionItem
        latestSubscriptionInvoice
        billingLimit
        lastSessionId
        lastTotalAmountPaid
        lastSubTotalAmountPaid
        lastPaymentAt
        lastPaymentIntentId
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaEmailLead = /* GraphQL */ `
  query GetManziaEmailLead($sessionId: ID!, $emailAddress: String!) {
    getManziaEmailLead(sessionId: $sessionId, emailAddress: $emailAddress) {
      sessionId
      accountId
      emailAddress
      emailUtterance
      webDomain
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaEmailLeads = /* GraphQL */ `
  query ListManziaEmailLeads(
    $sessionId: ID
    $emailAddress: ModelStringKeyConditionInput
    $filter: ModelManziaEmailLeadFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManziaEmailLeads(
      sessionId: $sessionId
      emailAddress: $emailAddress
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sessionId
        accountId
        emailAddress
        emailUtterance
        webDomain
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaBotSetting = /* GraphQL */ `
  query GetManziaBotSetting($chatbotId: ID!) {
    getManziaBotSetting(chatbotId: $chatbotId) {
      chatbotId
      accountId
      displayFrequency
      displayTiming
      displayDevices
      targetingType
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaBotSettings = /* GraphQL */ `
  query ListManziaBotSettings(
    $chatbotId: ID
    $filter: ModelManziaBotSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManziaBotSettings(
      chatbotId: $chatbotId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        chatbotId
        accountId
        displayFrequency
        displayTiming
        displayDevices
        targetingType
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaProductPages = /* GraphQL */ `
  query GetManziaProductPages($pageURL: ID!) {
    getManziaProductPages(pageURL: $pageURL) {
      pageURL
      accountId
      productName
      productDetails
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaProductPages = /* GraphQL */ `
  query ListManziaProductPages(
    $pageURL: ID
    $filter: ModelManziaProductPagesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManziaProductPages(
      pageURL: $pageURL
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pageURL
        accountId
        productName
        productDetails
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManziaConversationSetting = /* GraphQL */ `
  query GetManziaConversationSetting($accountId: ID!) {
    getManziaConversationSetting(accountId: $accountId) {
      accountId
      conversationEmailFilters
      timeZone
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManziaConversationSettings = /* GraphQL */ `
  query ListManziaConversationSettings(
    $accountId: ID
    $filter: ModelManziaConversationSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManziaConversationSettings(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        conversationEmailFilters
        timeZone
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaChatbotsByLexChatBotId = /* GraphQL */ `
  query ManziaChatbotsByLexChatBotId(
    $lexChatBotId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelManziaChatbotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaChatbotsByLexChatBotId(
      lexChatBotId: $lexChatBotId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaChabotWebUISByLexChatBotId = /* GraphQL */ `
  query ManziaChabotWebUISByLexChatBotId(
    $lexChatBotId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelManziaChabotWebUIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaChabotWebUISByLexChatBotId(
      lexChatBotId: $lexChatBotId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lexChatBotId
        webDomain
        loaderScriptUrl
        snippetUrl
        webAppBase
        webAppBucket
        chatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaAccountByDomain = /* GraphQL */ `
  query ManziaAccountByDomain(
    $webDomain: String!
    $sortDirection: ModelSortDirection
    $filter: ModelManziaAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaAccountByDomain(
      webDomain: $webDomain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaUsersByAccountId = /* GraphQL */ `
  query ManziaUsersByAccountId(
    $accountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelManziaUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaUsersByAccountId(
      accountId: $accountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaUsersByEmail = /* GraphQL */ `
  query ManziaUsersByEmail(
    $emailAddress: String!
    $sortDirection: ModelSortDirection
    $filter: ModelManziaUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaUsersByEmail(
      emailAddress: $emailAddress
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaUserProductsByCreatedByUserId = /* GraphQL */ `
  query ManziaUserProductsByCreatedByUserId(
    $createdByUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelManziaUserProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaUserProductsByCreatedByUserId(
      createdByUserId: $createdByUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productName
        productImage
        productType
        trainingStatus
        createdByUserId
        trainedChatBotId
        deleteTime
        trainedChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        productTraining {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaUserProductsByTrainedChatBotId = /* GraphQL */ `
  query ManziaUserProductsByTrainedChatBotId(
    $trainedChatBotId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelManziaUserProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaUserProductsByTrainedChatBotId(
      trainedChatBotId: $trainedChatBotId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productName
        productImage
        productType
        trainingStatus
        createdByUserId
        trainedChatBotId
        deleteTime
        trainedChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        productTraining {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaProductTrainingsByUserProductIdAndLastModifiedAt = /* GraphQL */ `
  query ManziaProductTrainingsByUserProductIdAndLastModifiedAt(
    $userProductId: ID!
    $lastModifiedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelManziaProductTrainingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaProductTrainingsByUserProductIdAndLastModifiedAt(
      userProductId: $userProductId
      lastModifiedAt: $lastModifiedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userProductId
        trainingData
        trainedByUserId
        trainedChatBotId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        trainedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        botDeployment {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaProductTrainingsByTrainedByUserIdAndLastModifiedAt = /* GraphQL */ `
  query ManziaProductTrainingsByTrainedByUserIdAndLastModifiedAt(
    $trainedByUserId: ID!
    $lastModifiedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelManziaProductTrainingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaProductTrainingsByTrainedByUserIdAndLastModifiedAt(
      trainedByUserId: $trainedByUserId
      lastModifiedAt: $lastModifiedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userProductId
        trainingData
        trainedByUserId
        trainedChatBotId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        trainedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        botDeployment {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaProductTrainingsByTrainedChatBotIdAndLastModifiedAt = /* GraphQL */ `
  query ManziaProductTrainingsByTrainedChatBotIdAndLastModifiedAt(
    $trainedChatBotId: ID!
    $lastModifiedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelManziaProductTrainingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaProductTrainingsByTrainedChatBotIdAndLastModifiedAt(
      trainedChatBotId: $trainedChatBotId
      lastModifiedAt: $lastModifiedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userProductId
        trainingData
        trainedByUserId
        trainedChatBotId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        trainedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        botDeployment {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaBotDeploymentsByManziaChatbotIdAndLastDeployedAt = /* GraphQL */ `
  query ManziaBotDeploymentsByManziaChatbotIdAndLastDeployedAt(
    $manziaChatbotId: ID!
    $lastDeployedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelManziaBotDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaBotDeploymentsByManziaChatbotIdAndLastDeployedAt(
      manziaChatbotId: $manziaChatbotId
      lastDeployedAt: $lastDeployedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        manziaChatbotId
        lastDeployedAt
        deployedByUserId
        productTrainingId
        productName
        deployedUsername
        userDeployMessage
        versionToDeploy
        status
        productTraining {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        manziaChatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        deployedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaBotDeploymentsByDeployedByUserIdAndLastDeployedAt = /* GraphQL */ `
  query ManziaBotDeploymentsByDeployedByUserIdAndLastDeployedAt(
    $deployedByUserId: ID!
    $lastDeployedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelManziaBotDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaBotDeploymentsByDeployedByUserIdAndLastDeployedAt(
      deployedByUserId: $deployedByUserId
      lastDeployedAt: $lastDeployedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        manziaChatbotId
        lastDeployedAt
        deployedByUserId
        productTrainingId
        productName
        deployedUsername
        userDeployMessage
        versionToDeploy
        status
        productTraining {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        manziaChatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        deployedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaTestConversationsByUserProductIdAndLastModifiedAt = /* GraphQL */ `
  query ManziaTestConversationsByUserProductIdAndLastModifiedAt(
    $userProductId: ID!
    $lastModifiedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelManziaTestConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaTestConversationsByUserProductIdAndLastModifiedAt(
      userProductId: $userProductId
      lastModifiedAt: $lastModifiedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userProductId
        testedByUserId
        lastModifiedAt
        title
        isExample
        deleteTime
        testChatMessages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        manziaIntentExampleConversationsBotId
        manziaIntentExampleConversationsUserProductIntentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaTestConversationsByTestedByUserIdAndUserProductId = /* GraphQL */ `
  query ManziaTestConversationsByTestedByUserIdAndUserProductId(
    $testedByUserId: ID!
    $userProductId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelManziaTestConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaTestConversationsByTestedByUserIdAndUserProductId(
      testedByUserId: $testedByUserId
      userProductId: $userProductId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userProductId
        testedByUserId
        lastModifiedAt
        title
        isExample
        deleteTime
        testChatMessages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        manziaIntentExampleConversationsBotId
        manziaIntentExampleConversationsUserProductIntentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaTestChatMessagesByTestConversationIdAndTimestamp = /* GraphQL */ `
  query ManziaTestChatMessagesByTestConversationIdAndTimestamp(
    $testConversationId: ID!
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelManziaTestChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaTestChatMessagesByTestConversationIdAndTimestamp(
      testConversationId: $testConversationId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testConversationId
        timestamp
        intentName
        message
        sender
        lastModifiedAt
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaConversationsByAccountIdAndStartTimestamp = /* GraphQL */ `
  query ManziaConversationsByAccountIdAndStartTimestamp(
    $accountId: ID!
    $startTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelManziaConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaConversationsByAccountIdAndStartTimestamp(
      accountId: $accountId
      startTimestamp: $startTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sessionId
        startTimestamp
        accountId
        startUtterance
        userProductId
        conversationPDFPath
        scheduledEmailAt
        lastUserEmailAt
        deleteTime
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        utterances {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaUtterancesByBotIdAndTimestamp = /* GraphQL */ `
  query ManziaUtterancesByBotIdAndTimestamp(
    $botId: ID!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelManziaUtteranceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaUtterancesByBotIdAndTimestamp(
      botId: $botId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sessionId
        timestamp
        botId
        conversationId
        userProductId
        userId
        intentId
        role
        content
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaUtterancesByConversationIdAndTimestamp = /* GraphQL */ `
  query ManziaUtterancesByConversationIdAndTimestamp(
    $conversationId: ID!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelManziaUtteranceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaUtterancesByConversationIdAndTimestamp(
      conversationId: $conversationId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sessionId
        timestamp
        botId
        conversationId
        userProductId
        userId
        intentId
        role
        content
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaUserRolesByAccount = /* GraphQL */ `
  query ManziaUserRolesByAccount(
    $accountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelManziaUserRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaUserRolesByAccount(
      accountId: $accountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        roleId
        accountId
        roleName
        roleDescription
        deleteTime
        teamUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        rolePolicy {
          roleId
          policyName
          policyDescription
          permissions
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaCheckoutByStripeCustomerId = /* GraphQL */ `
  query ManziaCheckoutByStripeCustomerId(
    $stripeCustomerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelManziaStripeCheckoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaCheckoutByStripeCustomerId(
      stripeCustomerId: $stripeCustomerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        stripeCustomerId
        stripeSubscription
        stripeSubscriptionItem
        latestSubscriptionInvoice
        billingLimit
        lastSessionId
        lastTotalAmountPaid
        lastSubTotalAmountPaid
        lastPaymentAt
        lastPaymentIntentId
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manziaEmailLeadsByAccount = /* GraphQL */ `
  query ManziaEmailLeadsByAccount(
    $accountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelManziaEmailLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manziaEmailLeadsByAccount(
      accountId: $accountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sessionId
        accountId
        emailAddress
        emailUtterance
        webDomain
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
