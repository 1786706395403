/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext } from "react";

// import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import Icon from "@mui/material/Icon";
// import Grid from "@mui/material/Grid"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

// Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// Manzia
import RadioButtons from "layouts/settings/components/RadioButtons";
import ManualBot from "layouts/settings/components/ManualBot";
import LinkURL from "layouts/common/LinkURL";
import InfoList from "layouts/settings/components/InfoList";
import { gtmWebContainer, gtmTagPublish } from "layouts/settings/components/InfoList/infodata";
// import EmailDeveloper from "layouts/settings/components/EmailDeveloper";
import InstallGTM from "layouts/settings/components/InstallGTM";
import BotCode from "layouts/settings/components/BotCode";

function InstallBot() {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { account: { webDomain } } = useLocalChatbotUser({ userId: USERID });

  const [installOption, setInstallOption] = useState('gtm_option');
  const [gtmIsInstalled, setgtmIsInstalled] = useState("gtm_installed_false");
  const [webContainer, setWebContainer] = useState("webcontainer_true");

  const handleOption = (option) => {
    if (option.installOption) setInstallOption(option.installOption);
    if (option.gtmIsInstalled) setgtmIsInstalled(option.gtmIsInstalled);
    if (option.webContainer) setWebContainer(option.webContainer);
  };

  return (
    <Card id="install-bot" sx={{ overflow: "visible" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDTypography variant="h5" color="dark">Install ManziaBot widget on your website</MDTypography>
        <MDBadge variant="contained" color="success" badgeContent="enabled" container />
      </MDBox>
      <MDBox px={3}>
        {/* <PublishState /> */}
        <MDTypography variant="body2" color="dark" fontWeight="regular">
            We strongly recommend using <LinkURL color="info" url="https://marketingplatform.google.com/about/tag-manager/" anchorText="Google Tag Manager" fontWeight="bold" />
            (GTM) because of the broad range of trigger events you can setup. Otherwise, select the Manual option 
            if you have solid web development experience and want to maintain control.
          </MDTypography>
      </MDBox>
      <Divider />
      <MDBox px={3}>
          <MDBox my={2}>
            <RadioButtons 
              name="installOption"
              title="Select chatbot install option:"
              value={installOption}
              handleChange={handleOption}
              radioList={[
                { id: "gtm_option", value: "gtm_option", label: "Google Tag Manager (Recommended)"},
                { id: "manual_option", value: "manual_option", label: "Manual Setup"}
              ]}
            />
             <Divider />
          </MDBox>
         
          {installOption === 'gtm_option' && <MDBox my={2}>
            <RadioButtons 
              name="gtmIsInstalled"
              title={`Is Google Tag Manager (GTM) already installed on ${webDomain}?`}
              value={gtmIsInstalled}
              handleChange={handleOption}
              radioList={[
                { id: "gtm_installed_true", value: "gtm_installed_true", label: "Yes"},
                { id: "gtm_installed_false", value: "gtm_installed_false", label: "No"},
                { id: "gtm_installed_notsure", value: "gtm_installed_notsure", label: "Not sure"}
              ]}
            />
            <Divider />
          </MDBox>}

          {installOption === 'manual_option' && <MDBox my={2}>
            <ManualBot  webDomain={webDomain} />
            <Divider />
          </MDBox>}
          
          {(installOption === 'gtm_option' && gtmIsInstalled === 'gtm_installed_true') && <MDBox my={2}>
            <RadioButtons 
              name="webContainer"
              title="Do you have a GTM container of type: Web, in your Google Tag Manager (GTM) account?"
              value={webContainer}
              handleChange={handleOption}
              radioList={[
                { id: "webcontainer_true", value: "webcontainer_true", label: "Yes"},
                { id: "webcontainer_false", value: "webcontainer_false", label: "No"}
              ]}
            />
            <Divider />
          </MDBox>}

          {(installOption === 'gtm_option' && gtmIsInstalled !== 'gtm_installed_true') && 
            // <EmailDeveloper />
            <InstallGTM />
          }

          {(installOption === 'gtm_option' && gtmIsInstalled === 'gtm_installed_true' && webContainer === 'webcontainer_true') && 
            <>
              <InfoList infoData={gtmWebContainer} />
              <BotCode webDomain={webDomain} />
              <Divider />
              <InfoList infoData={gtmTagPublish} />
            </>
            
          }

          {(installOption === 'gtm_option' && gtmIsInstalled === 'gtm_installed_true' && webContainer === 'webcontainer_false') && 
            // <InfoList infoData={gtmCreateContainer} />
            <InstallGTM action="createContainer" />
          }
          
      </MDBox>
    </Card>
  );
}

export default InstallBot;