/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Hooks
// import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// Manzia
// import CreateEmail from "layouts/settings/components/CreateEmail";
import teamRoles from "layouts/settings/data/teamroles";
import TeamTable from "layouts/settings/components/TeamTable";
import EmailInvite from "layouts/settings/components/EmailInvite";

// Constants
// const USERID = "20966bf1-2054-49ca-9328-7f43b8f8c65b";

function ManageTeam() {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;

  // Render the roles
  const renderRoles = teamRoles.map(role => (
    <MDBox  key={role.roleId} display="flex">
      <MDTypography variant="button" fontWeight="regular" textTransform="capitalize" color="dark">
        {role.label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="dark">
        &nbsp;{role.description}
      </MDTypography>
    </MDBox>
  ));
  
  return (
    <Card id="manage-team" sx={{ height: "100%", minHeight: "75vh", overflowY: "auto" }}>
      <MDBox p={3}>
        <MDTypography variant="h5" color="dark" fontWeight="bold">Manage Team</MDTypography>
        <MDTypography variant="body2" color="dark" fontWeight="regular">
          Enter the email addresses below of the team members to invite.
        </MDTypography>
      </MDBox>
      <EmailInvite />
      <Divider />
      <MDBox px={3} mb={1} mt={3}>
        <MDTypography variant="h6" fontWeight="bold" color="dark">Team Roles</MDTypography>
        {renderRoles}
      </MDBox>
      <Divider />
      <MDBox px={3} mt={3}>
          <TeamTable userId={USERID} />
      </MDBox>
    </Card>
  );
}

export default ManageTeam;
