// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";


function LoadView({ loadingMessage, size }) {
    return (
        <Grid container justifyContent="center" alignItems="center" my={2} mx={1}>
            <Grid item xs={12} lg={12}>
                <CircularProgress color="info" size={size} />
                <MDTypography variant="body2" fontWeight="medium">
                  {loadingMessage}
                </MDTypography>
            </Grid>
        </Grid>
    )
}

LoadView.defaultProps = {
    loadingMessage: "Loading...",
    size: 40
  };

LoadView.propTypes = {
    loadingMessage: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };

export default LoadView