/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import forms from "layouts/conversations/schema/form";

const { emailchat: { fullName: chatFullName, chatEmails } } = forms;

const initialValues = {
  emailchat: {
    [chatFullName.name]: "",
    [chatEmails.name]: "",
  }
}

export default initialValues;
