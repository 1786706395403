/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Helpers
import { extractAvatarInitials } from "helpers";

function ProductCell({ name }) {
  return (
    <MDBox display="flex" alignItems="center" pr={2}>
      <MDBox mr={2}>
        {/* <MDAvatar src={image} alt={name} /> */}
        <MDAvatar bgColor="info" size="sm">{extractAvatarInitials(name)}</MDAvatar>
      </MDBox>
      <MDTypography variant="button" fontWeight="regular" color="dark">
        {name}
      </MDTypography>
    </MDBox>
  );
}

// Typechecking props for the ProductCell
ProductCell.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ProductCell;
