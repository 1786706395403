import { useState, useEffect, useCallback } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from "@mui/material/Icon";
// import Tooltip from "@mui/material/Tooltip";
// import IconButton from "@mui/material/IconButton";
// import DeleteIcon from '@mui/icons-material/Delete';


// Material Dashboard
import MDButton from 'components/MDButton';

// Hooks
import useLazyAxios from "customhooks/useLazyAxios";

// Manzia
import NotificationView from 'layouts/common/Notification';
import LoadView from "layouts/common/LoadView";
import { DELETE_API } from "appSettings";

function AccountDelete({ userId, onDeleted }) {
    
    const [open, setOpen] = useState(false);

    const { loading: deleteLoading, error: deleteError, value: deleteData,
        fetch: deleteAccount } = useLazyAxios();
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = useCallback(() => {
        deleteAccount()({
            method: 'post', 
            url: DELETE_API.deleteAccount, 
            data: { userId },
            timeout: 10000,
            maxRedirects: 0, 
        });
    }, [userId]);

    useEffect(() => {
        if (!deleteData) return;
        onDeleted(userId);
    }, [deleteData]);

  return (
    <>
    <MDButton 
        variant="gradient" 
        color="error"
        aria-label="delete" 
        sx={{ height: "100%" }}
        onClick={handleClickOpen}
    >
        delete
    </MDButton>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are you sure you want to delete your Manzia AI account?
            </DialogContentText>

            {deleteLoading && <LoadView loadingMessage="Deleting..." />}
            
            {deleteError && <NotificationView 
                color="error"
                message="Encountered error in deleting account. Please retry later."
            />}

            {deleteData && <NotificationView 
                color="success"
                message="Account successfully deleted."
            />}
        </DialogContent>
        <DialogActions>
            <MDButton 
                variant="outlined"
                color="dark" 
                onClick={handleClose}
            >
                Cancel
            </MDButton>
            <MDButton 
                variant="gradient" 
                color="info" 
                disabled={deleteLoading || !!deleteError || !!deleteData}
                onClick={handleDelete}
            >
              <Icon>delete</Icon>&nbsp; Delete
            </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

// Typechecking props for BaseLayout
AccountDelete.propTypes = {
  userId: PropTypes.string.isRequired,
  onDeleted: PropTypes.func.isRequired
};


export default AccountDelete;