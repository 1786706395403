/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import forms from "layouts/conversations/schema/form";
import { FULL_NAME_REGEX } from "helpers";

const {
    emailchat: { fullName: chatFullName, chatEmails },
  } = forms;

  /* eslint-disable func-names */
const validations = {
  emailchat: Yup.object().shape({
    [chatFullName.name]: Yup.string().matches(FULL_NAME_REGEX, chatFullName.invalidMsg).required(chatFullName.errorMsg),
    [chatEmails.name]: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
    .required(chatEmails.errorMsg)
  })
}

/* eslint-enable func-names */

export default validations;
