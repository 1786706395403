/**
=========================================================
* Manzia Inc - v1.1.0
=========================================================

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Roy Manzi

 =========================================================

*/

const APP_NAME = "ManziaDashboard";

const APP_VERSION = "1.1.0";

const ENV = "prod";

const productActions = { edit: "edit", delete: "delete", test: "test", info: "info", save: "Save" };

const DeploymentStatus = { 
    pending: { key: "pending", label: "Pending" },
    inprogress: { key: "inprogress", label: "In Progress" },
    failed: { key: "failed", label: "Failed" },
    completed: { key: "completed", label: "Completed" },
};

const AppListenerChannels = {
    productTraining: "ManziaProductTraining",
    onCreateEvent: "onCreateEvent",
    onUpdateEvent: "onUpdateEvent",
    onDeleteEvent: "onDeleteEvent"
};

const TABS = {
    products: [
        {label: "Products"}, 
        {label: "Versions"}
    ],
    pricing: [
        {label: "Pricing"}, 
        {label: "Billing"}
    ],
    conversations: [
        {label: "Conversations" },
        {label: "Email Leads" }
    ]
};

const BOT_SESSION_KEY = "botSessionKey";

const lexV2Commands = {
    getSession: "GetSession",
    putSession: "PutSession",
    deleteSession: "DeleteSession",
    recognizeText: "RecognizeText",
}

const awsCommands = {
    lexV2: {
        getSession: "GetSession",
        putSession: "PutSession",
        deleteSession: "DeleteSession",
        recognizeText: "RecognizeText",
    },
    s3: {
        getObject: "GetObject",
        putObject: "PutObject"
    }
};

const CONVERSATION_TITLE = "Add chat title...";

const botStyleTemplates = {
    bucket: "manzia-lex-webui-apps",
    webuiTemplateKey: "templates/webui-template.html",
    cssStyleTemplateKey: "templates/chatbot-style-template.css",
    currentCssStyles: "custom-chatbot-style.css",
    configKey: "lex-web-ui-loader-config.json"
};

const WebAppDomain = "web.manzia.com";

const EMAIL_API = {
    endpoint: `https://config.manzia.com/sendmail-${ENV}`,
    configset: "Manzia-Retail-Emails",
    source: "Manzia <info@manzia.com>",
    fowardingEmail: "info@manzia.com",
    developerTemplate: { name: "ManziaDashboard-Developer-Template", arn: ""},
    inviteTemplate: { name: "ManziaDashboard-Invites-Template", arn: ""},
    dashboardLink: ENV === 'prod' ? "https://dashboard.manzia.com" : "https://devdashboard.manzia.com",
    homeLink: ENV === 'prod' ? "https://www.manzia.com" : "https://webdev.manzia.com",
    emailchat: { method: 'post', url: `https://hlagp1sbw7.execute-api.us-east-1.amazonaws.com/v1/emailchat-${ENV}` },
};

const PERIOD = {
    last1day: "last1day",
    last7days: "last7days",
    last14days: "last14days",
    last30days: "last30days",
    previous1day: "previous1day",
    previous7days: "previous7days",
    previous14days: "previous14days",
    previous30days: "previous30days"
};

const StatisticsSummary = {
    botSessions: {
        title: "Sessions",
        description: "Number of times the bot was launched."
    },
    botEngagements: {
        title: "Conversations",
        description: "Number of unique bot sessions during which visitor made utterance."
    },
    visitorUtterances: {
        title: "Utterances",
        description: "Number of utterances submitted by visitors."
    },
    emailLeads: {
        title: "Email Leads",
        description: "Number of unique bot sessions during which visitors provided an email address."
    },
    periods: [
        { interval: "1day", label: "Last 24 hours" },
        { interval: "7day", label: "Last 7 days" },
        { interval: "14day", label: "Last 14 days" },
        { interval: "30day", label: "Last 30 days" },
    ]
};

const ManziaBillingInfo = {
    title: "Conversations",
    legend: "Billed conversations",
    defaultPeriod: { interval: "7day", label: "Last 7 days" },
    periods: [
        { interval: "7day", label: "Last 7 days", groupBy: '1day', format: "ddd Do" },
        { interval: "30day", label: "Last 30 days", groupBy: '1day', format: "MMM Do" },
        { interval: "180day", label: "Last 180 days", groupBy: '30day', format: "MMM YYYY" },
        { interval: "365day", label: "Last 365 days", groupBy: '30day', format: "MMM YYYY" },
    ]
};

const ManziaConversationInfo = {
    defaultPeriod: { interval: "7day", label: "Last 7 days" },
    periods: [
        { interval: "7day", label: "Last 7 days" },
        { interval: "30day", label: "Last 30 days" },
        { interval: "60day", label: "Last 60 days" },
        { interval: "90day", label: "Last 90 days" },
        { interval: "180day", label: "Last 180 days" },
    ]
}

const ManziaSampleIntents = {
    "just_looking": 0,
    "think_about_it": 0,
    "not_sure": 0,
    "missing_functionality": 0,
    "no_budget": 0,
    "not_ready": 0,
    "not_interested": 0
};

const UserFlows = {
    LOGIN_FLOW: "LOGIN_FLOW",
    SIGNUP_FLOW: "SIGNUP_FLOW"
};

const OnboardState = {
    HAS_PRODUCT: "HAS_PRODUCT",
    NO_PRODUCTS: "NO_PRODUCTS",
    CREATED_PRODUCT: "CREATED_PRODUCT",
    PRODUCT_ERROR: "PRODUCT_ERROR",
    ACCOUNT_FAILED: "ACCOUNT_FAILED",
    CREATE_BOT_FAILED: "CREATE_BOT_FAILED",
    NO_CHATWEBUI: "NO_CHATWEBUI",
    ONBOARD_COMPLETE: "ONBOARD_COMPLETE",
    ONBOARD_STARTED: "ONBOARD_STARTED",
    ONBOARD_SUPPORT: "ONBOARD_SUPPORT"
};

const OnboardRole = {
    DEVELOPER: "DEVELOPER",
    TEAM_MEMBER: "TEAM_MEMBER",
    ACCOUNT_OWNER: "ACCOUNT_OWNER",
    PUBLIC: "PUBLIC",
};

const ManziaAuthState = {
    idle: { key: "idle", title: "Idle", description: "Idle"},
    setup: { key: "setup", title: "Setup", description: "Setup"},
    signIn: { key: "signIn", title: "Sign In", description: "Enter email and password to sign in to your account."},
    signUp: { key: "signUp", title: "Create your Manzia AI account", description: "Enter the details below to create your Manzia AI account."},
    confirmSignIn: { key: "confirmSignIn", title: "Confirm Sign In", description: "Confirm sign in"},
    confirmSignUp: { key: "confirmSignUp", title: "Confirm Sign Up", description: "Enter the 6 digit code that was sent to your email."},
    resendSignUp: { key: "resendSignUp", title: "Resent confirmation code", description: "Enter the 6 digit code that was sent to your email."},
    setupTOTP: { key: "setupTOTP", title: "Setup TOTP", description: "Setup TOTP."},
    forceNewPassword: { key: "forceNewPassword", title: "Set New Password", description: "Your password expired. Enter a new password."},
    changePassword: { key: "changePassword", title: "Change Password", description: "Change your password"},
    resetPassword: { key: "resetPassword", title: "Reset Password", description: "Enter your email address. You will receive a code to reset your password if your email is associated with a Manzia AI account."},
    confirmResetPassword: { key: "confirmResetPassword", title: "Confirm Reset Password", description: "Code will be sent to your email if its associated with a Manzia AI account. Enter the code and then create a new password."},
    verifyUser: { key: "verifyUser", title: "Verification", description: "Enter your email to receive verification code."},
    confirmVerifyUser: { key: "confirmVerifyUser", title: "Confirm Verification", description: "Enter verification code."},
    signOut: { key: "signOut", title: "Sign Out", description: "Sign out."},
    authenticated: { key: "authenticated", title: "Authenticated", description: "Authenticated."}
};

const ManziaAuthTransition = {
    toSignIn: "toSignIn",
    toSignUp: "toSignUp",
    toResetPassword: "toResetPassword",
    skipVerification: "skipVerification"
};

const SIGNUP_API = {
    createBot: `https://config.manzia.com/signup-${ENV}`,
    createProduct: `https://config.manzia.com/products-${ENV}`,
    checkDomain: `https://config.manzia.com/domain-${ENV}`
};

const DELETE_API = {
    deleteAccount: `https://config.manzia.com/delete-${ENV}` 
}

const ManziaAuthKeys = {
    signupUser: "manzia.signup.authuser",
    welcomeProduct: "manzia.product.details",
    chatSession: "manzia.chat.session"
};

const ProductDeployState = {
    NONE: "no_products",
    INCOMPLETE: "incomplete_products",
    COMPLETE: "complete_products",
    COMPLETE_INCOMPLETE: " complete_incomplete_products"
};

const ManziaNavLinks = {
    signOut: { key: "signOut", label: "Sign Out" },
    support: {key: "support", label: "Contact Support" },
    settings: {key: "settings", label: "Settings" },
    billing: { key: "billing", label: "Manage Billing" }
};

const MANZIA_CAPTCHA_KEY = "6Lc0FVsoAAAAANG79uWNLmVXyPzl5ly2DQn-SSfu";

const MANZIA_BILL_RATE = 0.15;

const ManziaStripeAPI = {
    checkoutSession: { method: "get", url: `https://api.manzia.com/checkout-${ENV}/session` },
    createCheckoutSession: { method: "post", url: `https://api.manzia.com/checkout-${ENV}/session` },
    checkoutPrice: { method: "get", url: `https://api.manzia.com/checkout-${ENV}/price` },
    portalSession: { method: "get", url: `https://api.manzia.com/checkout-${ENV}/billing`},
    subscriptionInvoice: { method: "get", url: `https://api.manzia.com/checkout-${ENV}/invoice`}
};

const SubscriptionStatus = {
    active: "active",
    paused: "paused",
    none: "none",
    canceled: "canceled",
    incomplete: "incomplete",
    incomplete_expired: "incomplete_expired",
    past_due: "past_due",
    unpaid: "unpaid",
    trialing: "trialing"
}

export {
    APP_NAME,
    ENV,
    APP_VERSION,
    productActions,
    DeploymentStatus,
    AppListenerChannels,
    TABS,
    BOT_SESSION_KEY,
    lexV2Commands,
    CONVERSATION_TITLE,
    awsCommands,
    botStyleTemplates,
    EMAIL_API,
    PERIOD,
    StatisticsSummary,
    UserFlows,
    OnboardRole,
    OnboardState,
    ManziaAuthState,
    ManziaAuthTransition,
    SIGNUP_API,
    ManziaAuthKeys,
    ProductDeployState,
    ManziaNavLinks,
    MANZIA_CAPTCHA_KEY,
    MANZIA_BILL_RATE,
    ManziaStripeAPI,
    ManziaBillingInfo,
    ManziaSampleIntents,
    SubscriptionStatus,
    WebAppDomain,
    ManziaConversationInfo,
    DELETE_API
};