/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import form from "layouts/onboard/schemas/form";
import industryCategories from "layouts/train/new-product/options/productCategories";
import demoTypes from "layouts/train/new-product/options/demoTypes";

const {
  formField: {
    botName,
    botTone,
    productName,
    productPage,
    demos
  },
  backend
} = form;

const initialValues = {
  welcome: {
    [botName.name]: "",
    [botTone.name]: "",
    [productName.name]: "",
    [productPage.name]: "",
    [demos.name]: {
      [demos.scheduleDemo.name]: "",
      [demos.demoType.name]: demoTypes[0]
    },
  },
  backend: {
    [backend.organizationName.name]: "",
    [backend.webDomain.name]: "",
    [backend.industryCode.name]: industryCategories[0].title
  }
  
};

export default initialValues;
