import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';


// Material Dashboard
import MDButton from 'components/MDButton';

// Mutations
import useDeleteConversation from "customhooks/useDeleteConversation";


// Manzia
import NotificationView from 'layouts/common/Notification';
import LoadView from "layouts/common/LoadView";

function ChatDelete({ conversation, onDeleted }) {
    
    const [open, setOpen] = useState(false);
    const { loading: deleteLoading, error: deleteError, value: deleteData,
        runCommand: deleteConversation } = useDeleteConversation();
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteConversation()([ conversation ]);
    }

    useEffect(() => {
        if (!deleteData) return;
        onDeleted(conversation);
    }, [deleteData]);

  return (
    <>
    <IconButton 
        size="small" 
        aria-label="delete" 
        color="text"
        onClick={handleClickOpen}
    >
        <Tooltip title="Delete" placement="top">
            <DeleteIcon fontSize="small" />
        </Tooltip>
    </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle>Delete conversation</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {`Are you sure you want to delete "${conversation.title}"?`}
            </DialogContentText>

            {deleteLoading && <LoadView loadingMessage="Deleting..." />}
            
            {deleteError && <NotificationView 
                color="error"
                message="Encountered error in deleting conversation. Please retry later."
            />}

            {deleteData && <NotificationView 
                color="success"
                message="Successfully deleted conversation."
            />}
        </DialogContent>
        <DialogActions>
            <MDButton 
                variant="outlined"
                color="dark" 
                onClick={handleClose}
            >
                Cancel
            </MDButton>
            <MDButton 
                variant="gradient" 
                color="info" 
                disabled={deleteLoading || !!deleteError || !!deleteData}
                onClick={handleDelete}
            >
              <Icon>delete</Icon>&nbsp; Delete
            </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

// Typechecking props for BaseLayout
ChatDelete.propTypes = {
  conversation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  onDeleted: PropTypes.func.isRequired
};


export default ChatDelete;