/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

// Formik
import { ErrorMessage } from 'formik';

// NewProduct page components
import FormField from "layouts/train/new-product/components/FormField";
import demoTypes from "layouts/train/new-product/options/demoTypes";
// import Media from "layouts/train/new-product/components/Media";
// import AutocompleteField from "layouts/train/new-product/components/AutocompleteField";
// import EditorField from "layouts/train/new-product/components/EditorField";


function ProductInfo({ formData }) {

  const { values, setFieldValue,
    formField: { productName, productDescription, demos, productPage } } = formData;

  const handleDemo = (e, value) => {
    switch (e.target.name) {
      case 'demo-type':
        setFieldValue(`${demos.name}.${demos.demoType.name}`, value, false);
        break;
      default:
        break;
    }
  }

  return (
    <MDBox>

      {/* <ExpandAccordion 
        title="Sample utterances and conversation flow."
      >
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <ProductIntent 
                  utterances={productInfo.utterances}
                  intentInfo={productInfo.info} 
                />
            </Grid>
            <Grid item xs={12} md={6}>
              <ChatUI chats={productInfo.conversations} />
            </Grid>
          </Grid>
      </ExpandAccordion> */}
      
      <MDBox mt={3} p={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5" color="dark">Basic Info</MDTypography> 
        </MDBox>
         
        <Grid container spacing={3}>
            
            <Grid item xs={12}>
              <FormField 
                type={productName.type} 
                label={productName.label} 
                name={productName.name}
                placeholder={productName.placeholder} 
              />
            </Grid>

            <Grid item xs={12}>
              <MDBox my={1}>
                <MDBox display="inline-block" my={1}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="dark"
                    >
                      Enter product page URL (e.g landing page) from which to extract product description, features, benefits etc.
                    </MDTypography>
                  </MDBox>
                    <FormField 
                      type={productPage.type} 
                      label={productPage.label} 
                      name={productPage.name}
                      placeholder={productPage.placeholder} 
                    />
                    {/* <InputURL 
                      type={productPage.type} 
                      label={productPage.label}
                      name={productPage.name}
                      placeholder={productPage.placeholder}
                      responseHandler={handleProductPage}
                      fetchData={{
                        productName: values[productName.name],
                        extractURL: values[productPage.name],
                        productType: productTypeVal
                      }}
                      disabled={!isValidUrl(values[productPage.name])}
                    /> */}
              </MDBox>
            </Grid>

            <Grid item xs={12}>
              <MDBox my={2}>
                  
                  <FormField 
                    label={productDescription.label}
                    name={productDescription.name}
                    type={productDescription.type}
                    placeholder={productDescription.placeholder}
                    multiline
                    rows={8}
                  />

                {/* <MDEditor value={editorVal} onChange={(value) => handleValues(`info.${productDescription.name}`, value)} name={productDescription.name} /> */}
              </MDBox>
            </Grid>

            <Grid item xs={12}>
              <MDBox my={2}>
                  <MDBox display="inline-block" my={1}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="dark"
                    >
                      Enter product demo, trial, schedule demo, book meeting etc. URL.
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={5} sm={4}>
                        <Autocomplete
                          value={values[demos.name][demos.demoType.name]}
                          name="demo-type"
                          autoComplete
                          onChange={handleDemo}
                          options={demoTypes}
                          renderInput={(params) => <MDInput {...params} variant="standard" label={demos.demoType.label} sx={{ height: '100%' }}/>}
                        />
                        <MDBox mt={0.75}>
                          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                            <ErrorMessage name={`${demos.name}.${demos.demoType.name}`} />
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={7} sm={8}>
                        <FormField 
                          type={demos.scheduleDemo.type} 
                          label={demos.scheduleDemo.label}
                          name={`${demos.name}.${demos.scheduleDemo.name}`}
                          placeholder={demos.scheduleDemo.placeholder}
                          variant="standard"
                        />
                      </Grid>
                  </Grid>
              </MDBox>
            </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}


// typechecking props for UserInfo
ProductInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};


export default ProductInfo;
