/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Manzia
import RadioButtons from "layouts/settings/components/RadioButtons";
import { OnboardRole } from "appSettings";

function getRoleLabel(role) {
  switch (role) {
      case OnboardRole.ACCOUNT_OWNER:
          return "Owner";
      case OnboardRole.TEAM_MEMBER:
          return "Member";
      case OnboardRole.DEVELOPER:
          return "Developer";
      default:
          return "";
  }
}

function EmailRole({ inviteEmail, onChange }) {
  const [roleId, setRoleId] = useState(OnboardRole.TEAM_MEMBER);

  const handleChange = (option) => {
    setRoleId(option.emailRole);
    onChange({ inviteEmail, roleId: option.emailRole });
  }

  return (
      <MDBox display="flex" justifyContent="center">
        <RadioButtons
          row 
          name="emailRole"
          value={roleId}
          handleChange={handleChange}
          disabled
          radioList={[
            { id: OnboardRole.TEAM_MEMBER, value: OnboardRole.TEAM_MEMBER, label: getRoleLabel(OnboardRole.TEAM_MEMBER)},
            { id: OnboardRole.DEVELOPER, value: OnboardRole.DEVELOPER, label: getRoleLabel(OnboardRole.DEVELOPER)},
          ]}
        />
      </MDBox>
  );
}


// Typechecking props for the StatusCell
EmailRole.propTypes = {
  onChange: PropTypes.func.isRequired,
  inviteEmail: PropTypes.string.isRequired
};

export default EmailRole;
