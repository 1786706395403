/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import form from "layouts/authentication/schema/form";

const {
  login: { loginEmail, loginPassword },
  signup: { fullName, signupEmail, signupPassword, confirmPassword },
  resetPassword,
  changePassword
} = form;

const initialValues = {
  login: {
    [loginEmail.name]: "",
    [loginPassword.name]: ""
  },
  signup: {
    [fullName.name]: "",
    [signupEmail.name]: "",
    [signupPassword.name]: "",
    [confirmPassword.name]: "",
  },
  resetPassword: {
    [resetPassword.resetCode.name]: "",
    [resetPassword.newPassword.name]: "",
    [resetPassword.confirmPassword.name]: "",
  },
  changePassword: {
    [changePassword.newPassword.name]: "",
    [changePassword.confirmPassword.name]: "",
  }
}

export default initialValues;
