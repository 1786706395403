/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";

// Manzia
import LinkURL from "layouts/common/LinkURL";

function BoldLinks({ text, links, bolds }) {
    // Function to create a regex pattern for each substring
  // const createPattern = (substring) => new RegExp(`(${substring})`, 'g');
  
  // Combine all substring patterns into one
  const linkTexts = {};
  links.filter(link => link.linkText?.length > 0).forEach(link => { linkTexts[link.linkText] = { ...link } });
  const linkKeys = Object.keys(linkTexts);
  const substrings = [ ...linkKeys, ...bolds.filter(bold => bold.length > 0) ];

  // Check we have substrings
  if (substrings.length === 0) {
    return (
        <MDTypography variant="body2" color="dark" fontWeight="regular">
            {text}
        </MDTypography>
    );
  }

  // Combine
  // Escaping special characters in substrings and creating a regex pattern
  const escapedSubstrings = substrings.map(s => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
  const regexPattern = new RegExp(`(${escapedSubstrings.join('|')})`, 'g');
  // const combinedPattern = new RegExp(substrings.map(createPattern).join('|'), 'g');

  // Splitting the main string based on the combined pattern
  const parts = text.split(regexPattern);

  /* eslint-disable no-nested-ternary */
  return (
    <MDTypography display="inline" variant="body2" color="dark" fontWeight="regular">
      {parts.map((part, index) => {
        const keyIdx = `index${index}`;
        return (
        bolds.includes(part) ? 
        <MDTypography 
            component="span"
            variant="body2" 
            color="dark" 
            key={keyIdx} 
            fontWeight="bold"
        >
            {part}
        </MDTypography> : 
        linkKeys.includes(part) ? 
        <LinkURL 
            key={keyIdx}
            color="info" 
            fontWeight="bold" 
            url={linkTexts[part].linkURL} 
            anchorText={linkTexts[part].linkText} 
            type={linkTexts[part]?.type} 
        /> : 
        <MDTypography 
            component="span"
            variant="body2" 
            color="dark" 
            key={keyIdx} 
            fontWeight="regular"
        >
            {part}
        </MDTypography>
      )}
      )}
    </MDTypography>
  );
  /* eslint-enable no-nested-ternary */
}

BoldLinks.defaultProps = {
    links: [{ linkText: "", linkURL: "", type: ""}],
    bolds: [""]
};

BoldLinks.propTypes = {
    text: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
        linkText: PropTypes.string,
        linkURL: PropTypes.string,
        type: PropTypes.string
    })),
    bolds: PropTypes.arrayOf(PropTypes.string)
};

export default BoldLinks;