import { useState, useCallback, useContext, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Material Dashboard
import MDButton from 'components/MDButton';
// import MDBox from 'components/MDBox';
// import MDTypography from 'components/MDTypography';

// Moustache
import Mustache from "mustache";

// S3
import useLazyS3Client from 'customhooks/useLazyS3Client';
import { botStyleTemplates, awsCommands } from "appSettings";

// Auth
import { AuthUserContext } from "App";

// Manzia
import ProgressButton from 'layouts/common/ProgressButton';
import NotificationView from 'layouts/common/Notification';
import useLocalChatbotUser from 'customhooks/useLocalChatbotUser';

// Template
import cssTemplate from "layouts/settings/templates/cssTemplate";

// const USERID = "20966bf1-2054-49ca-9328-7f43b8f8c65b";

function PublishStyles({ children, templateValues, disabled, onPublish }) {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const [open, setOpen] = useState(false);
  const { account: { webDomain } } = useLocalChatbotUser({ userId: USERID });
  const { loading, error, data, runCommand, isReady } = useLazyS3Client(awsCommands.s3.putObject);

  useEffect(() => {
    if (!data) return;
    onPublish(data);
  }, [data])
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getDialogContent() {
      return (
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to publish the bot style changes? Visitors to ${webDomain} will see these bot styles after you confirm.`} 
          </DialogContentText>
          
          {error && <NotificationView 
            color="error"
            message="There an error publishing the bot style changes. Please retry."
          />}
          {data && <NotificationView 
            color="success"
            message="The bot style changes have been successfully published."
          />}

        </DialogContent>
      );
  }

  const handleDeploy = useCallback(() => {
    const cssStyles = Mustache.render(cssTemplate, templateValues);
    const input = {
      Bucket: botStyleTemplates.bucket,
      Key: `${webDomain.replaceAll("-", "_").replaceAll(".", "-")}/${botStyleTemplates.currentCssStyles}`,
      Body: cssStyles,
      ContentType: "text/css",
      CacheControl: "no-cache"
    };

    // Publish
    runCommand()(input);
    
  }, [templateValues, isReady]);

  return (
    <div>
      <MDButton 
          variant="outlined" 
          color="dark" 
          fullWidth 
          onClick={handleClickOpen}
          disabled={disabled}
      >
        {children}
      </MDButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Publish new bot styles:</DialogTitle>
        {getDialogContent()}
        <DialogActions>
          <MDButton 
              variant="outlined"
              color="dark" 
              onClick={handleClose}
          >
            Cancel
          </MDButton>
          {data || error ? 
            (<MDButton variant="gradient" color="dark" disabled>
              Publish
            </MDButton>) :
            (<ProgressButton 
                variant="gradient"
                color="info" 
                clickHandler={handleDeploy}
                loading={loading}
              >
                Publish
              </ProgressButton>)
          }
          
        </DialogActions>
      </Dialog>
    </div>
  );
}

// Typechecking props for BaseLayout
PublishStyles.defaultProps = {
  disabled: false
};

PublishStyles.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  onPublish: PropTypes.func.isRequired,
  templateValues: PropTypes.shape({
    toolbar: PropTypes.shape({
      themedark: PropTypes.shape({
        backgroundColor: PropTypes.string
      })
    }).isRequired,
    messagelistcontainer: PropTypes.shape({
      backgroundColor: PropTypes.string
    }).isRequired,
    messagebubble: PropTypes.shape({
      messagebot: PropTypes.shape({
        backgroundColor: PropTypes.string
      }),
      messagehuman: PropTypes.shape({
        backgroundColor: PropTypes.string
      }),
      messagetext: PropTypes.shape({
        color: PropTypes.string
      })
    }).isRequired,
    cardtitle: PropTypes.shape({
      cardtitleprimary: PropTypes.shape({
        backgroundColor: PropTypes.string
      })
    }).isRequired,
    cardactions: PropTypes.shape({
        btn: PropTypes.shape({
          backgroundColor: PropTypes.string
        })
    }).isRequired
  }).isRequired
};


export default  PublishStyles;