/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/train/new-product/schemas/form";
import demoTypes from "layouts/train/new-product/options/demoTypes";
// import PriceTypes from "layouts/train/new-product/options/pricingTypes";

const {
  formField: {
    productName,
    productPage,
    productDescription,
    // pricingTypes,
    problems,
    jobsDone,
    features,
    offers,
    // pricing,
    insights,
    testimonials,
    reviews,
    caseStudies,
    socialMedia,
    press,
    demos,
    competition
  },
} = checkout;

// function priceTypeValidation() {
//   const validation = {};
//   PriceTypes.forEach((type) => {
//     validation[type.code] = false
//   });

//   return validation;
// }

const initialValues = {
  [productName.name]: "",
  [productDescription.name]: "",
  [productPage.name]: "",
  [problems.name]: [""],
  [jobsDone.name]: [""],
  [features.name]: [{
    [features.productFeature.name]: "",
    [features.productBenefit.name]: "",
    [features.productAdvantage.name]: "",
    [features.featureLink.name]: "",
  }],
  // [pricingTypes.name]: priceTypeValidation(),
  // [pricing.name]: {
  //   [pricing.callToAction.name]: "",
  //   [pricing.contactEmail.name]: "",
  // },
  [demos.name]: {
    [demos.demoType.name]: demoTypes[0],
    [demos.scheduleDemo.name]: "",
  },
  [insights.name]: [{
    [insights.companySize.name]: "",
    [insights.industry.name]: "",
    [insights.type.name]: "",
    [insights.jobTitle.name]: "",
    [insights.role.name]: "",
    [insights.responsibility.name]: "",
    [insights.useCase.name]: "",
  }],
  [offers.name]: [""],
  [testimonials.name]: [""],
  [reviews.name]: [""],
  [caseStudies.name]: [""],
  [socialMedia.name]: [""],
  [press.name]: [""],
  [competition.name]: "",
};

export default initialValues;
