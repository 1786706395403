/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import * as Yup from "yup";
import checkout from "layouts/train/new-product/schemas/form";
import demoTypes from "layouts/train/new-product/options/demoTypes";
// import PriceTypes from "layouts/train/new-product/options/pricingTypes";

/* eslint-disable */
const illegalChars = /^[a-zA-Z0-9,\.\-'"\?!()\s]*$/;
// const oneToFiveAndIllegalChars = /^\s*(?:[\w,'.\-\?!]+\s+){0,4}[\w,'.\-\?!]+\s*$/;
const fourAndIllegalChars = /^\s*(?:[\w,'.\-\?!]+\s+){4,}[\w,'.\-\?!]+\s*$/;
/* eslint-enable */

const {
  formField: { productName, productPage, problems, features, insights, testimonials,
  caseStudies, reviews, socialMedia, demos, productDescription },
} = checkout;

const validation =
  Yup.object().shape({
    [productName.name]: Yup.string().matches(illegalChars, productName.illegalChars).required(productName.errorMsg),
    [productPage.name]: Yup.string().url(productPage.invalidMsg).required(productPage.errorMsg),
    // [productFaq.name]: Yup.string().url(productFaq.invalidMsg),
    [productDescription.name]: Yup.string().matches(fourAndIllegalChars, productDescription.invalidMsg).required(productDescription.errorMsg),
    [demos.name]: Yup.object().shape({
        [demos.demoType.name]: Yup.string().oneOf(demoTypes).default(demoTypes[0]).required(demos.demoType.errorMsg),
        [demos.scheduleDemo.name]: Yup.string().url(demos.scheduleDemo.invalidMsg),
    }),
    [problems.name]: Yup.array().of(Yup.string().required(problems.errorMsg)).min(1, problems.errorMsg).max(10, problems.invalidMsg),
    // [jobsDone.name]: Yup.array().of(Yup.string().required(jobsDone.errorMsg)).min(1, jobsDone.errorMsg).max(10, jobsDone.invalidMsg),
    [features.name]: Yup.array().of(Yup.object().shape({
        [features.productFeature.name]: Yup.string().matches(illegalChars, features.productFeature.invalidMsg).required(features.productFeature.errorMsg),
        [features.productBenefit.name]: Yup.string().matches(illegalChars, features.productBenefit.invalidMsg).required(features.productBenefit.errorMsg),
        [features.productAdvantage.name]: Yup.string().matches(illegalChars, features.productAdvantage.invalidMsg),
        [features.featureLink.name]: Yup.string().url(features.featureLink.invalidMsg),
      })).min(1, features.errorMsg).max(10, features.invalidMsg),
    [insights.name]: Yup.array().of(Yup.object().shape({
      [insights.companySize.name]: Yup.string().matches(illegalChars, insights.companySize.invalidMsg).required(insights.companySize.errorMsg),
      [insights.industry.name]: Yup.string().matches(illegalChars, insights.industry.invalidMsg).required(insights.industry.errorMsg),
      [insights.type.name]: Yup.string().matches(illegalChars, insights.type.invalidMsg).required(insights.type.errorMsg),
      [insights.jobTitle.name]: Yup.string().matches(illegalChars, insights.jobTitle.invalidMsg).required(insights.jobTitle.errorMsg),
      [insights.role.name]: Yup.string().matches(illegalChars, insights.role.invalidMsg).required(insights.role.errorMsg),
      [insights.responsibility.name]: Yup.string().matches(illegalChars, insights.responsibility.invalidMsg).required(insights.responsibility.errorMsg),
      [insights.useCase.name]: Yup.string().matches(illegalChars, insights.useCase.invalidMsg).required(insights.useCase.errorMsg),
    })).min(1, insights.errorMsg).max(2, insights.invalidMsg),
    // [insights.name]: Yup.array().of(Yup.string().matches(fourAndIllegalChars, insights.illegalMsg).required(insights.errorMsg)).min(1, insights.errorMsg).max(10, insights.invalidMsg),
    // [competition.name]: Yup.string().matches(illegalChars, competition.invalidMsg),
    [testimonials.name]: Yup.array().of(Yup.string().url(testimonials.invalidMsg)),
    [reviews.name]: Yup.array().of(Yup.string().url(reviews.invalidMsg)),
    [caseStudies.name]: Yup.array().of(Yup.string().url(caseStudies.invalidMsg)),
    [socialMedia.name]: Yup.array().of(Yup.string().url(socialMedia.invalidMsg)),
    // [press.name]: Yup.array().of(Yup.string().url(press.invalidMsg).nullable()),
  });

export default validation;
