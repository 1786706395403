import { useCallback, useState, useEffect } from "react"

// Amplify
import { Auth } from 'aws-amplify';

// Network
import axios from "axios";

export default function useAxios() {
    const [token, setToken] = useState();

    useEffect(() => {
        const getJwtToken = async () => {
            // get session
            const session = await Auth.currentSession();    
            // set token
            setToken(session.idToken.jwtToken);
        }
        try {
            getJwtToken();
        } catch (_) {
            // console.log("Failed to retrieve session token", err);
        }
    }, []);

    /* eslint-disable arrow-body-style */
    const callbackMemoized = useCallback(() => (request) => {
        return axios({
            ...request,
            headers: {'Authorization': token},
        });
    }, [token]);
    /* eslint-enable arrow-body-style */

    return { fetch: callbackMemoized };
}
