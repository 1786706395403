const FAQS = [
    { 
        question: "What is a conversation?", 
        answer: "A session between ManziaBot and a website visitor in which the visitor makes one or more utterances is defined as a conversation and is billable. If the visitor does not respond, the session is not a conversation and thus not billable." 
    },
    { 
        question: "How much in funds should I add?", 
        answer: "How much funds to add primarily depends on how much new traffic you get to your website. For example, if you get around 10,000 new visitors every day, then with a 10-20% ManziaBot engagement rate, you should add - 10,000 visitors * 0.2 engagement rate * $0.1 per conversation = $200. In addition, if you are running a time-based marketing campaign you can increase your Account monthly spending limit and then reduce it after the marketing campaign has concluded." 
    },
    { 
        question: "Why do you charge $0.10?", 
        answer: "Our pricing is based on the value we provide. What is the value of a highly qualified lead to your business? What is your customer acquisition cost? Conversations with website visitors on average generate highly qualified leads at a 10-20% rate, meaning every 10 conversations ($0.1 * 10 = $1) generates about 2 highly qualified leads." 
    },
    { 
        question: "What happens when my balance is zero?", 
        answer: "By default, when your Account Balance reduces by 80%, the account owner will receive an email notification to add funds. ManziaBot engagements with website visitors cease when the Account Balance is zero dollars. This means missing out on visitor conversations and highly qualified leads." 
    },
];

export default FAQS;