/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createManziaChatbot = /* GraphQL */ `
  mutation CreateManziaChatbot(
    $input: CreateManziaChatbotInput!
    $condition: ModelManziaChatbotConditionInput
  ) {
    createManziaChatbot(input: $input, condition: $condition) {
      id
      accountId
      lexChatBotId
      chatbotName
      chatbotTone
      chatbotAliasId
      chatbotVersion
      testChatbotAliasId
      testChatbotVersion
      deployedVersion
      deploymentStatus
      deleteTime
      account {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotUserProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatbotDeployments {
        items {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatBotWebUI {
        id
        lexChatBotId
        webDomain
        loaderScriptUrl
        snippetUrl
        webAppBase
        webAppBucket
        chatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotProductTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaChatbot = /* GraphQL */ `
  mutation UpdateManziaChatbot(
    $input: UpdateManziaChatbotInput!
    $condition: ModelManziaChatbotConditionInput
  ) {
    updateManziaChatbot(input: $input, condition: $condition) {
      id
      accountId
      lexChatBotId
      chatbotName
      chatbotTone
      chatbotAliasId
      chatbotVersion
      testChatbotAliasId
      testChatbotVersion
      deployedVersion
      deploymentStatus
      deleteTime
      account {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotUserProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatbotDeployments {
        items {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatBotWebUI {
        id
        lexChatBotId
        webDomain
        loaderScriptUrl
        snippetUrl
        webAppBase
        webAppBucket
        chatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotProductTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaChatbot = /* GraphQL */ `
  mutation DeleteManziaChatbot(
    $input: DeleteManziaChatbotInput!
    $condition: ModelManziaChatbotConditionInput
  ) {
    deleteManziaChatbot(input: $input, condition: $condition) {
      id
      accountId
      lexChatBotId
      chatbotName
      chatbotTone
      chatbotAliasId
      chatbotVersion
      testChatbotAliasId
      testChatbotVersion
      deployedVersion
      deploymentStatus
      deleteTime
      account {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotUserProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatbotDeployments {
        items {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatBotWebUI {
        id
        lexChatBotId
        webDomain
        loaderScriptUrl
        snippetUrl
        webAppBase
        webAppBucket
        chatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotProductTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaChabotWebUI = /* GraphQL */ `
  mutation CreateManziaChabotWebUI(
    $input: CreateManziaChabotWebUIInput!
    $condition: ModelManziaChabotWebUIConditionInput
  ) {
    createManziaChabotWebUI(input: $input, condition: $condition) {
      id
      lexChatBotId
      webDomain
      loaderScriptUrl
      snippetUrl
      webAppBase
      webAppBucket
      chatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaChabotWebUI = /* GraphQL */ `
  mutation UpdateManziaChabotWebUI(
    $input: UpdateManziaChabotWebUIInput!
    $condition: ModelManziaChabotWebUIConditionInput
  ) {
    updateManziaChabotWebUI(input: $input, condition: $condition) {
      id
      lexChatBotId
      webDomain
      loaderScriptUrl
      snippetUrl
      webAppBase
      webAppBucket
      chatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaChabotWebUI = /* GraphQL */ `
  mutation DeleteManziaChabotWebUI(
    $input: DeleteManziaChabotWebUIInput!
    $condition: ModelManziaChabotWebUIConditionInput
  ) {
    deleteManziaChabotWebUI(input: $input, condition: $condition) {
      id
      lexChatBotId
      webDomain
      loaderScriptUrl
      snippetUrl
      webAppBase
      webAppBucket
      chatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaAccount = /* GraphQL */ `
  mutation CreateManziaAccount(
    $input: CreateManziaAccountInput!
    $condition: ModelManziaAccountConditionInput
  ) {
    createManziaAccount(input: $input, condition: $condition) {
      id
      v2chatBotId
      accountOwnerUserId
      accountOwnerEmail
      organizationName
      webDomain
      totalPaymentAmount
      totalConversations
      billRate
      currency
      accountType
      stripeSubscriptionStatus
      industryCode
      industryCategory
      lastBotDeploymentId
      deleteTime
      accountUsers {
        items {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      accountChatBot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaAccount = /* GraphQL */ `
  mutation UpdateManziaAccount(
    $input: UpdateManziaAccountInput!
    $condition: ModelManziaAccountConditionInput
  ) {
    updateManziaAccount(input: $input, condition: $condition) {
      id
      v2chatBotId
      accountOwnerUserId
      accountOwnerEmail
      organizationName
      webDomain
      totalPaymentAmount
      totalConversations
      billRate
      currency
      accountType
      stripeSubscriptionStatus
      industryCode
      industryCategory
      lastBotDeploymentId
      deleteTime
      accountUsers {
        items {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      accountChatBot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaAccount = /* GraphQL */ `
  mutation DeleteManziaAccount(
    $input: DeleteManziaAccountInput!
    $condition: ModelManziaAccountConditionInput
  ) {
    deleteManziaAccount(input: $input, condition: $condition) {
      id
      v2chatBotId
      accountOwnerUserId
      accountOwnerEmail
      organizationName
      webDomain
      totalPaymentAmount
      totalConversations
      billRate
      currency
      accountType
      stripeSubscriptionStatus
      industryCode
      industryCategory
      lastBotDeploymentId
      deleteTime
      accountUsers {
        items {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      accountChatBot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaUser = /* GraphQL */ `
  mutation CreateManziaUser(
    $input: CreateManziaUserInput!
    $condition: ModelManziaUserConditionInput
  ) {
    createManziaUser(input: $input, condition: $condition) {
      id
      accountId
      emailAddress
      fullName
      isAccountOwner
      roleId
      deleteTime
      userRole {
        userId
        roleId
        accountId
        roleName
        roleDescription
        deleteTime
        teamUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        rolePolicy {
          roleId
          policyName
          policyDescription
          permissions
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      userAccount {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      userProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      productTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      botDeployments {
        items {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      testConversations {
        items {
          id
          userProductId
          testedByUserId
          lastModifiedAt
          title
          isExample
          deleteTime
          createdAt
          updatedAt
          manziaIntentExampleConversationsBotId
          manziaIntentExampleConversationsUserProductIntentId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaUser = /* GraphQL */ `
  mutation UpdateManziaUser(
    $input: UpdateManziaUserInput!
    $condition: ModelManziaUserConditionInput
  ) {
    updateManziaUser(input: $input, condition: $condition) {
      id
      accountId
      emailAddress
      fullName
      isAccountOwner
      roleId
      deleteTime
      userRole {
        userId
        roleId
        accountId
        roleName
        roleDescription
        deleteTime
        teamUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        rolePolicy {
          roleId
          policyName
          policyDescription
          permissions
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      userAccount {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      userProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      productTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      botDeployments {
        items {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      testConversations {
        items {
          id
          userProductId
          testedByUserId
          lastModifiedAt
          title
          isExample
          deleteTime
          createdAt
          updatedAt
          manziaIntentExampleConversationsBotId
          manziaIntentExampleConversationsUserProductIntentId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaUser = /* GraphQL */ `
  mutation DeleteManziaUser(
    $input: DeleteManziaUserInput!
    $condition: ModelManziaUserConditionInput
  ) {
    deleteManziaUser(input: $input, condition: $condition) {
      id
      accountId
      emailAddress
      fullName
      isAccountOwner
      roleId
      deleteTime
      userRole {
        userId
        roleId
        accountId
        roleName
        roleDescription
        deleteTime
        teamUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        rolePolicy {
          roleId
          policyName
          policyDescription
          permissions
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      userAccount {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      userProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      productTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      botDeployments {
        items {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      testConversations {
        items {
          id
          userProductId
          testedByUserId
          lastModifiedAt
          title
          isExample
          deleteTime
          createdAt
          updatedAt
          manziaIntentExampleConversationsBotId
          manziaIntentExampleConversationsUserProductIntentId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaUserProduct = /* GraphQL */ `
  mutation CreateManziaUserProduct(
    $input: CreateManziaUserProductInput!
    $condition: ModelManziaUserProductConditionInput
  ) {
    createManziaUserProduct(input: $input, condition: $condition) {
      id
      productName
      productImage
      productType
      trainingStatus
      createdByUserId
      trainedChatBotId
      deleteTime
      trainedChatBot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      productTraining {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      testConversations {
        items {
          id
          userProductId
          testedByUserId
          lastModifiedAt
          title
          isExample
          deleteTime
          createdAt
          updatedAt
          manziaIntentExampleConversationsBotId
          manziaIntentExampleConversationsUserProductIntentId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaUserProduct = /* GraphQL */ `
  mutation UpdateManziaUserProduct(
    $input: UpdateManziaUserProductInput!
    $condition: ModelManziaUserProductConditionInput
  ) {
    updateManziaUserProduct(input: $input, condition: $condition) {
      id
      productName
      productImage
      productType
      trainingStatus
      createdByUserId
      trainedChatBotId
      deleteTime
      trainedChatBot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      productTraining {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      testConversations {
        items {
          id
          userProductId
          testedByUserId
          lastModifiedAt
          title
          isExample
          deleteTime
          createdAt
          updatedAt
          manziaIntentExampleConversationsBotId
          manziaIntentExampleConversationsUserProductIntentId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaUserProduct = /* GraphQL */ `
  mutation DeleteManziaUserProduct(
    $input: DeleteManziaUserProductInput!
    $condition: ModelManziaUserProductConditionInput
  ) {
    deleteManziaUserProduct(input: $input, condition: $condition) {
      id
      productName
      productImage
      productType
      trainingStatus
      createdByUserId
      trainedChatBotId
      deleteTime
      trainedChatBot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      productTraining {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      testConversations {
        items {
          id
          userProductId
          testedByUserId
          lastModifiedAt
          title
          isExample
          deleteTime
          createdAt
          updatedAt
          manziaIntentExampleConversationsBotId
          manziaIntentExampleConversationsUserProductIntentId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaProductTraining = /* GraphQL */ `
  mutation CreateManziaProductTraining(
    $input: CreateManziaProductTrainingInput!
    $condition: ModelManziaProductTrainingConditionInput
  ) {
    createManziaProductTraining(input: $input, condition: $condition) {
      id
      userProductId
      trainingData
      trainedByUserId
      trainedChatBotId
      botDeploymentId
      lastModifiedAt
      deleteTime
      trainingStatus
      userProduct {
        id
        productName
        productImage
        productType
        trainingStatus
        createdByUserId
        trainedChatBotId
        deleteTime
        trainedChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        productTraining {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      trainedByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      botDeployment {
        id
        manziaChatbotId
        lastDeployedAt
        deployedByUserId
        productTrainingId
        productName
        deployedUsername
        userDeployMessage
        versionToDeploy
        status
        productTraining {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        manziaChatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        deployedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaProductTraining = /* GraphQL */ `
  mutation UpdateManziaProductTraining(
    $input: UpdateManziaProductTrainingInput!
    $condition: ModelManziaProductTrainingConditionInput
  ) {
    updateManziaProductTraining(input: $input, condition: $condition) {
      id
      userProductId
      trainingData
      trainedByUserId
      trainedChatBotId
      botDeploymentId
      lastModifiedAt
      deleteTime
      trainingStatus
      userProduct {
        id
        productName
        productImage
        productType
        trainingStatus
        createdByUserId
        trainedChatBotId
        deleteTime
        trainedChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        productTraining {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      trainedByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      botDeployment {
        id
        manziaChatbotId
        lastDeployedAt
        deployedByUserId
        productTrainingId
        productName
        deployedUsername
        userDeployMessage
        versionToDeploy
        status
        productTraining {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        manziaChatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        deployedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaProductTraining = /* GraphQL */ `
  mutation DeleteManziaProductTraining(
    $input: DeleteManziaProductTrainingInput!
    $condition: ModelManziaProductTrainingConditionInput
  ) {
    deleteManziaProductTraining(input: $input, condition: $condition) {
      id
      userProductId
      trainingData
      trainedByUserId
      trainedChatBotId
      botDeploymentId
      lastModifiedAt
      deleteTime
      trainingStatus
      userProduct {
        id
        productName
        productImage
        productType
        trainingStatus
        createdByUserId
        trainedChatBotId
        deleteTime
        trainedChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        productTraining {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      trainedByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      botDeployment {
        id
        manziaChatbotId
        lastDeployedAt
        deployedByUserId
        productTrainingId
        productName
        deployedUsername
        userDeployMessage
        versionToDeploy
        status
        productTraining {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        manziaChatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        deployedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaBotDeployment = /* GraphQL */ `
  mutation CreateManziaBotDeployment(
    $input: CreateManziaBotDeploymentInput!
    $condition: ModelManziaBotDeploymentConditionInput
  ) {
    createManziaBotDeployment(input: $input, condition: $condition) {
      id
      manziaChatbotId
      lastDeployedAt
      deployedByUserId
      productTrainingId
      productName
      deployedUsername
      userDeployMessage
      versionToDeploy
      status
      productTraining {
        id
        userProductId
        trainingData
        trainedByUserId
        trainedChatBotId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        trainedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        botDeployment {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      manziaChatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      deployedByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaBotDeployment = /* GraphQL */ `
  mutation UpdateManziaBotDeployment(
    $input: UpdateManziaBotDeploymentInput!
    $condition: ModelManziaBotDeploymentConditionInput
  ) {
    updateManziaBotDeployment(input: $input, condition: $condition) {
      id
      manziaChatbotId
      lastDeployedAt
      deployedByUserId
      productTrainingId
      productName
      deployedUsername
      userDeployMessage
      versionToDeploy
      status
      productTraining {
        id
        userProductId
        trainingData
        trainedByUserId
        trainedChatBotId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        trainedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        botDeployment {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      manziaChatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      deployedByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaBotDeployment = /* GraphQL */ `
  mutation DeleteManziaBotDeployment(
    $input: DeleteManziaBotDeploymentInput!
    $condition: ModelManziaBotDeploymentConditionInput
  ) {
    deleteManziaBotDeployment(input: $input, condition: $condition) {
      id
      manziaChatbotId
      lastDeployedAt
      deployedByUserId
      productTrainingId
      productName
      deployedUsername
      userDeployMessage
      versionToDeploy
      status
      productTraining {
        id
        userProductId
        trainingData
        trainedByUserId
        trainedChatBotId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        trainedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        botDeployment {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      manziaChatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      deployedByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaTestConversation = /* GraphQL */ `
  mutation CreateManziaTestConversation(
    $input: CreateManziaTestConversationInput!
    $condition: ModelManziaTestConversationConditionInput
  ) {
    createManziaTestConversation(input: $input, condition: $condition) {
      id
      userProductId
      testedByUserId
      lastModifiedAt
      title
      isExample
      deleteTime
      testChatMessages {
        items {
          id
          testConversationId
          timestamp
          intentName
          message
          sender
          lastModifiedAt
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      manziaIntentExampleConversationsBotId
      manziaIntentExampleConversationsUserProductIntentId
      __typename
    }
  }
`;
export const updateManziaTestConversation = /* GraphQL */ `
  mutation UpdateManziaTestConversation(
    $input: UpdateManziaTestConversationInput!
    $condition: ModelManziaTestConversationConditionInput
  ) {
    updateManziaTestConversation(input: $input, condition: $condition) {
      id
      userProductId
      testedByUserId
      lastModifiedAt
      title
      isExample
      deleteTime
      testChatMessages {
        items {
          id
          testConversationId
          timestamp
          intentName
          message
          sender
          lastModifiedAt
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      manziaIntentExampleConversationsBotId
      manziaIntentExampleConversationsUserProductIntentId
      __typename
    }
  }
`;
export const deleteManziaTestConversation = /* GraphQL */ `
  mutation DeleteManziaTestConversation(
    $input: DeleteManziaTestConversationInput!
    $condition: ModelManziaTestConversationConditionInput
  ) {
    deleteManziaTestConversation(input: $input, condition: $condition) {
      id
      userProductId
      testedByUserId
      lastModifiedAt
      title
      isExample
      deleteTime
      testChatMessages {
        items {
          id
          testConversationId
          timestamp
          intentName
          message
          sender
          lastModifiedAt
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      manziaIntentExampleConversationsBotId
      manziaIntentExampleConversationsUserProductIntentId
      __typename
    }
  }
`;
export const createManziaTestChatMessage = /* GraphQL */ `
  mutation CreateManziaTestChatMessage(
    $input: CreateManziaTestChatMessageInput!
    $condition: ModelManziaTestChatMessageConditionInput
  ) {
    createManziaTestChatMessage(input: $input, condition: $condition) {
      id
      testConversationId
      timestamp
      intentName
      message
      sender
      lastModifiedAt
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaTestChatMessage = /* GraphQL */ `
  mutation UpdateManziaTestChatMessage(
    $input: UpdateManziaTestChatMessageInput!
    $condition: ModelManziaTestChatMessageConditionInput
  ) {
    updateManziaTestChatMessage(input: $input, condition: $condition) {
      id
      testConversationId
      timestamp
      intentName
      message
      sender
      lastModifiedAt
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaTestChatMessage = /* GraphQL */ `
  mutation DeleteManziaTestChatMessage(
    $input: DeleteManziaTestChatMessageInput!
    $condition: ModelManziaTestChatMessageConditionInput
  ) {
    deleteManziaTestChatMessage(input: $input, condition: $condition) {
      id
      testConversationId
      timestamp
      intentName
      message
      sender
      lastModifiedAt
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaIntentExample = /* GraphQL */ `
  mutation CreateManziaIntentExample(
    $input: CreateManziaIntentExampleInput!
    $condition: ModelManziaIntentExampleConditionInput
  ) {
    createManziaIntentExample(input: $input, condition: $condition) {
      botId
      userProductIntentId
      intentId
      userId
      deleteTime
      conversations {
        items {
          id
          userProductId
          testedByUserId
          lastModifiedAt
          title
          isExample
          deleteTime
          createdAt
          updatedAt
          manziaIntentExampleConversationsBotId
          manziaIntentExampleConversationsUserProductIntentId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaIntentExample = /* GraphQL */ `
  mutation UpdateManziaIntentExample(
    $input: UpdateManziaIntentExampleInput!
    $condition: ModelManziaIntentExampleConditionInput
  ) {
    updateManziaIntentExample(input: $input, condition: $condition) {
      botId
      userProductIntentId
      intentId
      userId
      deleteTime
      conversations {
        items {
          id
          userProductId
          testedByUserId
          lastModifiedAt
          title
          isExample
          deleteTime
          createdAt
          updatedAt
          manziaIntentExampleConversationsBotId
          manziaIntentExampleConversationsUserProductIntentId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaIntentExample = /* GraphQL */ `
  mutation DeleteManziaIntentExample(
    $input: DeleteManziaIntentExampleInput!
    $condition: ModelManziaIntentExampleConditionInput
  ) {
    deleteManziaIntentExample(input: $input, condition: $condition) {
      botId
      userProductIntentId
      intentId
      userId
      deleteTime
      conversations {
        items {
          id
          userProductId
          testedByUserId
          lastModifiedAt
          title
          isExample
          deleteTime
          createdAt
          updatedAt
          manziaIntentExampleConversationsBotId
          manziaIntentExampleConversationsUserProductIntentId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaTeamInvite = /* GraphQL */ `
  mutation CreateManziaTeamInvite(
    $input: CreateManziaTeamInviteInput!
    $condition: ModelManziaTeamInviteConditionInput
  ) {
    createManziaTeamInvite(input: $input, condition: $condition) {
      emailAddress
      accountId
      manziaChatbotId
      organizationName
      userRole
      emailMessageId
      senderUserId
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaTeamInvite = /* GraphQL */ `
  mutation UpdateManziaTeamInvite(
    $input: UpdateManziaTeamInviteInput!
    $condition: ModelManziaTeamInviteConditionInput
  ) {
    updateManziaTeamInvite(input: $input, condition: $condition) {
      emailAddress
      accountId
      manziaChatbotId
      organizationName
      userRole
      emailMessageId
      senderUserId
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaTeamInvite = /* GraphQL */ `
  mutation DeleteManziaTeamInvite(
    $input: DeleteManziaTeamInviteInput!
    $condition: ModelManziaTeamInviteConditionInput
  ) {
    deleteManziaTeamInvite(input: $input, condition: $condition) {
      emailAddress
      accountId
      manziaChatbotId
      organizationName
      userRole
      emailMessageId
      senderUserId
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaUserRole = /* GraphQL */ `
  mutation CreateManziaUserRole(
    $input: CreateManziaUserRoleInput!
    $condition: ModelManziaUserRoleConditionInput
  ) {
    createManziaUserRole(input: $input, condition: $condition) {
      userId
      roleId
      accountId
      roleName
      roleDescription
      deleteTime
      teamUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      rolePolicy {
        roleId
        policyName
        policyDescription
        permissions
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaUserRole = /* GraphQL */ `
  mutation UpdateManziaUserRole(
    $input: UpdateManziaUserRoleInput!
    $condition: ModelManziaUserRoleConditionInput
  ) {
    updateManziaUserRole(input: $input, condition: $condition) {
      userId
      roleId
      accountId
      roleName
      roleDescription
      deleteTime
      teamUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      rolePolicy {
        roleId
        policyName
        policyDescription
        permissions
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaUserRole = /* GraphQL */ `
  mutation DeleteManziaUserRole(
    $input: DeleteManziaUserRoleInput!
    $condition: ModelManziaUserRoleConditionInput
  ) {
    deleteManziaUserRole(input: $input, condition: $condition) {
      userId
      roleId
      accountId
      roleName
      roleDescription
      deleteTime
      teamUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      rolePolicy {
        roleId
        policyName
        policyDescription
        permissions
        deleteTime
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaRolePolicy = /* GraphQL */ `
  mutation CreateManziaRolePolicy(
    $input: CreateManziaRolePolicyInput!
    $condition: ModelManziaRolePolicyConditionInput
  ) {
    createManziaRolePolicy(input: $input, condition: $condition) {
      roleId
      policyName
      policyDescription
      permissions
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaRolePolicy = /* GraphQL */ `
  mutation UpdateManziaRolePolicy(
    $input: UpdateManziaRolePolicyInput!
    $condition: ModelManziaRolePolicyConditionInput
  ) {
    updateManziaRolePolicy(input: $input, condition: $condition) {
      roleId
      policyName
      policyDescription
      permissions
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaRolePolicy = /* GraphQL */ `
  mutation DeleteManziaRolePolicy(
    $input: DeleteManziaRolePolicyInput!
    $condition: ModelManziaRolePolicyConditionInput
  ) {
    deleteManziaRolePolicy(input: $input, condition: $condition) {
      roleId
      policyName
      policyDescription
      permissions
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaOnboard = /* GraphQL */ `
  mutation CreateManziaOnboard(
    $input: CreateManziaOnboardInput!
    $condition: ModelManziaOnboardConditionInput
  ) {
    createManziaOnboard(input: $input, condition: $condition) {
      emailAddress
      onboardState
      userId
      accountId
      webDomain
      organizationName
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaOnboard = /* GraphQL */ `
  mutation UpdateManziaOnboard(
    $input: UpdateManziaOnboardInput!
    $condition: ModelManziaOnboardConditionInput
  ) {
    updateManziaOnboard(input: $input, condition: $condition) {
      emailAddress
      onboardState
      userId
      accountId
      webDomain
      organizationName
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaOnboard = /* GraphQL */ `
  mutation DeleteManziaOnboard(
    $input: DeleteManziaOnboardInput!
    $condition: ModelManziaOnboardConditionInput
  ) {
    deleteManziaOnboard(input: $input, condition: $condition) {
      emailAddress
      onboardState
      userId
      accountId
      webDomain
      organizationName
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaStripeCheckout = /* GraphQL */ `
  mutation CreateManziaStripeCheckout(
    $input: CreateManziaStripeCheckoutInput!
    $condition: ModelManziaStripeCheckoutConditionInput
  ) {
    createManziaStripeCheckout(input: $input, condition: $condition) {
      accountId
      stripeCustomerId
      stripeSubscription
      stripeSubscriptionItem
      latestSubscriptionInvoice
      billingLimit
      lastSessionId
      lastTotalAmountPaid
      lastSubTotalAmountPaid
      lastPaymentAt
      lastPaymentIntentId
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaStripeCheckout = /* GraphQL */ `
  mutation UpdateManziaStripeCheckout(
    $input: UpdateManziaStripeCheckoutInput!
    $condition: ModelManziaStripeCheckoutConditionInput
  ) {
    updateManziaStripeCheckout(input: $input, condition: $condition) {
      accountId
      stripeCustomerId
      stripeSubscription
      stripeSubscriptionItem
      latestSubscriptionInvoice
      billingLimit
      lastSessionId
      lastTotalAmountPaid
      lastSubTotalAmountPaid
      lastPaymentAt
      lastPaymentIntentId
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaStripeCheckout = /* GraphQL */ `
  mutation DeleteManziaStripeCheckout(
    $input: DeleteManziaStripeCheckoutInput!
    $condition: ModelManziaStripeCheckoutConditionInput
  ) {
    deleteManziaStripeCheckout(input: $input, condition: $condition) {
      accountId
      stripeCustomerId
      stripeSubscription
      stripeSubscriptionItem
      latestSubscriptionInvoice
      billingLimit
      lastSessionId
      lastTotalAmountPaid
      lastSubTotalAmountPaid
      lastPaymentAt
      lastPaymentIntentId
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaBotSetting = /* GraphQL */ `
  mutation CreateManziaBotSetting(
    $input: CreateManziaBotSettingInput!
    $condition: ModelManziaBotSettingConditionInput
  ) {
    createManziaBotSetting(input: $input, condition: $condition) {
      chatbotId
      accountId
      displayFrequency
      displayTiming
      displayDevices
      targetingType
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaBotSetting = /* GraphQL */ `
  mutation UpdateManziaBotSetting(
    $input: UpdateManziaBotSettingInput!
    $condition: ModelManziaBotSettingConditionInput
  ) {
    updateManziaBotSetting(input: $input, condition: $condition) {
      chatbotId
      accountId
      displayFrequency
      displayTiming
      displayDevices
      targetingType
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaBotSetting = /* GraphQL */ `
  mutation DeleteManziaBotSetting(
    $input: DeleteManziaBotSettingInput!
    $condition: ModelManziaBotSettingConditionInput
  ) {
    deleteManziaBotSetting(input: $input, condition: $condition) {
      chatbotId
      accountId
      displayFrequency
      displayTiming
      displayDevices
      targetingType
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaProductPages = /* GraphQL */ `
  mutation CreateManziaProductPages(
    $input: CreateManziaProductPagesInput!
    $condition: ModelManziaProductPagesConditionInput
  ) {
    createManziaProductPages(input: $input, condition: $condition) {
      pageURL
      accountId
      productName
      productDetails
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaProductPages = /* GraphQL */ `
  mutation UpdateManziaProductPages(
    $input: UpdateManziaProductPagesInput!
    $condition: ModelManziaProductPagesConditionInput
  ) {
    updateManziaProductPages(input: $input, condition: $condition) {
      pageURL
      accountId
      productName
      productDetails
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaProductPages = /* GraphQL */ `
  mutation DeleteManziaProductPages(
    $input: DeleteManziaProductPagesInput!
    $condition: ModelManziaProductPagesConditionInput
  ) {
    deleteManziaProductPages(input: $input, condition: $condition) {
      pageURL
      accountId
      productName
      productDetails
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManziaConversationSetting = /* GraphQL */ `
  mutation CreateManziaConversationSetting(
    $input: CreateManziaConversationSettingInput!
    $condition: ModelManziaConversationSettingConditionInput
  ) {
    createManziaConversationSetting(input: $input, condition: $condition) {
      accountId
      conversationEmailFilters
      timeZone
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManziaConversationSetting = /* GraphQL */ `
  mutation UpdateManziaConversationSetting(
    $input: UpdateManziaConversationSettingInput!
    $condition: ModelManziaConversationSettingConditionInput
  ) {
    updateManziaConversationSetting(input: $input, condition: $condition) {
      accountId
      conversationEmailFilters
      timeZone
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManziaConversationSetting = /* GraphQL */ `
  mutation DeleteManziaConversationSetting(
    $input: DeleteManziaConversationSettingInput!
    $condition: ModelManziaConversationSettingConditionInput
  ) {
    deleteManziaConversationSetting(input: $input, condition: $condition) {
      accountId
      conversationEmailFilters
      timeZone
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
