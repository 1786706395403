import { useCallback, useState, useEffect } from "react"

// Amplify
import { Auth } from 'aws-amplify';

// Network
import axios from "axios";

export default function useLazyAxios() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [value, setValue] = useState();
    const [token, setToken] = useState();

    useEffect(() => {
        const getJwtToken = async () => {
            // get session
            const session = await Auth.currentSession();    
            // set token
            setToken(session.idToken.jwtToken);
        }
        try {
            getJwtToken();
        } catch (_) {
            // console.log("Failed to retrieve session token", err);
        }
    }, []);

    const callbackMemoized = useCallback(() => (request) => {
        setLoading(true);
        setError(undefined);
        setValue(undefined);
        axios({
            ...request,
            headers: {'Authorization': token},
        }).then((response) => setValue(response.data))
            .catch((err) => { 
                if (err.response && err.response.data) {
                    const { type = "Error", cause, message } = err.response.data;
                    setError(`${type}: ${cause || message || "Unknown failure."}`);
                } else {
                    setError("Error: Network fetch operation failed.");
                    // console.log(err.toJSON());
                }
            })
            .finally(() => setLoading(false));
    }, [token]);

    return { loading, error, value, fetch: callbackMemoized };
}
