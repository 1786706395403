/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Manzia
import RadioButtons from "layouts/settings/components/RadioButtons";
import { OnboardRole } from "appSettings";

function getRoleLabel(role) {
  switch (role) {
      case OnboardRole.ACCOUNT_OWNER:
          return "Owner";
      case OnboardRole.TEAM_MEMBER:
          return "Member";
      case OnboardRole.DEVELOPER:
          return "Developer";
      default:
          return "";
  }
}

function RoleCell({ user, onChange }) {
  // const { roleId = OnboardRole.ACCOUNT_OWNER } = user;
  const [roleId, setRoleId] = useState(user.roleId);

  const handleChange = (option) => {
    setRoleId(option.userRole);
    onChange({ ...user, roleId: option.userRole });
  }

  return (
      <MDBox display="flex" justifyContent="center">
        <RadioButtons
          row 
          name="userRole"
          value={roleId}
          handleChange={handleChange}
          disabled
          radioList={roleId === OnboardRole.ACCOUNT_OWNER ? [
            { id: OnboardRole.ACCOUNT_OWNER, value: OnboardRole.ACCOUNT_OWNER, label: getRoleLabel(OnboardRole.ACCOUNT_OWNER)}
          ]: [
            { id: OnboardRole.TEAM_MEMBER, value: OnboardRole.TEAM_MEMBER, label: getRoleLabel(OnboardRole.TEAM_MEMBER)},
            { id: OnboardRole.DEVELOPER, value: OnboardRole.DEVELOPER, label: getRoleLabel(OnboardRole.DEVELOPER)},
          ]}
        />
      </MDBox>
  );
}


// Typechecking props for the StatusCell
RoleCell.propTypes = {
  onChange: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    roleId: PropTypes.string,
  }).isRequired
};

export default RoleCell;
