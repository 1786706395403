const sidenavItems = [
    { icon: "person", label: "profile", href: "profile" },
    { icon: "install_desktop", label: "install bot", href: "install-bot" },
    { icon: "gradient", label: "bot appearance", href: "bot-appearance" },
    { icon: "lock", label: "change password", href: "change-password" },
    { icon: "group", label: "manage team", href: "manage-team" },
    // { icon: "adjust", label: "bot settings", href: "bot-settings" },
    // { icon: "security", label: "2FA", href: "2fa" },
    // { icon: "badge", label: "accounts", href: "accounts" },
    // { icon: "campaign", label: "notifications", href: "notifications" },
    // { icon: "settings_applications", label: "sessions", href: "sessions" }
  ];

  export const nestedNavItems = [
    { icon: "manage_accounts", label: "Account", key: "profile",
      nested: [
        { icon: "person", label: "Profile", href: "profile" },
        { icon: "lock", label: "Change Password", href: "change-password" },
      ]
    },
    { icon: "install_desktop", label: "Bot Settings", key: "install-bot",
      nested: [
        { icon: "install_desktop", label: "Install Bot", href: "install-bot" },
        { icon: "gradient", label: "Bot Appearance", href: "bot-appearance" },
      ]
    },
    { icon: "chat_outlined", label: "Conversations", key: "conversations",
      nested: [
        { icon: "install_desktop", label: "Conversations", href: "conversations" },
      ]
    },
    { icon: "group", label: "Team", key: "manage-team",
      nested: [
        { icon: "group", label: "Manage Team", href: "manage-team" },
      ]
    },
  ];

  export default sidenavItems;