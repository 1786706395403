
import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";


function DelayedLoadView({ messages, cycleTime, ...rest }) {
  const [message, setMessage] = useState(messages[0]);

  useEffect(() => {
    let currentMessageIndex = 0;

    const interval = setInterval(() => {
      currentMessageIndex = (currentMessageIndex + 1) % messages.length;
      setMessage(messages[currentMessageIndex]);
    }, Math.round(cycleTime / messages.length));

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
      <Grid container justifyContent="center" alignItems="center" my={2} mx={1}>
          <Grid item xs={12} lg={12}>
              <CircularProgress {...rest} />
              <MDTypography variant="h6" fontWeight="bold" color="success">
                {message?.title || ""}
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {message?.label || ""}
              </MDTypography>
          </Grid>
      </Grid>
  );
}

DelayedLoadView.defaultProps = {
    cycleTime: 10000
  };

DelayedLoadView.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string
    })).isRequired,
    cycleTime: PropTypes.number,
  };

export default DelayedLoadView;