/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import form from "layouts/authentication/schema/form";
import { FULL_NAME_REGEX, AWS_PASSWORD_REGEX, IS_VALID_CODE } from "helpers";

const {
  login: { 
    loginEmail,
    loginPassword
  },
  signup: { 
    fullName,
    signupEmail,
    signupPassword,
    confirmPassword
  },
  resetPassword,
  changePassword
} = form;

const validations = {
  login: Yup.object().shape({
    [loginEmail.name]: Yup.string().required(loginEmail.errorMsg).email(loginEmail.invalidMsg),
    [loginPassword.name]: Yup.string().required(loginPassword.errorMsg).matches(AWS_PASSWORD_REGEX, loginPassword.invalidMsg)
  }),
  signup: Yup.object().shape({
    [fullName.name]: Yup.string().matches(FULL_NAME_REGEX, fullName.invalidMsg).required(fullName.errorMsg),
    [signupEmail.name]: Yup.string().required(signupEmail.errorMsg).email(signupEmail.invalidMsg),
    [signupPassword.name]: Yup.string().required(signupPassword.errorMsg).matches(AWS_PASSWORD_REGEX, signupPassword.invalidMsg),
    [confirmPassword.name]: Yup.string()
      .required(confirmPassword.errorMsg)
      .matches(AWS_PASSWORD_REGEX, confirmPassword.invalidMsg)
      .oneOf([Yup.ref("signupPassword"), null], "Passwords do not match."),
  }),
  resetPassword: Yup.object().shape({
    [resetPassword.resetCode.name]: Yup.string().matches(IS_VALID_CODE, resetPassword.resetCode.invalidMsg).required(resetPassword.resetCode.errorMsg),
    [resetPassword.newPassword.name]: Yup.string().required(resetPassword.newPassword.errorMsg).matches(AWS_PASSWORD_REGEX, resetPassword.newPassword.invalidMsg),
    [resetPassword.confirmPassword.name]: Yup.string()
      .required(resetPassword.confirmPassword.errorMsg)
      .matches(AWS_PASSWORD_REGEX, resetPassword.confirmPassword.invalidMsg)
      .oneOf([Yup.ref("newPassword"), null], "Passwords do not match."),
  }),
  changePassword: Yup.object().shape({
    [changePassword.newPassword.name]: Yup.string().required(changePassword.newPassword.errorMsg).matches(AWS_PASSWORD_REGEX, changePassword.newPassword.invalidMsg),
    [changePassword.confirmPassword.name]: Yup.string()
      .required(changePassword.confirmPassword.errorMsg)
      .matches(AWS_PASSWORD_REGEX, changePassword.confirmPassword.invalidMsg)
      .oneOf([Yup.ref("newPassword"), null], "Passwords do not match."),
  }),
}

export default validations;
