/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";

import { useParams } from 'react-router-dom';

// @mui material components
import Grid from "@mui/material/Grid";

// Auth
import { AuthUserContext } from "App";

// Material
import MDBox from "components/MDBox";

// Settings page components
import BasicInfo from "layouts/settings/components/BasicInfo";
import ChangePassword from "layouts/settings/components/ChangePassword";
import Authentication from "layouts/settings/components/Authentication";
import ManageTeam from "layouts/settings/components/ManageTeam";
import Notifications from "layouts/settings/components/Notifications";
import Sessions from "layouts/settings/components/Sessions";
import DeleteAccount from "layouts/settings/components/DeleteAccount";
import InstallBot from "layouts/settings/components/InstallBot";
import BotAppearance from "layouts/settings/components/BotAppearance";
import BotSettings from "layouts/settings/components/BotSettings";
import ConversationSettings from "layouts/settings/components/EmailConversation";

// Nav Items
import { OnboardRole } from "appSettings";


function SettingItem() {
  // { icon: "install_desktop", label: "install bot", href: "install-bot" },
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;

  const { settingItem } = useParams();

  switch (settingItem) {
    case 'install-bot':
      return (
          <InstallBot />
      );
    case 'bot-appearance':
      return (
        <Grid item xs={12}>
          <BotAppearance />
        </Grid>
      );
    case 'bot-settings':
      return (
        <Grid item xs={12}>
          <BotSettings />
        </Grid>
      );
    case 'conversations':
      return (
        <Grid item xs={12}>
          <ConversationSettings />
        </Grid>
      );
    case 'change-password':
      return (
        <Grid item xs={12}>
          <ChangePassword />
        </Grid>
      );
    case 'manage-team':
      return (
        <Grid item xs={12}>
          <ManageTeam />
        </Grid>
      );
    case '2fa':
      return (
        <Grid item xs={12}>
          <Authentication />
        </Grid>
      );
    case 'notifications':
      return (
        <Grid item xs={12}>
          <Notifications />
        </Grid>
      );
    case 'sessions':
      return (
        <Grid item xs={12}>
          <Sessions />
        </Grid>
      );
    default:
      return (
        <>
            <BasicInfo />
          {(authUser && authUser["custom:roleId"] === OnboardRole.ACCOUNT_OWNER) && 
            <MDBox mt={3}>
              <DeleteAccount userId={USERID} />
            </MDBox>
          }
        </>
      );
  }
}

export default SettingItem;
