/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";

import PropTypes from "prop-types";

// MUI
import Divider from "@mui/material/Divider";

// Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// Manzia
import InfoList from "layouts/settings/components/InfoList";
import { gtmCreateAccount, 
    gtmWebContainer, 
    gtmCreateContainer,
    gtmTagPublish 
} from "layouts/settings/components/InfoList/infodata";
import EmailDeveloper from "layouts/settings/components/EmailDeveloper";
import BotCode from "layouts/settings/components/BotCode";

function InstallGTM({ action }) {
    const authUser = useContext(AuthUserContext);
    const USERID = authUser?.sub;
    const { account: { webDomain } } = useLocalChatbotUser({ userId: USERID });

    return (
        <>
            <InfoList infoData={action === 'createAccount' ? gtmCreateAccount : gtmCreateContainer} />
            <Divider />
            <EmailDeveloper />
            <Divider />
            <InfoList infoData={gtmWebContainer} />
            <BotCode webDomain={webDomain} />
            <Divider />
            <InfoList infoData={gtmTagPublish} />
        </>
    );
}

InstallGTM.defaultProps = {
    action: "createAccount"
}

InstallGTM.propTypes = {
    action: PropTypes.oneOf([ "createAccount", "createContainer" ])
}

export default InstallGTM;