/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// Components
import App from "App";
// import Home from "layouts/authentication/sign-in/illustration";

// import AuthApp from "AuthApp";

// Apollo Client
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, ApolloLink, from } from '@apollo/client';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { onError } from "@apollo/client/link/error";
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context'

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";

// Amplify
import { Amplify, Auth } from 'aws-amplify';

// App Settings
import { APP_NAME, APP_VERSION, ENV } from "appSettings";

import awsconfig from 'amplifyconfiguration.json';

// Configure amplify
Amplify.configure(awsconfig);

// replace console.* for disable log on production
/* eslint-disable no-console */
// if (process.env.NODE_ENV === 'production') {
//   console.log = () => {}
//   console.error = () => {}
//   console.debug = () => {}
// }
/* eslint-enable no-console */

// Apollo
const authLink = new ApolloLink((operation, forward) => {
  const { token } = operation.getContext();
  operation.setContext(({ headers }) => ({ headers: {
    // "x-api-key": awsconfig.aws_appsync_apiKey, // however you get your token
    Authorization: token || awsconfig.aws_appsync_apiKey,
    ...headers
  }}));
  return forward(operation);
});

const withToken = setContext(async () => {
  const token = (await Auth.currentSession()).idToken.jwtToken
  return { token }
});

// Log any GraphQL errors or network error that occurred
/* eslint-disable consistent-return */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
     `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      // console.log(
      //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      // )
    );
  if (networkError) return `[Network error]: ${networkError}`;
  // if (networkError) console.log(`[Network error]: ${networkError}`);
});
/* eslint-enable consistent-return */

const combinedLink = from([
  withToken,
  errorLink,
  new RetryLink(),
  authLink,
  new HttpLink({ uri: awsconfig.aws_appsync_graphqlEndpoint })
]);

const mergeFunction = (existing, incoming, keyFields = ["id"]) => {
  // console.log("incoming: ", incoming);
  const { __typename, nextToken, items = [] } = incoming;
  const merged = existing ? { ...existing } : { items: [], __typename, nextToken };
  merged.items = [ ...merged.items, ...items ];
  merged.nextToken = nextToken;
  const temp = {};
  merged.items.forEach(item => {
    const itemKey = keyFields.map(key => item[key]).join("_");
    temp[itemKey] = item;
  });
  merged.items = Object.values(temp);
  return merged;
}

// App Container
function AppContainer() {
  const [client, setClient] = useState(null);
  // const [persistor, setPersistor] = useState(null);

  useEffect(() => {
      async function init() {
        const cache = new InMemoryCache({
          typePolicies: {
            ManziaProductPages: {
              keyFields: ["pageURL"],
            },
            ManziaEmailLead: {
              keyFields: ["sessionId", "emailAddress"],
            },
            ManziaStripeCheckout: {
              keyFields: ["accountId"],
            },
            ManziaTeamInvite: {
              keyFields: ["emailAddress"],
            },
            ManziaIntentExample: {
              keyFields: ["botId", "userProductIntentId"]
            },
            ManziaUtterance: {
              keyFields: ["sessionId", "timestamp"],
            },
            ManziaConversation: {
              keyFields: ["sessionId"],
            },
            ManziaUserRole: {
              keyFields: ["userId"],
            },
            ManziaOnboard: {
              keyFields: ["emailAddress"],
            },
            ManziaBotSetting: {
              keyFields: ["chatbotId"],
            },
            ManziaTestConversation: {
              fields: {
                testChatMessages: {
                  merge(_, incoming) {
                    return { ...incoming };
                  },
                },
              }
            },
            ManziaAccount: {
              fields: {
                accountUsers: {
                  merge(existing, incoming) {
                    return mergeFunction(existing, incoming);
                  },
                },
              }
            },
            ManziaChatbot: {
              fields: {
                chatbotUserProducts: {
                  merge(existing, incoming) {
                    return mergeFunction(existing, incoming);
                  },
                },
              },
              chatbotDeployments: {
                merge(existing, incoming) {
                  return mergeFunction(existing, incoming);
                },
              },
              chatbotProductTrainings: {
                merge(existing, incoming) {
                  return mergeFunction(existing, incoming);
                },
              },
            },
            Query: {
              fields: {
                listManziaUserProducts: {
                  // The keyArgs list and merge function are the same as above.
                  keyArgs: [],
                  merge(existing, incoming) {
                    return mergeFunction(existing, incoming);
                  },
                },
                manziaTestConversationsByTestedByUserIdAndUserProductId: {
                  keyArgs: (args) => {
                    if (args.userProductId) return args.userProductId.eq;
                    return args.testedByUserId;
                  },
                  merge(_, incoming) {
                    return { ...incoming };
                  },
                },
                getManziaTestConversation: {
                  keyArgs: ['id'],
                  merge(_, incoming) {
                    return { ...incoming };
                  },
                },
                manziaEmailLeadsByAccount: {
                  keyArgs: ["accountId"],
                  merge(existing, incoming) {
                    return mergeFunction(existing, incoming, ["sessionId", "emailAddress"]);
                  },
                }
                // manziaUserProductsByTrainedChatBotId: {
                //   // The keyArgs list and merge function are the same as above.
                //   keyArgs: [],
                //   merge(existing, incoming, { args: { limit = 0 }}) {
                //     const { __typename, nextToken } = incoming;
                //     const merged = existing ? { ...existing } : { items: [], __typename, nextToken };
                //     merged.items = [ ...merged.items, ...incoming.items ];
                //     merged.nextToken = nextToken;
                //     const temp = {};
                //     merged.items.forEach(item => {
                //       temp[item.id] = item;
                //     });
                //     merged.items = Object.values(temp);
                //     return merged;
                //   },
                // },
              },
            },
          },
        });

        if (ENV === 'dev') {
          const newPersistor = new CachePersistor({
            cache,
            storage: new LocalStorageWrapper(window.localStorage),
            debug: process.env.NODE_ENV !== 'production',
            trigger: 'write',
            maxSize: false
          });
  
          await newPersistor.restore();
        }
        
        // setPersistor(newPersistor);
        setClient(
          new ApolloClient({
            cache,
            link: combinedLink,
            name: APP_NAME,
            version: APP_VERSION,
            defaultOptions: {
              watchQuery: {
                nextFetchPolicy(currentFetchPolicy) {
                  // currentFetchPolicy === 'network-only' || currentFetchPolicy === 'cache-and-network'
                  if (currentFetchPolicy === 'network-only') {
                    // Demote the network policies (except "no-cache") to "cache-first"
                    // after the first request.
                    return 'cache-first';
                  }
                  // Leave all other fetch policies unchanged.
                  return currentFetchPolicy;
                },
              },
            },
          }),
        );
      }

      init().catch("appclient error");
  }, []);

  // const clearCache = useCallback(() => {
  //   if (!persistor) {
  //     return;
  //   }
  //   persistor.purge();
  // }, [persistor]);

  if (!client) {
    return <h2>Initializing Manzia Dashboard...</h2>;
  }

  return (
    <ApolloProvider client={client}>
      <App />
      {/* <Routes>
        <Route path="/" element={<App />} />
      </Routes> */}
    </ApolloProvider>
  )
}

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <AppContainer />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
