/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext, useCallback } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// GraphQL
import { gql, useQuery, useMutation } from '@apollo/client';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';

// @mui material components
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
// import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import InputAdornment from '@mui/material/InputAdornment';
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Networking
// import useLazyAxios from "customhooks/useLazyAxios";
import useLexV2Client from "customhooks/useLexV2Client";

// Manzia
import Header from "layouts/test/products/components/Header";
import FormField from "layouts/test/components/FormField";
import EditTypography from "layouts/test/products/components/EditTypography";
import SimpleEditTypography from "layouts/test/products/components/SimpleEditTypography";
import { stringToColor, sortByTimestamp } from "helpers";
// import { withSessionState } from "layouts/test/aws/lexResponses";
import SuggestButton from "layouts/test/products/components/Suggestions";

// Auth
import { AuthUserContext } from "App";

// State Views
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import NoDataView from "layouts/common/NoDataView";

// Custom Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";
// import useRenderCount from "customhooks/useRenderCount";

// UUID
import { v4 as uuidv4 } from 'uuid';

// Validations
import { string } from 'yup';

// Lex, Session
// import useSessionId from "customhooks/useSessionId";
// import { elicitIntentResponse } from "layouts/test/aws/lexResponses";
import { withSessionState } from "layouts/test/aws/lexResponses";

// Utilities
import moment from "moment";

// Constants
import { BOT_SESSION_KEY, lexV2Commands, CONVERSATION_TITLE } from "appSettings";

// const USERID = "20966bf1-2054-49ca-9328-7f43b8f8c65b";

const sendSchema = string().min(2, 'must be at least 2 characters long');

function stringAvatar(name) {
  const nameArr = name.split(' ');

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: nameArr.length < 2 ? `${nameArr[0][0]}${nameArr[0][nameArr.length - 1]}` : `${nameArr[0][0]}${nameArr[1][0]}`,
  };
}

function Messages({ onProductSelected, initialConversation  }) {
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;
  // console.log("Initial conversation: ", initialConversation);

  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { chatbot: { id: trainedChatBotId, 
    chatbotName, chatbotAliasId, lexChatBotId }, loginUser,
    account: { webDomain } } = useLocalChatbotUser({ userId: USERID });
  const [conversation, setConversation] = useState({ ...initialConversation});
  const [ messages, setMessages ] = useState([]);
  const [ sendError, setSendError ] = useState("");
  const [chatMessage, setChatMessage] = useState("");
  const [conversationExample, setConversationExample] = useState(false);
  
  // Fetch conversation
  const { loading, error, data } = useQuery(gql`${queries.getManziaTestConversation}`, {
    variables: { id: initialConversation.id }, fetchPolicy: 'cache-first',
  });
  
  // Update Conversation
  const [ updateConversation, 
    { loading: conversationLoading, error: conversationError }] = useMutation(gql`${mutations.updateManziaTestConversation}`, {
      refetchQueries: [ 
        'ManziaTestConversationsByTestedByUserIdAndUserProductId',
        'GetManziaTestConversation' 
      ]
    });
  
    // Update Chat message
  const [ updateChatMessage, 
    { loading: updateChatLoading, error: updateChatError }] = useMutation(gql`${mutations.updateManziaTestChatMessage}`);
  
  // Create chat message
  const [ createChatMessage, 
    { loading: createChatLoading, error: createChatError }] = useMutation(gql`${mutations.createManziaTestChatMessage}`, {
      refetchQueries: [
        {query: gql`${queries.getManziaTestConversation}`, variables: { id: conversation.id } }
      ]
    });
  
  // Handle Lex Bot session
  const sessionId = sessionStorage.getItem(BOT_SESSION_KEY);
  // const { loading: sessionLoading, sessionId } = useSessionId(localSessionId);

  const { loading: gptLoading, error: gptError, value: gptResponse, 
    runCommand: gptFetch } = useLexV2Client(lexV2Commands.recognizeText, [sessionId]);
  
  
  useEffect(() => {
    if (data?.getManziaTestConversation) {
      setConversation(data.getManziaTestConversation);
      const { testChatMessages: { items = [] } } = data.getManziaTestConversation;
      setMessages([ ...items].sort(sortByTimestamp));
    }
  }, [data]);

  // Set the GPT response
  /**
   * Sample messages response:
   * "messages": [
        {
        "content": "We love it when people come in to browse. Let me highlight a couple of things.",
        "contentType": "PlainText"
        },
        { "content": "Do you want to learn more?", "contentType": "PlainText" },
        {
        "contentType": "ImageResponseCard",
        "imageResponseCard": {
          "title": "Select an option:",
          "buttons": [
            {"text": "Yes", "value": "Yes"},
            {"text": "No", "value": "No" },
            {"text": "Not Sure", "value": "Not Sure"}
          ]
        }
        }
      ],
   */
  useEffect(() => {
    if (gptResponse) {
      // console.log("GPT Response: ", gptResponse);
      const { messages: messageArr = [], interpretations = [] } = gptResponse;
      const content = messageArr
                      .filter(msg => msg.contentType === 'PlainText')
                      .map(item => item.content)
                      .join('\r\n');
      let intentName = "";
      if (interpretations.length > 0) {
        const [intentObj] = interpretations;
        intentName = intentObj.intent?.name ?? "";
      }

      // Update local cache
      const now = moment().toISOString();
      const input = { 
        id: uuidv4(),
        testConversationId: conversation.id,
        timestamp: now,
        message: content,
        sender: "manziauser",
        lastModifiedAt: now
      };
      if (intentName.length > 0) {
        input.intentName = intentName;
      }
      createChatMessage({ variables: { input } });
    }
  }, [gptResponse]);

  // Update conversation as example
  useEffect(() => {
    if (!conversationExample) return;

    // Update conversation in cache and backend
    const now = moment();
    updateConversation({
      variables: { 
        input: {
          id: conversation.id,
          manziaIntentExampleConversationsUserProductIntentId: conversation.userProductId,
          manziaIntentExampleConversationsBotId: trainedChatBotId,
          lastModifiedAt: now.toISOString()
        }
      }
    });

  }, [conversationExample]);

  const handleChatMessage = (e) => {
    // console.log(e.target.value);
    setChatMessage(e.target.value);
    setSendError("");
  }

  const handleConversationExample = () => setConversationExample(prevState => !prevState);  
  
  function sendMessage() {

    const now = moment().toISOString();
    const chatId = uuidv4();

    // Add message to state
    const input = {
      id: chatId,  
      testConversationId: conversation.id,
      timestamp: now,
      message: chatMessage,
      sender: 'visitor',
      lastModifiedAt: now
    };

    setMessages((prevState) => (
      [ ...prevState, { ...input } ]
    ));

    // Create message and add to conversation.
    createChatMessage({ variables: { input } });

  };

  const onEditConversation = (newTitle) => {
    // Update state
    setConversation((prevState) => (
      { ...prevState, title: newTitle }
    ));

    // Update conversation
    const now = moment();
    updateConversation({
      variables: { 
        input: {
          id: conversation.id,
          title: newTitle,
          lastModifiedAt: now.toISOString()
        }
      }
    });
  }

  const getNextResponse = () => {
    // Validation
    try {
      sendSchema.validateSync(chatMessage);
    } catch (err) {
      setSendError(err.errors[0]);
      return;
    }

    // Update states
    sendMessage();

    const gptParams = { 
      botId: lexChatBotId, // required
      botAliasId: chatbotAliasId, // required
      localeId: "en_US", // required
      sessionId, // required
      text: chatMessage, // text to recognize
      ...withSessionState({
        userId: USERID,
        sender: "manziauser",
        testConversationId: conversation.id,
        userProductId: conversation.userProductId,
        webDomain
      })
    };

    // console.log("GPT params: ", gptParams);
    
    gptFetch()(gptParams);

    // Set conversation title
    if (conversation.title === CONVERSATION_TITLE) {
      onEditConversation(chatMessage.substring(0, 20));
    }
  
    // Reset
    setChatMessage("");
  };

  const onProdSelected = useCallback((selectedProduct) => {
    onProductSelected(selectedProduct);
    // Update conversation locally
    setConversation((prevState) => (
      { ...prevState, userProductId: selectedProduct.id }
    ));
    // Update conversation in cache and backend
    const now = moment();
    updateConversation({
      variables: { 
        input: {
          id: conversation.id,
          userProductId: selectedProduct.id,
          lastModifiedAt: now.toISOString()
        }
      }
    });
  }, [initialConversation]);


  const onEditComplete = (inputMessage) => (newMessageText) => {
      const editedMessage = { ...inputMessage, message: newMessageText };
      setMessages((prevState) => (
        [ ...prevState.filter(item => item.id !== editedMessage.id), editedMessage ]
      ));

      // Update message
      const now = moment();
      updateChatMessage({
        variables: { 
          input: {
            id: editedMessage.id,
            message: editedMessage.message,
            lastModifiedAt: now.toISOString()
          }
        }
      });
  }
  
  const getError = () =>  ({ 
      noError: !(error || conversationError || updateChatError || createChatError), 
      error: (error || conversationError || updateChatError || createChatError)
  });

  const handleSuggestion = (utterance) => setChatMessage(utterance);

  function renderMessages() {
    
    return messages.map((item) => {
      const { sender, message, id } = item;
      const userName = sender === 'visitor' ? loginUser.fullName : chatbotName;
      
      return (
        <MDBox key={id}>
          <MDBox
            display="flex"
            justifyContent="flex-start"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDAvatar {...stringAvatar(userName)} alt={userName} variant="rounded" size="sm" />
            <MDBox ml={2}>
              <MDTypography variant="button" color="text" fontWeight="medium">
                {userName}
              </MDTypography>
            </MDBox>
          </MDBox>
          <EditTypography 
            inputText={message}
            fieldProps={{
              multiline: true,
              maxRows: 6
            }}
            typoProps={{
              variant: "button",
              color: "text",
              fontWeight: "regular"
            }}
            editable={sender !== "visitor"}
            onComplete={onEditComplete({ ...item })}
          />
          <Divider />
        </MDBox>
      );
    });
  }

  const { noError, error: errorMessage } = getError();

  // const renderCount = useRenderCount();
  // console.log("Messages render count:", renderCount);
  // console.log("Conversation: ", conversation);

  return (
    <>
    <Card id="accounts" sx={{ marginBottom: 2 }}>
      <MDBox mb={2}>
      {(loading || conversationLoading || updateChatLoading || createChatLoading) && <LoadView />}
      {!noError && <ErrorView error={{ message: `${errorMessage}` }} />}
      <MDBox px={3} pt={3} mb={2} sx={{ textAlign: 'center'}}>
        <MDTypography variant="h5" color="dark" fontWeight="bold">
          {`Engage with and customize responses from ${chatbotName} below.`}
        </MDTypography>
      </MDBox>
       
       <Header 
          trainedChatBotId={trainedChatBotId} 
          onProductSelected={onProdSelected} 
        />
      </MDBox>
    </Card>
    <Card>
      <MDBox p={3} lineHeight={1}>
        <MDBox
            display="flex"
            justifyContent={{ md: "flex-end" }}
            alignItems="center"
            lineHeight={1}
          >
            <MDTypography variant="button" color="text" fontWeight="regular">
              {`${chatbotName} should use this conversation when generating responses.`}
            </MDTypography>
            <MDBox ml={1}>
              <Switch 
                checked={conversationExample} 
                onChange={handleConversationExample}
                color="primary" 
              />
            </MDBox>
        </MDBox>
        <MDBox mb={1}>
          <SimpleEditTypography 
            inputText={conversation.title}
            fieldProps={{
              multiline: false,
            }}
            typoProps={{
              variant: "h6",
              color: "text",
              fontWeight: "medium"
            }}
            onComplete={onEditConversation}
          />
          {/* <MDTypography variant="h5">Conversation</MDTypography> */}
        </MDBox>
        
        </MDBox>
      {messages.length > 0 ? 
        (
          <>
            <MDBox pt={2} pb={3} px={3}>
              {renderMessages()}
            </MDBox>
            <MDBox pt={2} pb={3} px={3}>
              {gptLoading && <LoadView loadingMessage="Generating response..." />}
              {gptError && <ErrorView error={{ message: `${gptError}`}} />}
            </MDBox>
          </> 
        ) : 
        (
          <NoDataView info={{ 
            title:  conversation.isNewConversation ? "Start conversation" : "No messages", 
            message: `Conversation has no messages. Enter a message and click send below.`
          }}
          />
        )
      }
      <MDBox my={2} px={3}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={10}>
          <MDBox>
            <FormField
              name="sendMessage"
              label="Send Message"
              variant="outlined"
              value={chatMessage}
              onChange={handleChatMessage}
              multiline
              maxRows={6}
              error={sendError !== ""}
              InputProps={{
                endAdornment: 
                  <InputAdornment position="end">
                      <IconButton 
                        size="small" 
                        aria-label="send" 
                        color="info" 
                        onClick={getNextResponse}
                        disabled={sendError !== ""}
                      >
                        <Icon fontSize="small">send</Icon>
                      </IconButton>
                  </InputAdornment>
              }}
            />
            {sendError !== "" && (
              <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {sendError}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={2}>
            <SuggestButton
              onSelected={handleSuggestion}
            >
              Suggestions
            </SuggestButton>
        </Grid>
      </Grid>
      </MDBox>
    </Card>
    </>
  );
}

// Messages.defaultProps = {
//   initialConversation: null
// };

Messages.propTypes = {
  onProductSelected: PropTypes.func.isRequired,
  initialConversation: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired
};

export default Messages;
