/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import form from "layouts/settings/schemas/form";
import emailFilters from "layouts/settings/components/EmailConversation/emailFilters";

const {
  formField: {
    toolbar,
    messagelistcontainer,
    messagebubble,
    cardtitle,
    cardactions
  },
  changePassword,
  botSettings,
  conversations
} = form;

const initialValues = {
  [toolbar.name]: {
    [toolbar.themedark.name]: {
      [toolbar.themedark.backgroundColor.name]: "#2b2b2b"
    },
    [toolbar.title.name]: {
      [toolbar.title.fontFamily.name]: "Sans-serif",
      [toolbar.title.fontSize.name]: 1.875,
      [toolbar.title.color.name]: "#ffffff"
    }
  },
  [messagelistcontainer.name]: {
    [messagelistcontainer.backgroundColor.name]: "#dcdbdc"
  },
  [messagebubble.name]: {
    [messagebubble.messagebot.name]: {
      [messagebubble.messagebot.backgroundColor.name]: "#eeedeb"
    },
    [messagebubble.messagehuman.name]: {
      [messagebubble.messagehuman.backgroundColor.name]: "#afcffa"
    },
    [messagebubble.messagetext.name]: {
      [messagebubble.messagetext.color.name]: "#000000",
      [messagebubble.messagetext.fontSize.name]: 1
    }
  },
  [cardtitle.name]: {
    [cardtitle.cardtitleprimary.name]: {
      [cardtitle.cardtitleprimary.backgroundColor.name]: "#eeedeb"
    }
  },
  [cardactions.name]: {
    [cardactions.btn.name]: {
      [cardactions.btn.backgroundColor.name]: "#afcffa"
    }
  },
};

export const changePasswordValues = {
  [changePassword.currentPassword.name]: "",  
  [changePassword.newPassword.name]: "",
  [changePassword.confirmPassword.name]: "",
};

export const botSettingValues = {
  [botSettings.displayFrequency.name]: 1,  
}

export const conversationsValues = {
  [conversations.emailFilters.name]: emailFilters[0].value, 
  [conversations.conversationEmails.name]: [""], 
}

export default initialValues;
