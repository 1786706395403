/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useMemo, useRef } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, 
  useNavigate } from "react-router-dom";

// Apollo client
// import { useApolloClient } from '@apollo/client';

// Amplify
import { Hub } from 'aws-amplify';

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
// import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";
import navroutes from "onboardroutes";
import approutes from "approutes";

// Components
// import ProductServices from "layouts/train/product-page";
import Home from "layouts/authentication/sign-in/illustration";
import RouteLayout from "layouts/common/RouteLayout";
import AppRoutes from "layouts/common/AppRoutes";
// import NotAuthorized from "layouts/common/NotAuthorized";
import NoDataView from "layouts/common/NoDataView";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/Manzia-logo-nobg.png";
// import brandDark from "assets/images/Manzia-logo-nobg.png";

// Manzia
import useSessionStorage from "customhooks/useSessionStorage";
import { ManziaAuthKeys, UserFlows, OnboardRole, 
  ManziaAuthState } from "appSettings";
import useAuth from "customhooks/useAuth";
import LoadView from "layouts/common/LoadView";

// authUser context
export const AuthUserContext = React.createContext();


function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [sideNavRoutes, setSideNavRoutes] = useState(navroutes);

  // Session storage to persist through refreshes
  const [userAttributes, setUserAttributes] = useSessionStorage(ManziaAuthKeys.signupUser, {});
  const [authUser, setAuthUser] = useState(userAttributes);
  
  // Offline
  const [online, setOnline] = useState(true);

  // Sign Out
  const { value: signoutData, command: authSignout } = useAuth(ManziaAuthState.signOut.key);
  const navigate = useNavigate();

  const { loading: authLoading, value: authValue, command: authenticatedUser } = useAuth(ManziaAuthState.authenticated.key);

  // Start with least priviledged role
  const userRoleRef = useRef(userAttributes ? userAttributes["custom:roleId"] ?? OnboardRole.DEVELOPER : OnboardRole.DEVELOPER)
  // console.log("Session user attributes: ", userAttributes);
  
  // Navigation
  // const navigate = useNavigate();
 
  // const client = useApolloClient();

  // Routes
  const routeArr = [ ...routes, ...approutes ];
  
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    // Disable the side nav routes during the onboard process
    if (pathname.indexOf("/onboard") > -1) {
      setSideNavRoutes(navroutes);
    } else {
      setSideNavRoutes(routes);
    }
  }, [pathname]);

  useEffect(() => {
    if (!signoutData) return;
    navigate("/", { replace: true });
  }, [signoutData]);

  // Check if we have authenticated session and skip login.
  useEffect(() => {
    authenticatedUser()();
  }, []);

  useEffect(() => {
    if (!authValue) return;
    setAuthUser({ ...authValue.attributes, userFlow: UserFlows.LOGIN_FLOW });
    setUserAttributes({ ...authValue.attributes, userFlow: UserFlows.LOGIN_FLOW });
  }, [authValue]);

  // Clear cache (TESTING ONLY)
  // useEffect(() => {
    
  //   client.clearStore();
  //   console.log("Cleared cache...");
  //   // console.log("Featured cache: %o", client.cache.data)
      
  // }, []);

  useEffect(() => {
    // Listen for signup confirmation success and login
    const loginListener = Hub.listen('auth', ({ payload }) => {
      
      // console.log("Auth payload: ", payload);  
      const { event } = payload;
        switch (event) {
          case 'autoSignIn':
          case 'signIn':
            /**
             * user.attributes: {sub: '10225566-9903-4b6a-aaa9-561d78f72cf2', email_verified: true, name: 'Roy Manzi', email: 'roymanzi@yahoo.com'}
             */
            setAuthUser({ ...payload.data.attributes, userFlow: UserFlows.LOGIN_FLOW });
            setUserAttributes({ ...payload.data.attributes, userFlow: UserFlows.LOGIN_FLOW });
            userRoleRef.current = payload.data.attributes["custom:roleId"] ?? OnboardRole.TEAM_MEMBER;
            break;
          case 'signUp':
            /**
             * {
                "UserConfirmed": false,
                "UserSub": "e04d60a6-45dc-441c-a40b-e25a787d4862"
              }
             */
            if (payload?.data) {
              setUserAttributes({ sub: payload.data.UserSub, userFlow: UserFlows.SIGNUP_FLOW });
              setAuthUser({ sub: payload.data.UserSub, userFlow: UserFlows.SIGNUP_FLOW });
            }
            break;
          case 'signOut':
            // setAuthUser(null);
            // setUserAttributes({});
            
            break;
          case 'tokenRefresh':
            // Reload app to refresh any clients using tokens
            // console.log('token refresh succeeded');
            window.location.reload();
            break;
          case 'tokenRefresh_failure':
            // Force signout
            // console.log('token refresh failed');
            authSignout()();
            break;
          default:
            break;
        }
    });

    return () => loginListener();

  }, []);

  function updateOnlineStatus() {
    setOnline(navigator.onLine);
  }

  useEffect(() => {
    window.addEventListener('online',  updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  }, []);

  /* eslint-disable no-else-return */
  const getRoutes = (allRoutes, role) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse, role);
      }
      if (route.route) {
        if(route.roles.indexOf(role) > -1) {
          // console.log("Developer route: ", route.route);
          return route.nested ? 
          (<Route exact path={route.route} element={route.component} key={route.key}>
            <Route index element={route.indexComponent} />
            <Route path={`:${route.nestedKey}`} element={route.nestedComponent} key={route.nestedKey} />
          </Route>) : 
          (<Route exact path={route.route} element={route.component} key={route.key} />);
        } else if (authUser?.sub) { 
          return <Route exact path={route.route} element={<Navigate to="/unauthorized" />} key={route.key} />;
        } else {
          return <Route exact path={route.route} element={<Navigate to="/" />} key={route.key} />;
        }
      }  
      return null;
  });

  /* eslint-enable no-else-return */

  if (!online) return ( 
    <NoDataView 
      info={{ title: "Are you offline?", message: "No internet connection detected."}}
      action={{ label: "", href: "" }}
    />
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
        
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandWhite : brandWhite}
            brandName="MANZIA AI"
            routes={sideNavRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          
        )}
        
        <AuthUserContext.Provider value={authUser}>
          {(authLoading) && <LoadView />}
        <Routes>
          <Route path="/" element={<AppRoutes />}>
            <Route index element={<Home />} />
            <Route element={<RouteLayout />}>
              {getRoutes(routeArr, userRoleRef.current)}
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
        </AuthUserContext.Provider>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        
        <Sidenav
          color={sidenavColor}
          brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandWhite : brandWhite}
          brandName="MANZIA AI"
          routes={sideNavRoutes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
        
      )}
      <AuthUserContext.Provider value={authUser}>
      {(authLoading) && <LoadView />}
        <Routes>
          <Route path="/" element={<AppRoutes />}>
            <Route index element={<Home />} />
            <Route element={<RouteLayout />}>
              {getRoutes(routeArr, userRoleRef.current)}
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </AuthUserContext.Provider>
      
    </ThemeProvider>
  );
}

export default App;
