import { OnboardRole } from "appSettings";

function transformInput(input) {
    const output = [];
    const inputKeys = Object.keys(input);
    inputKeys.forEach(key => {
        input[key].forEach(email => {
            output.push({
                email,
                roleId: key
            });
        });
    });
    return output;
}

/* eslint-disable arrow-body-style */
function createEmailInvites(emails = "", details) {
    
    const emailArr = emails.split(",").filter(item => item.length > 0);
    const emailRoles = transformInput(details.emailRoles);

    const emailMutations = emailArr.map((email, idx) => {
       return `
            a${idx+1}: createManziaTeamInvite(input: {
                emailAddress: "${email}",
                accountId: "${details.accountId}",
                organizationName: "${details.organizationName}",
                userRole: "${emailRoles[email] ?? OnboardRole.DEVELOPER}",
                senderUserId: "${details.senderUserId}",
                manziaChatbotId: "${details.manziaChatbotId}",
                emailMessageId: "${details.messageId}"
            }) {
                emailAddress
                accountId
                manziaChatbotId
                organizationName
                userRole
                emailMessageId
                senderUserId
                deleteTime
                createdAt
                updatedAt
                __typename
            }
        `;
    })

    return `
        mutation createEmailInvites() {
            ${emailMutations.join(",")}
        }
    `;
}
/* eslint-enable arrow-body-style */ 

export default createEmailInvites;