const utterances = [
    { id: 'none:1', utterance: "None", intentId: "none"},
    { id: 'no_budget:1', utterance: "Can't afford it right now", intentId: "no_budget"},
    { id: 'no_budget:2', utterance: "My budget won't stretch that far", intentId: "no_budget"},
    { id: 'more_research:1', utterance: "I need to do some research", intentId: "more_research"},
    { id: 'more_research:2', utterance: "I need to dig deeper into this.", intentId: "more_research"},
    { id: 'not_a_fit:1', utterance: "It doesn't solve my problem", intentId: "not_a_fit"},
    { id: 'not_a_fit:2', utterance: "do I really need this product?", intentId: "not_a_fit"},
    { id: 'not_interested:1', utterance: "I'm not interested", intentId: "not_interested"},
    { id: 'not_interested:2', utterance: "I don't see the value", intentId: "not_interested"},
    { id: 'not_ready:1', utterance: "I'm not ready to commit", intentId: "not_ready"},
    { id: 'not_ready:2', utterance: "I think I'll hold out for the next release.", intentId: "not_ready"},
    { id: 'not_sure:1', utterance: "Not sure about this", intentId: "not_sure"},
    { id: 'not_sure:2', utterance: "Can you tell me who else is using this?", intentId: "not_sure"},
    { id: 'missing_functionality:1', utterance: "It does not have all the features I need", intentId: "missing_functionality"},
    { id: 'missing_functionality:2', utterance: "what are the key features?", intentId: "missing_functionality"},
    { id: 'new_updates:1', utterance: "So what's new", intentId: "new_updates"},
    { id: 'new_updates:2', utterance: "Subscribe to product updates", intentId: "new_updates"},
    { id: 'whats_the_price:1', utterance: "How much do you charge", intentId: "whats_the_price"},
    { id: 'whats_the_price:2', utterance: "What's the cost?", intentId: "whats_the_price"},
    { id: 'ask_help:1', utterance: "help, what do you do", intentId: "ask_help"},
    { id: 'ask_help:2', utterance: "how do I use this", intentId: "ask_help"},
    { id: 'dont_have_time:1', utterance: "How long will it take to setup?", intentId: "dont_have_time"},
    { id: 'dont_have_time:2', utterance: "We’re too busy right now to try anything new", intentId: "dont_have_time"},
    { id: 'get_discount:1', utterance: "Can you give me a discount", intentId: "get_discount"},
    { id: 'get_discount:2', utterance: "Can I pay less?", intentId: "get_discount"},
    { id: 'not_switching:1', utterance: "We are happy with what we have right now", intentId: "not_switching"},
    { id: 'not_switching:2', utterance: "A switch would mean additional expense and time commitment for us", intentId: "not_switching"},
    { id: 'compare_alternatives:1', utterance: "Is this product the best on the market", intentId: "compare_alternatives"},
    { id: 'compare_alternatives:2', utterance: "Let me look at other alternatives", intentId: "compare_alternatives"},
    { id: 'get_started:1', utterance: "how do I start?", intentId: "get_started"},
    { id: 'get_started:2', utterance: "How can I get started?", intentId: "get_started"},
    { id: 'just_looking:1', utterance: "I'm just looking around", intentId: "just_looking"},
    { id: 'just_looking:2', utterance: "Seeing what's on offer", intentId: "just_looking"},
    { id: 'think_about_it:1', utterance: "Let me think about it", intentId: "think_about_it"},
    { id: 'think_about_it:2', utterance: "Will touch base with you later.", intentId: "think_about_it"},
    { id: 'too_expensive:1', utterance: "you are too expensive", intentId: "too_expensive"},
    { id: 'too_expensive:2', utterance: "You are overpriced", intentId: "too_expensive"},
].sort((a, b) => a.id.localeCompare(b.id));



export default utterances;