
const getInvalidMsg = (fieldName) => `${fieldName} can only contain up to 50 alphanumeric, comma, dash, underscore, hyphen, whitespace characters.`;

const form = {
  formId: "chatbot-styles",
  formField: {
    toolbar: {
      name: "toolbar",
      label: "Toolbar",
      type: "text",
      themedark: {
        name: "themedark",
        label: "Toolbar Dark Theme",
        type: "text",
        backgroundColor: {
          name: "backgroundColor",
          label: "Toolbar Background Color",
          type: "text",
          errorMsg: "Toolbar background color is required.",
          invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
        }
      },
      title: {
        name: "title",
        label: "Toolbar Title",
        type: "text",
        fontFamily: {
          name: "fontFamily",
          label: "Toolbar Fonts",
          type: "text",
          errorMsg: "Toolbar font family is required.",
          invalidMsg: "Invalid font family"
        },
        fontSize: {
          name: "fontSize",
          label: "Toolbar Font Size (em)",
          type: "number",
          adorn: "em",
          errorMsg: "Toolbar font size is required.",
          invalidMsg: "Invalid font size, must be number greater than zero"
        },
        color: {
          name: "color",
          label: "Toolbar Title Color",
          type: "text",
          errorMsg: "Toolbar title color is required.",
          invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
        } 
      }
    },
    messagelistcontainer: {
      name: "messagelistcontainer",
      label: "Message Container",
      type: "text",
      backgroundColor: {
        name: "backgroundColor",
        label: "Message Container Background Color",
        type: "text",
        errorMsg: "Container background color is required.",
        invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
      }
    },
    messagebubble: {
      name: "messagebubble",
      label: "Message Bubble",
      type: "text",
      messagebot: {
        name: "messagebot",
        label: "Bot Message Bubble",
        type: "text",
        backgroundColor: {
          name: "backgroundColor",
          label: "Bot Message Background Color",
          type: "text",
          errorMsg: "Message bubble background color is required.",
          invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
        }
      },
      messagehuman: {
        name: "messagehuman",
        label: "Human Message Bubble",
        type: "text",
        backgroundColor: {
          name: "backgroundColor",
          label: "Human Message Background Color",
          type: "text",
          errorMsg: "Message bubble background color is required.",
          invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
        }
      },
      messagetext: {
        name: "messagetext",
        label: "Message Bubble Text",
        type: "text",
        color: {
          name: "color",
          label: "Message Text Color",
          type: "text",
          errorMsg: "Message text color is required.",
          invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
        },
        fontSize: {
          name: "fontSize",
          label: "Message Text Font Size",
          type: "number",
          adorn: "rem",
          errorMsg: "Message text font size is required.",
          invalidMsg: "Invalid font size, must be number greater than zero"
        }  
      }
    },
    cardtitle: {
      name: "cardtitle",
      label: "Card Title",
      type: "text",
      cardtitleprimary: {
        name: "cardtitleprimary",
        label: "Card Title",
        type: "text",
        backgroundColor: {
          name: "backgroundColor",
          label: "Card Title Background Color",
          type: "text",
          errorMsg: "Card title background color is required.",
          invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
        }
      }
    },
    cardactions: {
      name: "cardactions",
      label: "Card Actions",
      type: "text",
      btn: {
        name: "btn",
        label: "Card Action Btn",
        type: "text",
        backgroundColor: {
          name: "backgroundColor",
          label: "Card Action Btn Background Color",
          type: "text",
          errorMsg: "Background color is required.",
          invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
        }
      }
    }
  },
  changePassword: {
    formId: "change-password-form",
    currentPassword: {
      name: "currentPassword",
      label: "Current password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Requires at least 8 characters including lowercase, uppercase, number and special characters"
    },
    newPassword: {
      name: "newPassword",
      label: "New password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Requires at least 8 characters including lowercase, uppercase, number and special characters"
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Requires at least 8 characters including lowercase, uppercase, number and special characters"
    },
  },
  basicInfo: {
    formId: "basic-info-form",
    fullName: {
      name: "fullName",
      label: "Full name",
      type: "text",
      placeholder: "e.g John Doe",
      errorMsg: "Full name is required.",
      invalidMsg: getInvalidMsg("Full name")
    },
    signupEmail: {
      name: "signupEmail",
      label: "Email",
      type: "text",
      placeholder: "e.g abc@example.com",
      errorMsg: "Email is required.",
      invalidMsg: "Enter a valid email address"
    },
    userRole: {
      name: "userRole",
      label: "Role",
      type: "text",
      errorMsg: "Role is required.",
    }
  },
  botSettings: {
    formId: "bot-settings-form",
    displayFrequency: {
      name: "displayFrequency",
      label: "Display Frequency",
      type: "number",
      adorn: "day(s)",
      placeholder: "e.g 5",
      errorMsg: "Display frequency is required.",
      invalidMsg: "Display frequency must be an integer between 1 and 365"
    }
  },
  conversations: {
    formId: "conversations-form",
    emailFilters: {
      name: "emailFilters",
      label: "Email Filter",
      type: "text",
      errorMsg: "Email filter is required.",
      invalidMsg: "Invalid email filter"
    },
    conversationEmails: {
      name: "conversationEmails",
      label: "Email to send conversations",
      type: "text",
      errorMsg: "Email is required.",
      invalidEmailMsg: "Invalid email",
      invalidMsg: "Enter one (1) and up to ten (10) valid emails"
    },
    name: "conversations",
    label: "Conversations",
    type: "text",
  }
};

export default form;
