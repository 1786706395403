// Utils
import moment from 'moment';

import pako from 'pako';

export const decodeGzip = (encodedString) => {
  let result;
  const gzipedData = atob(encodedString)
  const gzipedDataArray = Uint8Array.from(gzipedData, c => c.charCodeAt(0))
  const ungzipedData = pako.ungzip(gzipedDataArray);
  result = new TextDecoder().decode(ungzipedData);
  try {
    result = JSON.parse(result);
  } catch (_) {
    // console.log("Decode error: ", error);
  }
  return result;
}

// export const mergeObjects = (obj1, obj2) => {
//     const result = {};
//     Object.keys(obj1).forEach((key) => {
//         if (obj2[key]) {
//           result[key] = obj2[key];
//         } else {
//           result[key] = obj1[key];
//         }
//     });

//     return result;
// }

/* eslint-disable */
export function deepMerge(obj1, obj2) {
  const result = {...obj1};

    for (const key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            // If the key leads to an object, recurse.
            if (typeof obj2[key] === 'object' && obj2[key] !== null) {
                // Special handling for arrays
                if (Array.isArray(obj2[key])) {
                    result[key] = mergeArrays(result[key], obj2[key]);
                } else {
                    result[key] = deepMerge(result[key] || {}, obj2[key]);
                }
            } else {
                // If it's not an object, simply overwrite the value.
                result[key] = obj2[key];
            }
        }
    }

    return result;
}

function mergeArrays(arr1 = [], arr2 = []) {
  const maxLength = Math.max(arr1.length, arr2.length);
  const result = [];

  for (let i = 0; i < maxLength; i++) {
      // Merge arrays by index, recursing if both elements are objects
      if (typeof arr1[i] === 'object' && typeof arr2[i] === 'object') {
          result[i] = deepMerge(arr1[i] || {}, arr2[i] || {});
      } else {
          // Use the value from the second array if it exists, otherwise use the first array's value
          result[i] = arr2[i] !== undefined ? arr2[i] : arr1[i];
      }
  }

  return result;
}

/* eslint-enable */

export const isValidUrl = (url) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^(https?:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
  return regex.test(url);
}

export const stringToColor = (string) => {
  if (!string || string.length < 1) return '#000000';
  
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

// eslint-disable-next-line arrow-body-style
// export const sortByTimestamp = (chatMessages = [], asc = true) => {
//   if (chatMessages.length === 0) return [];
//   chatMessages.sort();
// }

export const sortByTimestamp = (a, b) => {
  const aMom = moment(a.timestamp);
  const bMom = moment(b.timestamp);
  if (aMom.isBefore(bMom)) return -1;
  if (aMom.isAfter(bMom)) return 1;
  return 0;
}

/* eslint-disable func-names */
export function sortByField(field, asc = true) {
  return function(a, b) {
    const aMom = moment(a[field]);
    const bMom = moment(b[field]);
    if (aMom.isBefore(bMom)) return asc === true ? -1 : 1;
    if (aMom.isAfter(bMom)) return  asc === true ? 1 : -1;
    return 0;
  }
  
}
/* eslint-enable func-names */

// function that returns true if value is email, false otherwise
export function verifyEmail(value) {
  if (!value || value.length === 0) return false;
  const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
  }


export const uniqueShortId = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(0);

/* eslint-disable */
export const HEX_COLOR_REGEX = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/;

export const BASIC_FIELD_REGEX = /^[a-zA-Z0-9-,. _]{1,50}$/;

export const FULL_NAME_REGEX = /^[a-zA-Z0-9\s-]{1,100}$/;

export const AWS_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

export const IS_VALID_CODE = /^\d{6}$/;

export const DOMAIN_NAME = /^([\da-z\.-]+)\.([a-z\.]{2,6})$/;

export const ALPHA_SPACE_REGEX = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/;

export const illegalChars = /^[a-zA-Z0-9,\.\-'"\?!()\s]+$/;

export const oneToFiveAndIllegalChars = /^\s*(?:[\w,'.\-\?!]+\s+){0,4}[\w,'.\-\?!]+\s*$/;

export const fourAndIllegalChars = /^\s*(?:[\w,'.\-\?!]+\s+){4,}[\w,'.\-\?!]+\s*$/;

export const GTM_CONTAINER_ID = /GTM-[A-Z0-9]{6,8}/;

export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;


/* eslint-enable */

export const getHexColorCode = (colorString) => {
  if (!colorString) return colorString;
  const match = colorString.match(HEX_COLOR_REGEX);
  return match ? match[0] : match;
}

export function getRandomArrayElement(array = []) {
  if (array.length === 0) { return undefined; }
  
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

export function maskEmail(email) {
  
  const atIndex = email.indexOf('@');

  if (atIndex <= 1 || atIndex === email.length - 1) { return email; }

  const localPart = email.substring(0, atIndex);
  const domainPart = email.substring(atIndex);

  const maskedLocalPart = localPart.charAt(0) + '*'.repeat(localPart.length - 2) + localPart.charAt(localPart.length - 1);

  return maskedLocalPart + domainPart;
}

/* eslint-disable */
export const cssToJson = (css) => {
  const json = {};
  if (!css) return json;
  const rules = css.split('\n');

  let selector = '';
  let properties = {};

  for (let i=0; i<rules.length; i += 1) {
    let line = rules[i].trim();

    if (line.endsWith('{')) {
      // Start of a new rule
      selector = line.slice(0, -1).trim();
      properties = {};
    } else if (line.endsWith('}')) {
      // End of the current rule
      json[selector] = properties;
    } else {
      // Property line
      const [property, value] = line.split(':');
      if (property && value) {
        properties[property.trim()] = value.trim();
      }
    }
  }

  return json;
}

export function arraysAreEqual(arr1, arr2) {
  // Check if both arrays are the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort both arrays
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  // Compare the elements of the sorted arrays
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  // If the loop completes without returning false, the arrays are equal
  return true;
}

/* eslint-enable */

export function isEmptyObject(obj) {
  // Check if the object is undefined or null
  if (obj === null || obj === undefined) {
      return true;
  }
  // Check if array
  if (Array.isArray(obj)) return obj.length === 0;

  // Ensure obj is an object and has no keys
  return typeof obj === 'object' && Object.keys(obj).length === 0;
}

export function extractAvatarInitials(productName) {
  // Trim whitespace and split the product name by spaces
  const words = productName.trim().split(/\s+/);
  return words.length === 1 ? words[0].substring(0, 2).toUpperCase() : words[0][0].toUpperCase() + words[1][0].toUpperCase();
}

export function camelCaseToTitleCase(camelCase) {
  // First, add a space before each uppercase character
  let result = camelCase.replace(/([A-Z])/g, ' $1');
  
  // Then, capitalize the first character of the string
  result = result.charAt(0).toUpperCase() + result.slice(1);

  return result;
}

export const formattedCurrency = (amount, locale = 'en-US', currency = 'USD') => {
  const numberFormat = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });

  return numberFormat.format(amount.toFixed(2));
}

export function extractNumber(str) {
  const result = str.match(/-?\d*\.?\d+/);
  return result ? parseFloat(result[0]) : undefined;
}

export function decodeHtmlEntities(str) {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = str;
  return textArea.value;
}

export default deepMerge;