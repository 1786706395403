import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function ExpandAccordion({ title, children }) {
    
  return (
    <MDBox>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <MDTypography variant="h6">{title}</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </MDBox>
  );
}

// typechecking props for UserInfo
ExpandAccordion.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};
  
export default ExpandAccordion;