/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Manzia
import NotificationView from 'layouts/common/Notification';
import { ManziaAuthState } from "appSettings";
import InputField from "layouts/authentication/components/InputField";
import useAuth from "customhooks/useAuth";
import { maskEmail } from "helpers";
import ErrorView from "layouts/common/ErrorView";

// schemas for form and form fields
import validations from "layouts/authentication/schema/validations";
import form from "layouts/authentication/schema/form";
import initialValues from "layouts/authentication/schema/initialValues";

function ConfirmPassword({ authHandler, username }) {
    const { resetPassword: { formId, resetCode, newPassword, 
        confirmPassword } } = form;
    // const navigate = useNavigate();

    // Login
    const { loading: confirmLoading, error: confirmError, 
        value: confirmData, command: authConfirm } = useAuth(ManziaAuthState.confirmResetPassword.key);

    // useEffect(() => {
    //     if (!confirmData) return;
    //     // console.log(`User successfully changed password.`);
        
    //     // authHandler({ state: ManziaAuthState.signIn });

    // }, [confirmData]);

    const handleSubmit = useCallback((values, actions) => {
        
        // Signup
        authConfirm()({
            username,
            code: values[resetCode.name],
            password: values[newPassword.name]
        });

        actions.setSubmitting(false);
        // actions.resetForm();
        
    }, []);

  const getResetErrMessage = () => {
    if (!confirmError) return confirmError;
    const email = maskEmail(username);
    const { name: errorCode } = confirmError;
    switch (errorCode) {
        case 'CodeDeliveryFailureException':
            return `Failed to deliver password reset code to ${email}`;
        case 'UserNotFoundException':
            return "Unknown user account. Try to signup again."
        case 'TooManyFailedAttemptsException':
            return "Too many attempts. Wait a few minutes and try again.";
        case 'CodeMismatchException':
            return "Provided verification code does not match expected value. Try again.";
        case 'ExpiredCodeException':
            return "Provided verification code has expired. Please click resend code.";
        case 'AliasExistsException':
        case 'UsernameExistsException':
            return `Account with provided email: ${email} already exists. Please login.`;
        case 'TooManyRequestsException':
            return "Too many signup attempts. Wait a few minutes and try again.";
        case 'NotAuthorizedException':
            return "Not authorized. Please try signing up again.";
        default:
            return "Encountered an error during signup. Please try again later.";
    }
  }

  return (
    <Formik
        initialValues={initialValues.resetPassword}
        validationSchema={validations.resetPassword}
        onSubmit={handleSubmit}
    >
        {({ isSubmitting, isValid }) => {
            
            const resetError = getResetErrMessage();
            
            return (
            <Form id={formId} autoComplete="off">
                {confirmData && <NotificationView 
                    color="success"
                    message="Password change succeeded."
                />}
                {resetError && <ErrorView error={{ message: `${resetError}`}}/>}
                <MDBox my={2}>
                    <InputField 
                        name={resetCode.name} 
                        type={resetCode.type}
                        label={resetCode.label}
                        fullWidth 
                    />
                </MDBox>
                <MDBox mb={2}>
                    <InputField 
                        name={newPassword.name} 
                        type={newPassword.type}
                        label={newPassword.label}
                        fullWidth 
                    />
                </MDBox>
                <MDBox mb={2}>
                    <InputField 
                        name={confirmPassword.name} 
                        type={confirmPassword.type}
                        label={confirmPassword.label}
                        fullWidth 
                    />
                </MDBox>
                <MDBox 
                    mt={4} 
                    mb={1}
                    display="flex"
                    alignItems="center"
                    >
                    {confirmLoading && <CircularProgress />}
                    {confirmData ? 
                        <MDButton 
                            variant="gradient" 
                            color="info" 
                            size="large"
                            type="submit" 
                            fullWidth
                            disabled
                        >
                            Submit
                        </MDButton> :
                        <MDButton 
                            variant="gradient" 
                            color="info" 
                            size="large"
                            type="submit" 
                            fullWidth
                            disabled={isSubmitting || !isValid}
                        >
                            Submit
                        </MDButton>
                    }
                </MDBox>
                <MDBox mt={3} textAlign="center">
                    <MDTypography
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                        sx={{ cursor: "pointer" }}
                        onClick={
                            () => authHandler({ state: ManziaAuthState.signIn })
                        }
                    >
                        Return to sign in
                    </MDTypography>
                </MDBox>
            </Form>
          )}}
    </Formik>
  );

}

ConfirmPassword.propTypes = {
    authHandler: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired
};

export default ConfirmPassword;
