export const productCategories = [
    { code: "4411", title: "Automobiles", description: "new and used automobiles and light trucks, such as sport utility vehicles, and passenger and cargo vans." },
    { code: "44121", title: "Recreational Vehicles", description: "new and/or used recreational vehicles commonly referred to as RVs, new and/or used boats, new and/or used motorcycles, motor scooters, motorbikes, mopeds, off-road all-terrain vehicles (ATV), personal watercraft, utility trailers"},
    { code: "44133", title: "Automotive Parts and Accessories", description: "new, used, and/or rebuilt automotive parts and accessories"},
    { code: "44134", title: "Tires", description: "new and/or used tires and tubes"},
    { code: "4441", title: "Building Material and Supplies", description: "building materials and supplies"},
    { code: "44423", title: "Outdoor Power Equipment", description: "outdoor power equipment"},
    { code: "44424", title: "Nursery, Garden Center, and Farm Supply", description: "nursery and garden products, such as trees, shrubs, plants, seeds, bulbs, and sod"},
    { code: "4491", title: "Furniture and Home Furnishings", description: "furniture and home furnishings"},
    { code: "44921", title: "Electronics and Appliances", description: "household-type appliances and consumer-type electronic products, such as televisions, computers, electronic tablets, and cameras, new prepackaged or downloadable computer software, prerecorded audio and video media, such as downloadable digital music and video files, CDs and DVDs, Cellular telephone accessories, Stereo and sound system"},
    { code: "45612", title: "Cosmetics, Beauty Supplies, and Perfume", description: "cosmetics, perfumes, toiletries, and personal grooming products"},
    { code: "45811", title: "Clothing and Clothing Accessories", description: "general or specialized lines of new clothing and clothing accessories, such as hats and caps, costume jewelry, gloves, handbags, ties, wigs, toupees, and belts."},
    { code: "45821", title: "Shoes", description: "footwear (except hosiery and specialty sports footwear, such as golf shoes, bowling shoes, and cleated shoes)"},
    { code: "45831", title: "Jewelry", description: "jewelry (except costume jewelry), new sterling and plated silverware, new watches and clocks"},
    { code: "45832", title: "Luggage and Leather Goods", description: "luggage, briefcases, and trunks"},
    { code: "45911", title: "Sporting Goods", description: "sporting goods, such as bicycles and bicycle parts; camping equipment; exercise and fitness equipment; athletic uniforms; specialty sports footwear; and other sporting goods, equipment, and accessories"},
    { code: "45912", title: "Hobby, Toy, and Games", description: "toys, games, and hobby and craft supplies (except needlecraft)"},
    { code: "45913", title: "Sewing, Needlework, and Piece Goods", description: "sewing supplies, fabrics, patterns, yarns, and other needlework accessories"},
    { code: "45914", title: "Musical Instrument and Supplies", description: "musical instruments, sheet music, and related supplies"},
    { code: "45921", title: "Books and News", description: "books, newspapers, magazines, and other periodicals (without publishing)"},
    { code: "45931", title: "Flowers", description: "cut flowers, floral arrangements, and potted plants "},
    { code: "45941", title: "Office Supplies and Stationery", description: "office supplies, stationery, and school supplies, new office equipment, furniture, and supplies"},
    { code: "45942", title: "Gift, Novelty, and Souvenir", description: "gifts, novelty merchandise, souvenirs, greeting cards, seasonal and holiday decorations, and curios"},
    { code: "45992", title: "Artwork", description: "original and limited edition art works "},
    { code: "5121", title: "Motion Picture and Video", description: "production and/or distribution of motion pictures, videos, television programs, or commercials"},
    { code: "5122", title: "Sound Recording", description: "producing and distributing musical recordings, publishing music, or providing sound recording and related services"},
    { code: "5131", title: "Newspaper, Periodical, Book, and Directory", description: "publishing newspapers, magazines, other periodicals, books, directories and mailing lists, and other works, such as calendars, greeting cards, and maps."},
    { code: "51321", title: "Software", description: "producing and distributing computer software, such as designing, providing documentation, assisting in installation, and providing support services"},
    { code: "51621", title: "Media Streaming, Social Networks and Content Providers", description: "providing media streaming distribution services, operating social network sites, operating media broadcasting and cable television networks, and supplying information, such as news reports, articles, pictures, and features"},
    { code: "5171", title: "Wired and Wireless Telecommunications", description: "providing telecommunications services using wired and wireless telecommunications networks"},
    { code: "51821", title: "Computing Infrastructure, Data Processing, Web Hosting", description: "providing computing infrastructure, data processing services, Web hosting services (except software publishing), and related services, including streaming support services (except streaming distribution services)"},
    { code: "5321", title: "Automotive Equipment Rental and Leasing", description: "renting or leasing passenger cars and trucks without drivers and utility trailers"},
    { code: "5322", title: "Consumer Goods Rental", description: "renting personal and household-type goods"},
    { code: "5324", title: "Commercial, Industrial Machinery and Equipment Rental", description: "renting or leasing commercial-type and industrial-type machinery and equipment"},
    { code: "71", title: "Arts, Entertainment, and Recreation", description: "producing, promoting, or participating in live performances, events, or exhibits, preserve and exhibit objects and sites of historical, cultural, or educational interest, provide services that enable patrons to participate in recreational activities or pursue amusement, hobby, and leisure-time interests"},
    { code: "54", title: "Professional, Scientific, and Technical Services", description: "legal advice and representation; accounting, bookkeeping, and payroll services; architectural, engineering, and specialized design services; computer services; consulting services; research services; advertising services; photographic services; translation and interpretation services; veterinary services; and other professional, scientific, and technical services"},
];

const industryCategories = [
  { code: "11", title: "Agriculture, Forestry, Fishing and Hunting", description: "Agriculture, Forestry, Fishing and Hunting"},
  { code: "21", title: "Mining, Quarrying, and Oil and Gas Extraction", description: "Mining, Quarrying, and Oil and Gas Extraction"},
  { code: "22", title: "Utilities", description: "Utilities"},
  { code: "31-33", title: "Manufacturing", description: "Manufacturing"},
  { code: "42", title: "Wholesale Trade", description: "Wholesale Trade"},
  { code: "44-45", title: "Retail Trade", description: "Retail Trade"},
  { code: "48-49", title: "Transportation and Warehousing", description: "Transportation and Warehousing"},
  { code: "51", title: "Information", description: "Information"},
  { code: "52", title: "Finance and Insurance", description: "Finance and Insurance"},
  { code: "53", title: "Real Estate and Rental and Leasing", description: "Real Estate and Rental and Leasing"},
  { code: "54", title: "Professional, Scientific, and Technical Services", description: "Professional, Scientific, and Technical Services"},
  { code: "55", title: "Management of Companies and Enterprises", description: "Management of Companies and Enterprises"},
  { code: "56", title: "Administrative and Support and Waste Management and Remediation Services", description: "Administrative and Support and Waste Management and Remediation Services"},
  { code: "61", title: "Educational Services", description: "Educational Services"},
  { code: "62", title: "Health Care and Social Assistance", description: "Health Care and Social Assistance"},
  { code: "71", title: "Arts, Entertainment, and Recreation", description: "Arts, Entertainment, and Recreation"},
  { code: "72", title: "Accommodation and Food Services", description: "Accommodation and Food Services"},
  { code: "81", title: "Other Services (except Public Administration)", description: "Other Services (except Public Administration)"},
  { code: "92", title: "Public Administration", description: "Public Administration"},
];

function sortCategories(a, b) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    // a must be equal to b
    return 0;
};

industryCategories.sort(sortCategories);

export default industryCategories;