const UPDATE_USERROLE = `mutation updateUserRole($userRoleInput: UpdateManziaUserRoleInput!,
    $userInput: UpdateManziaUserInput!) { 
    a1: updateManziaUserRole(input: $userRoleInput) {
        userId
        roleId
        accountId
        roleName
        roleDescription
        deleteTime
        createdAt
        updatedAt
        __typename
    },
    a2: updateManziaUser(input: $userInput) {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        createdAt
        updatedAt
        __typename
    }
  }`;

  export default UPDATE_USERROLE;