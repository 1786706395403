/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Manzia
import { ManziaBillingInfo, ManziaSampleIntents } from "appSettings";
import { isEmptyObject } from "helpers";

/**
   * data:  
   * [
      {
        period_7day: '2023-06-21 19:00:00.000000000',
        intent_count: 2,
        intent_type: 'just_looking',
        account_id: 'd78195ed-441c-406e-9af4-edd63d9bf275'
      }
    ]
  */

  function sortByValuesDesc(obj) {
    const sortedEntries = Object.entries(obj).sort((a, b) => b[1] - a[1]);
    const sortedObject = Object.fromEntries(sortedEntries);
    return sortedObject;
  }
/* eslint-disable */
  function chartData(data) {
    // console.log("Intent data: ", data);
    let jsonData = [];
    try {
      jsonData = JSON.parse(data['getManziaConversationIntents']);
      if (typeof jsonData === 'string') {
        jsonData = JSON.parse(jsonData);
      }
    } catch (_) {
      // console.log("Failed to parse chart data: ", error);
    }
    
    
    let intents = {};
    jsonData.forEach(item => {
      const { intent_type, intent_count } = item;
      intents[intent_type] = intents[intent_type] ? intents[intent_type] + intent_count : intent_count;
    });

    // Sample intents
    intents = isEmptyObject(intents) ? ManziaSampleIntents : intents;
    
    // Sort in descending order
    intents = sortByValuesDesc(intents);

    return {
      labels: Object.keys(intents).slice(0, 10),
      datasets: [
        {
          label: "Objection count",
          color: "info",
          data: Object.values(intents).slice(0, 10),
        }
      ]
    };
  }
/* eslint-enable */

export function getBarChartData(data, interval) {
  let period = ManziaBillingInfo.periods.find(elem => elem.interval === interval);
  period = period ?? ManziaBillingInfo.periods[0];
  return chartData(data, period);
}

const horizontalBarChartDemo = {
  labels: ["missing_features", "not_sure", "more_research", "too_expensive", "not_a_fit", "just_looking"],
  datasets: [
    {
      label: "Top visitor objections",
      color: "dark",
      data: [60, 50, 45, 38, 26, 15],
    },
  ],
};

export default horizontalBarChartDemo;
