/**
=========================================================
* Manzia Inc - v1.1.0
=========================================================

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Roy Manzi

 =========================================================

*/

import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Manzia components
// import DeleteButton from "layouts/train/delete-product";

// Actions
import { productActions } from "appSettings";

function InfoCell({ icons, payload }) {
  
  const navigate = useNavigate();

  function clickHandler(action) {
    const { productTraining, productName, manziaChatbotId } = payload;
    switch(action) {
      case productActions.info:
        navigate(`/products/${productTraining.userProductId}`, { state: { 
          userProduct: {
            id: productTraining.userProductId,
            productName,
            trainedChatBotId: manziaChatbotId
          } 
        }});
        break;
      default:
        break;
    }
  }

  return (
      <MDBox mx={1} display="flex" alignItems="center" justifyContent="space-between">
        {icons.map(({ icon, color, action }) => 
           (
            <MDButton 
              key={icon} 
              sx={{ marginRight: "2px" }} 
              variant="outlined" 
              color={color} 
              size="medium" 
              iconOnly
              onClick={() => clickHandler(action)}
            >
              <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
            </MDButton>
          ))}
      </MDBox>
  );
}


// Typechecking props for the InfoCell
InfoCell.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    color: PropTypes.string,
    action: PropTypes.string,
  })).isRequired,
  payload: PropTypes.shape({
    id: PropTypes.string,
    productName: PropTypes.string,
    deployedByUserId: PropTypes.string,
    manziaChatbotId: PropTypes.string,
    productTraining: PropTypes.shape({
      id: PropTypes.string,
      userProductId: PropTypes.string,
    })
  }).isRequired
};

export default InfoCell;
