/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

import { useLocation, Navigate } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// GraphQL
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Auth
import { AuthUserContext } from "App";

// Manzia
import UserRoles from "layouts/onboard/components/UserRoles";
// import WelcomeView from "layouts/onboard/components/WelcomeView";
import { OnboardRole, OnboardState } from "appSettings";
import BackendView from "layouts/onboard/components/BackendView";

// State Views
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";

function BasicView({ children }) {
  return (
    <Card sx={{ overflow: "visible", marginTop: 4, padding: 3 }}>
      <MDBox my={2}>
          <MDTypography variant="h2" fontWeight="bold">
            Welcome to Manzia
          </MDTypography>
        </MDBox>
      {children}
    </Card>
  );
}

function CreateBackendUser() {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { state } = useLocation();

  // Fetch and cache user details
  const { loading, error, data } = useQuery(gql`${queries.getManziaUser}`, {
    variables: { id: USERID }, fetchPolicy: 'cache-and-network',
  });

  if (loading) return (
    <BasicView>
      <LoadView />
    </BasicView>
  );

  if (error) return (
    <BasicView>
      <ErrorView error={{ message: `${error}` }} />
    </BasicView>
  );

  return (
  <CreateBackend 
    userId={state?.userId || USERID}
    userEmail={state?.userEmail || data.getManziaUser?.emailAddress}
    manziaAccountId={state?.manziaAccountId || data.getManziaUser?.accountId}
  />);

}

function CreateBackend({ userId, userEmail, manziaAccountId }) {

  // const navigate = useNavigate();
  const [userRole, setUserRole] = useState(OnboardRole.ACCOUNT_OWNER);
  const [onboardState, setOnBoardState] = useState(OnboardState.NO_PRODUCTS);
  const [invitation, setInvitation] = useState(null);

  const { loading: inviteLoading, error: inviteError, data: inviteData } = useQuery(gql`${queries.listManziaTeamInvites}`, {
    variables: { emailAddress: userEmail }, fetchPolicy: 'cache-and-network',
  });

  // variables: { trainedChatBotId },
  const [getProducts, { loading: prodLoading, error: prodError, data: prodData, called: prodCalled }] = useLazyQuery(gql`${queries.manziaUserProductsByTrainedChatBotId}`, {
     fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!prodData || !prodCalled || prodLoading) return;
    const { manziaUserProductsByTrainedChatBotId: { items = [] } } = prodData;
    if (items.length > 0) setOnBoardState(OnboardState.HAS_PRODUCT);
  }, [prodCalled, prodLoading]);

  useEffect(() => {
    if (!inviteData) return;
    const { items = [] } = inviteData.listManziaTeamInvites;
    if (items.length > 0) {
        const [invite] = items;
        setUserRole(invite.userRole);
        setInvitation({ ...invite });

        // Fetch products
        getProducts({
          variables: { trainedChatBotId: invite.manziaChatbotId }
        });
    }
  }, [inviteData]);

  if (inviteLoading || prodLoading) return (
    <BasicView>
      <LoadView />
    </BasicView>
  );

  if (inviteError || prodError) return (
    <BasicView>
      <ErrorView error={{ message: `${inviteError || prodError}` }} />
    </BasicView>
  );

  // /train/product-page
  function renderStartView() {
    switch (userRole) {
        case OnboardRole.DEVELOPER:
        case OnboardRole.TEAM_MEMBER:
            return <UserRoles  
                        userId={userId}
                        onboardState={onboardState}
                        userRole={userRole}
                        invitation={invitation} 
                    />;
        case OnboardRole.ACCOUNT_OWNER:
            return <BackendView  
                      userId={userId}
                      userEmail={userEmail}
                      manziaAccountId={manziaAccountId}
                    />
        default:
          return <Navigate to="/onboard/welcome" state={{
                  onboardState: OnboardState.NO_PRODUCTS,
                  userEmail,
                  userId
                }} />
  
    }
  }

  return (
    <Card sx={{ overflow: "visible", marginTop: 4 }}>
      <MDBox mt={4}>
        { renderStartView()}
      </MDBox>
    </Card>
  );
}

BasicView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

CreateBackend.defaultProps = {
  manziaAccountId: null
}

CreateBackend.propTypes = {
  userId: PropTypes.string.isRequired, 
  userEmail: PropTypes.string.isRequired, 
  manziaAccountId: PropTypes.string
};

export default CreateBackendUser;
