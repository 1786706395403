const CreateBotDeployment = `mutation createBotDeployment(
    $manziaChatbotId: ID!, $lastDeployedAt: AWSDateTime!, $accountId: ID!,
    $userDeployMessage: String!, $productTrainingId: ID!,
    $deployedByUserId: ID!, $botDeploymentId: ID!, 
    $lastModifiedAt: AWSDateTime!, $status: DeploymentStatus, 
    $versionToDeploy: String, $productName: String, $deployedUsername: String
) {
    a1: createManziaBotDeployment(input: {
        id: $botDeploymentId
        manziaChatbotId: $manziaChatbotId,
        lastDeployedAt: $lastDeployedAt,
        userDeployMessage: $userDeployMessage,
        productTrainingId: $productTrainingId,
        productName: $productName
        deployedUsername: $deployedUsername
        deployedByUserId: $deployedByUserId,
        versionToDeploy: $versionToDeploy
        status: $status
    }) {
        id
        manziaChatbotId
        lastDeployedAt
        deployedByUserId
        productTrainingId
        productName
        deployedUsername
        userDeployMessage
        versionToDeploy
        status
        createdAt
        updatedAt
        __typename
    },
    a2: updateManziaProductTraining(input: {
        id: $productTrainingId,
        botDeploymentId: $botDeploymentId,
        lastModifiedAt: $lastModifiedAt
    }) {
      id
      userProductId
      trainingData
      botDeploymentId
      lastModifiedAt
      trainingStatus
      deleteTime
      createdAt
      updatedAt
      __typename
    },
    a3: updateManziaAccount(input: {
        id: $accountId,
        lastBotDeploymentId: $botDeploymentId
    }) {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        createdAt
        updatedAt
        __typename
    }
}`;


export default CreateBotDeployment;