/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useCallback, useContext } from "react";

// @mui material components
import Container from "@mui/material/Container";

// Material Dashboard 2 PRO React
// import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Auth
import { AuthUserContext } from "App";
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Pricing page components
import { TABS, MANZIA_BILL_RATE } from "appSettings";
import PricingCards from "layouts/pricing/components/PricingCards";
// import TrustedBrands from "layouts/pages/pricing-page/components/TrustedBrands";
import Faq from "layouts/pricing/components/Faq";
import ResizedTabs from "layouts/pricing/components/ResizedTabs";
import Conversations from "layouts/pricing/components/Conversations";

// { billRate = MANZIA_BILL_RATE }
function PricingPage() {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { account } = useLocalChatbotUser({ userId: USERID });
  const [prices] = useState([ account?.billRate?.toFixed(2) ?? MANZIA_BILL_RATE.toFixed(2) ]);
  const [activeTab, setActiveTab] = useState(TABS.pricing[0]);

  const onTabChange = useCallback((tab) => setActiveTab(tab), []);

  function getTabContent() {
    switch (activeTab.label) {
      case TABS.pricing[0].label:
        return (<>
          <PricingCards prices={prices} accountId={account.id} />
          <Faq />
        </>);
      case TABS.pricing[1].label:
        return (
        <Conversations />
        );
      default:
        return null;
    }
  }

  return (
        <Container>
          <MDBox mt={2}>
            <ResizedTabs onTabChange={onTabChange} tabs={TABS.pricing}/>
          </MDBox>
          {getTabContent()}
        </Container>
  );
}

export default PricingPage;
