
// Apollo
import { gql, useApolloClient } from '@apollo/client';

// const DEFAULT_ID = "20966bf1-2054-49ca-9328-7f43b8f8c65b";

const userProductFragment = `
  fragment LocalManziaUserProduct on ManziaUserProduct {
    id
    productName
    productImage
    productType
    trainingStatus
    createdByUserId
    trainedChatBotId
    createdAt
    updatedAt
  }
`;

const productTrainingFragment = `
  fragment LocalManziaProductTraining on ManziaProductTraining {
    id
    userProductId
    trainingData
    trainedByUserId
    trainedChatBotId
    botDeploymentId
    lastModifiedAt
    deleteTime
    trainingStatus
    createdAt
    updatedAt
  }
`;

const userProductsQuery = `
  query manziaUserProducts($userId: ID!) {
    getManziaUser(id: $userId) {
      id
      userProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      productTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

const userProductsByChatbotQuery = `
  query manziaUserProductsByChatbot($botId: ID!) {
    getManziaChatbot(id: $botId) {
      id
      accountId
      chatbotUserProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatbotProductTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;


export default function useLocalUserProducts({ userId, botId, 
  userProductId, productTrainingId }) {

    const client = useApolloClient();
    let userProducts = [];
    let productTrainings = [];

    if (botId) {
      const response = client.readQuery({
        query: gql`${userProductsByChatbotQuery}`,
        variables: { botId },
      });
      
      // console.log(`Response for botId ${botId}: `, response);
  
      userProducts = response?.getManziaChatbot?.chatbotUserProducts?.items || userProducts;
      productTrainings = response?.getManziaChatbot?.chatbotProductTrainings?.items || productTrainings;
    } 
    
    if (userId) {
      const response = client.readQuery({
        query: gql`${userProductsQuery}`,
        variables: { userId },
      });
      
      // console.log("Response: ", getManziaUser);

      userProducts = response?.getManziaUser?.userProducts?.items || userProducts;
      productTrainings = response?.getManziaUser?.productTrainings?.items || productTrainings;
    }

    if (userProductId) {
      const response = client.readFragment({
        id: `ManziaUserProduct:${userProductId}`, // The value of the to-do item's cache ID
        fragment: gql`${userProductFragment}`,
      });
      if (response) {
        userProducts.push(response);
      }
    }

    if (productTrainingId) {
      const response = client.readFragment({
        id: `ManziaProductTraining:${productTrainingId}`, // The value of the to-do item's cache ID
        fragment: gql`${productTrainingFragment}`,
      });
      if (response) {
        productTrainings.push(response);
      }
    }
    
  return { userProducts, productTrainings };
}