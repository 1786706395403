/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Chat
// import ChatUI from "layouts/train/new-product/components/Chat";
// import ProductIntent from "layouts/train/new-product/components/Intent";
// import ExpandAccordion from "layouts/train/new-product/components/Expand";

// Intent Data
// import { insightInfo } from "layouts/train/new-product/components/Intent/intentdata";

// NewUser page components
// import DynamicFormField from "layouts/train/new-product/components/DynamicFormField";
import DynamicInsightsField from "layouts/train/new-product/components/DynamicInsightsField";
// import FormField from "layouts/train/new-product/components/FormField";

function Insights({ formData }) {
  const { formField: { productName, insights }, values } = formData;
  
  return (
    <MDBox>
      <MDBox lineHeight={1} my={4}>
        <MDTypography variant="h5" fontWeight="bold">Ideal Customer Profile</MDTypography>
        <MDTypography variant="body2" color="dark">
          {`${values[productName.name]} uses the company and decision-maker profiles you provide below to qualify web visitors.`}
        </MDTypography>
      </MDBox>

      <MDBox my={2}>
          <DynamicInsightsField insights={insights} />
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Address
Insights.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Insights;
