/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useCallback } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useNavigate } from 'react-router-dom';

// formik components
import { Formik, Form } from "formik";

// @mui material components
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Manzia
import NotificationView from 'layouts/common/Notification';
import { ManziaAuthState } from "appSettings";
import InputField from "layouts/authentication/components/InputField";
import useAuth from "customhooks/useAuth";
// import { isEmptyObject } from "helpers";
import ErrorView from "layouts/common/ErrorView";


// schemas for form and form fields
import validations from "layouts/authentication/schema/validations";
import form from "layouts/authentication/schema/form";
import initialValues from "layouts/authentication/schema/initialValues";

function ChangePassword({ loginUser, authHandler }) {
    const { changePassword: { formId, newPassword, confirmPassword } } = form;
    // const { state: { loginUser } } = useLocation();

    const navigate = useNavigate();

    // Change Password
    const { loading: changeLoading, error: changeError, 
        value: changeData, command: authChange } = useAuth(ManziaAuthState.forceNewPassword.key);
    
    // Sign Out
    const { loading: signoutLoading, value: signoutData, command: authSignout } = useAuth(ManziaAuthState.signOut.key);

    useEffect(() => {
        if (!changeData) return;
        // console.log(`User successfully changed password.`);
        
        // Sign out to login with new password
        authSignout()();

    }, [changeData]);

    useEffect(() => {
        if (!signoutData) return;
        navigate("/", { replace: true });
    }, [signoutData]);

    const handleSubmit = useCallback((values, actions) => {
        // console.log("Submitted: ", values);

        // Signup
        // console.log("Change password user: ", loginUser);
        authChange()({
            user: loginUser,
            password: values[newPassword.name]
        });

        actions.setSubmitting(false);
        // actions.resetForm();
        
    }, []);

  const getChangeErrMessage = () => {
    if (!changeError) return changeError;
    const { name: errorCode } = changeError;
    switch (errorCode) {
        case 'UserNotFoundException':
            return "Unknown credentials. Try to signup again."
        case 'TooManyFailedAttemptsException':
            return "Too many attempts. Wait a few minutes and try again.";
        case 'PasswordResetRequiredException':
            return "Change password failed. Please try again later";
        case 'InvalidPasswordException':
            return `Invalid password. Please try again.`;
        case 'InternalErrorException':
            return `Internal error encountered. Please try again later.`;
        case 'TooManyRequestsException':
            return "Too many signup attempts. Wait a few minutes and try again.";
        case 'NotAuthorizedException':
            return "Not authorized. Please try to login again.";
        default:
            return "Encountered an error. Please try to login again later.";
    }
  }

  return (
    
    <Formik
        initialValues={initialValues.changePassword}
        validationSchema={validations.changePassword}
        onSubmit={handleSubmit}
    >
        {({ isSubmitting, isValid }) => {
            // console.log("Form values", values);
            // console.log("Field states: ", fieldStates)
            // console.log("Errors: ", errors);

            const chgError = getChangeErrMessage();
            
            return (
            <Form id={formId} autoComplete="off">
                {changeData && <NotificationView 
                    color="success"
                    message="Password change succeeded."
                />}
                {chgError && <ErrorView error={{ message: `${chgError}`}}/>}
                <MDBox my={2}>
                    <InputField 
                        name={newPassword.name} 
                        type={newPassword.type}
                        label={newPassword.label}
                        fullWidth 
                    />
                </MDBox>
                <MDBox mb={2}>
                    <InputField 
                        name={confirmPassword.name} 
                        type={confirmPassword.type}
                        label={confirmPassword.label}
                        fullWidth 
                    />
                </MDBox>
                <MDBox 
                    mt={4} 
                    mb={1}
                    display="flex"
                    alignItems="center"
                    >
                    {(changeLoading || signoutLoading) && <CircularProgress />}
                    <MDButton 
                        variant="gradient" 
                        color="info" 
                        size="large"
                        type="submit" 
                        fullWidth
                        disabled={isSubmitting || !isValid}
                    >
                        Submit
                    </MDButton>
                </MDBox>
                <MDBox mt={3} textAlign="center">
                    <MDTypography
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                        sx={{ cursor: "pointer" }}
                        onClick={
                            () => authHandler({ state: ManziaAuthState.signIn })
                        }
                    >
                        Return to sign in
                    </MDTypography>
                </MDBox>
            </Form>
        )}}
    </Formik>
   
    
  );

}

ChangePassword.defaultProps = {
    loginUser: null
};

ChangePassword.propTypes = {
    loginUser: PropTypes.shape({
        attributes: PropTypes.shape({
            username: PropTypes.string.isRequired
        })
    }),
    authHandler: PropTypes.func.isRequired
};

export default ChangePassword;
