/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef, useContext, useCallback } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Settings page components
// import BaseLayout from "layouts/test/components/BaseLayout";
import Sidenav from "layouts/test/products/components/Sidenav";
import Messages from "layouts/test/products/components/Messages";
// import TestSkeleton from "layouts/test/products/components/TestSkeleton";

// GraphQL
import { gql, useMutation, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';
// import * as mutations from 'graphql/mutations';

// Networking
import useLexV2Client from "customhooks/useLexV2Client";

// Custom Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";
// import useLocalUserProducts from "customhooks/useLocalUserProducts";
// import useSessionStorage from "customhooks/useSessionStorage";

// Auth
import { AuthUserContext } from "App";

// UUID
import { v4 as uuidv4 } from 'uuid';

// Lex, Session
// import useSessionId from "customhooks/useSessionId";
import { delegateResponse } from "layouts/test/aws/lexResponses";

// State Views
import ErrorView from "layouts/common/ErrorView";
import LoadView from "layouts/common/LoadView";
import NotificationView from "layouts/common/Notification";

// Manzia
import validations from "layouts/test/products/components/schemas/validations";

// import conversations from "layouts/test/products/messageData/messages";
import createTestConversationChatMessage from "layouts/test/products/components/Messages/mutations";

// Utils
import { decodeGzip, isEmptyObject } from "helpers";
import moment from "moment";

// Constants
import { BOT_SESSION_KEY, lexV2Commands, CONVERSATION_TITLE } from "appSettings";

function NoProductView({ incomplete, userId }) {
  const { chatbot: { chatbotName } } = useLocalChatbotUser({ userId });
  const navigate = useNavigate();
  // console.log("Incomplete: ", incomplete);
  return (
      <MDBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDBox mb={3}>
              <MDTypography variant="h4" fontWeight="bold" color="dark">
                {`Welcome to ${chatbotName}`}
              </MDTypography>
            </MDBox>
            <MDBox mb={3}>
              {incomplete.length > 0 ? 
                (<MDTypography variant="body2" fontWeight="regular" color="dark">
                  {`Insufficient product information is available to ${chatbotName} to engage website visitors. Click below to add more product data.`}
                </MDTypography>) : 
                (<MDTypography variant="body2" fontWeight="regular" color="dark">
                  {`Get started by adding the product information that ${chatbotName} will use to engage website visitors. Click below.`}
                </MDTypography>)
              }
            </MDBox>
            <MDBox mt={3}>
            <Grid container justifyContent="center" alignItems="center" my={2}>
              {incomplete.length > 0 ? 
                (<Grid item xs={4} sm={3} sx={{ textAlign: "right" }}>
                  <MDButton 
                    variant="gradient" 
                    color="info" 
                    fullWidth
                    onClick={() => navigate(`/products/${incomplete[0].id}`)}
                  >
                    <Icon>edit</Icon>&nbsp; Edit Product
                  </MDButton>
                </Grid>) : 
                (<Grid item xs={4} sm={3} sx={{ textAlign: "right" }}>
                  <MDButton 
                    variant="gradient" 
                    color="info" 
                    fullWidth
                    onClick={() => navigate("/new-product")}
                  >
                    <Icon>add</Icon>&nbsp; Add Product
                  </MDButton>
                </Grid>)
              }
            </Grid>
              
            </MDBox>

          </MDBox>
        </Card>
      </MDBox>
  );
}

// Check for complete products
function isTrainedProduct(training) {
  let trainingData;
  try {
    trainingData = JSON.parse(training.trainingData);
  } catch (error) { trainingData = null; }
  if (isEmptyObject(trainingData)) return false;
  const isValid = validations.isValidSync(trainingData);
  // console.log("Valid product: ", isValid);
  return isValid;
}

function completeProducts(trainings = []) {
  const result = { complete: [], incomplete: [] };
  if (trainings.length === 0) return result;
  trainings.forEach(training => {
    if (isTrainedProduct(training)) {
      result.complete.push(training);
    } else {
      result.incomplete.push(training);
    }
  });

  return result;
}


function TestProductData() {
  const [products, setProducts] = useState([]);
  const [incompleteProducts, setIncompleteProducts] = useState([]);
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { chatbot: { id: botId } } = useLocalChatbotUser({ userId: USERID });
  
  // Fetch chabot product trainings
  const { loading, error, data } = useQuery(gql`${queries.getManziaChatbot}`, {
    variables: { id: botId }, fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!data) return;
      const userProducts = data?.getManziaChatbot?.chatbotUserProducts?.items || [];
      const productTrainings = data?.getManziaChatbot?.chatbotProductTrainings?.items || [];
      const { complete, incomplete } = completeProducts(productTrainings);
      const completeSet = new Set(complete.map(train => train.userProductId));
      const incompleteSet = new Set(incomplete.map(train => train.userProductId));
      setProducts(userProducts.filter(prod => completeSet.has(prod.id)));
      setIncompleteProducts(userProducts.filter(prod => incompleteSet.has(prod.id)));
  }, [data]);

  if (loading) return ( 
      <LoadView />
  );

  // Errors
  if (error) return ( 
      <ErrorView error={{message: `${error}`}} />
  );
  
  return products.length > 0 ? <TestProduct userProducts={products} /> : <NoProductView incomplete={incompleteProducts} userId={USERID} />;
}

function TestErrorView() {

  return (
    <Card
      sx={{
        marginTop: 2,
        minHeight: '60vh'
      }}
    >
      <MDBox p={3}>
        <NotificationView 
          color="error"
          message="Encountered an error. Bot is not available for testing. Please retry later."
          route={{ text: "Home page", path: "/products" }}
        />
      </MDBox>
    </Card>
  )
}

/**
 * Scenarios:
 * 1- No conversations related to selected product
 * 2- User clicks 'New Chat' (new conversation)
 * 3- User deletes all conversations
 * 4- When exactly is the new conversation mutation called?
 * 
 * Deletes
 * Delete all conversations for which user did not set a title and
 * has not submitted/created at least once message (necessary to remove 'empty';
 * conversations as well as always show latest 'new conversation')
 */
function TestProduct({ userProducts }) {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { chatbot: { chatbotAliasId, lexChatBotId }, 
  account: { webDomain, organizationName } } = useLocalChatbotUser({ userId: USERID });
  // const { userProducts } = useLocalUserProducts({ userId: USERID });
  // console.log("Products: ", products);
  const [selectedProduct, setSelectedProduct] = useState(userProducts[0]);
  const sessionId = useRef(uuidv4());
  const newConversationId = useRef(uuidv4());
  const newChatMessageId = useRef(uuidv4());
  
  /**
   * Starting a new conversation
   * 1- Create a new conversation
   * 2- Fetch GPT welcome message via Lex BOT
   * 3- Create chat message from GPT welcome message via graphql (local cache, remote db )
   * 4- Add welcome chat message to new conversation
   * 5- Show skeleton during loading states
   */
  const newConversation = {
      id: newConversationId.current,
      userProductId: selectedProduct.id,
      testedByUserId: USERID,
      lastModifiedAt: moment().toISOString(),
      title: CONVERSATION_TITLE,
      isExample: false,
      isNewConversation: true,
  };
  
  const [selectedChat, setSelectedChat] = useState(newConversation);

  // Create chat message
  const [ createConversationChatMessage, 
    { loading: createChatLoading, 
      error: createChatError, data: createChatData }] = useMutation(gql`${createTestConversationChatMessage}`);
  
  // Handle Lex Bot session
  // const sessionId = sessionStorage.getItem(BOT_SESSION_KEY);
  // const { loading: sessionLoading, sessionId } = useSessionId(localSessionId);

  // Fetch GPT welcome
  const gptParams = { 
    botId: lexChatBotId, // required
    botAliasId: chatbotAliasId, // required
    localeId: "en_US", // required
    sessionId: sessionId.current, // required
    responseContentType: 'text/plain; charset=utf-8',
    ...delegateResponse([], "offers_intent",
      { // sessionAttributes
        userId: USERID,
        sender: "manziauser",
        testConversationId: selectedChat.id,
        userProductId: selectedProduct.id,
        webDomain,
        organizationName
      },
    )
  };

  const { loading: gptLoading, error: gptError, value: gptResponse, 
    runCommand: gptFetch, isReady } = useLexV2Client(lexV2Commands.putSession, [sessionId.current]);

  // Fetch GPT welcome message
  useEffect(() => {
    if (selectedChat.isNewConversation) {
      // GPT Response
      sessionStorage.setItem(BOT_SESSION_KEY, sessionId.current);
      if (isReady) {
        gptFetch()(gptParams);
      }
    }
  }, [selectedChat, sessionId.current, isReady]);

  // Set GPT response
  useEffect(() => {
    if (gptResponse && selectedChat.isNewConversation) {
      const messages = decodeGzip(gptResponse.messages);
      if (!Array.isArray(messages)) return;
      const [responseMessage] = messages;
      const { content, contentType } = responseMessage;
      if (contentType === 'PlainText') {
        
        // Update local cache
        const now = moment().toISOString();
        const chatMessageInput = { 
          id: newChatMessageId.current,
          testConversationId: selectedChat.id,
          timestamp: now,
          message: content,
          sender: "manziauser",
          lastModifiedAt: now
        };

        // Conversation and add message
        const conv = { ...selectedChat };
        delete conv.isNewConversation;

        createConversationChatMessage({
          variables: {
            conversationInput: { ...conv, lastModifiedAt: now},
            chatMessageInput
          }
        });

        setSelectedChat(conv);

        // createChatMessage({ variables: { input } });

        // setSelectedChat({ ...conv, testChatMessages: { items: [ { ...chatMessageInput } ]}});
      }
    }
  }, [gptResponse]);
  
  const onProdSelected = useCallback((product) => {
    setSelectedProduct(product);
  }, []);

  const onChatSelected = useCallback((chat) => {
    if (chat) {
      setSelectedChat(chat);
    } else {
      // Start a new conversation
      newConversationId.current = uuidv4();
      newChatMessageId.current = uuidv4();
      newConversation.id = newConversationId.current;
      setSelectedChat(newConversation);
    }
  }, []);

  if (createChatError || gptError) return <TestErrorView />

  if (createChatLoading || gptLoading || !gptResponse || !createChatData) 
  return ( 
      <LoadView />
  );

  return (
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Sidenav 
              product={selectedProduct} 
              onChatSelected={onChatSelected}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Messages 
                    onProductSelected={onProdSelected}
                    initialConversation={selectedChat} 
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
  );
}

NoProductView.propTypes = {
  incomplete: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    userProductId: PropTypes.string.isRequired,
    trainingData: PropTypes.string.isRequired
  })).isRequired,
  userId: PropTypes.string.isRequired
};

TestProduct.propTypes = {
  userProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired
  })).isRequired,
};

export default TestProductData;
