/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
// import { useNavigate } from "react-router-dom";


// Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// formik components
import { Formik, Form } from "formik";

// @mui material components
// import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// GraphQL
// import { gql, useLazyQuery } from '@apollo/client';
// import * as queries from 'graphql/queries';


// Manzia
// import NotificationView from 'layouts/common/Notification';
import { ManziaAuthState, ManziaAuthKeys, 
    MANZIA_CAPTCHA_KEY } from "appSettings";
import InputField from "layouts/authentication/components/InputField";
import useAuth from "customhooks/useAuth";
import useLocalStorage from "customhooks/useLocalStorage";
import { isEmptyObject } from "helpers";
import ErrorView from "layouts/common/ErrorView";
// import LoadView from "layouts/common/LoadView";

// schemas for form and form fields
import validations from "layouts/authentication/schema/validations";
import form from "layouts/authentication/schema/form";
import initialValues from "layouts/authentication/schema/initialValues";

function AuthSignup({ authHandler }) {
    const { signup: { formId, signupEmail, signupPassword, 
        fullName, confirmPassword } } = form;
    // const navigate = useNavigate();
    const [termsConditions, setTermsConditions] = useState(false);

    // Recaptcha
    const recaptchaRef = useRef();
    const [recaptchaError, setRecaptchaError] = useState(null);

    // Signup user
    const [signupUser, setSignupUser] = useLocalStorage(ManziaAuthKeys.signupUser, {});

    // Sign up
    const { loading: signupLoading, error: signupError, 
        value: signupData, command: authSignup } = useAuth(ManziaAuthState.signUp.key);

    useEffect(() => {
        if (!signupData) return;
        const { user, userSub } = signupData;
        // console.log(`User: ${user.username} signed up.`);
        
        // Update Local storage
        const authUser = { username: user.username, userSub };
        setSignupUser(authUser);
        

    }, [signupData]);

    useEffect(() => {
        if (isEmptyObject(signupUser)) return;
        // Confirm signup
        if (signupData) {
            authHandler({ state: ManziaAuthState.confirmSignUp, user: signupUser });
        }
    }, [signupUser]);

    const handleChange = () => setTermsConditions(!termsConditions);

    const handleSubmit = (values, actions) => {
        // console.log("Submitted: ", values);

        // Signup
        recaptchaRef.current.executeAsync()
            .then((recaptchaToken) => {
                authSignup()({
                    username: values[signupEmail.name],
                    password: values[signupPassword.name],
                    attributes: {
                        email: values[signupEmail.name],          // optional
                        name: values[fullName.name]
                    },
                    validationData: {
                        recaptchaToken,
                    },
                    clientMetadata: {
                        platform: "web",
                    },
                    autoSignIn: { // optional - enables auto sign in after user is confirmed
                        enabled: true,
                    }
                });
            }).catch(() => {
                setRecaptchaError("Signup validation failed. Try again later.");
                // console.log("Recaptcha error: ", error);

            }).finally(() => recaptchaRef.current.reset());

        actions.setSubmitting(false);
        actions.resetForm();
        
    };

  const getSignupErrMessage = () => {
    if (!signupError) return signupError;
    const { name: errorCode } = signupError;
    switch (errorCode) {
        case 'UsernameExistsException':
            return "An account already exists account with provided email. Please login."
        case 'TooManyRequestsException':
            return "Too many signup attempts. Wait a few minutes and try again.";
        case 'NotAuthorizedException':
            return "Not authorized. Please try signing up again.";
        default:
            return "Encountered an error during signup. Please try again later.";
    }
  }

  return (
    <Formik
        initialValues={initialValues.signup}
        validationSchema={validations.signup}
        onSubmit={handleSubmit}
        validateOnMount
    >
        {({ isSubmitting, isValid }) => {
            // console.log("Form values", values);
            // console.log("Field states: ", fieldStates)
            // console.log("Errors: ", errors);

            const errSignup = getSignupErrMessage() ?? recaptchaError;
            
            return (
            <Form id={formId} autoComplete="on">
                {errSignup && <ErrorView error={{ message: `${errSignup}`}}/>}
                <MDBox my={2}>
                    <InputField 
                        name={fullName.name} 
                        type={fullName.type}
                        label={fullName.label}
                        fullWidth 
                    />
                </MDBox>
                <MDBox mb={2}>
                    <InputField 
                        name={signupEmail.name} 
                        type={signupEmail.type}
                        label={signupEmail.label}
                        fullWidth 
                    />
                </MDBox>
                <MDBox mb={2}>
                    <InputField 
                        name={signupPassword.name} 
                        type={signupPassword.type}
                        label={signupPassword.label}
                        fullWidth 
                    />
                </MDBox>
                <MDBox mb={2}>
                    <InputField 
                        name={confirmPassword.name} 
                        type={confirmPassword.type}
                        label={confirmPassword.label}
                        fullWidth 
                    />
                </MDBox>

                <MDBox display="flex" alignItems="center" ml={-1}>
                    <Checkbox 
                        checked={termsConditions}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                        &nbsp;&nbsp;I agree to the&nbsp;
                    </MDTypography>
                    <MDTypography
                        component="a"
                        href="https://www.manzia.com/terms"
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="bold"
                        color="info"
                        textGradient
                    >
                        Terms and Conditions
                    </MDTypography>
                </MDBox>
                <MDBox 
                    mt={4} 
                    mb={1}
                    display="flex"
                    alignItems="center"
                    >
                    {(signupLoading) && <CircularProgress />}
                    <MDButton 
                        variant="gradient" 
                        color="info" 
                        size="large"
                        type="submit" 
                        fullWidth
                        disabled={isSubmitting || !isValid || !termsConditions || signupLoading}
                    >
                        sign up
                    </MDButton>
                </MDBox>
                <MDBox mt={3} textAlign="center">
                <MDTypography variant="button" color="text">
                    Already have an account?{" "}
                    <MDTypography
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                        sx={{ cursor: "pointer" }}
                        onClick={
                            () => authHandler({ state: ManziaAuthState.signIn })
                        }
                    >
                        Sign in
                    </MDTypography>
                </MDTypography>
                </MDBox>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={MANZIA_CAPTCHA_KEY}
                    badge="bottomright"
                />
            </Form>
          )}}
    </Formik>
  );

}

AuthSignup.propTypes = {
    authHandler: PropTypes.func.isRequired
};

export default AuthSignup;
