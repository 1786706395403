/**
=========================================================
* Manzia Inc - v1.1.0
=========================================================

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Roy Manzi

 =========================================================

*/
// import { useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Amplify
// import { API, graphqlOperation } from 'aws-amplify';
// import * as subscriptions from 'graphql/subscriptions';

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// Table
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "layouts/train/product-page/components/DefaultCell";
import InfoCell from "layouts/train/product-page/components/InfoCell";

// State Views
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import NoDataView from "layouts/common/NoDataView";

// Status
import { productActions } from "appSettings";

// Manzia
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Utilities
import moment from "moment";

function Deployments({ manziaChatbotId }) {
    const { chatbot: { chatbotName } } = useLocalChatbotUser({ chatbotId: manziaChatbotId });
    const { loading, error, data, fetchMore } = useQuery(gql`${queries.manziaBotDeploymentsByManziaChatbotIdAndLastDeployedAt}`, {
        variables: { manziaChatbotId, limit: 50, sortDirection: 'DESC' }, fetchPolicy: 'cache-and-network',
    });

    // Subscribe to bot deployment updates
    // useEffect(() => {
    //     const subscription = API.graphql(
    //         graphqlOperation(subscriptions.onUpdateManziaBotDeployment, {
    //             filter: {
    //                 manziaChatbotId: { eq: manziaChatbotId }
    //             }
    //         })
    //       ).subscribe({
    //         next: ({ value }) => {
    //             // console.log(value);
    //             const { data: { onUpdateManziaBotDeployment: { status } } } = value;
    //             if (status && status !== DeploymentStatus.inprogress.key) {
    //                 refetch();
    //             }
    //         },
    //         error: (suberror) => console.warn(suberror)
    //     });
          
    //     // Stop receiving data updates from the subscription
    //     return () => subscription.unsubscribe();
    // }, []);

    if (loading) return <LoadView />
    if (error) return <ErrorView error={{ message: `${error}` }} />
    
    if (data?.manziaBotDeploymentsByManziaChatbotIdAndLastDeployedAt?.items.length === 0) 
        return (
            <NoDataView 
                info={{ title: "No published versions.", message: `No product versions have been published for ${chatbotName}. Click 'Publish' above to publish a product version or click below to learn more about publishing product versions.`}} 
                action={{ label: "How to publish product versions", href: "https://www.manzia.com/help/getting-started/bot-publish/" }}
            />)
    
    const DEPLOYMENTS = data.manziaBotDeploymentsByManziaChatbotIdAndLastDeployedAt.items.map((item) => 
        
        ({
            productName: item.productName, 
            deployMessage: item.userDeployMessage,
            lastDeployedAt: moment(item.lastDeployedAt).fromNow(), 
            deployedBy: item.deployedUsername,
            actions: { 
                icons: [
                    {icon: "chevron_right", color: "info", action: productActions.info },
                ], 
                payload: { ...item } 
            }
        })
    );

    const tableData = {
        columns: [
          { Header: "Product", accessor: "productName", width: "20%" },
          { Header: "Message", accessor: "deployMessage", width: "40%" },
          { Header: "Published By", accessor: "deployedBy", align: "left", width: "20%" },
          { Header: "Published", accessor: "lastDeployedAt", width: "10%" },
          { Header: "Actions", accessor: "actions", align: "center", width: "10%" },
        ],
        rows: DEPLOYMENTS.map((item) => ({
              productName: <DefaultCell>{item.productName}</DefaultCell>,
              deployMessage: <DefaultCell>{item.deployMessage}</DefaultCell>,
              lastDeployedAt: <DefaultCell>{item.lastDeployedAt}</DefaultCell>,
              deployedBy: <DefaultCell>{item.deployedBy}</DefaultCell>,
              actions: <InfoCell icons={item.actions.icons} payload={item.actions.payload} />
        }))
    };

    return (
        <MDBox mt={8} mb={2}>
            <MDBox mb={1} ml={2}>
                <MDTypography variant="h5" fontWeight="medium">
                    Published versions
                </MDTypography>
            </MDBox>
            <DataTable
                table={tableData}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
                onLoadMore={() => fetchMore({
                    variables: {
                      nextToken: data.manziaBotDeploymentsByManziaChatbotIdAndLastDeployedAt.nextToken,
                      limit: 50
                    },
                })}
            />
        </MDBox>
    );
}

// Typechecking props for BaseLayout
Deployments.propTypes = {
    manziaChatbotId: PropTypes.string.isRequired,
  };

export default Deployments;