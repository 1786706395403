/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

// Material UI
import InputAdornment from '@mui/material/InputAdornment';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function MDInputURL({ ...rest }) {
  return (
    <MDInput
      {...rest}
      InputProps={{
        startAdornment: 
            <InputAdornment position="start">
              <MDTypography variant="button" color="dark" fontWeight="bold">
                https://
              </MDTypography>
            </InputAdornment>
      }}
    />
  )
}

function InputURL({ label, name, variant, ...rest }) {
  return (
    <MDBox mb={1.5}>
        <Field 
          {...rest} 
          name={name}
          as={MDInputURL} 
          variant={variant} 
          label={label} 
          fullWidth 
        />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Specifies the default values for props:
InputURL.defaultProps = {
  variant: 'outlined',
};

// typechecking props for FormField
InputURL.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

export default InputURL;
