/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// formik components
import { Formik, Form } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDBadge from "components/MDBadge";

// GraphQL
import { gql, useMutation, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';

// Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// Manzia
// import RadioButtons from "layouts/settings/components/RadioButtons";
import ErrorView from "layouts/common/ErrorView";
import LoadView from "layouts/common/LoadView";
import NotificationView from "layouts/common/Notification";
import InputField from "layouts/settings/components/InputField";
import form from "layouts/settings/schemas/form";
import { botSettingValidations } from "layouts/settings/schemas/validations";
import { botSettingValues } from "layouts/settings/schemas/initialValues";

function BotSettingsData() {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { chatbot: { id: chatbotId } } = useLocalChatbotUser({ userId: USERID });

  // Fetch botsettings
  const { loading: settingLoading, error: settingError, data: settingData } = useQuery(gql`${queries.getManziaBotSetting}`, {
    variables: { chatbotId }, fetchPolicy: 'cache-and-network',
  });

  if (settingLoading) {
    return <LoadView loadingMessage="Fetching settings..." />;
  }

  if (settingError) {
    return <NotificationView 
              color="error"
              message="Error fetching bot settings. Try again later."
          />;
  }

  return <BotSettings settings={settingData?.getManziaBotSetting} />;
}

function BotSettings({ settings }) {
  const { botSettings: { formId, displayFrequency } } = form;

  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { chatbot: { id: chatbotId } } = useLocalChatbotUser({ userId: USERID });

  // Update botsettings
  const [updateBotSetting, { loading: botLoading, 
    error: botError, data: botData }] = useMutation(gql`${mutations.updateManziaBotSetting}`);
  
  
  const handleSubmit = (values, actions) => {
    // console.log("Submitted: ", values);
    
    updateBotSetting({
      variables: {
        input: {
          chatbotId,
          displayFrequency: values[displayFrequency.name]
        }
      }
    });
    
    actions.setSubmitting(false);
    actions.resetForm();
  };

  return (
    <Card id="install-bot" sx={{ overflow: "visible" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDTypography variant="h5">Bot Settings</MDTypography>
        {/* <MDBadge variant="contained" color="success" badgeContent="enabled" container /> */}
      </MDBox>
      {botError && <ErrorView error={{ message: `${botError}`}}/>}
      {botData && <NotificationView 
                color="success"
                message="Successfully updated bot settings."
            />}
      <Divider />
      
      <Formik
        initialValues={settings ? 
          { displayFrequency: settings.displayFrequency} : 
          botSettingValues}
        validationSchema={botSettingValidations}
        onSubmit={handleSubmit}
        validateOnMount
        >
          {({ isSubmitting, isValid }) => 
            (
              <Form id={formId} autoComplete="off">
                  <MDBox p={3}>
                    <MDBox my={2} display="flex" justifyContent="space-between">
                        <MDBox>
                          <MDTypography variant="body" color="text">
                            {`Engage same visitor once every: `}
                          </MDTypography>
                        </MDBox>
                        <InputField 
                            name={displayFrequency.name} 
                            type={displayFrequency.type}
                            label={displayFrequency.label}
                            adorn={displayFrequency.adorn}
                            fullWidth
                        />
                    </MDBox>
                    
                    <MDBox display="flex" justifyContent="flex-end" alignItems="center" mt={4} mb={2}>
                      {(isSubmitting || botLoading) && <LoadView loadingMessage="" />}
                      <MDBox ml="auto">
                        <MDButton 
                          variant="gradient" 
                          color="info"
                          type="submit"
                          disabled={isSubmitting || !isValid || botLoading || !!botError}
                        >
                          Update settings
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
              </Form>
            )}
      </Formik>
    </Card>
  );
}

// typechecking props for FormField
BotSettings.defaultProps = {
  settings: null
}

BotSettings.propTypes = {
  settings: PropTypes.shape({
    chatbotId: PropTypes.string.isRequired,
    displayFrequency: PropTypes.number.isRequired
  })
}

export default BotSettingsData;
