/* eslint-disable */
function delegateResponse (
    activeContexts,
    intentName,
    sessionAttributes
  ) {
    return {
      sessionState: {
        activeContexts: activeContexts,
        intent: {
          name: intentName
        },
        sessionAttributes: sessionAttributes,
        dialogAction: {
          type: "Delegate",
        },
      },
    };
  };
  
  function elicitSlotResponse (
    intentName,
    slots,
    slotToElicit,
    sessionAttributes
  ) {
    return {
      sessionState: {
        sessionAttributes: sessionAttributes,
        dialogAction: {
          type: "ElicitSlot",
          slotToElicit: slotToElicit,
        },
        intent: {
          name: intentName,
          slots: slots,
        },
      },
    };
  };
  
 function closeResponse (
    sessionAttributes,
    fulfillmentState,
    intent,
  ) {
    // console.log("sessionAttributes=", sessionAttributes);
  
    return {
      sessionState: {
        sessionAttributes: sessionAttributes,
        dialogAction: {
          type: "Close",
        },
        intent: {
          confirmationState: "Confirmed",
          name: intent,
          state: fulfillmentState,
        },
      },
    };
  };
  
  function elicitIntentResponse (
    sessionAttributes,
    messageText
  ) {
    return {
      sessionState: {
        sessionAttributes: sessionAttributes,
        dialogAction: {
          type: "ElicitIntent",
        },
      },
      messages: [
        {
          contentType: "PlainText",
          content: messageText,
        },
      ],
    };
  };
  
  function confirmIntentResponse (
    sessionAttributes,
    intentName,
    slots
  ) {
    return {
      sessionState: {
        sessionAttributes: sessionAttributes,
        dialogAction: {
          type: "ConfirmIntent",
        },
        intent: {
          name: intentName,
          slots: slots,
        },
      },
    };
  };

  function withSessionState (
    sessionAttributes,
  ) {
    return {
      sessionState: {
        sessionAttributes: sessionAttributes,
        // dialogAction: {
        //   type: "ElicitIntent",
        // },
      }
    };
  };

  /* eslint-enable */

  export {
    delegateResponse,
    elicitSlotResponse,
    closeResponse,
    elicitIntentResponse,
    confirmIntentResponse,
    withSessionState
  }