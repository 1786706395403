/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Manzia
import ConversationTable from "layouts/conversations/components/Table";
import ResizedTabs from "layouts/pricing/components/ResizedTabs";
import EmailLeads from "layouts/analytics/components/Leads";
import { ManziaConversationInfo, TABS } from "appSettings";


function ConversationRecords() {
    const [period, setPeriod] = useState(ManziaConversationInfo.defaultPeriod);
    const [activeTab, setActiveTab] = useState(TABS.conversations[0]);

    const onTabChange = useCallback((tab) => setActiveTab(tab), []);

    const handlePeriod = (_, value) => setPeriod(value);

    function getTabContent() {
        switch (activeTab.label) {
          case TABS.conversations[0].label:
            return (<ConversationTable period={period} />);
          case TABS.conversations[1].label:
            return (<EmailLeads />);
          default:
            return null;
        }
      }
    
    return (
    <Card 
        id="conversations"
        sx={{ height: "100%", minHeight: "75vh", overflowY: "auto", marginTop: 2 }}
    >
        <MDBox p={3}>
            <MDBox mb={3}>
            <Grid 
                container 
                justifyContent="flex-end" 
                alignItems="center" 
                my={2}
                spacing={2}
            >
                <Grid item xs ={8} sm={9}>
                    <MDTypography variant="h5" fontWeight="bold" color="dark" sx={{ textAlign: "left" }}>
                        Conversations with web visitors
                    </MDTypography>
                </Grid>
                {activeTab.label === TABS.conversations[0].label && <Grid item xs={4} sm={3} sx={{ textAlign: "right" }}>
                    <Autocomplete
                        value={period}
                        name="select-period"
                        autoComplete
                        isOptionEqualToValue={(option, value) => option.interval === value.interval}
                        getOptionLabel={(option) => option.label}
                        onChange={handlePeriod}
                        options={ManziaConversationInfo.periods}
                        renderInput={(params) => <MDInput {...params} variant="outlined" />}
                    />
                </Grid>}
            </Grid>
            </MDBox>
            <MDBox mt={2}>
                <ResizedTabs onTabChange={onTabChange} tabs={TABS.conversations}/>
            </MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MDBox py={1}>
                        {getTabContent()}
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    </Card>
    );
}

export default ConversationRecords;
