
// import { useRef } from "react";

// Apollo
import { gql, useApolloClient } from '@apollo/client';

// const DEFAULT_ID = "20966bf1-2054-49ca-9328-7f43b8f8c65b";

const userFragment = `
  fragment LoginUser on ManziaUser {
    id
    accountId
    emailAddress
    fullName
    isAccountOwner
    roleId
    createdAt
    updatedAt
  }
`;

const accountFragment = `
  fragment LoginAccount on ManziaAccount {
    id
    v2chatBotId
    accountOwnerUserId
    accountOwnerEmail
    webDomain
    organizationName
    totalPaymentAmount
    totalConversations
    billRate
    currency
    accountType
    stripeSubscriptionStatus
    industryCode
    industryCategory
  }
`;

const chatbotFragment = `
  fragment LoginManziaChatbot on ManziaChatbot {
    id
    accountId
    lexChatBotId
    chatbotAliasId
    chatbotName
    chatbotTone
    chatbotVersion
    testChatbotAliasId
    testChatbotVersion
    deployedVersion
  }
`;

export default function useLocalChatbotUser({ userId, chatbotId, accountId }) {

    const client = useApolloClient();
    let loginUser = null;
    let account = null;
    let chatbot = null;

    // Logged in user
    if (userId) {
      loginUser = client.readFragment({
        id: `ManziaUser:${userId}`, // The value of the to-do item's cache ID
        fragment: gql`${userFragment}`,
      });
    }

    const accId = accountId || loginUser?.accountId;
    if (accId) {
      account = client.readFragment({
        id: `ManziaAccount:${accId}`, // The value of the to-do item's cache ID
        fragment: gql`${accountFragment}`,
      });
    }

    const botId = chatbotId || account?.v2chatBotId;
    if (botId) {
      chatbot = client.readFragment({
        id: `ManziaChatbot:${botId}`, // The value of the to-do item's cache ID
        fragment: gql`${chatbotFragment}`,
      });
    }
    
    // console.log("Account: ", account);

  return { loginUser, account, chatbot };
}