/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router components
import { Navigate } from "react-router-dom";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
// import Footer from "examples/Footer";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Manzia
// import ProductPage from "layouts/train/product-page";
// import AppSettings from "layouts/settings"
import AuthLogin from "layouts/authentication/components/AuthLogin";
import AuthSignup from "layouts/authentication/components/AuthSignup";
import ConfirmSignup from "layouts/authentication/components/ConfirmSignup";
import AuthPassword from "layouts/authentication/components/AuthPassword";
import ConfirmPassword from "layouts/authentication/components/ConfirmPassword";
import ChangePassword from "layouts/authentication/components/ChangePassword";
import { ManziaAuthState, OnboardRole } from "appSettings";

// Custom hooks
import useAuth from "customhooks/useAuth";
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// State Views
import LoadView from "layouts/common/LoadView";

// Image
// import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import bgImage from "assets/images/YManzia-AI-1200x1200.png";

// function WithLayout({ children }) {
//   return ( 
//     <DashboardLayout>
//       <DashboardNavbar />
//       {children}
//       <Footer />
//     </DashboardLayout>
//   );
// }

function ManziaAuthSession() {
  const { loading, value, command: authenticatedUser } = useAuth(ManziaAuthState.authenticated.key);

  // Check if we have authenticated session and skip login.
  useEffect(() => {
    authenticatedUser()();
  }, []);

  if (loading) 
  return (
      <IllustrationLayout
        title=""
        description=""
        illustration={bgImage}
      >
          <LoadView />
      </IllustrationLayout>
  );
  
  // console.log("Auth value: ", value);
  // { session: ..., attributes: { custom:roleId: "ACCOUNT_OWNER", email: "roymanzi@yahoo.com", email_verified: true, name: "Roy Agaba", sub: "fe23cff9-cfba-48c8-8a1f-5ff38cde6266"}}
  if (!value) {
    return <ManziaAuth />;
  }

  return <ManziaAuthNavigate userAttributes={value.attributes} />;
}

function ManziaAuthNavigate({ userAttributes }) {
  const { sub: userId } = userAttributes;
  const { account, chatbot } = useLocalChatbotUser({ userId });

  if (!account || !chatbot) {
    return <ManziaAuth />;
  }

  return userAttributes["custom:roleId"] === OnboardRole.DEVELOPER ? (<Navigate to="/settings/install-bot" />) : (<Navigate to="/products" />);  
}

function ManziaAuth() {
  const [authState, setAuthState] = useState(ManziaAuthState.signIn);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [loginUser, setLoginUser] = useState(null);

  /**
   * 
   * user:
   * {
   *    authenticationFlowType: ...,
   *    client: { ... },
   *    keyPrefifx: ....,
   *    pool: { ... },
   *    signInUserSession: ...,
   *    storage: { ... },
   *    userSub: ...,
   *    username: ...
   * } 
   */

  const authHandler = ({ state, user, signUser }) => { 
    // console.log("User: ", user);
    // console.log("State: ", state);
    if (user) setCognitoUser(user);
    if (signUser) setLoginUser(signUser);
    setAuthState(state); 
  }

  function authComponent() {
    switch (authState.key) {
      case ManziaAuthState.signIn.key:
        return <AuthLogin authHandler={authHandler} />;
      case ManziaAuthState.signUp.key:
        return <AuthSignup authHandler={authHandler} />;
      case ManziaAuthState.confirmSignUp.key:
        return cognitoUser ? 
          <ConfirmSignup 
            username={cognitoUser.username}
            userId={cognitoUser.userSub} 
          /> : <AuthLogin authHandler={authHandler} />;
      case ManziaAuthState.resetPassword.key:
        return <AuthPassword authHandler={authHandler} />;
      case ManziaAuthState.confirmResetPassword.key:
        return <ConfirmPassword authHandler={authHandler} username={cognitoUser.username} />
      case ManziaAuthState.forceNewPassword.key:
        return <ChangePassword loginUser={loginUser} authHandler={authHandler} />
      default:
        return <AuthLogin authHandler={authHandler} />;
    }
  }

  return (
    <IllustrationLayout
      title={authState.title}
      description={authState.description}
      illustration={bgImage}
    >
        {authComponent()}
    </IllustrationLayout>
  );
}

ManziaAuthNavigate.propTypes = {
  userAttributes: PropTypes.shape({
    sub: PropTypes.string.isRequired,
    "custom:roleId": PropTypes.string
  }).isRequired
}

// WithLayout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default ManziaAuthSession;
