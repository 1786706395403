/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
// import Grid from "@mui/material/Grid";

// Amplify
import { Auth } from 'aws-amplify';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";

// Manzia
import useAuth from "customhooks/useAuth";
import InputField from "layouts/settings/components/InputField";
import { changePasswordValues } from "layouts/settings/schemas/initialValues";
import { changePasswordValidations } from "layouts/settings/schemas/validations";
import form from "layouts/settings/schemas/form";
import { ManziaAuthState } from "appSettings";

// State
import ErrorView from "layouts/common/ErrorView";
import NotificationView from "layouts/common/Notification";

function ChangePassword() {
  const { changePassword: { formId, currentPassword, newPassword, 
   confirmPassword } } = form;
  
  // Change Password
  const { loading, error, value: authData, 
    command: authChangePassword } = useAuth(ManziaAuthState.changePassword.key);

  const getChangePasswordMessage = () => {
    if (!error) return error;
    const { name: errorCode } = error;
    switch (errorCode) {
        case 'UserNotFoundException':
            return "User does not exist. Please sign up."
        case 'TooManyRequestsException':
            return "Too many signup attempts. Wait a few minutes and try again.";
        case 'NotAuthorizedException':
            return "Not authorized. Please try logging in again.";
        default:
            return "Encountered an error during password change. Please try again later.";
    }
  };
  
  const handleSubmit = (values, actions) => {
  
    // Change Password
    Auth.currentAuthenticatedUser()
      .then((user) => {
          authChangePassword()({
            user,
            oldPassword: values[currentPassword.name],
            newPassword: values[newPassword.name],
          });
      })
      .catch(() => { 
          // console.log(err);
      });

    actions.setSubmitting(false);
    actions.resetForm();
    
  };

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      
      {authData && 
        <MDBox px={3}>
          <NotificationView 
              color="success"
              message="Password successfully updated."
            />
        </MDBox>
      }
      <Formik
        initialValues={changePasswordValues}
        validationSchema={changePasswordValidations}
        onSubmit={handleSubmit}
        validateOnMount
        >
            {({ isSubmitting, isValid }) => {
                // console.log("Form values", values);
                // console.log("Field states: ", fieldStates)
                // console.log("Errors: ", errors);

                const errPassword = getChangePasswordMessage();
                
                return (
                <Form id={formId} autoComplete="off">
                    {errPassword && <ErrorView error={{ message: `${errPassword}`}}/>}
                    <MDBox p={3}>
                      <MDBox my={2}>
                          <InputField 
                              name={currentPassword.name} 
                              type={currentPassword.type}
                              label={currentPassword.label}
                              fullWidth
                              required
                          />
                      </MDBox>
                      <MDBox mb={2}>
                          <InputField 
                              name={newPassword.name} 
                              type={newPassword.type}
                              label={newPassword.label}
                              fullWidth
                              required 
                          />
                      </MDBox>
                      <MDBox mb={2}>
                          <InputField 
                              name={confirmPassword.name} 
                              type={confirmPassword.type}
                              label={confirmPassword.label}
                              fullWidth
                              required 
                          />
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end" alignItems="center" mt={4} mb={2}>
                        {loading && <CircularProgress />}
                        <MDBox ml="auto">
                          <MDButton 
                            variant="gradient" 
                            color="info"
                            type="submit"
                            disabled={isSubmitting || !isValid}
                          >
                            Update password
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                </Form>
              )}}
      </Formik>
    </Card>
  );
}

export default ChangePassword;
