/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Manzia
import EmailInvite from "layouts/settings/components/EmailInvite";
import InfoList from "layouts/settings/components/InfoList";
import { gtmEmailDeveloper } from "layouts/settings/components/InfoList/infodata";
import { GTM_CONTAINER_ID } from "helpers";

function EmailDeveloper() {
  const [gtmContainer, setGtmContainer] = useState("");
  const [gtmContainerError, setGtmContainerError] = useState('');
  
  const handleChange = (e) => {
    const { target: { value } } = e;
    switch (e.target.name) {
      case 'gtmContainer':
        setGtmContainer(value);
        setGtmContainerError(GTM_CONTAINER_ID.test(value) ? "" : "Invalid GTM container ID. Must be in format GTM-XXXXXXXX.");
         break;
      default:
        break;
    }
  }

  return (
    <>
      <MDBox p={3}>
        <InfoList infoData={gtmEmailDeveloper} />
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          {(GTM_CONTAINER_ID.test(gtmContainer)) && 
          <Grid item xs={12}>
            <EmailInvite 
              gtmContainerId={gtmContainer}
              buttonLabel="Email developer" 
            />
          </Grid>
          }
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="GTM container ID"
              name="gtmContainer"
              value={gtmContainer}
              onChange={handleChange}
              inputProps={{ type: "text" }}
              required
              placeholder="GTM-XXXXXXX"
              error={gtmContainerError?.length !== 0}
            />
            {gtmContainerError.length !== 0 && <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
               {gtmContainerError}
              </MDTypography>
            </MDBox>}
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default EmailDeveloper;
