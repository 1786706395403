/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
// import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

function LinkURL({ url, anchorText, type, ...rest }) {
    return (
        <MDTypography
            component="a"
            href={url}
            target="_blank"
            rel="noreferrer"
            variant="body2"
            {...rest}
            >
            {`${anchorText} `}
            {type === 'file' && <Icon sx={{ fontWeight: "regular", verticalAlign: "middle" }}>file_download</Icon>}
        </MDTypography>
    );
}

LinkURL.defaultProps = {
    type: "link"
};

LinkURL.propTypes = {
    url: PropTypes.string.isRequired,
    anchorText: PropTypes.string.isRequired,
    type: PropTypes.string,
}

export default LinkURL;