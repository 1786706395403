import * as React from 'react';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material
import CircularProgress from '@mui/material/CircularProgress';

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
import colors from "assets/theme/base/colors";

// Material Dashboard
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';

function ProgressButton({ children, loading, clickHandler, ...rest}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    
      <MDBox sx={{ m: 1, position: 'relative' }}>
        <MDButton
          {...rest}
          onClick={clickHandler}
        >
          {children}
        </MDButton>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: darkMode ? colors.white.main : colors.info.main,
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </MDBox>
    
  );
}

// Typechecking props for BaseLayout
ProgressButton.propTypes = {
    children: PropTypes.oneOfType([ PropTypes.element, PropTypes.string, PropTypes.node ]).isRequired,
    loading: PropTypes.bool.isRequired,
    clickHandler: PropTypes.func.isRequired
  };

export default ProgressButton;