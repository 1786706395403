/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Manzia components
import DeleteButton from "layouts/train/delete-product";

// Actions
import { productActions } from "appSettings";

function ActionCell({ icons, payload }) {
  
  const navigate = useNavigate();

  function clickHandler(action) {
    
    switch(action) {
      case productActions.edit:
        navigate(`/products/${payload?.id}`);
        break;
      case productActions.delete:
        break;
      case productActions.test:
        navigate("/test-product", { state: { userProduct: payload } });
        break;
      default:
        break;
    }
  }

  return (
      <MDBox mx={1} display="flex" alignItems="center" justifyContent="center">
        {icons.map(({ icon, color, action }) => 
          action === productActions.delete ? 
          (
            <DeleteButton 
              key={icon} 
              sx={{ marginRight: "2px" }} 
              variant="outlined" 
              color={color} 
              size="medium" 
              iconOnly
              userProduct={payload}
            >
              <Tooltip title={action} placement="top">
                <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
              </Tooltip>
            </DeleteButton>
          ) : (
            <Tooltip key={icon} title={action} placement="top">
              <MDButton 
                 
                sx={{ marginRight: "2px" }} 
                variant="outlined" 
                color={color} 
                size="medium" 
                iconOnly
                onClick={() => clickHandler(action)}
              >
                <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
              </MDButton>
            </Tooltip>
          ))}
      </MDBox>
  );
}


// Typechecking props for the StatusCell
ActionCell.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    color: PropTypes.string,
    action: PropTypes.string,
  })).isRequired,
  payload: PropTypes.shape({
    id: PropTypes.string,
    createdByUserId: PropTypes.string,
    trainedChatBotId: PropTypes.string
  }).isRequired
};

export default ActionCell;
