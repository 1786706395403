/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useCallback, useContext, useRef } from "react";
import { useParams } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// import Icon from "@mui/material/Icon";
// import CircularProgress from '@mui/material/CircularProgress';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// GraphQL
import { gql, useQuery, useMutation } from '@apollo/client';
import * as queries from 'graphql/queries';

// UUID
// import { v4 as uuidv4 } from 'uuid';

// Auth
import { AuthUserContext } from "App";

// NewUser page components
import FitInfo from "layouts/train/new-product/components/FitInfo";
import Features from "layouts/train/new-product/components/Features";
import SocialProof from "layouts/train/new-product/components/SocialProof";
import ProductInfo from "layouts/train/new-product/components/ProductInfo";
// import ProductOptions from "layouts/train/new-product/components/ProductOptions";
// import ProductPricing from "layouts/train/new-product/components/ProductPricing";
import ProductInsights from "layouts/train/new-product/components/Insights";
import ProgressButton from "layouts/common/ProgressButton";

// Hooks
// import useRenderCount from "customhooks/useRenderCount";
import useLocalUserProducts from "customhooks/useLocalUserProducts";
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// NewUser layout schemas for form and form feilds
import validations from "layouts/train/new-product/schemas/validations";
import form from "layouts/train/new-product/schemas/form";
import originalValues from "layouts/train/new-product/schemas/initialValues";

// State Views
import LoadView from "layouts/common/LoadView";
// import ErrorView from "layouts/common/ErrorView";
import NoDataView from "layouts/common/NoDataView";
import NotificationView from "layouts/common/Notification";

// Data
import currencyOptions from "layouts/train/new-product/options/currency";
import productTypes from "layouts/train/new-product/options/productCategories";
import formSteps from "layouts/train/new-product/options/formSteps";

// Utilities
import moment from "moment";
import { deepMerge, UUID_REGEX } from "helpers";

// Constants
// const USERID = "20966bf1-2054-49ca-9328-7f43b8f8c65b";

// const FIELD_REGEX = /^\w+\.\d+$/;
const FIELD_REGEX = /^\w+\.\w+$/;

/**
 * Note that on each Product Update
 * 1- Update the ManziaUserProduct table
 * 2- Insert training data as new entry in ManziaProductTraining
 * 3- Set a deleteTime for 90 days later for existing TrainingData
 */
const UPDATE_USERPRODUCT = `mutation userProduct($productId: ID!, $productName: String!,
  $productImage: String, $productType: String!,$formStates: AWSJSON!, 
  $lastModifiedAt: AWSDateTime!, $trainingId: ID!, $deleteTime: Int,
  $trainedByUserId: ID!, $trainedChatBotId: ID! ) { 
  a1: updateManziaUserProduct(input: {
      id: $productId,
      productName: $productName,
      productImage: $productImage,
      productType: $productType,
  }) {
    id
    productName
    productImage
    productType
    createdByUserId
    trainedChatBotId
    trainingStatus
    deleteTime
    createdAt
    updatedAt
    __typename
  },
  a2: createManziaProductTraining(input: {
      userProductId: $productId,
      trainingData: $formStates,
      lastModifiedAt: $lastModifiedAt,
      trainingStatus: "active",
      trainedByUserId: $trainedByUserId,
      trainedChatBotId: $trainedChatBotId
  }) {
    id
    userProductId
    trainingData
    lastModifiedAt
    trainingStatus
    botDeploymentId
    trainedByUserId
    trainedChatBotId
    deleteTime
    createdAt
    updatedAt
    __typename
  },
  a3: updateManziaProductTraining(input: {
      id: $trainingId,
      deleteTime: $deleteTime,
      trainingStatus: "inactive"
  }) {
    id
    userProductId
    trainingData
    lastModifiedAt
    botDeploymentId
    trainedByUserId
    trainedChatBotId
    trainingStatus
    deleteTime
    createdAt
    updatedAt
    __typename
  }
}`;

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <ProductInfo formData={formData} />;
    case 1:
      return <ProductInsights formData={formData} />;
    case 2:
      return <FitInfo formData={formData} />;
    case 3:
      return <Features formData={formData} />;
    // case 4:
    //   return <ProductPricing formData={formData} />;
    case 4:
      return <SocialProof formData={formData} />;
    
    default:
      return null;
  }
}

function formStateFromProductTraining({ trainingData }) {
  const productData = JSON.parse(trainingData);
  const initialValues = deepMerge(originalValues, productData);

  const { formField: { currency, productType, 
    options: { optionCurrency, optionType, optionDescription }}} = form;
  
  const initialFields = {
    info: [{
      [currency.name]: productData[currency.name] ? productData[currency.name] : currencyOptions[0],
      [productType.name]: productData[productType.name] ? productData[productType.name] : productTypes[0].title,
    }],
    options: [{
      [optionCurrency.name]: currencyOptions[0],
      [optionType.name]: productTypes[0],
      [optionDescription.name]: "<p>[productName] is a ...</p><br><br><br><br>" ,
    }]
  };

  return {  initialFields, initialValues };
}

function initialFieldStates() {
  const { formField: { currency, productType }} = form;
  return {
    info: [{
      [currency.name]: currencyOptions[0],
      [productType.name]: productTypes[0].title,
    }]
  }
}

function isDirtyFieldStates(initialFields, fieldStates) {
  const initialStr = JSON.stringify(initialFields);
  const fieldStr = JSON.stringify(fieldStates);
  return initialStr !== fieldStr;
}

function EditProductData() {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;

  // UserProduct
  const { productId } = useParams();
  const { userProducts } = useLocalUserProducts({ userId: USERID });
  const editProductId = UUID_REGEX.test(productId) ? productId : userProducts[0].id;

  // console.log(`ProductId: ${productId}, editProductId: ${editProductId}`);

  // State
  const [submitted, setSubmitted] = useState(false);

  // Data 
  const { loading, error, data } = useQuery(gql`${queries.manziaProductTrainingsByUserProductIdAndLastModifiedAt}`, {
    variables: { userProductId: editProductId, limit: 10, 
      sortDirection: 'DESC', filter: { trainingStatus: {eq: "active"} } }, fetchPolicy: "cache-and-network"
  });

  const updateSubmit = useCallback((submit) => setSubmitted(submit), [submitted]);

  if (loading) return (
        <LoadView />
    )
  if (error) return (
    <NotificationView 
      color="error"
      message="Failed to fetch product data. Please retry later."
      route={{ text: "Back Home", path: "/products" }}
    />
      // <ErrorView error={{ message: `${error}` }} />
  )
  if (data?.manziaProductTrainingsByUserProductIdAndLastModifiedAt?.items.length === 0) 
    return (
        <NoDataView 
          info={{ title: "No Products Added", message: `No product data has been added to your chatbot. Click below to add product data.`}}
          action={{ route: "/new-product", label: "Add product" }}
        />
    );

  const productTraining = data.manziaProductTrainingsByUserProductIdAndLastModifiedAt.items.map((item) => {
    const { id, userProductId, trainingData, lastModifiedAt, deleteTime, trainingStatus } = item;
    return { id, userProductId, trainingData, lastModifiedAt, deleteTime, trainingStatus };
  });

  return (
    <EditProduct 
      productTraining={productTraining[0]}
      updateSubmit={updateSubmit} 
    />  
  );
}

function EditProduct({ productTraining, updateSubmit }) {
  
  // State
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { chatbot: { id: chatbotId } } = useLocalChatbotUser({ userId: USERID });
  const { initialFields, initialValues } = formStateFromProductTraining(productTraining);
  const [activeStep, setActiveStep] = useState(0);
  const [fieldStates, setFieldStates] = useState(initialFields);
  const { formId, formField } = form;
  const saveChangesRef = useRef(false);

  const [updateProduct, { loading, error, data }] = useMutation(gql`${UPDATE_USERPRODUCT}`, {
    refetchQueries: [ "GetManziaUser" ]
  });
  
  const handleFieldChanges = useCallback((fieldName, fieldValue, index = 0, operation = "update") => {

    if (!FIELD_REGEX.test(fieldName)) {
      // console.error(`Invalid fieldName provided: ${fieldName}`);
      return;
    }

    const fields = { ...fieldStates};
    const [ step, field ] = fieldName.split(".");

    if (!Array.isArray(fields[step])) {
      // console.error(`FieldName: ${fieldName} provided starts with unknown step: ${step}`);
      return;
    }

    let initials;

    switch (operation) {
      case 'insert':
        initials = initialFieldStates();
        fields[step].push(initials[step][0]);
        setFieldStates(fields);
        break;
      case 'update':
        fields[step][index] = { ...fields[step][index], [field]: fieldValue };
        setFieldStates(fields);
        break;
      case 'delete':
        fields[step].splice(index, 1);
        setFieldStates(fields);
        break;
      default:
        break;
    }
  }, [fieldStates]);

  const steps = formSteps;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const handleBack = useCallback(() => setActiveStep(activeStep - 1), [activeStep]);

  function submitEditForm (values, actions) {
  
    // Upload
    const { productName, productType } = values;
    const now = moment();

    // deleteTime: now.add(90, 'days').unix(),
    updateProduct({
      variables: { 
        productId: productTraining.userProductId, 
        productName, 
        productType,
        formStates: JSON.stringify(values),
        lastModifiedAt: now.toISOString(),
        trainingId: productTraining.id,
        trainedByUserId: USERID,
        trainedChatBotId: chatbotId
      }
    });

    actions.setSubmitting(false);
   
    // actions.resetForm({ values });

    setActiveStep(0);
  };

  const mergeStateValues = (values, states) => {
    // Add basic info
    const { info, options = [] } = states;
    const { options: valuesOptions = [] } = values; 
    const fieldValues = { ...values, ...info[0] };
    fieldValues.options = valuesOptions.map((elem, idx) => ( { ...elem, ...options[idx] } ))
    return fieldValues;
  };


  const handleSubmit = useCallback((values, actions) => {
    if (isLastStep || saveChangesRef.current === true) {
      // console.log("Called handleSubmit...");
      saveChangesRef.current = false;
      const formState = mergeStateValues(values, fieldStates);
      submitEditForm(formState, actions);
      updateSubmit(true);
      // isLastStep = activeStep === steps.length - 1;
      
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }, [fieldStates, activeStep]);

  // const renderCount = useRenderCount();

  // if (data) console.log("Submitted data: ", data);

  return (
      <MDBox py={3} mb={20} height="85vh">
        {error && <NotificationView 
          color="error"
          message="Failed to submit edited product data. Please retry later."
          route={{ text: "Back Home", path: "/products" }}
        />}
        {data && <NotificationView 
          color="success"
          message="Successfully submitted edited product data."
          route={{ text: "Back Home", path: "/products" }}
        />}
        
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, isValid, submitForm, dirty, setFieldValue }) => {
                // console.log("Form values", values);
                // console.log("Field states: ", fieldStates)
                // console.log("Render count:", renderCount);
                // console.log("Errors: ", errors);
                // if (isValid) console.log("Form is valid");
                const isDirty = dirty || isDirtyFieldStates(initialFields, fieldStates);
                // console.log(`loading: ${loading} dirty: ${dirty} and isDirty: ${isDirty}`);

                return (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    
                    <MDBox px={3}>
                      {!isLastStep && <MDBox sx={{ display: 'flex', justifyContent: 'center' }} my={2}>
                        <ProgressButton 
                            variant="outlined"
                            color="dark" 
                            clickHandler={() => {
                              saveChangesRef.current = true;
                              submitForm()
                                .then(result => `${result}`)
                                .catch(err => `${err}`);
                            }}
                            loading={loading || isSubmitting}
                            disabled={loading || isSubmitting || !isValid}
                          >
                            Save step
                        </ProgressButton>
                      </MDBox>}
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                          setFieldValue,
                          fieldStates, 
                          handleValues: handleFieldChanges}
                        )}

                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDBox>
                              <MDButton variant="outlined" color="dark" onClick={handleBack}>
                                back
                              </MDButton>
                            </MDBox>
                            
                          )}
                          <MDBox>
                            {/* {loading && <CircularProgress /> } */}
                            <ProgressButton 
                                variant="gradient"
                                color="info" 
                                clickHandler={submitForm}
                                loading={loading || isSubmitting}
                                disabled={loading || isSubmitting || (isLastStep && (!isValid || !isDirty))}
                              >
                                {isLastStep ? "Save product" : "next"}
                            </ProgressButton>
                            
                          </MDBox>
                          
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
  );
}

// Typechecking props for the StatusCell
EditProduct.propTypes = {
  productTraining: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userProductId: PropTypes.string.isRequired,
    trainingData: PropTypes.string.isRequired,
  }).isRequired,
  updateSubmit: PropTypes.func.isRequired
};

export default EditProductData;
