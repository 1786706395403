/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

// Material UI
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";


function MDPassword({ type, ...rest }) {
  const [passType, setPassType] = useState(type);

  const handleClickShowPassword = () => setPassType((prevType) => prevType === 'password' ? 'text' : 'password');

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <MDInput
      {...rest}
      type={passType}
      InputProps={{
        endAdornment: 
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {passType === 'text' ? <VisibilityOff /> : <Visibility />}
          </IconButton>
      }}
    />
  )
}

function PasswordField({ label, name, variant, type, ...rest }) {
  return (
    <MDBox mb={1.5}>
        <Field 
          {...rest} 
          name={name}
          type={type}
          as={MDPassword} 
          variant={variant} 
          label={label} 
          fullWidth 
        />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Specifies the default values for props:
PasswordField.defaultProps = {
  variant: 'outlined',
};

MDPassword.propTypes = {
  type: PropTypes.oneOf(["password"]).isRequired,
};

// typechecking props for FormField
PasswordField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  type: PropTypes.oneOf(["password"]).isRequired
};

export default PasswordField;
