/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useCallback } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

// React Color
import { SketchPicker } from 'react-color';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Hooks
import usePrevious from "customhooks/usePrevious";

function ColorPickerField({ name, label, initialColor, ...rest }) {
  
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(initialColor);
  const prevDisplay = usePrevious(displayColorPicker);

  // Toggle color picker
  const handleClick = () => setDisplayColorPicker(!prevDisplay);

  const handleClose = () => setDisplayColorPicker(false);

  const handleChange = useCallback((selectedColor) => setColor(selectedColor.hex), []);

  return (
    <MDBox>
      <MDBox display='flex' flexDirection='column' alignItems='flex-start'>
        <MDTypography
          component="label"
          variant="button"
          fontWeight="medium"
          color="text"
          mb={1}
        >
          {label}
        </MDTypography>
        <MDBox
          sx={{
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer'
          }}
          onClick={handleClick}
        >
          <MDBox sx={{ width: '48px', height: '24px', borderRadius: '2px', background: color }} />
        </MDBox>
      </MDBox>
      
      {displayColorPicker && <MDBox sx={{ position: 'absolute', zIndex: '2' }}>
          <MDBox 
            sx={{ 
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px'
            }}
            onClick={handleClose}
          />
          <ColorField 
            name={name}
            label={label}
            handleChange={handleChange}
            {...rest}
          />
      </MDBox>}
    </MDBox>
  );
  
}

function ColorField({ label, name: fieldName, handleChange, ...rest }) {
  return (
    <MDBox mb={1.5}>
      <Field 
        {...rest} 
        name={fieldName} 
        label={label} 
        fullWidth 
      >
        {({
          field: { value },
          form: { setFieldValue } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        }) => (
          <SketchPicker
            color={value}
            onChangeComplete={(color) => {
              // console.log(`Field name: ${fieldName}, field value: ${color.hex}`)
              setFieldValue(fieldName, color.hex);
              handleChange(color);
            }}
          />
        )}
      </Field>
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={fieldName} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for FormField
ColorPickerField.defaultProps = {
  initialColor: '#000000'
};

ColorPickerField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  initialColor: PropTypes.string
};

ColorField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default ColorPickerField;
