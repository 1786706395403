/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext } from "react";

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
// import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
// import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// Manzia
import DefaultLineChart from "layouts/pricing/components/DefaultLineChart";
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import { ManziaBillingInfo } from "appSettings";

// Custom Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// Data
// import defaultLineChartData from "layouts/pricing/components/Conversations/data/defaultLineChartData";
import { getLineChartData } from "layouts/pricing/components/Conversations/data/defaultLineChartData";

function getPeriod(label = ManziaBillingInfo.defaultPeriod.label) {
  const found = ManziaBillingInfo.periods.find(elem => elem.label === label);
  return found ? found.interval : ManziaBillingInfo.defaultPeriod.interval;
}

function Conversations() {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { account: { id: accountId } } = useLocalChatbotUser({ userId: USERID });

  // state for the dropdown value
  const [dropdownValue, setDropdownValue] = useState(ManziaBillingInfo.defaultPeriod.label);

  // state for the dropdown action
  const [dropdown, setDropdown] = useState(null);

  const { loading, error, data } = useQuery(gql`${queries.getManziaConversations}`, {
    variables: {
      accountId,
      period: getPeriod(dropdownValue)
    }, fetchPolicy: 'network-only'
  });

  // handler for the dropdown action
  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = ({ currentTarget }) => {
    setDropdown(null);
    setDropdownValue(currentTarget.innerText || dropdownValue);
  };

  if (loading) {
    return (
      <MDBox py={3}>
       <MDBox mb={3}>
        <LoadView />
       </MDBox>
      </MDBox>
    );
  }

  if (error) {
    return (
      <MDBox py={3}>
       <MDBox mb={3}>
        <ErrorView error={{ message: "Error retrieving conversation data. Try again later."}} />
       </MDBox>
      </MDBox>
    );
  }

  // Dropdown menu
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      {ManziaBillingInfo.periods.map(item => (
        <MenuItem key={item.interval} onClick={close}>
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    
    <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DefaultLineChart
                title="Conversations"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="info" size="sm" badgeContent={`Billed conversations - ${dropdownValue}`} />
                    </MDBox>
                    <MDBox mt={-4} mr={-1} position="absolute" right="2rem">
                      
                      <MDBox width="100%" textAlign="right" lineHeight={2}>
                        <MDTypography
                          variant="caption"
                          color="secondary"
                          fontWeight="regular"
                          sx={{ cursor: "pointer" }}
                          onClick={openDropdown}
                        >
                          {dropdownValue}
                        </MDTypography>
                        {renderMenu(dropdown, closeDropdown)}
                      </MDBox>
                    
                    </MDBox>
                  </MDBox>
                }
                chart={getLineChartData(data, getPeriod(dropdownValue))}
              />
            </Grid>
            
          </Grid>
        </MDBox>
    </MDBox>
    
  );
}

export default Conversations;
