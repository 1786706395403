
import PropTypes from "prop-types";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";


function RadioButtons({ name, title, value, handleChange, radioList }) {
  
  const radios = radioList.map((item) => {
    const { id, value: itemValue, label } = item;
    return (
      <FormControlLabel
        key={id} 
        value={itemValue}
        control={<Radio />} 
        label={<MDTypography variant="body2" color="text" fontWeight="medium">{label}</MDTypography>} 
      />
    );
  });

  return (
    <FormControl>
      <FormLabel id={name}><MDTypography variant="body2" color="text" fontWeight="regular">{title}</MDTypography></FormLabel>
      <RadioGroup
        aria-labelledby={name}
        name={name}
        value={value}
        onChange={(e) => handleChange({ [name]: e.target.value })}
      >
        {radios}
      </RadioGroup>
    </FormControl>
  );
}

RadioButtons.defaultProps = {
  title: ""
};

// typechecking props for FormField
RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  radioList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired
};

export default RadioButtons