/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import * as Yup from "yup";
import form from "layouts/settings/schemas/form";
import { HEX_COLOR_REGEX, AWS_PASSWORD_REGEX } from "helpers";
import emailFilters from "layouts/settings/components/EmailConversation/emailFilters";


const {
  formField: { toolbar, messagelistcontainer, messagebubble, cardtitle, cardactions },
  changePassword, botSettings, conversations
} = form;

const validations = 
  Yup.object().shape({
    [toolbar.themedark.backgroundColor.name]: Yup.string().matches(HEX_COLOR_REGEX, toolbar.themedark.backgroundColor.invalidMsg).required(toolbar.themedark.backgroundColor.errorMsg),
    [toolbar.title.fontFamily.name]: Yup.string().min(2, toolbar.title.fontFamily.invalidMsg).required(toolbar.title.fontFamily.errorMsg),
    [toolbar.title.fontSize.name]: Yup.number().min(0, toolbar.title.fontSize.invalidMsg).required(toolbar.title.fontSize.errorMsg),
    [toolbar.title.color.name]: Yup.string().matches(HEX_COLOR_REGEX, toolbar.title.color.invalidMsg).required(toolbar.title.color.errorMsg),
    [messagelistcontainer.backgroundColor.name]: Yup.string().matches(HEX_COLOR_REGEX, messagelistcontainer.backgroundColor.invalidMsg).required(messagelistcontainer.backgroundColor.errorMsg),
    [messagebubble.messagebot.backgroundColor.name]: Yup.string().matches(HEX_COLOR_REGEX, messagebubble.messagebot.backgroundColor.invalidMsg).required(messagebubble.messagebot.backgroundColor.errorMsg),
    [messagebubble.messagehuman.backgroundColor.name]: Yup.string().matches(HEX_COLOR_REGEX, messagebubble.messagehuman.backgroundColor.invalidMsg).required(messagebubble.messagehuman.backgroundColor.errorMsg),
    [messagebubble.messagetext.color.name]: Yup.string().matches(HEX_COLOR_REGEX, messagebubble.messagetext.color.invalidMsg).required(messagebubble.messagetext.color.errorMsg),
    [messagebubble.messagetext.fontSize.name]: Yup.number().min(0, messagebubble.messagetext.fontSize.invalidMsg).required(messagebubble.messagetext.fontSize.errorMsg),
    [cardtitle.cardtitleprimary.backgroundColor.name]: Yup.string().matches(HEX_COLOR_REGEX, cardtitle.cardtitleprimary.backgroundColor.invalidMsg).required(cardtitle.cardtitleprimary.backgroundColor.errorMsg),
    [cardactions.btn.backgroundColor.name]: Yup.string().matches(HEX_COLOR_REGEX, cardactions.btn.backgroundColor.invalidMsg).required(cardactions.btn.backgroundColor.errorMsg),
  });

export const changePasswordValidations = 
  Yup.object().shape({
    [changePassword.currentPassword.name]: Yup.string().required(changePassword.currentPassword.errorMsg).matches(AWS_PASSWORD_REGEX, changePassword.currentPassword.invalidMsg),
    [changePassword.newPassword.name]: Yup.string().required(changePassword.newPassword.errorMsg).matches(AWS_PASSWORD_REGEX, changePassword.newPassword.invalidMsg),
    [changePassword.confirmPassword.name]: Yup.string()
      .required(changePassword.confirmPassword.errorMsg)
      .matches(AWS_PASSWORD_REGEX, changePassword.confirmPassword.invalidMsg)
      .oneOf([Yup.ref("newPassword"), null], "Passwords do not match."),
  });

export const botSettingValidations = 
  Yup.object().shape({
    [botSettings.displayFrequency.name]: Yup.number().min(1, botSettings.displayFrequency.invalidMsg).max(365, botSettings.displayFrequency.invalidMsg).required(botSettings.displayFrequency.errorMsg),
  });

export const conversationsValidations = 
  Yup.object().shape({
    [conversations.emailFilters.name]: Yup.string().oneOf(emailFilters.map(item => item.value), conversations.emailFilters.invalidMsg).default(emailFilters[0].value), 
    [conversations.conversationEmails.name]: Yup.array().of(Yup.string().email(conversations.conversationEmails.invalidEmailMsg).required(conversations.conversationEmails.errorMsg)).min(1, conversations.conversationEmails.invalidMsg).max(10, conversations.conversationEmails.invalidMsg), 
  });

export default validations;
