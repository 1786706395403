/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

import { Outlet } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
// import Sidenav from "layouts/settings/components/Sidenav";
import NestedList from "layouts/settings/components/NestedList";

function Settings() {
  return (
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            {/* <Sidenav /> */}
            <NestedList />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
                <Outlet />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
  );
}

export default Settings;
