/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useCallback, useContext, useRef, useEffect } from "react";

// Apollo
import { gql, useMutation } from '@apollo/client';

// formik components
import { Formik, Form } from "formik";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// import CircularProgress from '@mui/material/CircularProgress';
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// GraphQL
// import * as mutations from 'graphql/mutations';

// UUID
import { v4 as uuidv4 } from 'uuid';

// Auth
import { AuthUserContext } from "App";

// Common components
import NotificationView from "layouts/common/Notification";
import LoadView from "layouts/common/LoadView";

// NewUser page components
import FitInfo from "layouts/train/new-product/components/FitInfo";
import Features from "layouts/train/new-product/components/Features";
import SocialProof from "layouts/train/new-product/components/SocialProof";
import ProductInfo from "layouts/train/new-product/components/ProductInfo";
// import ProductOptions from "layouts/train/new-product/components/ProductOptions";
// import ProductPricing from "layouts/train/new-product/components/ProductPricing";
import ProductInsights from "layouts/train/new-product/components/Insights";
import ProgressButton from "layouts/common/ProgressButton";

// Hooks
// import useRenderCount from "customhooks/useRenderCount";
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";


// NewUser layout schemas for form and form feilds
import validations from "layouts/train/new-product/schemas/validations";
import form from "layouts/train/new-product/schemas/form";
import initialValues from "layouts/train/new-product/schemas/initialValues";

// Data
import currencyOptions from "layouts/train/new-product/options/currency";
import productTypes from "layouts/train/new-product/options/productCategories";
import formSteps from "layouts/train/new-product/options/formSteps";

// Utilities
import moment from "moment";

// Constants
// const USERID = "20966bf1-2054-49ca-9328-7f43b8f8c65b";

// const FIELD_REGEX = /^\w+\.\d+$/;
const FIELD_REGEX = /^\w+\.\w+$/;

const ADD_USERPRODUCT = `mutation userProduct($productId: ID!, $productName: String!,
  $productImage: String, $productType: String!, $createdByUserId: ID!,
  $trainedChatBotId: ID!, $formStates: AWSJSON!, $lastModifiedAt: AWSDateTime!,
  $trainingStatus: String, $trainedByUserId: ID! ) { 
  a1: createManziaUserProduct(input: {
      id: $productId,
      productName: $productName,
      productImage: $productImage,
      productType: $productType,
      createdByUserId: $createdByUserId,
      trainedChatBotId: $trainedChatBotId
  }) {
    id
    productName
    productImage
    productType
    createdByUserId
    trainedChatBotId
    trainingStatus
    createdAt
    updatedAt
    deleteTime
  },
  a2: createManziaProductTraining(input: {
      userProductId: $productId,
      trainedByUserId: $trainedByUserId,
      trainedChatBotId: $trainedChatBotId,
      trainingData: $formStates,
      lastModifiedAt: $lastModifiedAt,
      trainingStatus: $trainingStatus
  }) {
    id
    userProductId
    trainingData
    lastModifiedAt
    botDeploymentId
    trainedByUserId
    trainedChatBotId
    trainingStatus
    deleteTime
    createdAt
    updatedAt
  },
  a3: createManziaIntentExample(input: {
    botId: $trainedChatBotId,
    userProductIntentId: $productId,
    userId: $createdByUserId
  }) {
    botId
    userProductIntentId
    intentId
    userId
    deleteTime
    createdAt
    updatedAt
    __typename
  }
}`;

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <ProductInfo formData={formData} />;
    case 1:
      return <ProductInsights formData={formData} />;
    case 2:
      return <FitInfo formData={formData} />;
    case 3:
      return <Features formData={formData} />;
    // case 4:
    //   return <ProductPricing formData={formData} />;
    case 4:
      return <SocialProof formData={formData} />;
    
    default:
      return null;
  }

}

function initialFieldStates() {
  const { formField: { currency, productType }} = form;
  return {
    info: [{
      [currency.name]: currencyOptions[0],
      [productType.name]: productTypes[0].title,
    }]
  }
}

function NewProduct() {
  // State
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const [activeStep, setActiveStep] = useState(0);
  const [fieldStates, setFieldStates] = useState(initialFieldStates());
  const { formId, formField } = form;
  const { chatbot } = useLocalChatbotUser({ userId: USERID });
  const saveChangesRef = useRef(false);
  const productId = useRef(uuidv4());

  // Navigation
  const navigate = useNavigate();

  const [createProduct, { data, loading, error }] = useMutation(gql`${ADD_USERPRODUCT}`, {
    refetchQueries: [ "manziaUserProductsByTrainedChatBotId", "GetManziaUser" ]
  });

  useEffect(() => {
    if (!data) return;
    
    if (saveChangesRef.current === true) {
      // Navigate to Edit view
      navigate(`/products/${data.a1.id}`);
    }
  }, [data]);
  
  const handleFieldChanges = useCallback((fieldName, fieldValue, index = 0, operation = "update") => {

    if (!FIELD_REGEX.test(fieldName)) {
      // console.error(`Invalid fieldName provided: ${fieldName}`);
      return;
    }

    const fields = { ...fieldStates};
    const [ step, field ] = fieldName.split(".");

    if (!Array.isArray(fields[step])) {
      // console.error(`FieldName: ${fieldName} provided starts with unknown step: ${step}`);
      return;
    }

    let initials;

    switch (operation) {
      case 'insert':
        initials = initialFieldStates();
        fields[step].push(initials[step][0]);
        setFieldStates(fields);
        break;
      case 'update':
        fields[step][index] = { ...fields[step][index], [field]: fieldValue };
        setFieldStates(fields);
        break;
      case 'delete':
        fields[step].splice(index, 1);
        setFieldStates(fields);
        break;
      default:
        break;
    }
  }, [fieldStates]);

  const steps = formSteps;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const handleBack = useCallback(() => setActiveStep(activeStep - 1), [activeStep]);

  function submitFormValues (values, actions) {
    
    // Upload
    createProduct({
      variables: { 
        productId: productId.current, 
        productName: values[formField.productName.name], 
        productType: values[formField.productType.name], 
        createdByUserId: USERID,
        trainedChatBotId: chatbot.id,
        formStates: JSON.stringify(values),
        lastModifiedAt: moment().toISOString(),
        trainingStatus: "active",
        trainedByUserId: USERID
      }
    });

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const mergeStateValues = (values, states) => {
    // Add basic info
    const { info, options = [] } = states;
    const { options: valuesOptions = [] } = values; 
    const fieldValues = { ...values, ...info[0] };
    fieldValues.options = valuesOptions.map((elem, idx) => ( { ...elem, ...options[idx] } ))
    return fieldValues;
  };

  const handleSubmit = useCallback((values, actions) => {
    if (isLastStep || saveChangesRef.current === true) {
      // saveChangesRef.current = false;
      const formState = mergeStateValues(values, fieldStates);
      submitFormValues(formState, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }, [fieldStates, activeStep]);

  // const renderCount = useRenderCount();

  // if (data) console.log("Submitted data: ", data);
  
  return (
      <MDBox py={3} mb={20} height="85vh">
        {error && <NotificationView 
          color="error"
          message="Failed to add product data to bot. Please retry later."
          route={{ text: "Back Home", path: "/products" }}
        />}
        {(data && saveChangesRef.current !== true) && <NotificationView 
          color="success"
          message="Successfully added product data to bot."
          route={{ text: "Back Home", path: "/products" }}
        />}
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, isValid, submitForm, setFieldValue }) => 
                (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      {!isLastStep && <MDBox display="flex" justifyContent="center" my={2}>
                          <ProgressButton 
                            variant="outlined"
                            color="dark" 
                            clickHandler={() => {
                              saveChangesRef.current = true;
                              submitForm()
                                .then(result => `${result}`)
                                .catch(err => `${err}`);
                            }}
                            loading={isSubmitting}
                            disabled={isSubmitting || !isValid || values[formField.productName.name]?.length < 2}
                          >
                            <Icon>save</Icon>&nbsp; Save step
                          </ProgressButton>
                      </MDBox>}
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                          fieldStates,
                          setFieldValue, 
                          handleValues: handleFieldChanges}
                        )}

                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDBox>
                              <MDButton 
                                variant="outlined" 
                                color="dark" 
                                onClick={handleBack}
                                disabled={loading || isSubmitting}
                              >
                                back
                              </MDButton>
                            </MDBox>
                          )}
                          <MDBox>
                            <MDBox mr={2}>
                              {(loading || isSubmitting) && <LoadView loadingMessage="" /> }
                            </MDBox>
                            <MDButton
                              disabled={isSubmitting || (isLastStep && !isValid) || loading}
                              type="submit"
                              variant="gradient"
                              color="info"
                            >
                              {isLastStep ? "save product" : "next"}
                            </MDButton>
                          </MDBox>
                          
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
  );
}

export default NewProduct;
