/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext, useEffect, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// GraphQL
import { gql, useMutation, useQuery } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';

// Material Dashboard
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Manzia
import NotificationView from 'layouts/common/Notification';
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import useLocalChatbotUser from 'customhooks/useLocalChatbotUser';
import { SubscriptionStatus, MANZIA_BILL_RATE } from "appSettings";
import ExpandAccordion from "layouts/train/new-product/components/Expand";

// Auth
import { AuthUserContext } from "App";

// Yup
// import * as Yup from "yup";

// Utterances
import statements from "layouts/common/Subscribe/statements";

// Utils
import moment from "moment";

// Constants
const MIN_LIMIT = 10.00;

function SubscribeButton({ children, checkoutHandler,
    currentLimit, subscriptionStatus, ...rest }) {
    
    // User
    const authUser = useContext(AuthUserContext);
    const USERID = authUser?.sub;
    const { account: { id: accountId, billRate = MANZIA_BILL_RATE, currency } } = useLocalChatbotUser({ userId: USERID });
    const [open, setOpen] = useState(false);
    const [billingLimit, setBillingLimit] = useState("100.00");
    const [billingLimitState, setBillingLimitState] = useState(false);
    const [usage, setUsage] = useState("0.00");
    const billingLimitFloat = useRef(Number.parseFloat(billingLimit));
    const increaseLimit =  useRef(billingLimitFloat.current - (currentLimit ?? 0));

    // Validation
    // const validSchema = useMemo(() => Yup.number().min(Math.max(10.00, Number.parseFloat(usage))).required().positive(), [usage]);

    // Update Stripe checkout table
    const [updateCheckout, { data: updateCheckoutData, loading: updateCheckoutLoading, 
        error: updateCheckoutError }] = useMutation(gql`${mutations.updateManziaStripeCheckout}`);
    
    // Get conversations for current billing cycle
    const { loading: convLoading, error: convError, data: convData } = useQuery(gql`${queries.getManziaConversations}`, {
        variables: {
            accountId,
            period: `${moment().date()}day`
        }, fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        billingLimitFloat.current = Number.parseFloat(billingLimit);
        increaseLimit.current = billingLimitFloat.current - (currentLimit ?? 0);
    }, [billingLimit]);
    
    useEffect(() => {
        if (!convData) return;
        let jsonData = [];
        try {
            jsonData = JSON.parse(convData.getManziaConversations);
            if (typeof jsonData === 'string') {
                jsonData = JSON.parse(jsonData);
                const totalCount = jsonData.reduce(
                    (accumulator, currentValue) => accumulator + currentValue.session_counts, 0);
                setUsage((totalCount * billRate).toFixed(2));
            }
        } catch (_) {
            // console.log("Failed to parse conversation data: ", error);
        }
    }, [convData]);
    
    const handleClickOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        const limitValue = e.target.value;
        const limitFloat = Number.parseFloat(limitValue);
        const limitIncrease =  limitFloat - (currentLimit ?? 0);

        // console.log(`Limit value: ${limitValue}, currentLimit: ${currentLimit}, billingLimitFloat: ${limitFloat}, increaseLimit: ${limitIncrease}`);
        
        setBillingLimit(limitValue);

        if (Number.isNaN(limitFloat) || limitFloat < MIN_LIMIT) {
            setBillingLimitState(true);
            return;
        }
        setBillingLimitState(limitIncrease < 2);
    }

    const handleCheckout = () => {
        const newLimit = Number.parseFloat(billingLimit);
        let increase = newLimit;
        if (currentLimit) { increase = Math.max(0, (newLimit - currentLimit)); }

        // Spending limit has succeeded
        if (increase === 0 && updateCheckoutData) { handleClose(); }
        
        switch (subscriptionStatus) {
            case SubscriptionStatus.active:
                if (increase > 0) {
                    // Go to checkout to pay increase
                    checkoutHandler(newLimit);
                    handleClose();
                } else {
                    // Update billingLimit
                    updateCheckout({
                        variables: {
                          input: {
                            accountId,
                            billingLimit: newLimit
                          }
                        },
                        refetchQueries: [ "GetManziaStripeCheckout" ]
                    });
                }
                break;
            default:
                // Go to checkout to pay or resume/create subscription
                checkoutHandler(newLimit);
                handleClose();
        }
    };

    const subscriptionMessage = () => {
        const newLimit = Number.parseFloat(billingLimit);
        let increase = newLimit;
        if (currentLimit) { increase = Math.max(0, newLimit - currentLimit); }
        
        switch (subscriptionStatus) {
            case SubscriptionStatus.active:
                if (increase === 0 && updateCheckoutData) {
                    return (<NotificationView 
                        color="success"
                        message="Spending limit has been successfully updated."
                    />);
                }
                return null;
            case SubscriptionStatus.canceled:
            case SubscriptionStatus.none:
                return (<NotificationView 
                    color="info"
                    message="Monthly usage-based billing will start after successful checkout."
                />);
            case SubscriptionStatus.paused:
                return (<NotificationView 
                    color="info"
                    message="Monthly usage-based billing was paused but will resume after successful checkout."
                />)
            default:
                return null;
        }
    }

    const renderStatements = statements(billRate).map((item) => 
        (
          <MDBox key={item.id} component="li" lineHeight={1}>
            <MDTypography variant="button" verticalAlign="middle" color="dark">
              {item.statement}
            </MDTypography>
          </MDBox>
        )
      );

  function getDialogContent() {
    return (
        <DialogContent>
            <DialogContentText>
                <MDTypography variant="button" color="dark">
                    Spending limit sets the maximum usage charges in a monthly billing cycle.
                </MDTypography>
            </DialogContentText>
            <MDBox>
                {(updateCheckoutLoading || convLoading) && <LoadView />}
                {updateCheckoutError || convError ? <ErrorView error={{ message: `${updateCheckoutError || convError}`}}/> : subscriptionMessage()}
            </MDBox>
            <MDBox mb={4}  mt={2}>
                <ExpandAccordion 
                    title="Spending limit requirements"
                >
                    <MDBox component="ul" m={1} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                        {renderStatements}
                    </MDBox>
                </ExpandAccordion>
            </MDBox>
            <MDBox my={2}>
                <MDBox display="flex" my={2}>
                    <MDTypography variant="body2" fontWeight="bold" color="dark">
                        Current monthly spending limit: &nbsp;
                    </MDTypography>
                    <MDTypography variant="body2" fontWeight="regular" color="dark">
                        &nbsp;{`${currency} ${(updateCheckoutData?.billingLimit ?? currentLimit ?? 0).toFixed(2)}`}
                    </MDTypography>
                </MDBox>
                <MDBox display="flex" mt={2} mb={5}>
                    <MDTypography variant="body2" fontWeight="bold" color="dark">
                        Current billing cycle usage: &nbsp;
                    </MDTypography>
                    <MDTypography variant="body2" fontWeight="regular" color="dark">
                        &nbsp;{`${currency} ${usage}`}
                    </MDTypography>
                </MDBox>
                <MDInput
                    id="billingLimit"
                    name="billingLimit"
                    label="New spending limit"
                    placeholder="100.00"
                    variant="outlined"
                    value={billingLimit}
                    onChange={handleChange}
                    fullWidth
                    error={billingLimitState}
                    InputProps={{
                        startAdornment: 
                            <InputAdornment position="start">
                                {currency}
                            </InputAdornment>
                    }}
                />
                <MDBox mt={0.75}>
                    <MDTypography variant="button" color={billingLimitState ? "error" : "dark"}>
                        {billingLimitState ? `Spending limit amount entered is invalid, or less than USD ${MIN_LIMIT} or less than current billing cycle usage.` : `Minimum spending limit amount is ${currency} ${Math.max(10.00, Number.parseFloat(usage))}`}
                    </MDTypography>
                </MDBox>
            </MDBox>
        </DialogContent>
      );
  }

  return (
    <>
        <MDButton 
            {...rest}
            variant="gradient" 
            color="info"
            onClick={handleClickOpen}
        >
           {children}
        </MDButton>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle>Set monthly spending limit:</DialogTitle>
            {getDialogContent()}
        <DialogActions>
            
            <MDButton 
                variant="outlined"
                color="dark" 
                onClick={handleClose}
                disabled={updateCheckoutLoading}
            >
                Cancel
            </MDButton>
            <MDButton 
                variant="gradient" 
                color="info" 
                disabled={billingLimitState || updateCheckoutLoading}
                onClick={handleCheckout}
            >
              Checkout
            </MDButton>
          
        </DialogActions>
      </Dialog>
    </>
  );
}

SubscribeButton.defaultProps = {
    currentLimit: 0
};

// Typechecking props for BaseLayout
SubscribeButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]).isRequired,
  checkoutHandler: PropTypes.func.isRequired,
  currentLimit: PropTypes.number,
  subscriptionStatus: PropTypes.string.isRequired
};


export default  SubscribeButton;