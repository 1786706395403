/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Pricing page components
import FaqCollapse from "layouts/pricing/components/FaqCollapse";
import FAQS from "layouts/pricing/components/Faq/faqs";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MDBox mt={8} mb={6}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <MDTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
            Frequently Asked Questions
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={10}>
          <FaqCollapse
            title={FAQS[0].question}
            open={collapse === 1}
            onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
          >
            {FAQS[0].answer}
          </FaqCollapse>
          <FaqCollapse
            title={FAQS[1].question}
            open={collapse === 2}
            onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
          >
            {FAQS[1].answer}
          </FaqCollapse>
          <FaqCollapse
            title={FAQS[2].question}
            open={collapse === 3}
            onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
          >
            {FAQS[2].answer}
          </FaqCollapse>
          <FaqCollapse
            title={FAQS[3].question}
            open={collapse === 4}
            onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
          >
            {FAQS[3].answer}
          </FaqCollapse>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Faq;
