import { useState, useEffect, useContext } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import Divider from '@mui/material/Divider';
// import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";

// Select
// import MenuItem from '@mui/material/MenuItem';

// Material Dashboard
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
// import MDTypography from 'components/MDTypography';

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// Manzia
// import useLexV2ModelClient from 'customhooks/useLexV2ModelClient';
import NotificationView from 'layouts/common/Notification';
import useLocalChatbotUser from 'customhooks/useLocalChatbotUser';
import LoadView from "layouts/common/LoadView";

// Auth
import { AuthUserContext } from "App";

// Utilities
import { getRandomArrayElement } from 'helpers';

// Utterances
import utterances from 'layouts/test/products/components/Suggestions/utterances';
import stopWords from "layouts/test/products/components/Suggestions/stopwords";

// function getMenuItems(items) {
//   return items.map(item => {
//       const { id, utterance } = item;
//       return (<MenuItem key={id} value={utterance}>{utterance}</MenuItem>);
//   });
// }

function SuggestButton({ children, onSelected }) {
    const authUser = useContext(AuthUserContext);
    const USERID = authUser?.sub;
    const { chatbot: { lexChatBotId: botId } } = useLocalChatbotUser({ userId: USERID });
    const [open, setOpen] = useState(false);
    const initial = getRandomArrayElement(utterances);
    const [commonUtterance, setCommonUtterance] = useState(initial.utterance);
    const [recentUtterances, setRecentUtterances] = useState(["None"]);
    const [recentUtterance, setRecentUtterance] = useState("None"); 

    // const { value: data, loading, error, runCommand: fetchUtterances } = useLexV2ModelClient();

    // Fetch recent utterances
    const { loading: recentLoading, error: recentError, data: recentData } = useQuery(gql`${queries.manziaUtterancesByBotIdAndTimestamp}`, {
        variables: { botId, filter: { role: {eq: "user" } }, limit: 20, sortDirection: 'DESC' }, fetchPolicy: 'cache-and-network',
    });
    
    useEffect(() => {
        if (!recentData) return;
        const { items = [] } = recentData.manziaUtterancesByBotIdAndTimestamp;
        const recents = items.filter(item => !stopWords.has(item.content.toLowerCase())).map(item => item.content);
        setRecentUtterances([ ...recents, "None" ]);
    }, [recentData])
    
    // Fetch recent utterances
    // useEffect(() => {
    //     fetchUtterances()({
    //         botId, // required
    //         botAliasId,
    //         localeId: "en_US", // required
    //         aggregationDuration: { // UtteranceAggregationDuration
    //             relativeAggregationDuration: { // RelativeAggregationDuration
    //                 timeDimension: "Weeks", // required
    //                 timeValue: Number("2"), // required
    //             },
    //         },
    //         sortBy: { // AggregatedUtterancesSortBy
    //             attribute: "HitCount", // required
    //             order: "Descending", // required
    //         },
    //         maxResults: Number("50"),
    //     });
    // }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUtterances = (name, value) => {
        switch (name) {
            case 'common-utterance':
                setCommonUtterance(value);
                setRecentUtterance("None")
                break;
            case 'recent-utterance':
                setRecentUtterance(value);
                setCommonUtterance("None");
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        const utterance = recentUtterance.length > 0 && recentUtterance !== "None" ? recentUtterance : commonUtterance;
        // console.log("Selected utterance: ", utterance);
        onSelected(utterance);
        handleClose();
    };

  function getDialogContent() {
    return (
        <DialogContent>
            <DialogContentText>
                Select a sample utterance to test.
            </DialogContentText>

            {recentLoading && <LoadView loadingMessage="" />}
            
            {recentError && <NotificationView 
                color="error"
                message="Encountered a problem fetching recent utterances."
            />}

            <MDBox my={2}>

                <Autocomplete
                    id="recent-utterance"
                    value={recentUtterance}
                    name="recent-utterance"
                    onChange={(e, value) => handleUtterances("recent-utterance", value)}
                    // options={data ? [ ...data.aggregatedUtterancesSummaries.map(item => item.utterance), "None"] : ["None"]}
                    options={recentUtterances}
                    renderInput={(params) => <MDInput {...params} label="Recent utterances" variant="standard" />}
                    fullWidth
                />
            </MDBox>
            <MDBox mb={2} mt={4}>
                <Autocomplete
                    id="common-utterance"
                    value={commonUtterance}
                    name="common-utterance"
                    onChange={(e, value) => handleUtterances("common-utterance", value)}
                    options={utterances.map(item => item.utterance)}
                    renderInput={(params) => <MDInput {...params} label="Common utterances" variant="standard" />}
                    fullWidth
                />
            </MDBox>
        </DialogContent>
      );
  }

  return (
    <div>
      <MDButton 
          variant="text" 
          color="info" 
          fullWidth 
          onClick={handleClickOpen}
          disabled={utterances.length === 0}
      >
        {children}
      </MDButton>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle>Suggested test utterances:</DialogTitle>
            {getDialogContent()}
        <DialogActions>
            
            <MDButton 
                variant="outlined"
                color="dark" 
                onClick={handleClose}
            >
                Cancel
            </MDButton>
            <MDButton 
                variant="gradient" 
                color="info" 
                disabled={commonUtterance.length === 0 && recentUtterance.length === 0}
                onClick={handleSubmit}
            >
              <Icon>add</Icon>&nbsp; Add
            </MDButton>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}

// Typechecking props for BaseLayout
SuggestButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  onSelected: PropTypes.func.isRequired
};


export default  SuggestButton;