/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";

// @mui material components
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Manzia
import BotCode from "layouts/settings/components/BotCode";

function ManualBot({ webDomain }) {

  return (
      <MDBox>
        <MDTypography display="inline" variant="body2" color="dark" fontWeight="regular">
          Add the following Chatbot code just before the closing <MDTypography display="inline" variant="body2" color="text" fontWeight="medium">{`</body>`}</MDTypography>{` `}tag on every webpage on which you want to trigger the Chatbot.
        </MDTypography>
        <Divider />
        <BotCode webDomain={webDomain} />
      </MDBox>
      
  );
}

ManualBot.propTypes = {
  webDomain: PropTypes.string.isRequired
}

export default ManualBot;
