// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";


function ErrorView({ error }) {
    return (
        <Grid container justifyContent="center" alignItems="center" my={2}>
            <Grid item xs={12} lg={12}>
                <MDTypography variant="body2" fontWeight="medium" color="error">
                  {error?.message ?? "Oops. There was an error."}
                </MDTypography>
            </Grid>
        </Grid>
    )
}

// typechecking props for UserInfo
ErrorView.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string
    }).isRequired,
};

export default ErrorView