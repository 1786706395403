/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";

// Chat
// import ChatUI from "layouts/train/new-product/components/Chat";
// import ProductIntent from "layouts/train/new-product/components/Intent";
// import ExpandAccordion from "layouts/train/new-product/components/Expand";

// NewUser page components
import DynamicFeatureField from "layouts/train/new-product/components/DynamicFeatureField";

// Intent Data
// import { featureInfo } from "layouts/train/new-product/components/Intent/intentdata";

function Features({ formData }) {
  const { formField: { features } } = formData;


  return (
    <MDBox>
      {/* <ExpandAccordion title="Sample utterances and conversation flow">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
              <ProductIntent 
                utterances={featureInfo.utterances}
                intentInfo={featureInfo.info} 
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChatUI chats={featureInfo.conversations} />
          </Grid>
        </Grid>
      </ExpandAccordion> */}
      

      <MDBox lineHeight={1}>
        <MDTypography variant="h5" fontWeight="bold">Features</MDTypography>
        <MDTypography variant="body2" color="dark">
          Recommended: Feature statements should follow the Feature-Benefit-Advantage pattern.
        </MDTypography>
        <MDBox my={2}>
          <MDTypography variant="body2" color="dark" fontWeight="medium">
            Example
          </MDTypography>
          <MDTypography variant="body2" color="dark">
            Our product has a (Feature: Deploy with Google Tag Manager option) which allows you to (Benefit: trigger the CloserBot on specific page events or landing pages) and that means you can (Advantage: drive up conversion rates in specific marketing campaigns)
          </MDTypography>
        </MDBox>
        
      </MDBox>

      <MDBox mt={3}>
        
        <DynamicFeatureField features={features} />
        
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Address
Features.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Features;
