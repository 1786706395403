/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import { ManziaBillingInfo } from "appSettings";

// Custom Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// HorizontalBarChart configurations
import configs from "layouts/analytics/components/TopIntents/configs";
import { getBarChartData } from "layouts/analytics/data/horizontalBarChartData";

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

function getPeriod(label = "Last 7 days") {
  const found = ManziaBillingInfo.periods.find(elem => elem.label === label);
  return found ? found.interval : "7day";
}

function BarChart({ height, chart }) {
  
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => ({
        ...dataset,
        weight: 5,
        borderWidth: 0,
        borderRadius: 4,
        backgroundColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        fill: false,
        maxBarThickness: 35,
      }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);
  
  return (
    <MDBox height={height}>
      <Bar data={data} options={options} />
    </MDBox>
  );
}

function TopIntents({ icon, title, description, height }) {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { account: { id: accountId } } = useLocalChatbotUser({ userId: USERID });

  // state for the dropdown value
  const [dropdownValue, setDropdownValue] = useState(ManziaBillingInfo.defaultPeriod.label);

  // state for the dropdown action
  const [dropdown, setDropdown] = useState(null);

  const { loading, error, data: intentData } = useQuery(gql`${queries.getManziaConversationIntents}`, {
    variables: {
      accountId,
      period: getPeriod(dropdownValue)
    }, fetchPolicy: 'cache-and-network'
  });

  // handler for the dropdown action
  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = ({ currentTarget }) => {
    setDropdown(null);
    setDropdownValue(currentTarget.innerText || dropdownValue);
  };

  if (loading) {
    return (
      <MDBox py={3}>
       <MDBox mb={3}>
        <LoadView />
       </MDBox>
      </MDBox>
    );
  }

  if (error) {
    return (
      <MDBox py={3}>
       <MDBox mb={3}>
        <ErrorView error={{ message: "Error retrieving visitor objections data. Try again later."}} />
       </MDBox>
      </MDBox>
    );
  }

  // Dropdown menu
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      {ManziaBillingInfo.periods.map(item => (
        <MenuItem key={item.interval} onClick={close}>
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );

  const chart = getBarChartData(intentData);

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox 
            mt={icon.component ? -2 : 0} 
            display="flex"
            justifyContent="space-between"
          >
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
            <MDBox mt={-2} mr={-1} position="absolute" right="2rem">
              <MDBox width="100%" textAlign="right" lineHeight={2}>
                <MDTypography
                  variant="caption"
                  color="secondary"
                  fontWeight="regular"
                  sx={{ cursor: "pointer" }}
                  onClick={openDropdown}
                >
                  {dropdownValue}
                </MDTypography>
                {renderMenu(dropdown, closeDropdown)}
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      <BarChart height={height} chart={chart} />
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of HorizontalBarChart
BarChart.defaultProps = {
  height: "25.125rem",
};

BarChart.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

TopIntents.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "25.125rem",
};

// Typechecking props for the HorizontalBarChart
TopIntents.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TopIntents;
