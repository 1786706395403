/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const getInvalidMsg = (fieldName) => `${fieldName} can only contain up to 50 alphanumeric, comma, dash, underscore, hyphen, whitespace characters.`;

const authForms = {
  login: {
    formId: "login-form",
    loginEmail: {
      name: "loginEmail",
      label: "Email",
      type: "text",
      placeholder: "e.g abc@example.com",
      errorMsg: "Email is required.",
      invalidMsg: "Enter a valid email address"
    },
    loginPassword: {
      name: "loginPassword",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Requires at least 8 characters including lowercase, uppercase, number and special characters"
    }
  },
  signup: {
    formId: "signup-form",
    fullName: {
      name: "fullName",
      label: "Full name",
      type: "text",
      placeholder: "e.g John Doe",
      errorMsg: "Full name is required.",
      invalidMsg: getInvalidMsg("Full name")
    },
    signupEmail: {
      name: "signupEmail",
      label: "Email",
      type: "text",
      placeholder: "e.g abc@example.com",
      errorMsg: "Email is required.",
      invalidMsg: "Enter a valid email address"
    },
    signupPassword: {
      name: "signupPassword",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Requires at least 8 characters including lowercase, uppercase, number and special characters"
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Requires at least 8 characters including lowercase, uppercase, number and special characters"
    }
  },
  resetPassword: {
    formId: "reset-password-form",
    resetCode: {
      name: "resetCode",
      label: "Enter code",
      type: "text",
      errorMsg: "Reset code is required.",
      invalidMsg: "Enter a valid code"
    },
    newPassword: {
      name: "newPassword",
      label: "New password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Requires at least 8 characters including lowercase, uppercase, number and special characters"
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Requires at least 8 characters including lowercase, uppercase, number and special characters"
    }
  },
  changePassword: {
    formId: "change-password-form",
    newPassword: {
      name: "newPassword",
      label: "New password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Requires at least 8 characters including lowercase, uppercase, number and special characters"
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Requires at least 8 characters including lowercase, uppercase, number and special characters"
    }
  }
}

export default authForms;
