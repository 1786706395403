/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useState } from "react"

// Amplify
import { Auth } from 'aws-amplify';

// Manzia
import { ManziaAuthState } from "appSettings";

export default function useAuth(authType = ManziaAuthState.signIn.key, dependencies = []) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [value, setValue] = useState();

    const callbackMemoized = useCallback(() => (authParams) => {
        setLoading(true);
        setError(undefined);
        setValue(undefined);

        switch (authType) {
            case ManziaAuthState.signIn.key:
                Auth.signIn(authParams.username, authParams.password, 
                    authParams.validationData)
                    .then((data) => setValue(data))
                    .catch((err) => { 
                        setError(err);
                        // console.log(err);
                    })
                    .finally(() => setLoading(false));
                break;
            case ManziaAuthState.confirmSignIn.key:
                Auth.confirmSignIn(authParams.user, authParams.code, 
                    authParams.mfaType)
                    .then((data) => setValue(data))
                    .catch((err) => { 
                        setError(err);
                        // console.log(err);
                    })
                    .finally(() => setLoading(false));
                break;
            case ManziaAuthState.signUp.key:
                Auth.signUp({
                    ...authParams })
                .then((data) => setValue(data))
                .catch((err) => { 
                    setError(err);
                    // console.log(err);
                })
                .finally(() => setLoading(false));
                break;
            case ManziaAuthState.resendSignUp.key:
                Auth.resendSignUp(authParams.username)
                .then((data) => setValue(data))
                .catch((err) => { 
                    setError(err);
                    // console.log(err);
                })
                .finally(() => setLoading(false));
                break;
            case ManziaAuthState.confirmSignUp.key:
                Auth.confirmSignUp(authParams.username, authParams.code,
                    { forceAliasCreation: false })
                .then((data) => setValue(data))
                .catch((err) => { 
                    setError(err);
                    // console.log(err);
                })
                .finally(() => setLoading(false));
                break;
            case ManziaAuthState.resetPassword.key:
                Auth.forgotPassword(authParams.username)
                .then((data) => setValue(data))
                .catch((err) => { 
                    setError(err);
                    // console.log(err);
                })
                .finally(() => setLoading(false));
                break;
            case ManziaAuthState.confirmResetPassword.key:
                Auth.forgotPasswordSubmit(authParams.username, 
                    authParams.code, authParams.password)
                .then((data) => setValue(data))
                .catch((err) => { 
                    setError(err);
                    // console.log(err);
                })
                .finally(() => setLoading(false));
                break;
            case ManziaAuthState.forceNewPassword.key:
                Auth.completeNewPassword(authParams.user, 
                    authParams.password)
                .then((data) => setValue(data))
                .catch((err) => { 
                    setError(err);
                    // console.log(err);
                })
                .finally(() => setLoading(false));
                break;
            case ManziaAuthState.changePassword.key:
                Auth.changePassword(authParams.user, 
                    authParams.oldPassword, authParams.newPassword)
                .then((data) => setValue(data))
                .catch((err) => { 
                    setError(err);
                    // console.log(err);
                })
                .finally(() => setLoading(false));
                break;
            case ManziaAuthState.authenticated.key:
                Auth.currentAuthenticatedUser()
                    .then((data) => setValue(data))
                    .catch((err) => { 
                        setError(err);
                        // console.log(err);
                    })
                    .finally(() => setLoading(false));
                break;
            case ManziaAuthState.signOut.key:
                Auth.signOut()
                    .then(() => setValue({ message: "User signed out" }))
                    .catch((err) => { 
                        setError(err);
                        // console.log(err);
                    })
                    .finally(() => setLoading(false));
                break;
            default:
                break;
        };

    }, dependencies);

    return { loading, error, value, command: callbackMemoized };
}
