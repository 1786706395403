/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// Manzia
import { camelCaseToTitleCase } from "helpers";
import industryCategories from "layouts/train/new-product/options/productCategories";

// Utils
import moment from "moment";


function BasicInfo() {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { account: { webDomain, organizationName, industryCode },
  loginUser: { emailAddress, fullName, createdAt } } = useLocalChatbotUser({ userId: USERID });

  const industry = industryCategories.find(item => item.code === industryCode);
  const userAttributes = {
    fullName,
    emailAddress,
    signedUp: moment(createdAt).format("dddd, MMM Do YYYY"),
    organizationName,
    webDomain,
    industry: industry.title,
  }

  // Render the form values
  const renderItems = Object.keys(userAttributes).map(key => (
    <MDBox key={key}>
      <MDBox  display="flex">
        <MDTypography variant="body2" fontWeight="bold" textTransform="capitalize">
          {camelCaseToTitleCase(key)}: &nbsp;
        </MDTypography>
        <MDTypography variant="body2" fontWeight="regular" color="text">
          &nbsp;{userAttributes[key]}
        </MDTypography>
      </MDBox>
      <Divider />
    </MDBox> 
  ));

  return (
    <Card id="basic-info" sx={{ height: "100%", minHeight: "75vh", overflowY: "auto" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Profile</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        {renderItems}
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
