/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


// Manzia
import { ManziaAuthState } from "appSettings";
import { maskEmail, verifyEmail } from "helpers";
import useAuth from "customhooks/useAuth";

// Common components
// import NotificationView from "layouts/common/Notification";
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";

function AuthPassword({ authHandler }) {
    
    const [userEmail, setUserEmail] = useState('');

    // Confirm signup
    const { loading: resetLoading, error: resetError, 
        value: resetData, command: authReset } = useAuth(ManziaAuthState.resetPassword.key);


    useEffect(() => {
        if (!resetData) return;
        // console.log(`User: ${userEmail} forgot password.`);
        authHandler({ state: ManziaAuthState.confirmResetPassword, user: { username: userEmail } });
    }, [resetData]);

    // Included to 'hide' which emails have Manzia accounts
    useEffect(() => {
        if (!resetError) return;
        const { name: errorCode } = resetError;
        if (errorCode === 'UserNotFoundException') {
            authHandler({ state: ManziaAuthState.confirmResetPassword, user: { username: userEmail } });
        }
    }, [resetError]);

    const handleChange = ({ currentTarget }) => setUserEmail(currentTarget.value);

    const handleSubmit = () => authReset()({ username: userEmail });
    

  const getResetErrMessage = () => {
    if (!resetError) return resetError;
    const { name: errorCode } = resetError;
    const email = maskEmail(userEmail);
    switch (errorCode) {
        case 'CodeDeliveryFailureException':
            return `Failed to deliver password reset code to ${email}`;
        case 'UserNotFoundException':
            return "Check your email. Code will be sent if an account with specified email exists."
        case 'TooManyFailedAttemptsException':
            return "Too many attempts. Wait a few minutes and try again.";
        case 'CodeMismatchException':
            return "Provided verification code does not match expected value. Try again.";
        case 'ExpiredCodeException':
            return "Provided verification code has expired. Please click resend code.";
        case 'AliasExistsException':
            return `Account with provided email: ${email} already exists. Please login.`;
        case 'UsernameExistsException':
            return `Found existing account with provided email: ${email}. Please login.`;
        case 'TooManyRequestsException':
            return "Too many signup attempts. Wait a few minutes and try again.";
        case 'NotAuthorizedException':
            return "Not authorized. Please try signing up again.";
        default:
            return "Encountered an error during signup. Please try again later.";
    }
  }

  const error = getResetErrMessage();

  return (
    <MDBox>
        {resetLoading && <LoadView />}
        {error && <ErrorView error={{ message: `${error}` }} />}
        <MDBox my={2}>
            <MDInput 
                name="resetPassword"
                type="text"
                label="Enter email address "
                variant="outlined"
                value={userEmail}
                onChange={handleChange}
                fullWidth 
            />
        </MDBox>
        <MDBox 
            mt={4} 
            mb={1}
            display="flex"
            alignItems="center"
            >
            {resetLoading && <CircularProgress />}
            <MDButton 
                variant="gradient" 
                color="info" 
                size="large"
                type="submit" 
                fullWidth
                disabled={!verifyEmail(userEmail)}
                onClick={handleSubmit}
            >
                Continue
            </MDButton>
        </MDBox>
        <MDBox mt={3} textAlign="center">
            <MDTypography
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
                sx={{ cursor: "pointer" }}
                onClick={
                    () => authHandler({ state: ManziaAuthState.signIn })
                }
            >
                Return to sign in
            </MDTypography>
        </MDBox>
    </MDBox>
  )
}

AuthPassword.propTypes = {
    authHandler: PropTypes.func.isRequired
};

export default AuthPassword;
