/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import CircularProgress from '@mui/material/CircularProgress';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";
import useLazyAxios from "customhooks/useLazyAxios";

// Auth
import { AuthUserContext } from "App";

// UUID
import { v4 as uuidv4 } from 'uuid';

// Manzia
import { verifyEmail } from "helpers";
import { EMAIL_API, OnboardRole } from "appSettings";
import DefaultCell from "layouts/settings/components/DefaultCell";
import EmailRole from "layouts/settings/components/EmailRole";
import CreateEmail from "layouts/settings/components/CreateEmail";

// Assets
import colors from "assets/theme/base/colors";

function EmailInvite({ gtmContainerId, buttonLabel }) {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { loginUser, account: { id, webDomain, organizationName } } 
    = useLocalChatbotUser({ userId: USERID });
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState([]);
  const [emailError, setEmailError] = useState('');
  const emailRoles = useRef({
    [OnboardRole.ACCOUNT_OWNER]: [],
    [OnboardRole.TEAM_MEMBER]: [],
    [OnboardRole.DEVELOPER]: []
  });
  const emailMessageIdRef = useRef(uuidv4());
  const { loading, error, value: emailResponse, fetch } = useLazyAxios();

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'email':
        setEmail(e.target.value);
        setEmailError("");
        break;
      default:
        break;
    }
  }

  const validEmails = (emails) => {
    if (!emails || emails.length === 0) return false;
    const emailArr = emails.split(",");
    const valid = emailArr.reduce((memo, mail) => memo && verifyEmail(mail.trim()), true);
    return valid;
  }

  const addEmails = () => {
    if (validEmails(email)) {
      setValidEmail([ ...new Set(email.split(",").map(e => e.trim()))]);
    }
  }

  const handleEmailRole = ({ inviteEmail, roleId }) => {
    const inviteSet = new Set(emailRoles.current[roleId]);
    if (!inviteSet.has(inviteEmail)) {
      emailRoles.current[roleId].push(inviteEmail);
    }
  }

  const removeEmailRole = (removeEmail) => {
    const emailArr = [ ...validEmail];
    emailArr.splice(validEmail.indexOf(removeEmail), 1);
    setValidEmail(emailArr);
    setEmail(emailArr.join(","));
  }

  const handleCompleteInvite = () => {
    // Reset
    setEmail("");
    setValidEmail([]);
    setEmailError("");
    emailRoles.current = {
      [OnboardRole.ACCOUNT_OWNER]: [],
      [OnboardRole.TEAM_MEMBER]: [],
      [OnboardRole.DEVELOPER]: []
    };
  }

  const handleEmail = () => {
    // Emails
    if (!validEmails(email)) {
      setEmailError("One or more email addresses entered is not valid."); 
      return; 
    }
    const emailArr = email.split(",");
    if (emailArr.length > 10) {
      setEmailError("Please enter no more than 10 email addresses."); 
      return; 
    }
  
    // Submit emails
    const emailParams = {
      Destination: {
        ToAddresses: emailArr.map(e => e.trim())
      },
      Content: {
        Template: { // Template
          TemplateName: buttonLabel.indexOf('developer') > -1 ? EMAIL_API.developerTemplate.name : EMAIL_API.inviteTemplate.name,
          TemplateData: JSON.stringify({
            full_name: loginUser.fullName,
            organizationName,
            webDomain,
            manzia_dashboard_link: EMAIL_API.dashboardLink,
            manzia_home_link: EMAIL_API.homeLink,
            gtm_containerId: gtmContainerId
          }),
        },
      },
      FromEmailAddress: EMAIL_API.source,
      ConfigurationSetName: EMAIL_API.configset,
      ReplyToAddresses: [
        loginUser.emailAddress
      ],
      FeedbackForwardingEmailAddress: EMAIL_API.fowardingEmail
    };

    // Send
    fetch()({
      method: 'post', 
      url: EMAIL_API.endpoint, 
      data: {
        uid: id,
        webDomain,
        emailData: JSON.stringify(emailParams)
      },
      timeout: 20000,
      maxRedirects: 0, 
    });
  }

  const hasError = (error && error.length > 0) || emailError.length !== 0;

  // Render invites and role options
  const hasValidEmails = validEmails(email);

  function renderInvites() {
    return validEmail.length > 0 ? validEmail.map(item => (
        <MDBox  key={item} display="flex" px={3} py={1} justifyContent="space-around" alignItems="center">
          <DefaultCell>{item}</DefaultCell>
          <EmailRole inviteEmail={item} onChange={handleEmailRole} />
          <MDButton 
                variant="outlined" 
                color="info"
                onClick={() => removeEmailRole(item)}
                iconOnly
              >
              <Icon>remove</Icon>
            </MDButton>
        </MDBox>
      )) : null;
  }
  
  return (
    <>
      <MDBox px={3} display="flex">
        {(emailResponse && validEmails(email)) && 
          <CreateEmail
            messageId={emailResponse.MessageId ?? emailMessageIdRef.current}
            email={email}
            emailRoles={emailRoles.current}
            onComplete={handleCompleteInvite}
          />
        }
        <MDBox sx={{ flexGrow: "8"}}>
            <MDInput
              fullWidth
              label="Email"
              name="email"
              value={email}
              onChange={handleChange}
              inputProps={{ type: "email", autoComplete: "on" }}
              required
              placeholder="abc@example.com"
              error={emailError.length !== 0}
            />
            {emailError.length !== 0 && 
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
               {emailError}
              </MDTypography>
            </MDBox>}
        </MDBox>
        <MDBox ml={1} sx={{flexGrow: "1" }}>
          <MDButton 
              variant="outlined" 
              color="info"
              onClick={addEmails}
              disabled={loading || !hasValidEmails}
            >
            <Icon>add</Icon>&nbsp; Add
          </MDButton>
        </MDBox>
      </MDBox>
      {(validEmail.length > 0) && 
        <>
          {renderInvites()}
          <MDBox my={2} display="flex" justifyContent="center" alignItems="center">
            {loading && <MDBox mr={2}>
              <CircularProgress
                sx={{ color: colors.info.main }}
              />
            </MDBox>}
            <MDButton 
                variant="gradient" 
                color="info" 
                onClick={handleEmail}
                disabled={hasError || loading || !!emailResponse}
              >
              {buttonLabel}
            </MDButton>
          </MDBox>
        </>
      }
    </>
  );
}

EmailInvite.defaultProps = {
  buttonLabel: "Send Invite",
  gtmContainerId: ""
}

EmailInvite.propTypes = {
  gtmContainerId: PropTypes.string,
  buttonLabel: PropTypes.string
}

export default EmailInvite;
