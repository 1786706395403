/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useCallback, useState, useContext } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// import { useParams } from 'react-router-dom';

// formik components
import { Formik, Form } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Hooks
import useLazyAxios from "customhooks/useLazyAxios";
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// Manzia
import InputField from "layouts/onboard/components/InputField";
import ErrorView from "layouts/common/ErrorView";
import LoadView from "layouts/common/LoadView";
import NotificationView from "layouts/common/Notification";

// schemas for form and form fields
import validations from "layouts/conversations/schema/validations";
import forms from "layouts/conversations/schema/form";
import initialValues from "layouts/conversations/schema/initialValues";
import { EMAIL_API, ENV } from "appSettings";

function EmailChat({ conversation }) {
    const authUser = useContext(AuthUserContext);
    const USERID = authUser?.sub;
    const { emailchat: { formId, fullName, chatEmails } } = forms;
    const [submitted, setSubmitted] = useState(false);
    const { loginUser } = useLocalChatbotUser({ userId: USERID });

    const { loading, error, value, fetch } = useLazyAxios();

    // Fetch complete
    useEffect(() => {
        if (!value) return;
        setSubmitted(true);
    }, [value]);

    // Submit
    const handleSubmit = useCallback((values, actions) => {

        fetch()({
            method: EMAIL_API.emailchat.method, 
            url: EMAIL_API.emailchat.url,
            data: { 
                ...values,
                conversationId: conversation.sessionId,
                accountId: conversation.accountId,
                productName: conversation.userProduct?.productName,
                source: ENV 
            },
            timeout: 60000,
            maxRedirects: 0, 
        });
        
        // Update state
        actions.setSubmitting(false);
        // actions.resetForm();
    
    }, []);

  return (
    <Formik
        initialValues={{...initialValues.emailchat, [fullName.name]: loginUser.fullName ?? "" }}
        validationSchema={validations.emailchat}
        onSubmit={handleSubmit}
        validateOnMount
    >
        {({ isSubmitting, isValid }) => {
            const isDisabled = isSubmitting || !isValid;
            return (
            <Form id={formId} autoComplete="on">
                {(error)  && <ErrorView error={{ message: `${error}` }} />}
                {submitted && 
                    <NotificationView  
                        color="success" 
                        message="Conversation was successfully sent via email."
                    />
                }
                <MDBox my={2}>
                    <InputField 
                        name={fullName.name} 
                        type={fullName.type}
                        label={fullName.label}
                        fullWidth
                        disabled={!!loginUser?.fullName} 
                    />
                </MDBox>
                <MDBox mb={2}>
                    <InputField 
                        name={chatEmails.name} 
                        type={chatEmails.type}
                        label={chatEmails.label}
                        fullWidth
                        helperText="Enter comma separated email addresses." 
                    />
                </MDBox>
                <MDBox 
                    mt={4} 
                    mb={1}
                    display="flex"
                    alignItems="center"
                >
                
                {loading && <LoadView loadingMessage="" />}
                <MDButton 
                    variant="gradient" 
                    color="info" 
                    size="large"
                    type="submit"
                    fullWidth
                    disabled={isDisabled || submitted || error}
                >
                    Email conversation
                </MDButton>
                </MDBox>
            </Form>
          )}}
    </Formik>
  );

}

EmailChat.propTypes = {
    conversation: PropTypes.shape({
        sessionId: PropTypes.string.isRequired,
        userProduct: PropTypes.shape({
          id: PropTypes.string.isRequired,
          productName: PropTypes.string.isRequired
        }),
        accountId: PropTypes.string.isRequired,
      }).isRequired,
  };

export default EmailChat;


