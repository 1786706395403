/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

*/

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material MUI
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Manzia
import FormField from "layouts/test/components/FormField";

function SimpleEditTypography({ inputText, fieldProps, typoProps, editable, onComplete }) {
  
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(`${inputText}`);
  const [tempText, setTempText] = useState(`${inputText}`);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setText(tempText);
    setIsEditing(false);
    onComplete(tempText);
  };

  const handleCancelClick = () => {
    setTempText(text);
    setIsEditing(false);
  };

  const handleChange = (event) => {
    setTempText(event.target.value);
  };

  return (
      <MDBox>
        {isEditing ?
        (
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <MDBox lineHeight={1}>
                <FormField
                  name="editText"
                  label="Edit Text"
                  value={tempText}
                  onChange={handleChange}
                  variant="outlined"
                  {...fieldProps}
                />
              </MDBox>
              
              <MDBox mr={1}>
                <IconButton size="small" aria-label="done" color="success" onClick={handleSaveClick}>
                <Tooltip title="Done" placement="top">
                  <Icon fontSize="small">done</Icon>
                </Tooltip>
                </IconButton>
              </MDBox>
              <MDBox mr={1}>
                <IconButton size="small" aria-label="cancel" color="text" onClick={handleCancelClick}>
                <Tooltip title="Cancel" placement="top">
                  <Icon fontSize="small">cancel</Icon>
                </Tooltip>
                </IconButton>
              </MDBox>

            </MDBox>
          
        ) : 
        (
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
              sx={{
                '&:hover .editButton': {
                  visibility: 'visible',
                }
              }}
            >
              <MDBox lineHeight={1}>
                <MDTypography   
                  {...typoProps}
                >
                  {inputText}
                </MDTypography>
              </MDBox>
              {editable && <MDBox mr={1}>
                <IconButton 
                  size="small"
                  className="editButton" 
                  aria-label="edit" 
                  color="text" 
                  onClick={handleEditClick}
                  sx={{ visibility: 'hidden' }}
                >
                <Tooltip title="Edit" placement="top">
                  <Icon fontSize="small">edit</Icon>
                </Tooltip>
                </IconButton>
              </MDBox>}
              
            </MDBox>
          )
        }
      </MDBox>
  );
}

SimpleEditTypography.defaultProps = {
  editable: true,
};

SimpleEditTypography.propTypes = {
  inputText: PropTypes.string.isRequired,
  fieldProps: PropTypes.shape({
    multiline: PropTypes.bool,
    maxRows: PropTypes.number,
    rows: PropTypes.number
  }).isRequired,
  typoProps: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    color: PropTypes.string,
    fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"])
  }).isRequired,
  editable: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
};

export default SimpleEditTypography;
