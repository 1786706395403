/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useCallback, useContext } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// GraphQL
import { gql, useQuery } from '@apollo/client';
// import GetManziaUser from "layouts/train/product-page/queries/graphql";
import * as queries from 'graphql/queries';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Auth
import { AuthUserContext } from "App";

// Amplify
// import { Hub } from 'aws-amplify';

// State Views
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import NoDataView from "layouts/common/NoDataView";
import PublishState from "layouts/common/PublishStatus";
import BalanceState from "layouts/common/BalanceStatus";

// Custom Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// ProductPage page components
import ResizedTabs from "layouts/train/product-page/components/ResizedTabs";
import TrainedProducts from "layouts/train/product-page/components/TrainedProducts";
import PublishButton from "layouts/deploy/publish/components/PublishButton";
import Deployments from "layouts/train/product-page/components/Deployed";
import InfoList from "layouts/train/product-page/components/InfoList";

// Constants
import { TABS } from "appSettings";

// const USERID = "20966bf1-2054-49ca-9328-7f43b8f8c65b";

function ProductPageData() {
  // Run query to save user, account and chatbot data in apollo cache.
  // Can be retrieved locally using the useLocalChatbotUser hook.
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { loading, error, data } = useQuery(gql`${queries.getManziaUser}`, {
    variables: { id: USERID }, fetchPolicy: 'cache-first',
  });

  if (loading) return (
      <LoadView />
  );

  if (error) return (
      <ErrorView error={{ message: `${error}` }} />
  );

  // console.log("User: ", data);

  if (!data?.getManziaUser) 
  return (
      <NoDataView 
        info={{ title: "Authentication failed", message: `Authentication failed. Please retry login.`}} 
      />
  );

  return (
      <ProductPage userId={USERID} />
  );
}

function NoProductView({ chatbotName }) {
  const navigate = useNavigate();
  return (
      <MDBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
          <InfoList 
                infoData={{
                  title: { text: `Hi, Welcome to ${chatbotName}` },
                  description: { text: "Follow the steps below to get started."},
                  steps: [
                  { text: `Click Add Product Info below to add the product information that ${chatbotName} will use to engage website visitors.`, bolds: ["Add Product Info"]},
                  { text: `Click Test Bot above to customize the responses from ${chatbotName}.`, bolds: ["Test Bot"]},
                  { text: `Click Install Bot above to add the ${chatbotName} code snippet (tag) to your website.`, bolds: ["Install Bot"]},
                  { text: `Click Publish above to make a version of product information live. The ${chatbotName} deployed on your website always uses the latest published version.`, bolds: ["Publish"]},
                ]
              }}
            />
            <MDBox mt={3}>
            <Grid container justifyContent="center" alignItems="center" my={2}>
                
                <Grid item xs={4} sm={3} sx={{ textAlign: "right" }}>
                  <MDButton 
                    variant="gradient" 
                    color="info" 
                    fullWidth
                    onClick={() => navigate("/new-product")}
                  >
                    <Icon>add</Icon>&nbsp; Add Product Info
                  </MDButton>
                </Grid>
            </Grid>
              
            </MDBox>

          </MDBox>
        </Card>
      </MDBox>
  );
}

function getUserProduct(items) {
  const training = items.find(item => !!item.userProduct);
  return training ? { ...training.userProduct } : training;
}

function getTrainingInfo(items) {
  const trainings = items.filter((item) => item.userProduct && !item.botDeploymentId);
  return { trainings };
}

function ProductPage({ userId }) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(TABS.products[0]);
  const [published, setPublished] = useState(false);
  const { chatbot: { id: v2chatBotId, chatbotName } } = useLocalChatbotUser({ userId });

  const { loading, error, data } = useQuery(gql`${queries.manziaProductTrainingsByTrainedChatBotIdAndLastModifiedAt}`, {
    variables: { trainedChatBotId: v2chatBotId, sortDirection: 'DESC', limit: 100 }, fetchPolicy: 'cache-and-network',
  });

  const onTabChange = useCallback((tab) => setActiveTab(tab), []);

  const onPublish = useCallback(() => setPublished(true), []);

  const startTesting = useCallback(() => navigate("/test-product"), []);

  const goInstallBot = useCallback(() => navigate("/settings/install-bot", {
    state: { navItemPath: "install-bot" }
  }), []);

  if (loading) return (<LoadView />)
  if (error) return (<ErrorView error={{ message: `${error}` }} />)
  if (data?.manziaProductTrainingsByTrainedChatBotIdAndLastModifiedAt?.items.length === 0) return (<NoProductView chatbotName={chatbotName} />)

  const { manziaProductTrainingsByTrainedChatBotIdAndLastModifiedAt: { items = [] } } = data;
  
  const { trainings } = getTrainingInfo(items);
  // console.log("ProductTrainings: ", items);
  // console.log("User data: ", data);

  function getTabContent() {
    switch (activeTab.label) {
      case TABS.products[0].label:
        return (<TrainedProducts trainedChatBotId={v2chatBotId} trainings={items} />);
      case TABS.products[1].label:
        return (<Deployments manziaChatbotId={v2chatBotId} />);
      default:
        return null;
    }
  }

  const navigateNewProduct = () => navigate("/new-product");

  const editUserProduct = getUserProduct(items);
  
  const navigateEditProduct = () => navigate(`/products/${editUserProduct?.id}`);

  return (
    <MDBox py={3}>
      <Card sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          {!published && <PublishState />}
          <BalanceState />
          <Grid 
            container 
            justifyContent="flex-end" 
            alignItems="center" 
            my={2}
            spacing={2}
          >
              <Grid item xs={4} sm={2} sx={{ textAlign: "right" }}>
              <Tooltip 
                title={`Customize the responses from ${chatbotName}.`}
                placement="top">
                  <MDButton 
                    variant="outlined" 
                    color="dark" 
                    fullWidth
                    // disabled={trainings.length === 0}
                    onClick={startTesting}
                  >
                    Test Bot
                  </MDButton>
              </Tooltip>
              </Grid>
              <Grid item xs={4} sm={2} sx={{ textAlign: "right" }}>
              <Tooltip 
                title={`Deploy ${chatbotName} to your website.`}
                placement="top">
                    <MDButton 
                      variant="outlined" 
                      color="dark" 
                      fullWidth
                      // disabled={trainings.length === 0}
                      onClick={goInstallBot}
                    >
                      Install Bot
                    </MDButton>
                </Tooltip>
              </Grid>
            
              <Grid item xs={4} sm={2} sx={{ textAlign: "right" }}>
                <PublishButton 
                  chatbotId={v2chatBotId}
                  trainings={trainings}
                  onPublish={onPublish}
                >
                  Publish
                </PublishButton>
              </Grid>
          </Grid>

          <InfoList 
                infoData={{
                  title: { text: `Hi, Welcome to ${chatbotName}` },
                  description: { text: "Follow the steps below to get started."},
                  steps: [
                  { text: `Click Add New Product below to add the product information that ${chatbotName} will use to engage website visitors.`, bolds: ["Add New Product"]},
                  { text: `Click Test Bot above to customize the responses from ${chatbotName}.`, bolds: ["Test Bot"]},
                  { text: `Click Install Bot above to add the ${chatbotName} code snippet (tag) to your website.`, bolds: ["Install Bot"]},
                  { text: `Click Publish above to make a version of product information live. The ${chatbotName} deployed on your website always uses the latest published version.`, bolds: ["Publish"]},
                ]
              }}
            />

          <MDBox mb={2}>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
              
              {editUserProduct && <Grid item xs={6} sm={4} sx={{ textAlign: "right" }}>
                <MDButton 
                  variant="outlined" 
                  color="dark"
                  fullWidth
                  onClick={navigateEditProduct}
                >
                  <Icon>edit</Icon>&nbsp; {`Edit ${editUserProduct.productName?.length > 12 ? `${editUserProduct.productName?.substring(0, 12)}...` : editUserProduct.productName} Info`}
                </MDButton>
              </Grid>}
              <Grid item xs={6} sm={4} sx={{ textAlign: "right" }}>
                <MDButton 
                  variant="gradient" 
                  color="info"
                  fullWidth
                  onClick={navigateNewProduct}
                >
                  <Icon>add</Icon>&nbsp; Add new product
                </MDButton>
              </Grid>
          </Grid>
            
          </MDBox>
          <MDBox mt={3} mb={1}>
            <ResizedTabs onTabChange={onTabChange} tabs={TABS.products}/>
          </MDBox>

          {getTabContent()}

        </MDBox>
      </Card>
    </MDBox>
  );
}

// Typechecking props
ProductPage.propTypes = {
  userId: PropTypes.string.isRequired,
};

NoProductView.propTypes = {
  chatbotName: PropTypes.string.isRequired,
};

export default ProductPageData;
