/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import * as Yup from "yup";
import form from "layouts/onboard/schemas/form";
import { BASIC_FIELD_REGEX, DOMAIN_NAME, ALPHA_SPACE_REGEX } from "helpers";
import industryCategories from "layouts/train/new-product/options/productCategories";
import invalidDomains from "layouts/onboard/data/invalidDomains";
import demoTypes from "layouts/train/new-product/options/demoTypes";

const {
  formField: { 
    botName,
    botTone,
    productName,
    productPage,
    demos 
  },
  backend
} = form;

const industryCodes = industryCategories.map((industry => industry.title));

const validations = {
  welcome: Yup.object().shape({
    [botName.name]: Yup.string().matches(BASIC_FIELD_REGEX, botName.invalidMsg).required(botName.errorMsg),
    [botTone.name]: Yup.string().matches(ALPHA_SPACE_REGEX, botTone.invalidMsg).required(botTone.errorMsg),
    [productName.name]: Yup.string().matches(BASIC_FIELD_REGEX, botName.invalidMsg).required(botName.errorMsg),
    [productPage.name]: Yup.string().required(productPage.errorMsg),
    [demos.name]: Yup.object().shape({
      [demos.scheduleDemo.name]: Yup.string().url(demos.scheduleDemo.invalidMsg),
      [demos.demoType.name]: Yup.string().oneOf(demoTypes, demos.demoType.invalidMsg).default(demoTypes[0]),
    }),
  }),
  backend: Yup.object().shape({
    [backend.organizationName.name]: Yup.string().matches(BASIC_FIELD_REGEX, backend.organizationName.invalidMsg).required(backend.organizationName.errorMsg),
    [backend.webDomain.name]: Yup.string().matches(DOMAIN_NAME, backend.webDomain.invalidMsg).notOneOf(invalidDomains, backend.webDomain.invalidMsg).required(backend.webDomain.errorMsg),
    [backend.industryCode.name]: Yup.mixed().oneOf(industryCodes, backend.industryCode.invalidMsg).required(backend.industryCode.errorMsg),
  })
}
  

export default validations;
