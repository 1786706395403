/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DefaultPricingCard from "layouts/pricing/components/DefaultPricingCard";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

// Manzia
import { ManziaStripeAPI } from "appSettings";
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

function PricingCards({ prices, accountId }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [starter ] = prices;
  const { account } = useLocalChatbotUser({ accountId });

  return (
    <MDBox position="relative" zIndex={10} mt={3} px={{ xs: 1, sm: 0 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <DefaultPricingCard
            color={darkMode ? "dark" : "white"}
            badge={{ color: darkMode ? "warning" : "light", label: "Pay as you go" }}
            price={{ currency: "$", value: starter, type: "conversation" }}
            specifications={[
              { label: "Simple and flexible pricing", includes: true },
              { label: "Pay only when website visitors engage in conversation", includes: true },
              { label: "Enable auto recharge to keep the insights and leads flowing", includes: true },
            ]}
            action={{
              type: "external",
              route: `${ManziaStripeAPI.portalSession.url}?accountId=${accountId}`,
              color: darkMode ? "warning" : "info",
              label: "Manage Billing",
              disabled: account.totalPaymentAmount === 0
            }}
            shadow={darkMode}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
  accountId: PropTypes.string.isRequired
};

export default PricingCards;
