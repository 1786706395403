/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

*/

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material MUI
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Manzia
import FormField from "layouts/test/components/FormField";

function EditTypography({ inputText, fieldProps, typoProps, editable, onComplete }) {

  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(`${inputText}`);
  const [tempText, setTempText] = useState(`${inputText}`);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setText(tempText);
    setIsEditing(false);
    onComplete(tempText);
  };

  const handleCancelClick = () => {
    setTempText(text);
    setIsEditing(false);
  };

  const handleChange = (event) => {
    setTempText(event.target.value);
  };

  return (
      <MDBox>
        {isEditing ?
        (<>
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <MDBox mr={1}>
                <IconButton size="small" aria-label="done" color="success" onClick={handleSaveClick}>
                <Tooltip title="Done" placement="top">
                  <Icon fontSize="small">done</Icon>
                </Tooltip>
                </IconButton>
              </MDBox>
              <MDBox mr={1}>
                <IconButton size="small" aria-label="cancel" color="text" onClick={handleCancelClick}>
                <Tooltip title="Cancel" placement="top">
                  <Icon fontSize="small">cancel</Icon>
                </Tooltip>
                </IconButton>
              </MDBox>
            </MDBox>
          <MDBox ml={2} pl={4} lineHeight={1}>
            <FormField
              name="editText"
              label="Edit Text"
              value={tempText}
              onChange={handleChange}
              variant="outlined"
              {...fieldProps}
            />
          </MDBox>
          </>
        ) : 
        (<>
            {editable && <MDBox
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <MDBox mr={1} mt={-4}>
                <IconButton size="small" aria-label="edit" color="info" onClick={handleEditClick}>
                <Tooltip title="Edit" placement="top">
                  <Icon fontSize="small">edit</Icon>
                </Tooltip>
                </IconButton>
              </MDBox>
            </MDBox>}
          <MDBox ml={2} pl={4} lineHeight={1}>
            <MDTypography   
              {...typoProps}
            >
              {inputText}
            </MDTypography>
          </MDBox>
        </>)
        }
      </MDBox>
  );
}

EditTypography.defaultProps = {
  editable: true,
};

EditTypography.propTypes = {
  inputText: PropTypes.string.isRequired,
  fieldProps: PropTypes.shape({
    multiline: PropTypes.bool,
    maxRows: PropTypes.number,
    rows: PropTypes.number
  }).isRequired,
  typoProps: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    color: PropTypes.string,
    fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"])
  }).isRequired,
  editable: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
};

export default EditTypography;
