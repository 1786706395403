/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import * as Yup from "yup";
import checkout from "layouts/train/new-product/schemas/form";
import demoTypes from "layouts/train/new-product/options/demoTypes";
// import PriceTypes from "layouts/train/new-product/options/pricingTypes";

/* eslint-disable */
const illegalChars = /^[a-zA-Z0-9,\.\-'"\?!()\s]+$/;
// const oneToFiveAndIllegalChars = /^\s*(?:[\w,'.\-\?!]+\s+){0,4}[\w,'.\-\?!]+\s*$/;
const fourAndIllegalChars = /^\s*(?:[\w,'.\-\?!]+\s+){4,}[\w,'.\-\?!]+\s*$/;
/* eslint-enable */

const {
  formField: { productName, productPage, problems, jobsDone, features, insights, testimonials,
  caseStudies, reviews, socialMedia, press, demos, productDescription },
} = checkout;

// function priceTypeValidation() {
//   const validation = {};
//   PriceTypes.forEach((type) => {
//     validation[type.code] = Yup.boolean()
//   });

//   return validation;
// }

const validations = [
  Yup.object().shape({
    [productName.name]: Yup.string().matches(illegalChars, productName.illegalChars).required(productName.errorMsg),
    [productPage.name]: Yup.string().url(productPage.invalidMsg).required(productPage.errorMsg),
    [productDescription.name]: Yup.string().min(5, productDescription.invalidMsg).required(productDescription.errorMsg),
    [demos.name]: Yup.object().shape({
      [demos.demoType.name]: Yup.string().oneOf(demoTypes, demos.demoType.invalidMsg).default(demoTypes[0]),
      [demos.scheduleDemo.name]: Yup.string().url(demos.scheduleDemo.invalidMsg),
    }),
  }),
  Yup.object().shape({
    [insights.name]: Yup.array().of(Yup.object().shape({
      [insights.companySize.name]: Yup.string().matches(illegalChars, insights.companySize.invalidMsg).required(insights.companySize.errorMsg),
      [insights.industry.name]: Yup.string().matches(illegalChars, insights.industry.invalidMsg).required(insights.industry.errorMsg),
      [insights.type.name]: Yup.string().matches(illegalChars, insights.type.invalidMsg).required(insights.type.errorMsg),
      [insights.jobTitle.name]: Yup.string().matches(illegalChars, insights.jobTitle.invalidMsg).required(insights.jobTitle.errorMsg),
      [insights.role.name]: Yup.string().matches(illegalChars, insights.role.invalidMsg).required(insights.role.errorMsg),
      [insights.responsibility.name]: Yup.string().matches(illegalChars, insights.responsibility.invalidMsg).required(insights.responsibility.errorMsg),
      [insights.useCase.name]: Yup.string().matches(illegalChars, insights.useCase.invalidMsg).required(insights.useCase.errorMsg),
    })).min(1, insights.errorMsg).max(2, insights.invalidMsg),
    // [insights.name]: Yup.array().of(Yup.string().matches(fourAndIllegalChars, insights.illegalMsg).required(insights.errorMsg)).min(1, insights.errorMsg).max(10, insights.invalidMsg),
    // [competition.name]: Yup.string().matches(fourAndIllegalChars, competition.invalidMsg),
  }),
  Yup.object().shape({
    [problems.name]: Yup.array().of(Yup.string().required(problems.errorMsg)).min(1, problems.errorMsg).max(10, problems.invalidMsg),
    [jobsDone.name]: Yup.array().of(Yup.string()).min(0, jobsDone.errorMsg).max(10, jobsDone.invalidMsg),
  }),
  Yup.object().shape({
    [features.name]: Yup.array().of(Yup.object().shape({
      [features.productFeature.name]: Yup.string().matches(illegalChars, features.productFeature.invalidMsg).required(features.productFeature.errorMsg),
      [features.productBenefit.name]: Yup.string().matches(illegalChars, features.productBenefit.invalidMsg).required(features.productBenefit.errorMsg),
      [features.productAdvantage.name]: Yup.string().matches(illegalChars, features.productAdvantage.invalidMsg),
      [features.featureLink.name]: Yup.string().url(features.featureLink.invalidMsg),
    })).min(1, features.errorMsg).max(10, features.invalidMsg),
  }),
  // Yup.object().shape({
  //   [pricing.name]: Yup.object().shape({
  //     [pricing.callToAction.name]: Yup.string().matches(oneToFiveAndIllegalChars, pricing.callToAction.invalidMsg),
  //     [pricing.contactEmail.name]: Yup.string().required(pricing.contactEmail.errorMsg).email(pricing.contactEmail.invalidMsg),
  //   }),
  //   [pricingTypes.name]: Yup.object().shape(priceTypeValidation()).test("isSelected", "Please select at least one pricing type.", 
  //   (value) => {
  //     const checkboxNotSelected = Object.values(value).every(item => item === false);
  //     return !checkboxNotSelected;
  //   }),
  // }),
  Yup.object().shape({
    [testimonials.name]: Yup.array().of(Yup.string().url(testimonials.invalidMsg)),
    [reviews.name]: Yup.array().of(Yup.string().url(reviews.invalidMsg)),
    [caseStudies.name]: Yup.array().of(Yup.string().url(caseStudies.invalidMsg)),
    [socialMedia.name]: Yup.array().of(Yup.string().url(socialMedia.invalidMsg)),
    [press.name]: Yup.array().of(Yup.string().url(press.invalidMsg).nullable()),
  }),
  // Yup.object().shape({
  //   [firstName.name]: Yup.string().required(firstName.errorMsg),
  //   [lastName.name]: Yup.string().required(lastName.errorMsg),
  //   [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
  //   [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
  //   [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
  //   [repeatPassword.name]: Yup.string()
  //     .required(repeatPassword.errorMsg)
  //     .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  // }),
];

export default validations;
