/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useCallback } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// GraphQL
import { gql, useQuery, useMutation } from '@apollo/client';
import * as queries from 'graphql/queries';

// Table
import DataTable from "examples/Tables/DataTable";
import RoleCell from "layouts/settings/components/RoleCell";
import DefaultCell from "layouts/settings/components/DefaultCell";
import ActionCell from "layouts/settings/components/ActionCell";

// State Views
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import NoDataView from "layouts/common/NoDataView";
import NotificationView from "layouts/common/Notification";

// Manzia
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";
import { productActions, OnboardRole } from "appSettings";
import UPDATE_USERROLE from "layouts/settings/components/TeamTable/updatemutation";

// Utilities
import moment from "moment";

function TeamTable({ userId }) {
    const { account: { id: accountId, organizationName } } = useLocalChatbotUser({ userId });
    const [changedUser, setChangedUser] = useState();
    
    // Fetch users
    const { loading, error, data, fetchMore } = useQuery(gql`${queries.manziaUsersByAccountId}`, {
        variables: { accountId, limit: 50 }, fetchPolicy: 'cache-and-network',
    });

    // Update user role
    const [updateUserRole, { data: roleData, loading: roleLoading, 
        error: roleError }] = useMutation(gql`${UPDATE_USERROLE}`);

    const handleUserRoles = useCallback((user) => setChangedUser(user), []);

    const handleSaveRole = useCallback(() => {
        const { id, roleId } = changedUser;
        updateUserRole({
            variables: {
                userRoleInput: {
                    userId: id,
                    roleId,
                    roleName: roleId,
                },
                userInput: {
                    id,
                    roleId
                }
            }
        });
    }, []);

    const fetchNext = () => fetchMore({
        variables: {
          nextToken: data.manziaUsersByAccountId.nextToken,
          limit: 50
        },
    });

    if (loading) return <LoadView />;
    if (error) return <ErrorView error={{ message: `${error}` }} />;

    const USERS = data.manziaUsersByAccountId.items.map((item) => 

        ({
            userName: item.fullName, 
            roleId: item.roleId,
            createdAt: moment(item.createdAt).format("MMM Do, YYYY"),
            actions: { 
                icons: [{icon: "save", color: "info", action: productActions.save }], 
                userId: item.id 
            }
        })
    );

    if (USERS.length === 0) {
        return (
            <NoDataView 
              info={{ title: "No Team Members", message: `No team members have been invited to join the ${organizationName} team. Enter one or more emails above to send invitations.`}}
            />
        );
    }

    const tableData = {
        columns: [
          { Header: "User", accessor: "userName", align: "center", width: "20%" },
          { Header: "Role", accessor: "roleId", align: "center", width: "50%" },
          { Header: "Joined", accessor: "createdAt", align: "center", width: "20%" },
          { Header: "Actions", accessor: "actions", align: "center", width: "10%" },
        ],
        rows: USERS.map((item) => ({
              userName: <DefaultCell>{item.userName}</DefaultCell>,
              roleId: <RoleCell user={item} onChange={handleUserRoles} />,
              createdAt: <DefaultCell>{item.createdAt}</DefaultCell>,
              actions: item.roleId === OnboardRole.ACCOUNT_OWNER ? 
              (null) : (
                <ActionCell 
                    actions={item.actions} 
                    onSave={handleSaveRole}
                    changedUserId={changedUser?.id} 
                />)
        }))
    };

    return (
        <MDBox mb={2}>
            <MDBox mb={1}>
                <MDTypography variant="h6" fontWeight="medium">
                    Team Members
                </MDTypography>
            </MDBox>
            {roleLoading && <LoadView loadingMessage="Updating team..." />}
            {roleError && <ErrorView error={{ message: "Encountered an error updating team roles. Please retry later."}} />}
            {roleData && <NotificationView 
                color="success"
                message="Role update succeeded."
            />}
            <DataTable
                table={tableData}
                entriesPerPage={false}
                showTotalEntries
                isSorted
                onLoadMore={fetchNext}
            />
        </MDBox>
    );
}

// Typechecking props for BaseLayout
TeamTable.propTypes = {
    userId: PropTypes.string.isRequired
  };

export default TeamTable;