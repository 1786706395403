/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

function NotAuthorized() {
    // Navigation
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    }

    return (
        <Card sx={{ height: "100%", minHeight: "75vh", overflowY: "auto" }}>
            <MDBox pt={3} px={3}>
                <Grid 
                    container 
                    justifyContent="center" 
                    alignItems="center" 
                    my={2}
                    spacing={4}
                >
                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="bold" align="center" color="dark">
                        Not Authorized.
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography 
                            variant="body2" 
                            fontWeight="regular"
                            color="dark"
                        >
                        You are not authorized to view this page. Reach out to the Account Owner to give you the appropriate permissions.
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MDBox display="flex" justifyContent="center">
                            <MDButton 
                                variant="gradient" 
                                color="info"
                                onClick={handleClick}
                            >
                                Go Back
                            </MDButton>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default NotAuthorized