/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";

// Manzia
import { StatisticsSummary } from "appSettings";

// Custom Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// Helpers
// import { getRandomArrayElement } from "helpers";

// Constants
// const DEMO = [1250, 876, 456, 1675, 256, 2100, 950, 3450, 175, 625 ];

function getPeriod(label = "Last 24 hours") {
  const found = StatisticsSummary.periods.find(elem => elem.label === label);
  return found ? found.interval : "1day";
}

function DefaultStatisticsCard({ query, info }) {
  const authUser = useContext(AuthUserContext);
  // const [sessionUser] = useSessionStorage(ManziaAuthKeys.signupUser, {});
  const USERID = authUser?.sub;
  // console.log("USERID: ", USERID);
  const { account: { id: accountId } } = useLocalChatbotUser({ userId: USERID });

  // DefaultStatisticsCard state for the dropdown value
  const [dropdownValue, setDropdownValue] = useState("Last 24 hours");

  // DefaultStatisticsCard state for the dropdown action
  const [dropdown, setDropdown] = useState(null);

  const { loading, error, data } = useQuery(gql`${queries[query]}`, {
    variables: {
      accountId,
      period: getPeriod(dropdownValue)
    }, fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first'
  });

  // Dark Mode
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // DefaultStatisticsCard handler for the dropdown action
  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = ({ currentTarget }) => {
    setDropdown(null);
    setDropdownValue(currentTarget.innerText || dropdownValue);
  };

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      {StatisticsSummary.periods.map(item => (
        <MenuItem key={item.interval} onClick={close}>{item.label}</MenuItem>
      ))}
    </Menu>
  );

  /**
   * data:  
   * [
      {
        period_1h: '2023-06-21 19:00:00.000000000',
        session_counts: 2,
        account_id: 'd78195ed-441c-406e-9af4-edd63d9bf275'
      }
    ]
   * @returns 
   */
  /* eslint-disable prefer-destructuring */
  function renderStats() {
    // console.log("data: ", data);
    let jsonData = [];
    try {
      jsonData = JSON.parse(data[query]);
      if (typeof jsonData === 'string') {
        jsonData = JSON.parse(jsonData);
      }
    } catch (_) {
      // console.log("JSON parsing failed: ", data);
    }
    
    let count = 0;
    let previousCount = 0;
    let percentValue = 0;
    const percentage = {
      color: "success",
      value: `+${percentValue}`,
      label: dropdown && dropdown.value ? `since ${dropdown.value.replace("Last", "prior")}` : " "
    }
    if (Array.isArray(jsonData)) {
        let arr1;
        let arr2;
        switch (jsonData.length) {
          case 1:
            arr1 = Object.values(jsonData[0]).filter(item => Number.isInteger(item));
            if (arr1.length > 0) count = arr1[0];
            break;
          case 2:
            arr1 = Object.values(jsonData[0]).filter(item => Number.isInteger(item));
            if (arr1.length > 0) previousCount = arr1[0];
            arr2 = Object.values(jsonData[1]).filter(item => Number.isInteger(item));
            if (arr2.length > 0) count = arr2[0];
            break;
          default:
            break;
        }
    }
    /* eslint-enable prefer-destructuring */

    // For demo purposes only
    // count = count === 0 ? getRandomArrayElement(DEMO) : count;
    // previousCount = previousCount === 0 ? getRandomArrayElement(DEMO) : previousCount;

    if (count > 0) {
      if (previousCount < 1) {
        percentValue = count;
        percentage.value = `+${percentValue * 100}%`;
      } else {
        percentValue = ((count - previousCount) / previousCount) * 100;
        const isNegative = Math.sign(percentValue) === -1;
        percentage.color = isNegative ? "error" : "success";
        percentage.value = `${isNegative ? "-" : "+"}${Math.round(Math.abs(percentValue))}%`
      }
    }

    return (
      <MDBox lineHeight={1} display="flex" justifyContent="space-between" alignItems="center">
        <Tooltip title={StatisticsSummary[info].description} placement="top">
          <MDTypography variant="h5" fontWeight="bold" color="dark">
            {count}
          </MDTypography>
        </Tooltip>
        <MDTypography variant="button" fontWeight="bold" color={percentage.color}>
          {percentage.value}&nbsp;
          <MDTypography
            variant="button"
            fontWeight="regular"
            color={darkMode ? "dark" : "secondary"}
          >
            {percentage.label}
          </MDTypography>
        </MDTypography>
        
      </MDBox>
    );
  }

  return (
    <Card>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={7}>
            <MDBox mb={0.5} lineHeight={1}>
              <MDTypography
                variant="button"
                fontWeight="medium"
                color="dark"
                textTransform="capitalize"
              >
                {StatisticsSummary[info].title}
              </MDTypography>
            </MDBox>
            {loading && (
              <MDBox lineHeight={1}>
                <LoadView loadingMessage="" size={24} />
              </MDBox>
            )}
            {error && (
              <MDBox lineHeight={1}>
                <ErrorView error={{ message: `${error}` }} />
              </MDBox>
            )}
            {data && renderStats()}
          </Grid>
          <Grid item xs={5}>
            {(!loading && !error) && (
              <MDBox width="100%" textAlign="right" lineHeight={1}>
                <MDTypography
                  variant="caption"
                  color="dark"
                  fontWeight="regular"
                  sx={{ cursor: "pointer" }}
                  onClick={openDropdown}
                >
                  {dropdownValue}
                </MDTypography>
                {renderMenu(dropdown, closeDropdown)}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

// Typechecking props for the DefaultStatisticsCard
DefaultStatisticsCard.propTypes = {
  query: PropTypes.oneOf([
    "getManziaBotSessions",
    "getManziaBotSessionsWithUtterances",
    "getManziaVisitorUtterances",
    "getManziaBotSessionsWithCallToAction",
    "getManziaEmailLeads"
  ]).isRequired,
  info: PropTypes.oneOf([
    "botSessions",
    "botEngagements",
    "visitorUtterances",
    "callToAction",
    "emailLeads"
  ]).isRequired,
};

export default DefaultStatisticsCard;
