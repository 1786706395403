
// import { useRef } from "react";

// Apollo
import { gql, useApolloClient } from '@apollo/client';

// const DEFAULT_ID = "20966bf1-2054-49ca-9328-7f43b8f8c65b";

const onboardFragment = `
  fragment OnboardUser on ManziaOnboard {
    emailAddress
    onboardState
    userId
    accountId
    webDomain
    organizationName
  }
`;

export default function useLocalOnboardState({ emailAddress }) {

    const client = useApolloClient();
    let onboardState = null;

    // Logged in user
    if (emailAddress) {
      onboardState = client.readFragment({
        id: `ManziaOnboard:{"emailAddress":"${emailAddress}"}`, // item's cache ID
        fragment: gql`${onboardFragment}`,
      });
    }

  return { onboardState };
}