/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import { useLocation, Navigate } from 'react-router-dom';

// GraphQL
import { gql, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// @mui material components
// import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Settings page components
import BaseLayout from "layouts/onboard/components/BaseLayout";

// Custom Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Manzia
// import UserRoles from "layouts/onboard/components/UserRoles";
import WelcomeView from "layouts/onboard/components/WelcomeView";
import { OnboardRole, OnboardState } from "appSettings";

// State Views
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";

function Onboard() {

  const { state: { userId } } = useLocation();
  // const navigate = useNavigate();
  const { chatbot: { id: trainedChatBotId } } = useLocalChatbotUser({ userId });
  const [onboardState, setOnBoardState] = useState(OnboardState.NO_PRODUCTS);
  const [userRole, setUserRole] = useState(OnboardRole.ACCOUNT_OWNER);

  const { loading, error, data } = useQuery(gql`${queries.manziaUserProductsByTrainedChatBotId}`, {
    variables: { trainedChatBotId }, fetchPolicy: 'cache-and-network',
  });

  const { loading: roleLoading, error: roleError, data: roleData } = useQuery(gql`${queries.getManziaUserRole}`, {
    variables: { userId }, fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!data) return;
    const { manziaUserProductsByTrainedChatBotId: { items = [] } } = data;
    if (items.length > 0) setOnBoardState(OnboardState.HAS_PRODUCT);
  }, [data]);

  useEffect(() => {
    if (!roleData) return;
    const { getManziaUserRole } = roleData;
    if (getManziaUserRole) {
      setUserRole(getManziaUserRole.roleId)
    }
  }, [roleData]);


  function renderTitle() {
    return (
      <MDBox my={2}>
          <MDTypography variant="h2" fontWeight="medium">
            Welcome to Manzia
          </MDTypography>
        </MDBox>
    );
  }

  if (loading || roleLoading) return (
    <BaseLayout>
      {renderTitle()}
      <LoadView />
    </BaseLayout>
  );

  if (error || roleError) return (
    <BaseLayout>
      {renderTitle()}
      <ErrorView error={{ message: `${error || roleError}` }} />
    </BaseLayout>
  );

  // /train/product-page
  function renderStartView() {
    if (onboardState === OnboardState.HAS_PRODUCT) {
      switch (userRole) {
        case OnboardRole.DEVELOPER:
          return <Navigate 
                  to="/settings/install-bot" 
                  replace 
                  state={{navItemPath: 'install-bot'}}
                />;
        case OnboardRole.TEAM_MEMBER:
          return <Navigate to="/products" replace />;
        case OnboardRole.ACCOUNT_OWNER:
          return <Navigate to="/products" replace />;
        default:
          return <WelcomeView 
                    onboardState={onboardState} 
                    userId={userId}
                  />;
      }
    } else {
      return  <WelcomeView 
                  onboardState={onboardState}
                  userId={userId} 
                />;
    }
  }

  return (
    <BaseLayout>
      <MDBox mt={4}>
        { renderStartView()}
      </MDBox>
    </BaseLayout>
  );
}

export default Onboard;
