/**
* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Roy Manzi

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import SignInIllustration from "layouts/authentication/sign-in/illustration";
import EditProductData from "layouts/train/edit-product";
import Onboard from "layouts/onboard";
import CreateBackend from "layouts/onboard/components/Backend";
import WelcomeView from "layouts/onboard/components/WelcomeView";
import NotAuthorized from "layouts/common/NotAuthorized";
import PricingPage from "layouts/pricing";
import Conversation from "layouts/common/Conversation";
import NotAvailable from "layouts/common/NotAvailable";
import SupportTeam from "layouts/common/SupportTeam";

// Roles
import { OnboardRole } from "appSettings";

const approutes = [
    // {
    //   name: "Sign In",
    //   key: "sign-in",
    //   type: "collapse",
    //   route: "/",
    //   component: <SignInIllustration />,
    //   roles: [ OnboardRole.PUBLIC ]
    // },
    {
        type: "collapse",
        name: "Edit Product",
        key: "editProduct",
        route: "/products/:productId",
        component: <EditProductData />,
        noCollapse: true,
        roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER ]
    },
    {
      type: "collapse",
      name: "Onboard",
      key: "onboard",
      route: "/onboard",
      component: <Onboard />,
      noCollapse: true,
      roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER ]
    },
    {
      type: "collapse",
      name: "Backend",
      key: "createBackend",
      route: "/onboard/backend",
      component: <CreateBackend />,
      noCollapse: true,
      roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER, OnboardRole.DEVELOPER ]
    },
    {
      type: "collapse",
      name: "Welcome",
      key: "onboardWelcome",
      route: "/onboard/welcome",
      component: <WelcomeView />,
      noCollapse: true,
      roles: [ OnboardRole.ACCOUNT_OWNER ]
    },
    {
      type: "collapse",
      name: "NotAuthorized",
      key: "notAuthorized",
      route: "/unauthorized",
      component: <NotAuthorized />,
      noCollapse: true,
      roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER, OnboardRole.DEVELOPER ]
    },
    {
      type: "collapse",
      name: "NotAvailable",
      key: "notAvailable",
      route: "/notavailable",
      component: <NotAvailable />,
      noCollapse: true,
      roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER, OnboardRole.DEVELOPER ]
    },
    {
      type: "collapse",
      name: "Pricing",
      key: "pricingPage",
      route: "/pricing",
      component: <PricingPage />,
      noCollapse: true,
      roles: [ OnboardRole.ACCOUNT_OWNER ]
    },
    {
      type: "collapse",
      name: "Conversation",
      key: "chatSession",
      route: "/session/:conversationId",
      component: <Conversation />,
      noCollapse: true,
      roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER ]
    },
    {
      type: "collapse",
      name: "SupportTeam",
      key: "supportTeam",
      route: "/support",
      component: <SupportTeam />,
      noCollapse: true,
      roles: [ OnboardRole.ACCOUNT_OWNER, OnboardRole.TEAM_MEMBER, OnboardRole.DEVELOPER ]
    },
];

export default approutes;