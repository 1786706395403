/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";


import PropTypes from "prop-types";

// react-router components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Manzia
import AccountDelete from "layouts/settings/components/AccountDelete";
import { ManziaAuthState } from "appSettings";
import useAuth from "customhooks/useAuth";
import LoadView from "layouts/common/LoadView";

// On Delete account, sign out user
function DeleteAccount({ userId }) {
  const navigate = useNavigate();

  // Sign Out
  const { loading: signoutLoading, value: signoutData, 
    command: authSignout } = useAuth(ManziaAuthState.signOut.key);

  useEffect(() => {
    if (!signoutData) return;
    navigate("/", { replace: true });
  }, [signoutData]);

  const onDeleted = () => {
    authSignout()();
  }

  return (
    <Card id="delete-account">
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5" color="dark" fontWeight="bold">Delete Account</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="dark" fontWeight="regular">
            Once you delete your account, there is no going back. Please be certain.
          </MDTypography>
        </MDBox>
        <MDBox p={3} ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            {signoutLoading ? 
            (<LoadView loadingMessage="Signing out..." />) : 
            (<AccountDelete 
              userId={userId}
              onDeleted={onDeleted} 
            />)}
        </MDBox>
      </MDBox>
    </Card>
  );
}

DeleteAccount.propTypes = {
  userId: PropTypes.string.isRequired
};

export default DeleteAccount;
