/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


function ActionCell({ actions, onSave, changedUserId, }) {
  
  const clickHandler = () => onSave(actions.userId);

  return (
      <MDBox mx={1} display="flex" alignItems="center" justifyContent="space-between">
        {actions.icons.map(({ icon, color, action }) => 
          (
            <Tooltip key={icon}  title={action}>
              <MDButton
                sx={{ marginRight: "2px" }} 
                variant="outlined" 
                color={color} 
                size="small" 
                disabled={changedUserId !== actions.userId}
                onClick={clickHandler}
              >
                {action}
              </MDButton>
            </Tooltip>
          ))}
      </MDBox>
  );
}

ActionCell.defaultProps = {
  changedUserId: ""
}

// Typechecking props for the StatusCell
ActionCell.propTypes = {
  actions: PropTypes.shape({
    icons: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.string,
      color: PropTypes.string,
      action: PropTypes.string,
    })).isRequired,
    userId: PropTypes.string.isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  changedUserId: PropTypes.string
};

export default ActionCell;
