/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateManziaChatbot = /* GraphQL */ `
  subscription OnCreateManziaChatbot(
    $filter: ModelSubscriptionManziaChatbotFilterInput
  ) {
    onCreateManziaChatbot(filter: $filter) {
      id
      accountId
      lexChatBotId
      chatbotName
      chatbotTone
      chatbotAliasId
      chatbotVersion
      testChatbotAliasId
      testChatbotVersion
      deployedVersion
      deploymentStatus
      deleteTime
      account {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotUserProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatbotDeployments {
        items {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatBotWebUI {
        id
        lexChatBotId
        webDomain
        loaderScriptUrl
        snippetUrl
        webAppBase
        webAppBucket
        chatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotProductTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateManziaChatbot = /* GraphQL */ `
  subscription OnUpdateManziaChatbot(
    $filter: ModelSubscriptionManziaChatbotFilterInput
  ) {
    onUpdateManziaChatbot(filter: $filter) {
      id
      accountId
      lexChatBotId
      chatbotName
      chatbotTone
      chatbotAliasId
      chatbotVersion
      testChatbotAliasId
      testChatbotVersion
      deployedVersion
      deploymentStatus
      deleteTime
      account {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotUserProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatbotDeployments {
        items {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatBotWebUI {
        id
        lexChatBotId
        webDomain
        loaderScriptUrl
        snippetUrl
        webAppBase
        webAppBucket
        chatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotProductTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteManziaChatbot = /* GraphQL */ `
  subscription OnDeleteManziaChatbot(
    $filter: ModelSubscriptionManziaChatbotFilterInput
  ) {
    onDeleteManziaChatbot(filter: $filter) {
      id
      accountId
      lexChatBotId
      chatbotName
      chatbotTone
      chatbotAliasId
      chatbotVersion
      testChatbotAliasId
      testChatbotVersion
      deployedVersion
      deploymentStatus
      deleteTime
      account {
        id
        v2chatBotId
        accountOwnerUserId
        accountOwnerEmail
        organizationName
        webDomain
        totalPaymentAmount
        totalConversations
        billRate
        currency
        accountType
        stripeSubscriptionStatus
        industryCode
        industryCategory
        lastBotDeploymentId
        deleteTime
        accountUsers {
          nextToken
          __typename
        }
        accountChatBot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotUserProducts {
        items {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatbotDeployments {
        items {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chatBotWebUI {
        id
        lexChatBotId
        webDomain
        loaderScriptUrl
        snippetUrl
        webAppBase
        webAppBucket
        chatbot {
          id
          accountId
          lexChatBotId
          chatbotName
          chatbotTone
          chatbotAliasId
          chatbotVersion
          testChatbotAliasId
          testChatbotVersion
          deployedVersion
          deploymentStatus
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chatbotProductTrainings {
        items {
          id
          userProductId
          trainingData
          trainedByUserId
          trainedChatBotId
          botDeploymentId
          lastModifiedAt
          deleteTime
          trainingStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateManziaChabotWebUI = /* GraphQL */ `
  subscription OnCreateManziaChabotWebUI(
    $filter: ModelSubscriptionManziaChabotWebUIFilterInput
  ) {
    onCreateManziaChabotWebUI(filter: $filter) {
      id
      lexChatBotId
      webDomain
      loaderScriptUrl
      snippetUrl
      webAppBase
      webAppBucket
      chatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateManziaChabotWebUI = /* GraphQL */ `
  subscription OnUpdateManziaChabotWebUI(
    $filter: ModelSubscriptionManziaChabotWebUIFilterInput
  ) {
    onUpdateManziaChabotWebUI(filter: $filter) {
      id
      lexChatBotId
      webDomain
      loaderScriptUrl
      snippetUrl
      webAppBase
      webAppBucket
      chatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteManziaChabotWebUI = /* GraphQL */ `
  subscription OnDeleteManziaChabotWebUI(
    $filter: ModelSubscriptionManziaChabotWebUIFilterInput
  ) {
    onDeleteManziaChabotWebUI(filter: $filter) {
      id
      lexChatBotId
      webDomain
      loaderScriptUrl
      snippetUrl
      webAppBase
      webAppBucket
      chatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateManziaAccount = /* GraphQL */ `
  subscription OnCreateManziaAccount(
    $filter: ModelSubscriptionManziaAccountFilterInput
  ) {
    onCreateManziaAccount(filter: $filter) {
      id
      v2chatBotId
      accountOwnerUserId
      accountOwnerEmail
      organizationName
      webDomain
      totalPaymentAmount
      totalConversations
      billRate
      currency
      accountType
      stripeSubscriptionStatus
      industryCode
      industryCategory
      lastBotDeploymentId
      deleteTime
      accountUsers {
        items {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      accountChatBot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateManziaAccount = /* GraphQL */ `
  subscription OnUpdateManziaAccount(
    $filter: ModelSubscriptionManziaAccountFilterInput
  ) {
    onUpdateManziaAccount(filter: $filter) {
      id
      v2chatBotId
      accountOwnerUserId
      accountOwnerEmail
      organizationName
      webDomain
      totalPaymentAmount
      totalConversations
      billRate
      currency
      accountType
      stripeSubscriptionStatus
      industryCode
      industryCategory
      lastBotDeploymentId
      deleteTime
      accountUsers {
        items {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      accountChatBot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteManziaAccount = /* GraphQL */ `
  subscription OnDeleteManziaAccount(
    $filter: ModelSubscriptionManziaAccountFilterInput
  ) {
    onDeleteManziaAccount(filter: $filter) {
      id
      v2chatBotId
      accountOwnerUserId
      accountOwnerEmail
      organizationName
      webDomain
      totalPaymentAmount
      totalConversations
      billRate
      currency
      accountType
      stripeSubscriptionStatus
      industryCode
      industryCategory
      lastBotDeploymentId
      deleteTime
      accountUsers {
        items {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      accountChatBot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateManziaBotDeployment = /* GraphQL */ `
  subscription OnCreateManziaBotDeployment(
    $filter: ModelSubscriptionManziaBotDeploymentFilterInput
  ) {
    onCreateManziaBotDeployment(filter: $filter) {
      id
      manziaChatbotId
      lastDeployedAt
      deployedByUserId
      productTrainingId
      productName
      deployedUsername
      userDeployMessage
      versionToDeploy
      status
      productTraining {
        id
        userProductId
        trainingData
        trainedByUserId
        trainedChatBotId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        trainedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        botDeployment {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      manziaChatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      deployedByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateManziaBotDeployment = /* GraphQL */ `
  subscription OnUpdateManziaBotDeployment(
    $filter: ModelSubscriptionManziaBotDeploymentFilterInput
  ) {
    onUpdateManziaBotDeployment(filter: $filter) {
      id
      manziaChatbotId
      lastDeployedAt
      deployedByUserId
      productTrainingId
      productName
      deployedUsername
      userDeployMessage
      versionToDeploy
      status
      productTraining {
        id
        userProductId
        trainingData
        trainedByUserId
        trainedChatBotId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        trainedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        botDeployment {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      manziaChatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      deployedByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteManziaBotDeployment = /* GraphQL */ `
  subscription OnDeleteManziaBotDeployment(
    $filter: ModelSubscriptionManziaBotDeploymentFilterInput
  ) {
    onDeleteManziaBotDeployment(filter: $filter) {
      id
      manziaChatbotId
      lastDeployedAt
      deployedByUserId
      productTrainingId
      productName
      deployedUsername
      userDeployMessage
      versionToDeploy
      status
      productTraining {
        id
        userProductId
        trainingData
        trainedByUserId
        trainedChatBotId
        botDeploymentId
        lastModifiedAt
        deleteTime
        trainingStatus
        userProduct {
          id
          productName
          productImage
          productType
          trainingStatus
          createdByUserId
          trainedChatBotId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        trainedByUser {
          id
          accountId
          emailAddress
          fullName
          isAccountOwner
          roleId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        botDeployment {
          id
          manziaChatbotId
          lastDeployedAt
          deployedByUserId
          productTrainingId
          productName
          deployedUsername
          userDeployMessage
          versionToDeploy
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      manziaChatbot {
        id
        accountId
        lexChatBotId
        chatbotName
        chatbotTone
        chatbotAliasId
        chatbotVersion
        testChatbotAliasId
        testChatbotVersion
        deployedVersion
        deploymentStatus
        deleteTime
        account {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        chatbotUserProducts {
          nextToken
          __typename
        }
        chatbotDeployments {
          nextToken
          __typename
        }
        chatBotWebUI {
          id
          lexChatBotId
          webDomain
          loaderScriptUrl
          snippetUrl
          webAppBase
          webAppBucket
          createdAt
          updatedAt
          __typename
        }
        chatbotProductTrainings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      deployedByUser {
        id
        accountId
        emailAddress
        fullName
        isAccountOwner
        roleId
        deleteTime
        userRole {
          userId
          roleId
          accountId
          roleName
          roleDescription
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userAccount {
          id
          v2chatBotId
          accountOwnerUserId
          accountOwnerEmail
          organizationName
          webDomain
          totalPaymentAmount
          totalConversations
          billRate
          currency
          accountType
          stripeSubscriptionStatus
          industryCode
          industryCategory
          lastBotDeploymentId
          deleteTime
          createdAt
          updatedAt
          __typename
        }
        userProducts {
          nextToken
          __typename
        }
        productTrainings {
          nextToken
          __typename
        }
        botDeployments {
          nextToken
          __typename
        }
        testConversations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateManziaProductPages = /* GraphQL */ `
  subscription OnCreateManziaProductPages(
    $filter: ModelSubscriptionManziaProductPagesFilterInput
  ) {
    onCreateManziaProductPages(filter: $filter) {
      pageURL
      accountId
      productName
      productDetails
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateManziaProductPages = /* GraphQL */ `
  subscription OnUpdateManziaProductPages(
    $filter: ModelSubscriptionManziaProductPagesFilterInput
  ) {
    onUpdateManziaProductPages(filter: $filter) {
      pageURL
      accountId
      productName
      productDetails
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteManziaProductPages = /* GraphQL */ `
  subscription OnDeleteManziaProductPages(
    $filter: ModelSubscriptionManziaProductPagesFilterInput
  ) {
    onDeleteManziaProductPages(filter: $filter) {
      pageURL
      accountId
      productName
      productDetails
      deleteTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
