/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDBadgeDot from "components/MDBadgeDot";
// import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Manzia
import StatisticsSummary from "layouts/analytics/components/StatisticsSummary";
import TopIntents from "layouts/analytics/components/TopIntents";
import EmailLeads from "layouts/analytics/components/Leads";

// Data
import horizontalBarChartData from "layouts/analytics/data/horizontalBarChartData";


function Analytics() {
  
  return (
    <MDBox py={3}>
        <MDBox mb={3}>
          <StatisticsSummary />
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TopIntents title="Top visitor objections" chart={horizontalBarChartData} />
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="bold">
                  Email Leads
                </MDTypography>
              </MDBox>
              <MDBox py={1} px={3}>
                <EmailLeads />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  );
}

export default Analytics;
