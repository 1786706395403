// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

// Manzia
import LinkURL from "layouts/common/LinkURL";


function NoDataView({ info, action }) {
    // Navigation
    const navigate = useNavigate();

    const handleClick = () => {
        if (action.state) {
            navigate(action.route, { state: action.state });
        } else {
            navigate(action.route);
        }
    }
    
    return (
        <Grid 
            container 
            justifyContent="center" 
            alignItems="center" 
            my={2}
            spacing={2}
            columns={{ xs: 12 }}
        >
            <Grid item xs="auto">
                <MDTypography variant="h5" fontWeight="bold" color="dark">
                  {info?.title ?? "No data found."}
                </MDTypography>
            </Grid>
            <Grid item xs={12}>
                <MDBox display="flex" justifyContent="center">
                    <MDTypography 
                        variant="body2" 
                        fontWeight="regular"
                        color="dark"
                    >
                    {info?.message ?? "Click below to go to Help section."}
                    </MDTypography>
                </MDBox>
            </Grid>
            <Grid item xs="auto">
                {action.route ? 
                    (<MDButton 
                        variant="gradient" 
                        color="info"
                        onClick={handleClick}
                    >
                        {action?.label ?? "Get Help"}
                    </MDButton>) : 
                    (<LinkURL 
                        url={action.href}
                        anchorText={action.label}
                        color="info"
                        fontWeight="medium"
                    />)
                }
            </Grid>
        </Grid>
    );
}

// typechecking props for UserInfo
NoDataView.defaultProps = {
    action: {
        label: "Get Help",
        route: null,
        state: null,
        href: "https://www.manzia.com/help"
    }
}

NoDataView.propTypes = {
    info: PropTypes.shape({
        title: PropTypes.string,
        message: PropTypes.string
    }).isRequired,
    action: PropTypes.shape({
        label: PropTypes.string,
        route: PropTypes.string,
        state: PropTypes.shape({}),
        href: PropTypes.string
    })
};

export default NoDataView