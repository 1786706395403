/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Manzia
import DefaultStatisticsCard from "layouts/analytics/components/DefaultStatisticsCard";

/**
 * yearMonth Results:  
 * [{
    years: 2023,
    months: 6,
    session_counts: 2,
    account_id: 'd78195ed-441c-406e-9af4-edd63d9bf275'
  }]
 */

function StatisticsSummary() {
  
  return (
    <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
            <DefaultStatisticsCard
                query="getManziaBotSessions"
                info="botSessions"
            />
        </Grid>
        <Grid item xs={12} sm={3}>
            <DefaultStatisticsCard
                query="getManziaBotSessionsWithUtterances"
                info="botEngagements"
            />
        </Grid>
        <Grid item xs={12} sm={3}>
            <DefaultStatisticsCard
                query="getManziaVisitorUtterances"
                info="visitorUtterances"
            />
        </Grid>
        <Grid item xs={12} sm={3}>
            <DefaultStatisticsCard
                query="getManziaEmailLeads"
                info="emailLeads"
            />
        </Grid>
    </Grid>
  );
}

export default StatisticsSummary;
