/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";

import PropTypes from "prop-types";

// GraphQL
import { gql, useMutation, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDBadge from "components/MDBadge";

// formik components
import { Formik, Form, ErrorMessage } from "formik";

// Hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";

// Auth
import { AuthUserContext } from "App";

// Manzia
import RadioButtons from "layouts/settings/components/RadioButtons";
import emailFilters from "layouts/settings/components/EmailConversation/emailFilters";
import form from "layouts/settings/schemas/form";
import DynamicFormField from "layouts/train/new-product/components/DynamicFormField";
import { conversationsValidations } from "layouts/settings/schemas/validations";
import { conversationsValues } from "layouts/settings/schemas/initialValues";

// State
import LoadView from "layouts/common/LoadView";
import ErrorView from "layouts/common/ErrorView";
import NotificationView from "layouts/common/Notification";

function ConversationSettingsData() {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const { account: { id: accountId } } = useLocalChatbotUser({ userId: USERID });

  // Fetch botsettings
  const { loading: settingLoading, error: settingError, data: settingData } = useQuery(gql`${queries.getManziaConversationSetting}`, {
    variables: { accountId }, fetchPolicy: 'cache-and-network',
  });

  if (settingLoading) {
    return <LoadView loadingMessage="Fetching settings..." />;
  }

  if (settingError) {
    // console.log("Setting error: ", settingError);
    return <NotificationView 
              color="error"
              message="Error fetching conversation settings. Try again later."
          />;
  }

  return <ConversationSettings settings={settingData?.getManziaConversationSetting} />;

}

/* eslint-disable no-empty */
function getInitialValues(conversationEmailFilters) {
  let initialValues = conversationsValues;
  try {
    initialValues = JSON.parse(conversationEmailFilters);
  } catch { }
  return initialValues;
}
/* eslint-enable no-empty */

function ConversationSettings({ settings }) {
  const { conversations: { formId, emailFilters: emailFilterField, conversationEmails } } = form;
  const { accountId, conversationEmailFilters  } = settings;
  const initialValues = getInitialValues(conversationEmailFilters);

  // Update conversation settings
  const [updateConversationSetting, { loading: convLoading, 
    error: convError, data: convData }] = useMutation(gql`${mutations.updateManziaConversationSetting}`);
  
  const handleSubmit = (values, actions) => {
    // console.log("Submitted: ", values);
    
    updateConversationSetting({
      variables: {
        input: {
          accountId,
          conversationEmailFilters: JSON.stringify({
            [emailFilterField.name]: values[emailFilterField.name],
            [conversationEmails.name]: values[conversationEmails.name]
          })
        }
      }
    });
    
    actions.setSubmitting(false);
    // actions.resetForm();
  };

  return (
    <Card id="conversation-settings" sx={{ overflow: "visible" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDTypography variant="h5" color="dark">Conversation Settings</MDTypography>
        {/* <MDBadge variant="contained" color="success" badgeContent="enabled" container /> */}
      </MDBox>
      <MDBox px={3}>
        {/* <PublishState /> */}
        <MDTypography variant="body2" color="dark" fontWeight="regular">
            Edit these settings to change how conversations are emailed to your team.
          </MDTypography>
          {convError && <ErrorView error={{ message: `${convError}`}}/>}
          {convData && 
            <NotificationView 
                color="success"
                message="Successfully updated conversation settings."
            />}
      </MDBox>
      <Divider />
      <Formik
        initialValues={initialValues}
          validationSchema={conversationsValidations}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {({ isSubmitting, isValid, values, errors, touched, setFieldValue }) => 
            (
              <Form id={formId} autoComplete="off">
                  <MDBox px={3}>
                    <MDBox my={2}>
                      {/* <MDTypography variant="h6" fontWeight="bold" color="dark">Filters</MDTypography> */}
                      <RadioButtons 
                        name={emailFilterField.name}
                        title="Filter the conversations emailed to my team:"
                        value={values[emailFilterField.name]}
                        handleChange={(option) => {
                          if (option[emailFilterField.name]) 
                            setFieldValue(emailFilterField.name, option[emailFilterField.name], false);
                        }}
                        radioList={emailFilters}
                      />
                      <MDBox mt={0.75}>
                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                          <ErrorMessage name={emailFilterField.name} />
                        </MDTypography>
                      </MDBox>
                      <Divider />
                    </MDBox>
                    <MDBox mt={1.625}>
                      <DynamicFormField
                          type={conversationEmails.type}
                          label={conversationEmails.label}
                          name={conversationEmails.name}
                          errors={errors}
                          touched={touched}
                      />
                    </MDBox>
                    <MDBox display="flex" justifyContent="flex-end" alignItems="center" mt={4} mb={2}>
                      {(isSubmitting || convLoading) && <LoadView loadingMessage="" />}
                      <MDBox ml="auto">
                        <MDButton 
                          variant="gradient" 
                          color="info"
                          type="submit"
                          disabled={isSubmitting || !isValid || convLoading || !!convError}
                        >
                          Update settings
                        </MDButton>
                      </MDBox>
                    </MDBox>
                </MDBox>
              </Form>
            )}
      </Formik>
    </Card>
  );
}

ConversationSettings.propTypes = {
  settings: PropTypes.shape({
    accountId: PropTypes.string.isRequired,
    conversationEmailFilters: PropTypes.string.isRequired
  }).isRequired
}

export default ConversationSettingsData;
