/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const getInvalidMsg = (fieldName) => `${fieldName} can only contain up to 50 alphanumeric, comma, dash, underscore, hyphen, whitespace characters.`;

const forms = {
  emailchat: {
    formId: "emailchat-form",
    fullName: {
      name: "fullName",
      label: "Your name",
      type: "text",
      placeholder: "e.g Jane Doe",
      errorMsg: "Your name is required.",
      invalidMsg: getInvalidMsg("Your name")
    },
    chatEmails: {
      name: "chatEmails",
      label: "Send to email(s)",
      type: "text",
      placeholder: "e.g abc@company.com, xyz@company.com",
      errorMsg: "Send to email(s) required.",
      invalidMsg: "Enter valid email address(es)"
    },
  },
}

export default forms;
