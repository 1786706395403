const invalidDomains = [
    'example.com', "xyz.com", "test.com", "testing.com"
];

export const allowedURLs = (url) => {
    let success;
    invalidDomains.forEach(domain => { success = success && url.indexOf(domain) < 0 });
    return success;
};


export default invalidDomains;