import { useState } from "react";

import PropTypes from "prop-types";

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Icon from '@mui/material/Icon';

// Material Dashboard
import MDButton from 'components/MDButton';

// Manzia
import EmailChat from "layouts/conversations/components/EmailChat";

function SendEmail({ conversation }) {
    // console.log("Conversation: ", conversation);

    const [open, setOpen] = useState(false);
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

  return (
    <>
    <MDButton 
        variant="outlined" 
        color="dark"
        aria-label="email conversation" 
        onClick={handleClickOpen}
    >
        <Icon>share</Icon>&nbsp;
        Share
    </MDButton>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle>Email the ManziaBot conversation.</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
            <EmailChat conversation={conversation} />
        </DialogContent>
      </Dialog>
    </>
  );
}

SendEmail.propTypes = {
  conversation: PropTypes.shape({
      sessionId: PropTypes.string.isRequired,
      userProduct: PropTypes.shape({
        id: PropTypes.string.isRequired,
        productName: PropTypes.string.isRequired
      }),
      accountId: PropTypes.string.isRequired
    }).isRequired,
};

export default SendEmail;