

const form = {
  formId: "new-product-form",
  formField: {
    productName: {
      name: "productName",
      label: "Product Name",
      type: "text",
      errorMsg: "Product name is required.",
      invalidMsg: "Invalid product name",
      illegalChars: "Only alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
    },
    productPage: {
      name: "productPage",
      label: "Product Page URL",
      type: "text",
      errorMsg: "Product page is required.",
      invalidMsg: "Product page URL is invalid.",
      placeholder: "https://www.example.com/product-page"
    },
    productFaq: {
      name: "productFaq",
      label: "Product FAQ URL",
      type: "text",
      invalidMsg: "Product FAQ URL is invalid.",
      placeholder: "https://www.example.com/faq"
    },
    demos: {
      demoType: {
        name: "demoType",
        label: "Call to action",
        type: "text",
        errorMsg: "Call to action is required.",
        invalidMsg: "Invalid call to action"
      },
      scheduleDemo: {
        name: "scheduleDemo",
        label: "Call to action URL",
        type: "text",
        errorMsg: "Call to action URL is required.",
        invalidMsg: "Invalid URL"
      },
      name: "demos",
      label: "Demos",
      type: "text",
    },
    pricing: {
      callToAction: {
        name: "callToAction",
        label: "Call To Action",
        type: "text",
        placeholder: "e.g Buy Now, Sign up today",
        errorMsg: "Call to action is required.",
        invalidMsg: "1 to 5 words with alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      },
      contactEmail: {
        name: "contactEmail",
        label: "Contact Email",
        type: "text",
        placeholder: "e.g sales@example.com",
        errorMsg: "Contact email is required.",
        invalidMsg: "Invalid contact email.",
      },
      price: {
        name: "price",
        label: "Product Price",
        type: "number",
        errorMsg: "Product price is required.",
      },
      pricingPage: {
        name: "pricingPage",
        label: "Pricing Page URL",
        type: "text",
        invalidMsg: "Invalid URL.",
      },
      priceRange: {
        low: {
          name: "low",
          label: "Low Price",
          type: "number",
          invalidMsg: "Low price must be less than high price",
        },
        high: {
          name: "high",
          label: "High Price",
          type: "number",
          invalidMsg: "High price must be less than low price",
        },
        name: "priceRange",
      },
      schedulePriceLink: {
        name: "priceLink",
        label: "Schedule Pricing Demo",
        type: "text",
        errorMsg: "Scheduling price link is required.",
        invalidMsg: "Invalid URL"
      },
      hasPriceDiscounts: {
        name: "priceDiscount",
        label: "Pricing Discounts",
        type: "text",
        errorMsg: "Pricing discount is required.",
      },
      name: "productPricing",
      label: "Pricing",
      type: "text",
    },
    productType: {
      name: "productType",
      label: "Product Type",
      type: "text",
      errorMsg: "Product type is required.",
      invalidMsg: "Product type is invalid",
    },
    productCategory: {
      name: "productCategory",
      label: "Product Category",
      type: "text",
      errorMsg: "Product category is required.",
      invalidMsg: "Product category is invalid",
    },
    currency: {
      name: "currency",
      label: "Currency",
      type: "text",
      errorMsg: "Currency is required.",
      invalidMsg: "Currency should have exactly 3 characters",
    },
    productDescription: {
      name: "productDescription",
      label: "Product Description",
      type: "text",
      errorMsg: "Product description is required.",
      invalidMsg: "At least 5 words with alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      placeholder: "Write a brief description...",
    },
    problems: {
      name: "problems",
      label: "Problems",
      type: "text",
      errorMsg: "Problem is required.",
      invalidMsg: "Enter one (1) and up to ten (10) problems"
    },
    jobsDone: {
      name: "jobsDone",
      label: "Jobs",
      type: "text",
      errorMsg: "Job is required.",
      invalidMsg: "Enter one (1) and up to ten (10) jobs/tasks"
    },
    features: {
      productFeature: {
        name: "productFeature",
        label: "Feature",
        type: "text",
        placeholder: "e.g automatic report generator",
        errorMsg: "Feature is required.",
        invalidMsg: "Only alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      },
      productBenefit: {
        name: "productBenefit",
        label: "Benefit",
        type: "text",
        placeholder: "avoid manually designing and running reports",
        errorMsg: "Benefit is required.",
        invalidMsg: "Only alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      },
      productAdvantage: {
        name: "productAdvantage",
        label: "Advantage",
        type: "text",
        placeholder: "spend more time on growing your business",
        errorMsg: "Advantage is required.",
        invalidMsg: "Only alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      },
      featureLink: {
        name: "featureLink",
        label: "Optional Feature URL",
        type: "text",
        placeholder: "https://www.example.com/featureA",
        invalidMsg: "Invalid feature URL"
      },
      name: "features",
      label: "Features",
      type: "text",
      errorMsg: "Features are required.",
      invalidMsg: "Enter one (1) and up to ten (10) features"
    },
    options: {
      optionName: {
        name: "optionName",
        label: "Product Name",
        type: "text",
        errorMsg: "Product name is required.",
      },
      optionPrice: {
        name: "optionPrice",
        label: "Product Price",
        type: "number",
        errorMsg: "Product price is required.",
      },
      optionCurrency: {
        name: "optionCurrency",
        label: "Currency",
        type: "text",
        errorMsg: "Currency is required.",
        invalidMsg: "Currency should have exactly 3 characters",
      },
      optionType: {
        name: "optionType",
        label: "Product Type",
        type: "text",
        errorMsg: "Product type is required.",
        invalidMsg: "Product type is invalid",
      },
      optionDescription: {
        name: "optionDescription",
        label: "Product Description",
        type: "text",
        errorMsg: "Product description is required.",
      },
      name: "options",
      label: "Product Options",
      type: "text",
    },
    pricingTypes: {
      name: "pricingTypes",
      label: "Pricing Types",
      type: "text",
      errorMsg: "Pricing type is required.",
      invalidMsg: "Please select at least one option"
    },
    insights: {
      companySize: {
        name: "companySize",
        label: "Company Size",
        type: "text",
        placeholder: "e.g medium sized SaaS businesses",
        errorMsg: "Company size is required.",
        invalidMsg: "Only alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      },
      industry: {
        name: "industry",
        label: "Industry",
        type: "text",
        placeholder: "Software and Technology",
        errorMsg: "Industry is required.",
        invalidMsg: "Only alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      },
      type: {
        name: "type",
        label: "Company Type",
        type: "text",
        placeholder: "B2B companies",
        errorMsg: "Company type is required.",
        invalidMsg: "Only alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      },
      jobTitle: {
        name: "jobTitle",
        label: "Job Title",
        type: "text",
        placeholder: "Marketing Managers",
        errorMsg: "Job title is required.",
        invalidMsg: "Only alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      },
      role: {
        name: "role",
        label: "Customer Role",
        type: "text",
        placeholder: "Lead Generation",
        errorMsg: "Customer role is required.",
        invalidMsg: "Only alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      },
      responsibility: {
        name: "responsibility",
        label: "Customer Responsibility",
        type: "text",
        placeholder: "Improving lead conversion",
        errorMsg: "Customer responsibility is required.",
        invalidMsg: "Only alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      },
      useCase: {
        name: "useCase",
        label: "Customer Use Case",
        type: "text",
        placeholder: "Qualifying leads, Engaging website visitors",
        errorMsg: "Customer use case is required.",
        invalidMsg: "Only alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      },
      name: "insights",
      label: "Ideal customer profiles",
      errorMsg: "Ideal customer profiles are required.",
      invalidMsg: "Enter up to two (2) customer profiles",
      illegalMsg: "At least 5 words with alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
    },
    competition: {
      name: "competitionSummary",
      label: "Competition Summary",
      type: "text",
      errorMsg: "Competition summary is required.",
      invalidMsg: "At least 5 words with alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
      placeholder: "Write a brief competition summary...",
    },
    offers: {
      name: "productOffers",
      label: "Product Offers",
      type: "text",
      errorMsg: "Offer is required.",
      invalidMsg: "Enter up to ten (10) offers"
    },
    testimonials: {
      name: "testimonials",
      label: "Testimonials URL",
      type: "text",
      errorMsg: "Testimonial url is required.",
      invalidMsg: "Testimonial url is not valid.",
      placeholder: "e.g https://wwww.example.com/testimonials#latest"
    },
    reviews: {
      name: "reviews",
      label: "Reviews URL",
      type: "text",
      errorMsg: "Reviews url is required.",
      invalidMsg: "Reviews url is not valid.",
      placeholder: "e.g https://wwww.example.com/reviews#latest"
    },
    caseStudies: {
      name: "caseStudies",
      label: "Case Studies URL",
      type: "text",
      errorMsg: "Case studies url is required.",
      invalidMsg: "Case studies url is not valid.",
      placeholder: "e.g https://wwww.example.com/case-studies#latest"
    },
    socialMedia: {
      name: "socialMedia",
      label: "Social media URL",
      type: "text",
      errorMsg: "Social media url is required.",
      invalidMsg: "Social media url is not valid.",
      placeholder: "e.g https://wwww.example.com/social-media#latest"
    },
    press: {
      name: "press",
      label: "Press URL",
      type: "text",
      errorMsg: "Press url is required.",
      invalidMsg: "Press url is not valid.",
      placeholder: "e.g https://wwww.example.com/press#latest"
    },
    bio: {
      name: "bio",
      label: "Bio",
    },
  },
};

export default form;
