/**
 * Sample utterances + conversation flow
 */

// Images
// import image1 from "assets/images/products/product-details-1.jpg";

const infoData = {
    gtmTagPublish: {
        title: { 
            text: "Publish the ManziaBot tag.", 
            image: null
        },
        description: {
            text: "Verify your tag setup. When the ManziaBot tag works as intended, publish it.",
            image: null,
            links: []
        },
        steps: [
            { text: "Open Google Tag Manager (GTM). Click Workspace and select your Web container.", image: null, links:[{ linkText: "Google Tag Manager", linkURL: "https://tagmanager.google.com/" }], bolds: ["Workspace", "Web"] },
            { text: "Click the Preview button (top right hand side of screen). Google Tag Assistant launches in a new tab.", image: null, bolds: ["Preview"] },
            { text: "Enter the website URL on which you installed the Tag Manager container. If there are any errors, start debugging with the Tag Assistant.", image: null, links:[{ linkText: "debugging with the Tag Assistant", linkURL: "https://support.google.com/tagassistant/answer/10039345" }] },
            { text: "Click Submit button (top right hand side of screen). The Submit Changes screen will appear, with options to publish the container and save a version of your container.", image: null, bolds: ["Submit", "Submit Changes"] },
            { text: "Select Publish and Create Version if it is not already selected.", image: null, bolds: ["Publish", "Create Version"] },
            { text: "Review the Workspace Changes section to see if your configuration appears as you expect.", image: null, bolds: ["Workspace Changes"] },
            { text: "Enter a Version Name and Version Description.", image: null, bolds: ["Version Name", "Version Description"] },
            { text: "Click Publish.", image: null, bolds: ["Publish"] },
        ],
        getStartedLink: { text: "Learn more", links: [{ linkText: "Learn more", linkURL: "https://developers.google.com/tag-platform/tag-manager/web" }] },
      },  
   gtmWebContainer: {
    title: { 
        text: "Add ManziaBot tag to GTM container.", 
        image: null
    },
    description: {
        text: "After installing the GTM container on your website, follow these steps below to add the ManziaBot tag.",
        image: null,
        links: []
    },
    steps: [
        { text: "Open Google Tag Manager (GTM). Click Workspace.", image: null, links:[{ linkText: "Google Tag Manager", linkURL: "https://tagmanager.google.com/" }], bolds: ["Workspace"] },
        { text: "In your Workspace, select your Web container and click Tags.", bolds: ["Tags", "Web"] },
        { text: "To add a tag, click New.", image: null, bolds: ["New"] },
        { text: "Click below Tag Configuration. Under Choose tag type, scroll down and select Custom HTML", image: null, bolds: ["Tag Configuration", "Custom HTML" ] },
        { text: "Copy and paste the code snippet below into the field labelled HTML", image: null },
        { text: "Scroll down and click below Triggering.", image: null, bolds: ["Triggering"] },
        { text: "Under Choose a trigger, select All Pages.", image: null, bolds: ["All Pages"] },
        { text: "At the top, give the tag a descriptive name and click Save.", bolds: ["Save"]},
    ],
    getStartedLink: { text: "Learn more", links: [{ linkText: "Learn more", linkURL: "https://developers.google.com/tag-platform/tag-manager/web" }] },
  },
  gtmCreateContainer: {
    title: { 
        text: "Create GTM container.", 
        image: null
    },
    description: {
        text: "Follow the steps below to create a Web GTM container.",
        image: null,
        links: []
    },
    steps: [
        { text: "Open Google Tag Manager (GTM). Click Workspace.", image: null, links:[{ linkText: "Google Tag Manager", linkURL: "https://tagmanager.google.com/" }], bolds: ["Workspace"] },
        { text: "In the Accounts tab, next to the account name, click More Actions and Select Create Container (Web)", image: null, bolds: ["Accounts", "More Actions", "Create Container"] },
        { text: "Enter a descriptive Container name and select Web for container type. To create your container, click Create and agree to the Terms of Service.", image: null, bolds: ["Create", "Web"] },
        { text: "Near the top of the window, find your container ID, formatted as GTM-XXXXXX.", image: null, bolds: ["GTM-XXXXXX"] },
        { text: "Click your container ID to launch the Install Tag Manager box.", image: null },
        { text: "Copy and paste the container ID below to send an email wth the code snippets to your developer.", image: null, bolds: ["code snippets"] },
    ],
    getStartedLink: { text: "Learn more", links: [{ linkText: "Learn more", linkURL: "https://developers.google.com/tag-platform/tag-manager/web" }] },
  },
  gtmCreateAccount: {
    title: { 
        text: "Setup and install Google Tag Manager (GTM).", 
        image: null
    },
    description: {
        text: "Follow the steps below to create a GTM account and web container.",
        image: null,
        links: []
    },
    steps: [
        { text: "Click and follow the instructions here - Setup and install GTM", image: null, links:[{ linkText: "Setup and install GTM", linkURL: "https://support.google.com/tagmanager/answer/6103696" }] },
        { text: "Near the top of the window, find your container ID, formatted as GTM-XXXXXX.", image: null, bolds: ["GTM-XXXXXX"] },
        { text: "Click your container ID to launch the Install Tag Manager box.", image: null },
        { text: "Copy and paste the container ID below to send an email with the code snippets to your developer.", image: null, bolds: ["code snippets"] },
    ],
    videoLink: "https://www.youtube.com/embed/P4suvDuj0kI",
  },
  gtmEmailDeveloper: {
    title: { 
        text: "Send email with GTM code snippets to developer.", 
        image: null
    },
    description: {
        text: "Enter the GTM container ID below to send an email with the GTM code snippets to your developer. You can also invite the developer to join your Team under Settings > Manage Team.",
        image: null,
        links: [],
        bolds: ["Settings", "Manage Team"]
    },
    steps: []
  },
};

export const gtmWebContainer = { ...infoData.gtmWebContainer };

export const gtmCreateContainer = { ...infoData.gtmCreateContainer };

export const gtmCreateAccount = { ...infoData.gtmCreateAccount };

export const gtmTagPublish = { ...infoData.gtmTagPublish };

export const gtmEmailDeveloper = { ...infoData.gtmEmailDeveloper };

export default infoData;