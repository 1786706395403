/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import InputAdornment from '@mui/material/InputAdornment';

// formik components
import { FieldArray } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// New product components
import FormField from "layouts/train/new-product/components/FormField";
import MDTypography from "components/MDTypography";
// import MDTypography from "components/MDTypography";

function DynamicFormField({ label, name, type, fieldPrefix, ...rest}) {
  return (
    <MDBox mb={1.5}>
      <FieldArray name={name}>
          {(fieldArrayProps) => {
                const { push, remove, form: { values } } = fieldArrayProps;
                const valueArray = values[name];

                return <MDBox>
                  {
                    valueArray.length > 0 && valueArray.map((_, index) =>
                    (
                      <Grid container spacing={2} key={`key${index+1}`}>
                        
                        <Grid item xs={8} sm={10}>
                          <FormField
                            type={type}
                            label={label}
                            name={`${name}[${index}]`}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">
                                <MDTypography variant="body2" color="text" fontWeight="regular">{fieldPrefix}</MDTypography></InputAdornment>,
                            }}
                            {...rest}
                          />
                        </Grid>
                        <Grid item xs={2} sm={1} sx={{ textAlign: "right" }}>
                          <MDButton 
                              variant="outlined" 
                              color="dark" 
                              disabled={index === 0 && valueArray.length === 1}
                              onClick={() => remove(index)}
                              size="medium"
                              iconOnly
                          >
                            <Icon>remove</Icon>
                          </MDButton>
                        </Grid>
                        <Grid item xs={2} sm={1} sx={{ textAlign: "left" }}>
                          {(index === valueArray.length - 1) && <MDButton 
                              variant="gradient" 
                              color="dark"
                              size="medium" 
                              onClick={() => push('')}
                              disabled={valueArray.length === 10}
                              iconOnly
                          >
                            <Icon>add</Icon>
                          </MDButton>}
                        </Grid>
                        
                      </Grid>
                      
                    ))
                  }
                </MDBox>
          }}
      </FieldArray>
    </MDBox>
  );
}

// typechecking props for FormField
// Setting default values for the props of MDInput
DynamicFormField.defaultProps = {
  fieldPrefix: "",
};

DynamicFormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fieldPrefix: PropTypes.string,
};

export default DynamicFormField;
