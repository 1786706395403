import { useState, useContext, useEffect, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router components
// import { useNavigate } from "react-router-dom";

// Material
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import Tooltip from "@mui/material/Tooltip";

// GraphQL
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import * as queries from 'graphql/queries';

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
import colors from "assets/theme/base/colors";

// Material Dashboard
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';
import MDSnackbar from "components/MDSnackbar";

// Auth
import { AuthUserContext } from "App";

// Custom hooks
import useLocalChatbotUser from "customhooks/useLocalChatbotUser";
import useLazyAxios from "customhooks/useLazyAxios";

// Manzia
import { ManziaStripeAPI, OnboardRole } from "appSettings";
import { formattedCurrency } from "helpers";
import InvoiceButton from "layouts/common/Invoice";

/**
 * 1- Fetch the subscription latest invoice
 * 2- Display past due amount
 * 3- On "Pay Balance", show invoice details in dialog and direct to email
 */
function PastDue({ onSubscriptionStatus }) {
  const authUser = useContext(AuthUserContext);
  const USERID = authUser?.sub;
  const userRoleRef = useRef(authUser ? authUser["custom:roleId"]: null);
  const { account: { id: accountId } } = useLocalChatbotUser({ userId: USERID });
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [amountDue, setAmountDue] = useState(0);
  const [invoiceStatus, setInvoiceStatus] = useState("");

  // Fetch stripe customer
  const { loading: custLoading, error: custError, data: custData } = useQuery(gql`${queries.getManziaStripeCheckout}`, {
    variables: { accountId }, fetchPolicy: 'cache-and-network',
  });

  // Stripe Checkout
  const { loading: invoiceLoading, error: invoiceError, value: invoiceData, 
    fetch: getInvoice } = useLazyAxios();

  // Fetch account
  const [getAccount, { loading: accountLoading, data: accountData, called: accountCalled }] = 
    useLazyQuery(gql`${queries.getManziaAccount}`, {
    variables: { id: accountId }, fetchPolicy: 'cache-and-network',
  });
  
  // const showInvoiceStatus = () => setInvoiceStatus("error");
  const hideInvoiceStatus = () => setInvoiceStatus("");

  useEffect(() => {
    if (!invoiceData) return;
    const { code, result } = invoiceData;
    if (code === 'Success') {
      setAmountDue(result.amount_due ?? 0);
      setInvoiceStatus("");
    }
  }, [invoiceData]);

  const handleFunds = () => {
    const invoice = custData?.getManziaStripeCheckout?.latestSubscriptionInvoice;
    
    const request = {
      method: ManziaStripeAPI.subscriptionInvoice.method, 
      url: `${ManziaStripeAPI.subscriptionInvoice.url}`,
      params: {
        invoice
      },
      timeout: 10000,
      maxRedirects: 5, 
    };

    getInvoice()(request);

  };

  const handleReload = () => {
    getAccount();
  }

  useEffect(() => {
    if (!accountData || !accountCalled || accountLoading) return;
    const status = accountData.getManziaAccount?.stripeSubscriptionStatus ?? "none";
    onSubscriptionStatus(status);
  }, [accountLoading, accountCalled])

  useEffect(() => {
    if (!custData) return;
    handleFunds();
  }, [custData]);

  useEffect(() => {
    const error = custError ?? invoiceError;
    if (!error) return;
    setInvoiceStatus("error");
  }, [custError, invoiceError]);

  

  const renderInvoiceError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Pay Balance"
      content="Error encountered while retrieving over due invoice. Try again later."
      dateTime=""
      open={invoiceStatus === "error"}
      onClose={hideInvoiceStatus}
      close={hideInvoiceStatus}
      bgWhite
    />
  );

  return (
      <>
        <MDBox mx={1}>
          <MDBadge
            variant="contained" 
            color="error" 
            badgeContent={`Past Due: ${formattedCurrency((amountDue / 100))}`} 
            container
            size="lg"
          />
        </MDBox>
        {(invoiceLoading || custLoading || accountLoading) ? 
          (<MDBox mx={1}>
            <CircularProgress
              size={24}
              sx={{
                color: darkMode ? colors.white.main : colors.info.main,
              }}
            />
            </MDBox>) : 
          (<MDBox mx={1}>
            <Tooltip title="Refresh past due amount" placement="top">
              <MDButton 
                color={darkMode ? "white" : "dark"}
                variant="outlined"
                onClick={handleReload}
                disabled={!custData}
                iconOnly
              >
                <Icon>refresh</Icon>
              </MDButton>
            </Tooltip>
          </MDBox>)
        }
        <MDBox mx={1}>
          {invoiceData?.code === 'Success' ? (
            <InvoiceButton
              invoice={invoiceData.result}
              disabled={invoiceLoading || custLoading || accountLoading || userRoleRef.current !== OnboardRole.ACCOUNT_OWNER}
            >
              <Icon>payment</Icon>&nbsp; Pay Balance
            </InvoiceButton>
            ) : (
            <MDButton 
              variant="gradient" 
              color="info"
              disabled
            >
              <Icon>payment</Icon>&nbsp; Pay Balance
            </MDButton>
            )}
        </MDBox>
        {renderInvoiceError}
      </>
  );
}

PastDue.propTypes = {
  onSubscriptionStatus: PropTypes.func.isRequired
};

export default PastDue;