/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from '@mui/material/Divider';

// formik components
import { FieldArray } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Theme
// import colors from "assets/theme/base/colors";

// New product components
import FormField from "layouts/train/new-product/components/FormField";

function DynamicFeatureField({ features }) {
  const { name, productFeature, productBenefit, productAdvantage, featureLink } = features;
  return (
    <MDBox mb={1.5}>
      <FieldArray name={name}>
          {(fieldArrayProps) => {
                const { push, remove, form: { values } } = fieldArrayProps;
                const valueArray = values[name];
                return <MDBox>
                  {
                    valueArray.length > 0 && valueArray.map((_, index) =>(
                      <MDBox key={`key${index+1}`} my={2}>
                        <MDBox my={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}>
                              <MDBox mt={-1.625}>
                                <FormField
                                  type={productFeature.type}
                                  label={productFeature.label}
                                  name={`${name}.${index}.${productFeature.name}`}
                                  placeholder={productFeature.placeholder}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      
                        <MDBox my={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}>
                              <MDBox mt={-1.625}>
                                <FormField
                                  type={productBenefit.type}
                                  label={productBenefit.label}
                                  name={`${name}.${index}.${productBenefit.name}`}
                                  placeholder={productBenefit.placeholder}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                        
                        <MDBox my={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}>
                              <MDBox mt={-1.625}>
                                <FormField
                                  type={productAdvantage.type}
                                  label={productAdvantage.label}
                                  name={`${name}.${index}.${productAdvantage.name}`}
                                  placeholder={productAdvantage.placeholder}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>

                        <MDBox my={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}>
                              <MDBox mt={-1.625}>
                                <FormField
                                  type={featureLink.type}
                                  label={featureLink.label}
                                  name={`${name}.${index}.${featureLink.name}`}
                                  placeholder={featureLink.placeholder}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>

                        <Grid container spacing={3}>
                          <Grid item xs={6} sx={{ textAlign: "right" }}>
                            <MDButton 
                                variant="outlined" 
                                color="dark"
                                size="medium"  
                                disabled={index === 0 && valueArray.length === 1}
                                onClick={() => remove(index)}
                                iconOnly
                            >
                              <Icon>remove</Icon>
                            </MDButton>
                          </Grid>
                          <Grid item xs={6} sx={{ textAlign: "left" }}>
                            {(index === valueArray.length - 1) && <MDButton 
                                variant="gradient" 
                                color="dark"
                                size="medium" 
                                onClick={() => push({
                                  productFeature: "",
                                  productBenefit: "",
                                  productAdvantage: ""
                                })}
                                disabled={valueArray.length === 10}
                                iconOnly
                            >
                              <Icon>add</Icon>
                            </MDButton>}
                          </Grid>
                        </Grid>
                        <MDBox my={2}>
                          <Divider />
                        </MDBox>
                      </MDBox>
                    ))
                  }
                </MDBox>
          }}
      </FieldArray>
      
    </MDBox>
  );
}

// typechecking props for FormField
DynamicFeatureField.propTypes = {
  features: PropTypes.shape({
    name: PropTypes.string,
    productFeature: PropTypes.objectOf(PropTypes.string),
    productBenefit: PropTypes.objectOf(PropTypes.string),
    productAdvantage: PropTypes.objectOf(PropTypes.string),
    featureLink: PropTypes.objectOf(PropTypes.string),
  }).isRequired
};

export default DynamicFeatureField;
