/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// NewUser page components
import DynamicFormField from "layouts/train/new-product/components/DynamicFormField";

// Chat
// import ChatUI from "layouts/train/new-product/components/Chat";
// import ProductIntent from "layouts/train/new-product/components/Intent";
// import ExpandAccordion from "layouts/train/new-product/components/Expand";

// // Intent Data
// import { fitInfo } from "layouts/train/new-product/components/Intent/intentdata";

/*
const [state, setState] = React.useState({});

  const handleFieldChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
*/

function FitInfo({ formData }) {
  const { formField, errors, touched, values } = formData;
  const { problems, productName } = formField;

  return (
    <MDBox>
      {/* <ExpandAccordion title="Sample utterances and conversation flow">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
              <ProductIntent 
                utterances={fitInfo.utterances}
                intentInfo={fitInfo.info} 
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChatUI chats={fitInfo.conversations} />
          </Grid>
        </Grid>
      </ExpandAccordion> */}
      

      <MDBox lineHeight={0} mt={2}>
        <MDTypography variant="h5">Product Fit</MDTypography>
        <MDTypography variant="body2" color="dark">
          {`${values[productName.name]} uses the problem statements below to qualify site visitors as well as respond to no need/interest objections.`} 
        </MDTypography>
      </MDBox>
      <MDBox lineHeight={0} mt={2}>
        <MDTypography variant="h6" fontWeight="medium">Problem Statement(s)</MDTypography>
        <MDTypography variant="body2" color="dark" fontWeight="medium">
          Example
        </MDTypography>
        <MDTypography variant="body2" color="dark">
          Netflix - Wander the aisles, fighting traffic and waiting in long lines just to get a single movie when you go to the video store. 
        </MDTypography>
        <MDBox my={2}>
          <MDTypography variant="body2" color="dark" fontWeight="medium">
            {`What problem(s) does ${values[productName.name]} solve?`} 
          </MDTypography>
        </MDBox>
        
      </MDBox>
      <MDBox mt={1.625}>

        <DynamicFormField
            type={problems.type}
            label={problems.label}
            name={problems.name}
            errors={errors}
            touched={touched}
        />

        {/* <MDBox lineHeight={0} mt={3}>
          <MDTypography variant="h6" fontWeight="medium">Job(s) To Be Done</MDTypography>
          <MDTypography variant="body2" color="text" fontWeight="medium">
            Examples
          </MDTypography>
          <MDTypography variant="body2" color="text">
            Manzia - Do you want to overcome sales objections from website visitors? 
          </MDTypography>
          <MDTypography variant="body2" color="text">
            Bosch Circular Saw - Do you want to cut a piece of wood in a straight line?
          </MDTypography>
          <MDBox my={2}>
            <MDTypography variant="body2" color="text" fontWeight="medium">
              {`What Job(s) or Task(s) do customers employ ${values[productName.name]} to accomplish?`} 
            </MDTypography>
          </MDBox>
        </MDBox>

        <DynamicFormField
            type={jobsDone.type}
            label={jobsDone.label}
            name={jobsDone.name}
            errors={errors}
            touched={touched}
        /> */}
        
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
FitInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default FitInfo;
