// react-router-dom components
import { Link } from "react-router-dom";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Manzia
import LinkURL from "layouts/common/LinkURL";
import MDButton from "components/MDButton";

function NotificationView({ color, message, link, route }) {
    return (
        <MDBox p={2}>
            <MDAlert color={color} dismissible>
                <MDTypography variant="button" color="white">
                    {message}{" "}
                    {link?.text?.length > 0 && 
                        (<LinkURL 
                            url={link.href} 
                            anchorText={link.text} 
                            variant="body2" 
                            fontWeight="medium" 
                            color="white" />)}
                </MDTypography>
            </MDAlert>
            {route?.text?.length > 0 && 
                <Link to={route.path} replace={route.replace === true}>
                    <MDBox mt={2} display="flex" justifyContent="center">
                        <MDButton
                            variant="outlined"
                            color="dark"
                        >
                            {route.text}
                        </MDButton>
                    </MDBox>
                </Link>}
        </MDBox>
    );
}

// Specifies the default values for props:
NotificationView.defaultProps = {
    link: {text: '', href: "#" },
    route: {text: '', path: "/products", replace: false },
  };
  

// typechecking props for UserInfo
NotificationView.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]).isRequired,
    message: PropTypes.string.isRequired,
    link: PropTypes.shape({
        text: PropTypes.string,
        href: PropTypes.string
    }),
    route: PropTypes.shape({
        text: PropTypes.string,
        path: PropTypes.string,
        replace: PropTypes.bool
    })
};

export default NotificationView;