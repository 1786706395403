/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from '@mui/material/Divider';

// formik components
import { FieldArray } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Theme
// import colors from "assets/theme/base/colors";

// New product components
import FormField from "layouts/train/new-product/components/FormField";

function DynamicInsightsField({ insights }) {
  const { name, companySize, industry, type, jobTitle, role, responsibility, useCase } = insights;
  return (
    <MDBox mb={1.5}>
      <FieldArray name={name}>
          {(fieldArrayProps) => {
                const { push, remove, form: { values } } = fieldArrayProps;
                const valueArray = values[name];
                return <MDBox>
                  {
                    valueArray.length > 0 && valueArray.map((_, index) =>(
                      <MDBox key={`key${index+1}`} my={2}>
                        <MDBox my={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}>
                              <MDBox mt={-1.625}>
                                <FormField
                                  type={companySize.type}
                                  label={companySize.label}
                                  name={`${name}.${index}.${companySize.name}`}
                                  placeholder={companySize.placeholder}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      
                        <MDBox my={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}>
                              <MDBox mt={-1.625}>
                                <FormField
                                  type={industry.type}
                                  label={industry.label}
                                  name={`${name}.${index}.${industry.name}`}
                                  placeholder={industry.placeholder}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                        
                        <MDBox my={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}>
                              <MDBox mt={-1.625}>
                                <FormField
                                  type={type.type}
                                  label={type.label}
                                  name={`${name}.${index}.${type.name}`}
                                  placeholder={type.placeholder}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>

                        <MDBox my={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}>
                              <MDBox mt={-1.625}>
                                <FormField
                                  type={jobTitle.type}
                                  label={jobTitle.label}
                                  name={`${name}.${index}.${jobTitle.name}`}
                                  placeholder={jobTitle.placeholder}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>

                        <MDBox my={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}>
                              <MDBox mt={-1.625}>
                                <FormField
                                  type={role.type}
                                  label={role.label}
                                  name={`${name}.${index}.${role.name}`}
                                  placeholder={role.placeholder}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>

                        <MDBox my={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}>
                              <MDBox mt={-1.625}>
                                <FormField
                                  type={responsibility.type}
                                  label={responsibility.label}
                                  name={`${name}.${index}.${responsibility.name}`}
                                  placeholder={responsibility.placeholder}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>

                        <MDBox my={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}>
                              <MDBox mt={-1.625}>
                                <FormField
                                  type={useCase.type}
                                  label={useCase.label}
                                  name={`${name}.${index}.${useCase.name}`}
                                  placeholder={useCase.placeholder}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>

                        <Grid container spacing={3}>
                          <Grid item xs={6} sx={{ textAlign: "right" }}>
                            <MDButton 
                                variant="outlined" 
                                color="dark"
                                size="medium"  
                                disabled={index === 0 && valueArray.length === 1}
                                onClick={() => remove(index)}
                                iconOnly
                            >
                              <Icon>remove</Icon>
                            </MDButton>
                          </Grid>
                          <Grid item xs={6} sx={{ textAlign: "left" }}>
                            {(index === valueArray.length - 1) && <MDButton 
                                variant="gradient" 
                                color="dark"
                                size="medium" 
                                onClick={() => push({
                                  productFeature: "",
                                  productBenefit: "",
                                  productAdvantage: ""
                                })}
                                disabled={valueArray.length === 2}
                                iconOnly
                            >
                              <Icon>add</Icon>
                            </MDButton>}
                          </Grid>
                        </Grid>
                        <MDBox my={2}>
                          <Divider />
                        </MDBox>
                      </MDBox>
                    ))
                  }
                </MDBox>
          }}
      </FieldArray>
      
    </MDBox>
  );
}

// typechecking props for FormField
DynamicInsightsField.propTypes = {
  insights: PropTypes.shape({
    name: PropTypes.string,
    companySize: PropTypes.objectOf(PropTypes.string),
    industry: PropTypes.objectOf(PropTypes.string),
    type: PropTypes.objectOf(PropTypes.string),
    jobTitle: PropTypes.objectOf(PropTypes.string),
    role: PropTypes.objectOf(PropTypes.string),
    responsibility: PropTypes.objectOf(PropTypes.string),
    useCase: PropTypes.objectOf(PropTypes.string),
  }).isRequired
};

export default DynamicInsightsField;
