import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Select
// import MenuItem from '@mui/material/MenuItem';

// Material Dashboard
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Manzia
import LinkURL from "layouts/common/LinkURL";
import { formattedCurrency, maskEmail } from "helpers";

function InvoiceButton({ children, invoice, ...rest }) {
    const { amount_due, account_name, currency, 
        customer_email, hosted_invoice_url } = invoice;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    function getDialogContent() {
        return (
            <DialogContent>
                <DialogContentText>
                    <MDTypography variant="button">
                        Pay off this over due amount to resume chatbot engagements with your website visitors.
                    </MDTypography>
                </DialogContentText>
                <MDBox my={2}>
                    <MDBox display="flex" my={2}>
                        <MDTypography variant="body2" fontWeight="bold">
                            Account name: &nbsp;
                        </MDTypography>
                        <MDTypography variant="body2" fontWeight="regular" color="text">
                            &nbsp;{account_name}
                        </MDTypography>
                    </MDBox>
                    <MDBox display="flex" my={2}>
                        <MDTypography variant="body2" fontWeight="bold">
                            Amount due: &nbsp;
                        </MDTypography>
                        <MDTypography variant="body2" fontWeight="regular" color="text">
                            &nbsp;{`${currency.toUpperCase()} ${(amount_due / 100).toFixed(2)}`}
                        </MDTypography>
                    </MDBox>
                    <MDBox display="flex" my={2}>
                        <MDTypography variant="body2" fontWeight="bold">
                            Invoice email: &nbsp;
                        </MDTypography>
                        <MDTypography variant="body2" fontWeight="regular" color="text">
                            &nbsp;{maskEmail(customer_email)}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </DialogContent>
        );
    }

    return (
        <>
            <MDButton 
                {...rest}
                variant="gradient" 
                color="info"
                onClick={handleClickOpen}
            >
            {children}
            </MDButton>
            <Dialog open={open} onClose={handleClose} fullWidth >
                <DialogTitle>Pay overdue balance:</DialogTitle>
                    {getDialogContent()}
                <DialogActions>
                    
                    <MDButton 
                        variant="outlined"
                        color="dark" 
                        onClick={handleClose}
                    >
                        Cancel
                    </MDButton>
                    <MDButton 
                        variant="gradient" 
                        color="info" 
                        disabled={!hosted_invoice_url}
                    >
                        <LinkURL 
                            anchorText={`Pay ${formattedCurrency(amount_due / 100)}`}
                            url={hosted_invoice_url}
                            color="white"
                            fontWeight="medium"
                        />
                    </MDButton>
                
                </DialogActions>
            </Dialog>
        </>
    );
}

// Typechecking props for BaseLayout
InvoiceButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]).isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    amount_due: PropTypes.number.isRequired,
    account_name: PropTypes.string.isRequired,
    customer_email: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    hosted_invoice_url: PropTypes.string.isRequired
  }).isRequired
};


export default InvoiceButton;