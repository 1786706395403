/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Manzia
import { ManziaBillingInfo } from "appSettings";

// utils
import moment from "moment";

/**
   * data:  
   * [
      {
        period_1h: '2023-06-21 19:00:00.000000000',
        session_counts: 2,
        account_id: 'd78195ed-441c-406e-9af4-edd63d9bf275'
      }
    ]
  */
/* eslint-disable */
function expectedLabels(period) {
  const labels = [];
  switch (period.interval) {
    case '7day':
      for (let i=0; i<7; i++) {
        labels.push(moment().subtract(i, 'days').format(period.format));
      }
      break;
    case '30day':
      for (let i=0; i<30; i++) {
        labels.push(moment().subtract(i, 'days').format(period.format));
      }
      break;
    case '180day':
      for (let i=0; i<6; i++) {
        labels.push(moment().subtract(i, 'months').format(period.format));
      }
      break;
    case '365day':
      for (let i=0; i<12; i++) {
        labels.push(moment().subtract(i, 'months').format(period.format));
      }
      break;
    default:
      for (let i=0; i<7; i++) {
        labels.push(moment().subtract(i, 'days').format(period.format));
      }
      break;
  }
  return labels.reverse();
}
/* eslint-enable */

function chartData(data, period) {
  const { groupBy, format } = period;
  const labels = expectedLabels(period);
  const labelSet = new Set(labels);
  const counts = Array.from(labels, () => 0);

  let jsonData = [];
  try {
    jsonData = JSON.parse(data.getManziaConversations);
    if (typeof jsonData === 'string') {
      jsonData = JSON.parse(jsonData);
    }
  } catch (_) {
    // console.log("Failed to parse chart data: ", error);
  }

  jsonData.forEach(item => {
    const timestamp = item[`period_${groupBy}`];
    const dataLabel = moment(timestamp).format(format);
    if (labelSet.has(dataLabel)) {
      const labelIdx = labels.indexOf(dataLabel);
      counts[labelIdx] = item.session_counts;
    }
  });

  return {
    labels,
    datasets: [
      {
        label: "Billed conversations",
        color: "info",
        data: counts,
      }
    ]
  };
}

export function getLineChartData(data, interval) {
  let period = ManziaBillingInfo.periods.find(elem => elem.interval === interval);
  period = period ?? ManziaBillingInfo.periods[0];
  return chartData(data, period);
}

const defaultLineChartData = {
  labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Facebook Ads",
      color: "info",
      data: [50, 100, 200, 190, 400, 350, 500, 450, 700],
    },
    {
      label: "Google Ads",
      color: "dark",
      data: [10, 30, 40, 120, 150, 220, 280, 250, 280],
    },
  ],
};

export default defaultLineChartData;
